<template>
  <OverallSettings v-bind="$props">
    <div class="c-two-image" v-if="fields" ref="rootEl">
      <div class="c-two-image__content" :class="{ reverse: fields.reverse.value }" ref="wrapperEl">
        <div class="c-two-image__large-group">
          <div class="c-two-image__media-container" :class="[`ratio-${$settingValue(fields.largeImageRatio, '4-3').replace(/-/, '_')}`, { interlace: fields.interlace.value }]" ref="largeImageEl">
            <adaptive-image
              class="c-two-image__media-container-image"
              :field="fields.largeAdaptiveImage"
              :full-field="fields.largeAdaptiveImageFull"
              :fallback-image="largeImage"
              :fallback-image-full="fields.largeImageFull"
              fullscreen-enabled
            />
          </div>
          <div class="c-two-image__des" v-if="fields.largeTitle.value">
            <div class="c-two-image__icon" :class="`__c-${fields.largeIconColor?.fields.phrase.value ?? 'black'}`" ref="largeIconEl">
              <AnimatedContent type="fade-in-top-left" ease="cubic-bezier(0.055, 0.215, 0.000, 1.000)">
                <Icon :svg="fields.largeIcon.value.svgCode" ref="largeIconEl" />
              </AnimatedContent>
            </div>
            <div class="c-two-image__title" ref="largeTitleEl">
              <AnimatedContent type="skew-in">
                <JssRichText :field="fields.largeTitle" />
              </AnimatedContent>
            </div>
            <div class="c-two-image__body" ref="largebodyEl">
              <AnimatedContent type="fade-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)">
                <JssRichText :field="fields.largeBody" />
              </AnimatedContent>
            </div>
            <div class="c-two-image__btns" v-if="fields.largeButton" ref="largeBtnEl">
              <AnimatedContent type="bottom-fade-in" :delay="index * 200">
                <SiteButton class="c-two-image__btn" v-bind="fields.largeButton" />
              </AnimatedContent>
            </div>
          </div>
        </div>
        <div class="c-two-image__small-group" :class="{ fix: !fields.interlace.value }">
          <div class="c-two-image__media-container" :class="`ratio-${$settingValue(fields.smallImageRatio, '1-1').replace(/-/, '_')}`" ref="smallImageEl">
            <adaptive-image
              class="c-two-image__media-container-image"
              :field="fields.smallAdaptiveImage"
              :full-field="fields.smallAdaptiveImageFull"
              :fallback-image="smallImage"
              :fallback-image-full="fields.smallImageFull"
              fullscreen-enabled
            />
          </div>
          <div class="c-two-image__des" ref="desEl">
            <div class="c-two-image__icon" :class="`__c-${fields.smallIconColor?.fields.phrase.value ?? 'black'}`" ref="smallIconEl">
              <AnimatedContent type="fade-in-top-left" ease="cubic-bezier(0.055, 0.215, 0.000, 1.000)">
                <Icon :svg="fields.smallIcon.value.svgCode" ref="smallIconEl" />
              </AnimatedContent>
            </div>
            <div class="c-two-image__title">
              <AnimatedContent type="skew-in">
                <JssRichText :field="fields.smallTitle" />
              </AnimatedContent>
            </div>
            <div class="c-two-image__body">
              <AnimatedContent type="fade-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)">
                <JssRichText :field="fields.smallBody" />
              </AnimatedContent>
            </div>
            <div class="c-two-image__btns" v-if="fields.smallButton" ref="smallBtnEl">
              <AnimatedContent type="bottom-fade-in" :delay="index * 200">
                <SiteButton class="c-two-image__btn" v-bind="fields.smallButton" />
              </AnimatedContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from 'vue';
import { loadScrollMagic } from '@/utils/site-utils';
import { largeThanTablet } from '@/utils/dom-utils';
import gsap from 'gsap';
export default {
  name: 'TwoImage',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let ScrollMagic, controller, scene, tl;
    const state = reactive({
      rootEl: null,
      wrapperEl: null,
      largeImageEl: null,
      largeTextEl: null,
      largeBtnEl: null,
      smallImageEl: null,
      smallTextEl: null,
      smallBtnEl: null,
      desEl: null
    });

    onMounted(() => {
      if (!state.smallImageEl || !state.largeImageEl) return;
      ScrollMagic = loadScrollMagic();
      controller = new ScrollMagic.Controller();
      tl = gsap.timeline();
      const smallImageOffset = state.smallImageEl.getBoundingClientRect();
      const largeImageOffset = state.largeImageEl.getBoundingClientRect();
      const wrapperOffset = state.wrapperEl.getBoundingClientRect();
      const desOffset = state.desEl.getBoundingClientRect();
      if (largeThanTablet()) {
        if (props.fields.interlace?.value) {
          tl.to(state.largeImageEl, { y: -(largeImageOffset.top + largeImageOffset.height - desOffset.top - desOffset.height - 60) }, 'first')
            .to(state.smallImageEl, { y: -(largeImageOffset.top + largeImageOffset.height - desOffset.top - desOffset.height - 60) }, 'first')
            .to(state.wrapperEl, { height: wrapperOffset.height - (largeImageOffset.top + largeImageOffset.height - desOffset.top - desOffset.height - 60) }, 'first');
          scene = new ScrollMagic.Scene({
            triggerElement: state.wrapperEl,
            triggerHook: 0,
            offset: smallImageOffset.height / 2,
            duration: '50%'
          })
            .setTween(tl)
            .setPin(state.wrapperEl)
            .addTo(controller);
        } else {
          scene = new ScrollMagic.Scene({
            triggerElement: state.wrapperEl,
            triggerHook: 0,
            offset: 0,
            duration: '50%'
          }).addTo(controller);
        }
      } else {
        tl.to(state.desEl, { y: 120 });
        scene = new ScrollMagic.Scene({
          triggerElement: state.wrapperEl,
          triggerHook: 0,
          offset: 0,
          duration: 240
        })
          .setTween(tl)
          .addTo(controller);
      }
    });
    onUnmounted(() => {
      scene.destroy(true);
      controller.destroy(true);
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/color.scss';
@import '../styles/rte/fontSize.scss';
.c-two-image {
  &__content {
    @include grid-container;
  }
  &__icon {
    margin-top: $space-40;
    margin-left: -16px;
  }
  &__title {
    margin-top: $space-8;
    margin-bottom: $space-10;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
  }
  &__body {
    margin-top: $space-16;
  }
  &__des {
    margin-left: $space-20;
    margin-bottom: $space-140;
  }
  &__large-group {
    @include grid-block(2, 10, 2);
    &-text {
      margin-top: $space-24;
      margin-bottom: $space-24;
    }
  }
  &__small-group {
    @include grid-block(2, 10, 1);
  }
  &__media-container {
    &.ratio-1_1 {
      aspect-ratio: 1/1;
    }
    &.ratio-3_2 {
      aspect-ratio: 3/2;
    }
    &.ratio-3_4 {
      aspect-ratio: 3/4;
    }
    &.ratio-4_3 {
      aspect-ratio: 4/3;
    }
    &.ratio-16_9 {
      aspect-ratio: 16/9;
    }
    &-image {
      width: 100%;
    }
    > div {
      height: 100%;
    }
  }
  &__btns {
    margin-bottom: $space-60;
    margin-top: 24px;
  }

  @include tablet-landscape {
    &__content {
      &.reverse {
        flex-direction: row-reverse;
      }
    }
    &__large-group {
      @include grid-block(3, 11, 1);
      .interlace {
        margin-top: $space-300;
      }
    }
    &__small-group {
      @include grid-block(16, 7, 1);
      &.fix {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .c-two-image__des {
          margin-bottom: 60px;
        }
      }
    }
    &__des {
      margin-left: 0;
      margin-bottom: 0;
      padding: 0 grid-width(1);
    }
    &__icon {
      margin-top: $space-100;
    }
    &__body {
      margin-top: $space-24;
    }
  }
}
</style>
