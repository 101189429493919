<template>
  <overall-settings v-bind="$props" class="c-contentBlockImage-overOut">
    <div class="c-contentBlockImage-video" ref="contentBLockImage">
      <section ref="contentBLockImage_grid" class="c-contentBlockImage__grid--image" :style="pageStyle">
        <div class="media-content--spPin" ref="spPin">
          <adaptive-media class="c-contentBlockImage__media--spPin" :field="fields.media" />
        </div>
      </section>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, ref, watch, nextTick } from 'vue';
import { isDesktop, isTabletLandscape, isDesktopLarge } from '@/utils/dom-utils';

import { loadScrollMagic } from '@/utils/site-utils';
import gsap from 'gsap';

import _ from 'lodash';

/**
 * @typedef ContentBlockImageFields
 * @property {GlobalSettingEntry} theme
 * */
export default {
  name: 'SimpleVideo',
  props: {
    /**@type SimpleVideoFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    let ScrollMagic, controller, scene, tl;

    const state = reactive({
      addStatus: 0,
      contentBLockImage: null,
      contentBLockImage_grid: null,
      spPin: null,
      pageStyle: ''
    });

    const methods = {
      isDesktop: isDesktop,
      isDesktopLarge: isDesktopLarge,
      isTabletLandscape: isTabletLandscape,
      resizePageSet: _.debounce(async (e) => {
        controller.updateScene(scene, true);
      }, 300),
      pageInit() {
        let windowWidth = state.contentBLockImage.parentElement.clientWidth,
          windowHeight = state.contentBLockImage.parentElement.clientHeight;

        let gridWidth = state.contentBLockImage_grid.clientWidth,
          gridHeight = state.contentBLockImage_grid.clientHeight;

        let distancePercent = windowWidth / gridWidth,
          distancePercent2 = windowHeight / gridHeight;

        controller = new ScrollMagic.Controller();

        scene = new ScrollMagic.Scene({
          triggerElement: state.contentBLockImage,
          offset: 0,
          triggerHook: 0,
          duration: state.contentBLockImage.clientHeight
        })
          .setPin(state.contentBLockImage)
          .addTo(controller);

        scene.on('progress', (e) => {
          if (e.progress * 100 <= 1) {
            state.spPin.style['transform'] = 'scale(1)';
            //state.contentBLockImage.style['transform'] = 'scale(1)';
          }
          if (e.progress * 100 > 1 && e.progress * 100 < 100) {
            state.spPin.style['transform'] = 'scale(' + distancePercent + ')';
            //state.contentBLockImage.style['transform'] = 'scale(1,' + distancePercent2 + ')';
          }
        });
      }
    };

    onMounted(() => {
      if (props.fields && props.fields.grid && props.fields.grid.fields.phrase.value) {
        state.pageStyle =
          !isDesktop() && !isDesktopLarge() && !isTabletLandscape()
            ? 'grid-area: auto / ' +
              (parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) + 1) +
              ' / span ' +
              (parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) + 1) +
              ' / span ' +
              (12 - parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) * 2) +
              ';'
            : 'grid-area: auto / ' +
              (parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) + 1) +
              ' / span ' +
              (parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) + 1) +
              ' / span ' +
              parseInt(props.fields.grid.fields.phrase.value.split('-')[1]) +
              ';';
      }

      nextTick(() => {
        ScrollMagic = loadScrollMagic();

        tl = gsap.timeline();
        methods.pageInit();
        window.addEventListener('resize', methods.resizePageSet);
      });
    });

    onUnmounted(() => {
      window.removeEventListener('resize', methods.resizePageSet);

      if (controller && scene) {
        controller.destroy();
        scene.destroy();
      }
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-contentBlockImage-overOut {
  overflow: hidden;
}
.c-contentBlockImage-video {
  position: relative;
  width: 100%;
  height: 100vh;
  @include grid-container;

  .c-contentBlockImage__grid--image {
    position: relative;
    width: 100%;
    height: 100%;
    @include grid-block(1, 12);
  }

  .media-content--spPin {
    width: 100%;
    height: 100%;
    transform: scale(1);
    transform-origin: center center;

    transition: transform 0.6s ease;
  }
  .c-contentBlockImage__media--spPin {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}

@include tablet-landscape {
  .c-contentBlockImage-video {
    .c-contentBlockImage__grid--image {
      @include grid-block(1, 24);
    }
  }
}
</style>
