<template>
  <overall-settings v-bind="$props">
    <div class="c-uk-cards" :class="rootClasses">
      <div class="c-uk-cards_column">
        <placeholder class="c-uk-cards__placeholder" :rendering="rendering" name="uk-cards-01" />
      </div>
      <div class="c-uk-cards_column">
        <placeholder class="c-uk-cards__placeholder" :rendering="rendering" name="uk-cards-02" />
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { computed } from 'vue';
import { settingValue, themeClass } from '@/utils/site-utils';

export default {
  name: 'UkCards',
  props: {
    rendering: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      rootClasses: computed(() => {
        const { align } = props.fields;
        const alignVal = settingValue(align);
        return [
          themeClass(props),
          {
            [`align-${alignVal}`]: alignVal
          }
        ];
      })
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-uk-cards {
  @include component-themes;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  &_column {
    & + & {
      margin-top: 24px;
    }
    > div {
      &:empty {
        height: 100px;
      }
    }
  }
  &.align {
    &-left {
      align-items: flex-start;
    }
    &-center {
      align-items: center;
    }
    &-right {
      align-items: flex-end;
    }
  }
  @include tablet-landscape {
    flex-direction: row;
    padding: 40px grid-width(2);
    &_column {
      width: grid-width(9);
      & + & {
        margin-left: grid-width(2);
        margin-top: 0;
      }
    }
  }
}
</style>
