<script setup>
import { computed, inject, onMounted, ref } from 'vue';
import { sitecoreComponentProps } from '@/utils/constants';
import { getQueryStrings } from '@/utils/uri-utils';
import api from '@/api';
import services from '@/services';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { getCarSeries } from '@/services/siteService';
import { toFistLetterUpper } from '@/utils/string-utils';
import { qtUtils } from '@/utils/ali-tracker-utils';

const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
const loading = inject('loading');
const toast = inject('toast');
const detail = ref(null);
const orderStatus = ref(null);
const carSeries = ref(null);
const country = ref(null);
const billingCountry = ref(null);
const store = ref(null);
const mainRef = ref();
const fData = computed(() => ({
  name: toFistLetterUpper(detail.value?.buyerName),
  model: toFistLetterUpper(detail.value?.carCode),
  email: detail.value?.email
}));
const loadOrderDetail = async () => {
  await loading.show();
  const [id] = getQueryStrings('orderId');
  const [res, ex] = await api.order.detail(null, {
    id
  });
  if (ex) {
    await toast.showEx(ex);
    return;
  }
  detail.value = res;
  carSeries.value = await getCarSeries(res.carCode);
  console.log('carSeries.value', carSeries.value);
  country.value = await services.site.getCountry((x) => x.alpha2Code === res.detailAddress?.country);
  billingCountry.value = await services.site.getCountry((x) => x.alpha2Code === res.billingAddress?.country);
  if (!isNullOrEmpty(res.storeId)) {
    console.log('res.storeId', res.storeId);
    [store.value] = await api.store.detail({
      storeId: res.storeId
    });
  }
  orderStatus.value = await services.order.getOrderStatus(res?.salesOrderInfo?.orderStatus);
  qtUtils.trackScPv(props.page, {
    order_number: res?.salesOrderInfo?.orderId,
    model_selection: res?.vehicleInfo?.basicInfo?.model,
    car_package_info: res?.vehicleInfo?.basicInfo?.infoJson,
    order_stage: orderStatus.value?.name
  });
  await loading.hide();
};
const onMore = () => {
  mainRef.value?.scrollIntoView();
};
onMounted(() => {
  loadOrderDetail();
});
</script>

<template>
  <div class="c-reservation-v2-detail" v-if="fields && detail">
    <div class="c-reservation-v2-detail__banner">
      <background-image
        class="c-reservation-v2-detail__banner-image"
        :is-background="false"
        :image="$adaptiveValueField(carSeries?.reservationImage, carSeries?.reservationMobileImage, null, null, (x) => x?.src)"
      />
      <div class="c-reservation-v2-detail__banner-info">
        <div class="c-reservation-v2-detail__overline mg-b-8 tl-mg-b-0" v-html="$formatString(fields.overline?.value, fData).toUpperCase()" v-if="!$isNullOrEmpty(fields.overline?.value)" />
        <div class="c-reservation-v2-detail__title mg-b-32" v-html="$formatString(fields.title?.value, fData).toUpperCase()" v-if="!$isNullOrEmpty(fields.title?.value)" />
        <div class="c-reservation-v2-detail__body mg-b-32" v-html="$formatString(fields.body?.value, fData)" v-if="!$isNullOrEmpty(fields.body?.value)" />
        <site-button class="c-reservation-v2-detail__btn" v-bind="fields.jumpButton" />
        <div class="c-reservation-v2-detail__slide">
          <jss-rich-text class="c-reservation-v2-detail__slide-text" :field="fields.slideScreenText" @click="onMore" tag="div" />
          <icon class="c-reservation-v2-detail__slide-icon" :field="fields.slideScreenIcon" @click="onMore" />
        </div>
      </div>
    </div>
    <div class="c-reservation-v2-detail__main" ref="mainRef">
      <jss-rich-text class="c-reservation-v2-detail__steps-title fs-32 mg-t-56 mg-b-96" :field="fields.stepsTitle" />
      <div class="c-reservation-v2-detail__steps">
        <template v-for="(step, i) in fields.steps" :key="i">
          <div class="c-reservation-v2-detail__step">
            <div class="c-reservation-v2-detail__step-index fs-56">{{ (i + 1).toString().padStart(2, '0') }}</div>
            <div class="c-reservation-v2-detail__step-title mg-b-8" v-html="$formatString(step.fields.title?.value, fData)" v-if="step.fields.title?.value" />
            <div class="c-reservation-v2-detail__step-subtitle" v-html="$formatString(step.fields.subtitle?.value, fData)" v-if="step.fields.subtitle?.value" />
          </div>
        </template>
      </div>
      <div class="c-reservation-v2-detail__section">
        <div class="c-reservation-v2-detail__section-title" v-html="$t('Reservation details')" />
        <div class="c-reservation-v2-detail__section-content">
          <div class="c-reservation-v2-detail__section-grid">
            <div class="c-reservation-v2-detail__kv">
              <label>{{ $t('Reservation number') }}</label>
              <div>{{ detail.orderNo }}</div>
            </div>
            <div class="c-reservation-v2-detail__kv">
              <label>{{ $t('Reservation date') }}</label>
              <div>{{ $formatDate(detail.createTime) }}</div>
            </div>
            <div class="c-reservation-v2-detail__kv">
              <label>{{ $t('Lotus id') }}</label>
              <div>{{ detail.lotusId }}</div>
            </div>
            <div class="c-reservation-v2-detail__kv">
              <label>{{ $t('Model') }}</label>
              <div>
                {{ detail.carCode }}
              </div>
            </div>
            <div class="c-reservation-v2-detail__kv">
              <label>{{ $t('Name') }}</label>
              <div>{{ detail.buyerName }}</div>
            </div>
            <div class="c-reservation-v2-detail__kv">
              <label>{{ $t('Phone number') }}</label>
              <div>+{{ detail.areaCode }}{{ detail.buyerPhone }}</div>
            </div>
            <div class="c-reservation-v2-detail__kv">
              <label>{{ $t('Address') }}</label>
              <div>{{ $buildAddress(detail.detailAddress.street, detail.detailAddress.zipCode, detail.detailAddress.city, country?.countryName) }}</div>
            </div>
            <div class="c-reservation-v2-detail__kv">
              <label>{{ $t('Billing address') }}</label>
              <div>
                {{ $buildAddress(detail.billingAddress.street, detail.billingAddress.zipCode, detail.billingAddress.city, billingCountry?.countryName) }}
              </div>
            </div>
          </div>
          <div class="c-reservation-v2-detail__dealer">
            <jss-rich-text class="mg-b-8 fs-14 fw-700" :field="fields.dealerTitle" />
            <dealer-card-v1 :dealer="store" :selectable="false" theme="dark" />
          </div>
        </div>
      </div>
      <div class="c-reservation-v2-detail__section">
        <div class="c-reservation-v2-detail__section-title" v-html="$t('Purchase summary')" />
        <div class="c-reservation-v2-detail__section-content">
          <div class="c-reservation-v2-detail__purchase">
            <label-value :label="$t('Reservation fee')" :value="$formatMoney(detail.paymentAmountNumber, detail.currency)" />
            <jss-rich-text class="c-reservation-v2-detail__purchase-desc" :field="fields.purchaseDescription" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "sass:math";
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-reservation-v2-detail {
  $c: &;
  background: $black;
  color: $white;
  &__banner {
    .e-background-image {
      &__img {
        position: relative;
        img {
          display: unset;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, $black, transparent);
        }
      }
    }
    &-info {
      position: relative;
      z-index: 1;
      padding: 60px 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background: linear-gradient(to top, $black, transparent);
      margin-top: -40vw;
    }
  }
  &__btn {
    width: 100%;
    margin-bottom: 32px;
  }
  &__title {
    font-size: 32px;
  }
  &__slide {
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 36px;
    &-icon {
      svg {
        path {
          fill: $white !important;
        }
      }
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 24px 24px 24px;
  }
  &__steps {
    display: flex;
    flex-direction: column;
    gap: 64px;
    margin-bottom: 112px;
    &-title {
      padding-bottom: 24px;
      border-bottom: 1px solid $grey-dark;
    }
  }
  &__step {
    &-index {
      color: $yellow;
      font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
    }
    &-subtitle {
      opacity: 0.7;
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &-title {
      display: block;
      text-transform: uppercase;
      font-size: 26px;
    }
    &-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 48px;
      column-gap: 24px;
    }
    &-content {
      width: 100%;
    }
    + #{$c}__section {
      margin-top: 8px;
      padding-top: 40px;
      border-top: 1px solid $grey-dark;
    }
  }
  &__kv {
    > label {
      display: block;
      text-transform: uppercase;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: bold;
    }
    > div {
      opacity: 0.7;
    }
  }
  &__dealer {
    margin-top: 48px;
  }
  &__purchase {
    border: 1px solid $grey-night;
    padding: 16px;
    .e-label-value {
      border-bottom: 1px solid $grey-night;
      margin-bottom: 16px;
    }
    &-desc {
      opacity: 0.7;
    }
  }
  @include tablet-landscape {
    &__main {
      padding: 60px grid-width(3);
    }

    &__banner {
      position: relative;
      min-height: #{math.div(100 * 9, 16)}vw;
      &-info {
        position: absolute;
        left: 0;
        bottom: -1px;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 60px grid-width(2);
        margin-top: 0;
      }
    }
    &__btn {
      width: fit-content;
      margin-bottom: 0;
    }
    &__slide {
      display: flex;
      gap: 12px;
      margin-top: 200px;
      justify-content: flex-start;
      > div {
        cursor: pointer;
      }
      &-icon {
        cursor: pointer;
        svg {
          path {
            fill: $white !important;
          }
        }
      }
    }
    &__title {
      font-size: 56px;
    }
    &__steps {
      flex-direction: row;
    }
    &__step {
      width: calc((100% - 128px) / 3);
    }
    &__section {
      flex-direction: row;
      gap: 0;
      &-title {
        flex-shrink: 0;
        width: grid-width(7);
        font-size: 32px;
      }
      &-content {
        flex-grow: 1;
        width: fit-content;
      }
    }
  }
}
html[lang='ja-JP'] {
  .c-reservation-v2-detail {
    &__step {
      &-index {
        font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
      }
    }
  }
}
html[lang='ko-KR'] {
  .c-reservation-v2-detail {
    &__step {
      &-index {
        font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
      }
    }
  }
}
</style>
