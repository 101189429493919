<template>
  <OverallSettings v-bind="$props">
    <div class="c-store-locator-landing" v-if="fields">
      <div class="c-store-locator-landing__head">
        <AdaptiveMedia class="c-store-locator-landing__banner" :field="fields.media" />
        <div class="c-store-locator-landing__search">
          <Heading class="c-store-locator-landing__search-title" :field="fields.title" :type="fields.titleType" rich />
          <DynamicForm class="c-store-locator-landing__search-form" ref="formRef" :form="fields.searchForm" @change="onFormChange" />
          <div class="c-store-locator-landing__search-location" @click="getPosition()">
            <Icon class="c-store-locator-landing__search-location-icon" :svg="fields.locationIcon?.value.svgCode" />
            <JssRichText class="c-store-locator-landing__search-location-text" :field="fields.locationText" />
          </div>
          <div class="c-store-locator-landing__search-back" @click="onPrev()">
            <JssRichText class="c-store-locator-landing__search-back-text" :field="{ value: searchBackText }" />
            <Icon class="c-store-locator-landing__search-back-icon" name="down" size="tiny" />
          </div>
        </div>
      </div>
      <div class="c-store-locator-landing__main" ref="mainEl">
        <div v-if="noStore">
          <JssRichText class="c-store-locator-landing__main-empty-title" :field="{ value: noStoreTitle }" />
          <JssRichText class="c-store-locator-landing__main-empty-body" :field="fields.noStorebody" />
        </div>
        <template v-else>
          <div class="c-store-locator-landing__main-filter">
            <JssRichText class="c-store-locator-landing__main-filter-title" :field="{ value: searchingSubtitle ?? subTitle }" />
            <JssRichText class="c-store-locator-landing__main-filter-label" :field="fields.filterLabel" />
            <div class="c-store-locator-landing__main-filter-btns-content">
              <div class="c-store-locator-landing__main-filter-btns">
                <template v-for="type in storeTypes">
                  <div class="c-store-locator-landing__main-filter-btn" :class="{ active: type.active, disable: !type.num }" :key="type.code" v-if="type.num" @click="toggle(type)">
                    <Icon class="c-store-locator-landing__main-filter-btn-icon" name="check" size="tiny" v-if="type.active && type.num" />
                    <div class="c-store-locator-landing__main-filter-btn-text">{{ `${type.name} (${type.num})` }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="c-store-locator-landing__main-group">
            <template v-if="storeList?.length">
              <div class="c-store-locator-landing__main-list">
                <div class="c-store-locator-landing__main-list-item" v-for="store in storeList" :key="store.id" @click="jump(store)">
                  <div class="c-store-locator-landing__main-list-item-left">
                    <div class="c-store-locator-landing__main-list-item-name">{{ store.storeName }}</div>
                    <div class="c-store-locator-landing__main-list-item-address">{{ store.addressDetail }}</div>
                    <div class="c-store-locator-landing__main-list-item-address">{{ store.cityName }}, {{ store.countryName }}</div>
                    <div class="c-store-locator-landing__main-list-item-types">
                      <template v-if="store.storeType.indexOf('1') >= 0 || store.storeType.indexOf('4') >= 0">
                        <div class="c-store-locator-landing__main-list-item-type">
                          {{ storeTypes.find((t) => t.code.indexOf('1') >= 0)?.name }}
                        </div>
                      </template>
                      <template v-if="store.storeType.indexOf('2') >= 0">
                        <div class="c-store-locator-landing__main-list-item-type">{{ storeTypes.find((t) => t.code.indexOf('2') >= 0)?.name }}</div>
                      </template>
                    </div>
                  </div>
                  <div class="c-store-locator-landing__main-list-item-right">
                    <div class="c-store-locator-landing__main-list-item-right-target" v-if="!$isNullOrEmpty(store?.distance)">
                      <div class="c-store-locator-landing__main-list-item-right-target-icon" v-html="fields.targetIcon.value.svgCode" />
                      <div class="c-store-locator-landing__main-list-item-right-target-text">{{ $formatDistance(store.metre, 'm', 0) }}</div>
                      <!-- <div class="c-store-locator-landing__main-list-item-right-target-unit">{{ $t('Km') }}</div> -->
                    </div>
                    <div class="c-store-locator-landing__main-list-item-right-icon" v-html="fields.storeIcon.value.svgCode" />
                  </div>
                </div>
              </div>
              <div class="c-store-locator-landing__main-showroom" v-if="fields.hasShowroom?.value">
                <JssRichText class="c-store-locator-landing__main-showroom-title" :field="fields.showroomTitle" />
                <JssRichText class="c-store-locator-landing__main-showroom-body" :field="fields.showroomBody" />
                <SiteButton class="c-store-locator-landing__main-showroom-btn" v-bind="fields.showroomButton" />
                <AdaptiveMedia class="c-store-locator-landing__main-showroom-media" :field="fields.showroomMedia" />
              </div>
            </template>
            <template v-else>
              <JssRichText class="c-store-locator-landing__main-list-des" :field="fields.description" />
            </template>
          </div>
        </template>
      </div>
      <ListContent class="c-store-locator-landing__swiper" :fields="listContentFields" v-if="!storeList?.length" />
    </div>
  </OverallSettings>
</template>
<script>
import { computed, onMounted, reactive, toRefs, inject } from 'vue';
import { useRouter } from 'vue-router';
import { equalString, formatString } from '@/utils/string-utils';
import { deepCopy, isNullOrEmpty } from '@/utils/obj-utils';
import { getStoreTypes, getCountries } from '@/services/siteService';
import { getAgentList, getWebLinkStores } from '@/services/agentService';
import { geoDecode } from '@/services/mapService';
import { sortByChar } from '@/utils/array-utils';
import { getCurrentAlpha2Code, getGlobalConfigs } from '@/utils/site-utils';
import { redirectTo, appendQuery } from '@/utils/uri-utils';
import { setMetaData } from '@/utils/dom-utils';
import ListContent from '@/components/ListContent';
export default {
  name: 'StoreLocatorLanding',
  components: { ListContent },
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    let countryList,
      webLinkStores,
      allWebLinkStores = [];
    const loading = inject('loading');
    const router = useRouter();
    const alpha2Code = getCurrentAlpha2Code();
    const [hiddenStores, storeLocatorOverviewLink] = getGlobalConfigs(props.page, 'hiddenStores', 'storeLocatorOverviewLink');
    const hiddenStoreCodes = (hiddenStores?.value ?? '').split(';');
    const state = reactive({
      mainEl: null,
      allStore: [],
      storeList: [],
      storeTypes: null,
      currentCountry: null,
      formRef: null,
      noStore: false,
      searchingSubtitle: null,
      searched: false
    });

    const computes = {
      searchBackText: computed(() => formatString(props.fields.listButtonText?.value, { country: state.currentCountry?.countryName.toUpperCase() })),
      subTitle: computed(() => formatString(props.fields.subTitle?.value, { country: state.currentCountry?.countryName })),
      noStoreTitle: computed(() => {
        const address = state.formRef.getItemValueByName('keyword');
        return formatString(props.fields.noStoreTitle?.value, { address: address ? `${address}, ` : '', country: state.currentCountry?.countryName });
      }),
      listContentFields: computed(() => {
        let fields = deepCopy(props.fields.listContent?.fields);
        if (!props.fields.hasShowroom?.value) {
          fields.itemList = fields.itemList.filter((i) => !equalString(i.fields.code.value, 'showroom'));
        }
        if (fields.itemList.length > 1 && fields.itemList.length < 5) {
          fields.countPerView.fields.phrase.value = fields.itemList.length;
        }
        return fields;
      })
    };

    const methods = {
      init: async () => {
        state.storeTypes = await getStoreTypes();
        await methods.getStoreList(null, false);
        loading.hide();
      },
      initTypeList: () => {
        state.storeTypes.forEach((i) => {
          i.num = 0;
          i.active = true;
          state.storeList.forEach((s) => {
            let tmp = s.storeType.split(',');
            if (((tmp.includes('1') || tmp.includes('4')) && i.code.indexOf('1') >= 0) || (tmp.includes('2') && i.code.indexOf('2') >= 0)) {
              i.num++;
            }
          });
        });
      },
      onFormChange: async (name, value, item) => {
        if (name === 'keyword') {
          if (value?.length < 3) {
            state.searchingSubtitle = null;
            await methods.getStoreList({ radius: 1000000 }, true);
            return;
          }
          const geoResult = await geoDecode(props.page, {
            placeId: item.controlProps.selectedOption.data.placeId
          });
          if (!geoResult) return;
          await methods.getStoreList(
            {
              latitude: geoResult.lat,
              longitude: geoResult.lng,
              radius: 1000000
            },
            true
          );
          state.searched = true;
          state.searchingSubtitle = formatString(props.fields.searchingSubtitle?.value, { location: value });
          const offset = state.mainEl.getBoundingClientRect();
          window.scrollTo(0, offset.top + window.scrollY);
        }
      },
      getPosition: () => {
        if (navigator.geolocation) {
          state.searchingSubtitle = null;
          state.formRef.updateItem('keyword', { controlProps: { value: '' } });
          navigator.geolocation.getCurrentPosition(async (e) => {
            await methods.getStoreList(
              {
                latitude: e.coords?.latitude,
                longitude: e.coords?.longitude,
                radius: 1000000
              },
              true
            );
            state.searched = true;
            const offset = state.mainEl.getBoundingClientRect();
            window.scrollTo(0, offset.top + window.scrollY);
          });
        }
      },
      toggle: (t) => {
        if (t.num) {
          t.active = !t.active;
          state.storeList = [];
          const activeTypes = state.storeTypes.filter((i) => i.active);
          activeTypes.forEach((i) => {
            let codeArr = i.code.split(',');
            codeArr.forEach((c) => {
              let tmp = state.allStore.filter((s) => s.storeType.indexOf(c) >= 0);
              state.storeList.push(...tmp);
            });
          });
          if (state.searched) {
            state.storeList = sortByChar(Array.from(new Set(state.storeList)), (e) => e.metre);
          } else {
            state.storeList = sortByChar(Array.from(new Set(state.storeList)), (e) => e.storeName);
          }
        }
      },
      onPrev: () => {
        state.searchingSubtitle = null;
        state.searched = false;
        state.formRef.updateItem('keyword', { controlProps: { value: '' } });
        methods.init();
        const offset = state.mainEl.getBoundingClientRect();
        window.scrollTo(0, offset.top + window.scrollY);
      },
      jump: (s) => {
        const reg = /[`~!@#$^&*()=|{}':;',\\\\[\]\\.<>\\/?~@#……&*——|{}'""'\s]/g;
        const webLinkStore = allWebLinkStores.find((i) => equalString(i.storeCode, s.storeCode));
        if (webLinkStore?.detailsLink?.url) {
          window.open(webLinkStore.detailsLink.url);
        } else {
          const detailsLink = appendQuery(`${storeLocatorOverviewLink?.value.href}/${s.countryName.replace(reg, '')}/${s.storeName.replace(reg, '')}`);
          redirectTo({ url: detailsLink, router });
        }
      },
      getStoreList: async (param, globalFlag) => {
        const storeList = await getAgentList(props.page, null, param, null, globalFlag);
        state.allStore = storeList.filter((r) => !hiddenStoreCodes.some((y) => equalString(y, r.storeCode)));
        state.storeList = state.allStore;
        state.noStore = !state.storeList?.length;
        methods.initTypeList();
      }
    };
    onMounted(async () => {
      loading.show();
      countryList = await getCountries();
      webLinkStores = await getWebLinkStores();
      webLinkStores.forEach((i) => {
        if (i.children?.length) {
          allWebLinkStores = allWebLinkStores.concat(i.children);
        }
      });
      state.currentCountry = countryList.find((c) => equalString(c.alpha2Code, alpha2Code));
      setMetaData('title', formatString(props.fields?.metaTitle?.value, { countryName: state.currentCountry.countryName }));
      setMetaData('description', formatString(props.fields?.metaDescription?.value, { countryName: state.currentCountry.countryName }));
      methods.init();
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-store-locator-landing {
  &__head {
    display: flex;
    flex-direction: column;
  }
  &__search {
    padding: 48px grid-width-m(1) 80px;
    &-form {
      padding: 0;
      margin-top: 40px;
      .e-autocomplete-input {
        &__label {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
        &__main {
          border-color: $grey-next;
          flex-direction: row-reverse;
        }
        &__options {
          color: currentColor;
          border-color: currentColor;
          background: $black;
        }
        &__messages {
          height: 16px;
        }
      }
    }
    &-location {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      &-text {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
      }
      &-icon {
        color: $black;
      }
    }
    &-back {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      margin-top: 24px;
      cursor: pointer;
    }
  }
  &__main {
    padding: 40px grid-width-m(1) 0;
    &-empty {
      &-title {
        font-size: 26px;
        font-weight: 500;
        line-height: 32px;
      }
      &-body {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $grey-taubmans;
        margin-top: 16px;
        margin-bottom: 80px;
      }
    }
    &-filter {
      &-title {
        font-size: 26px;
        font-weight: 500;
        line-height: 32px;
      }
      &-label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $grey-taubmans;
        margin-top: 16px;
      }
      &-btns {
        width: max-content;
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-top: 12px;
        &-content {
          overflow: auto;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      &-btn {
        width: fit-content;
        display: flex;
        flex-direction: row;
        gap: 12px;
        padding: 12px 20px;
        align-items: center;
        text-align: center;
        font-size: 16px;
        line-height: 1;
        font-weight: 400;
        border: 1px solid;
        cursor: pointer;
        &.active {
          color: $yellow !important;
        }
        &.disable {
          cursor: not-allowed;
          color: $grey-night !important;
        }
      }
    }
    &-list {
      max-height: 1800px;
      margin-top: 40px;
      overflow: auto;
      &-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        padding: 24px 0;
        border-bottom: 1px solid $grey-night;
        cursor: pointer;
        &:first-child {
          padding-top: 0;
        }
        &-left {
          width: grid-width-m(7);
        }
        &-right {
          position: relative;
          fill: currentColor;
          margin-right: 14px;
          display: flex;
          align-items: center;
          &-target {
            width: max-content;
            position: absolute;
            top: 6px;
            right: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 20px;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: $grey-taubmans;
            &-icon {
              display: flex;
              align-items: center;
              margin-right: 6px;
            }
            &-text {
              margin-right: 2px;
            }
          }
          &-icon {
            display: flex;
            align-items: center;
          }
        }
        &-name {
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
        }
        &-address {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: $grey-taubmans;
          margin-top: 6px;
          opacity: 0.8;
        }
        &-types {
          display: flex;
          flex-direction: row;
          gap: 6px;
          flex-wrap: wrap;
          margin-top: 12px;
        }
        &-type {
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          line-height: 8px;
          padding: 8px 12px;
          border: 1px solid $grey-next;
        }
        &-right {
          > svg {
            width: 16px;
          }
        }
      }
      &-des {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $grey-taubmans;
        margin-top: 40px;
        margin-bottom: 80px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &-showroom {
      margin-top: 24px;
      &-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
      }
      &-body {
        margin-top: 4px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $grey-taubmans;
      }
      &-media {
        &.e-adaptive-media {
          width: 100vw;
          height: grid-width-m(8);
          margin-left: grid-width-m(-1);
        }
      }
    }
  }
  @include tablet-landscape {
    padding-bottom: grid-width(2);
    &__head {
      display: flex;
      flex-direction: row-reverse;
      > div {
        width: grid-width(12);
      }
    }
    &__search {
      padding: grid-width(4) grid-width(2) 0;
      &-back {
        margin-top: grid-width(3);
      }
    }
    &__main {
      display: flex;
      flex-direction: row;
      gap: grid-width(2);
      padding: grid-width(1.5) grid-width(2) 0;
      > div {
        width: grid-width(9);
      }
      &-filter {
        &-btns {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
      &-showroom-media.e-adaptive-media {
        width: grid-width(9);
        height: grid-width(math.div(9 * 9, 16));
        margin-left: 0;
        margin-top: 32px;
      }
    }
  }
}
</style>
