<template>
  <overall-settings v-bind="$props">
    <div class="c-typography" v-if="fields" ref="wrapperEl">
      <div class="c-typography-btns">
        <SiteButton class="c-typography-btns-item" v-for="btn in fields.topButtons" :key="btn.id" v-bind="btn" />
      </div>
      <div class="c-typography-image-content">
        <div class="c-typography-image-inner-content" ref="imageEl">
          <BackgroundImage class="c-typography-pcimage" :image="fields.pcImage" />
          <BackgroundImage class="c-typography-mobileimage" :image="fields.mobileImage" />
        </div>
        <div class="c-typography-title-top-content">
          <div class="c-typography-title-white-content" ref="whiteTextEl">
            <AnimatedContent type="bottom-fade-in" :delay="100" :turn-off="fields.turnOffTextAnimation?.value">
              <jss-rich-text class="c-typography-pc-white-title" :field="fields.pcTitleIn" tag="div" />
              <jss-rich-text class="c-typography-mobile-white-title" :field="fields.mobileTitleIn" tag="div" />
            </AnimatedContent>
          </div>
        </div>
      </div>
      <div class="c-typography-title-bottom-content">
        <div class="c-typography-title-black-content" ref="blackTextEl">
          <AnimatedContent type="bottom-fade-in" :delay="100" :turn-off="fields.turnOffTextAnimation?.value">
            <jss-rich-text class="c-typography-pc-black-title" :field="fields.pcTitleOut" tag="div" />
            <jss-rich-text class="c-typography-mobile-black-title" :field="fields.mobileTitleOut" tag="div" />
          </AnimatedContent>
        </div>
      </div>
      <div class="c-typography-desc-content" ref="descEl">
        <AnimatedContent type="bottom-fade-in" :delay="200" :turn-off="fields.turnOffTextAnimation?.value">
          <jss-rich-text class="c-typography-desc" :field="fields.desction" tag="div" />
        </AnimatedContent>
        <AnimatedContent type="bottom-fade-in" :delay="200" :turn-off="fields.turnOffTextAnimation?.value">
          <div class="c-typography-btns c-typography-des-btns">
            <SiteButton class="c-typography-des-btns-item" v-for="btn in fields.desButtons" :key="btn.id" v-bind="btn" />
          </div>
        </AnimatedContent>
      </div>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef TypographyFields
 * @property {SimpleField} pcTitleIn
 * @property {SimpleField} mobileTitleIn
 * @property {SimpleField} pcTitleOut
 * @property {SimpleField} mobileTitleOut
 * @property {SimpleField} desction
 * @property {CheckField} turnOffTextAnimation
 * @property {Array<ButtonField>} topButtons
 * @property {Array<ButtonField>} desButtons
 * */
import { toRefs, onMounted, onUnmounted, nextTick, reactive } from 'vue';
import { loadScrollMagic } from '@/utils/site-utils';
import { isMobile } from '@/utils/dom-utils';
import gsap from 'gsap';
import { debounce } from 'lodash';
export default {
  name: 'Typography',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let ScrollMagic, controller, scene, gridWidth, textMove, tl;
    const state = reactive({
      wrapperEl: null,
      imageEl: null,
      whiteTextEl: null,
      blackTextEl: null,
      descEl: null
    });

    const methods = {
      initScrollMagic() {
        // if (!props.fields.turnOffImageAnimation?.value) {
        gridWidth = document.documentElement.clientWidth / 24;
        textMove = isMobile() ? gridWidth * -4.5 : gridWidth * -2;
        controller = new ScrollMagic.Controller();
        tl.to(state.imageEl, { scale: 1 }, 'first');

        scene = new ScrollMagic.Scene({
          triggerElement: state.wrapperEl,
          offset: isMobile() ? -100 : '100%',
          triggerHook: 0,
          duration: isMobile() ? 400 : 600
        })
          .setTween(tl)
          .addTo(controller);
        if (!isMobile()) {
          scene.setPin(state.wrapperEl);
        }
        // }
      },
      resizePageSet: debounce(async (e) => {
        await nextTick();
        controller.updateScene(scene, true);
      }, 300)
    };

    onMounted(() => {
      nextTick(() => {
        ScrollMagic = loadScrollMagic();
        tl = gsap.timeline();
        methods.initScrollMagic();
      });
      window.addEventListener('resize', methods.resizePageSet);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', methods.resizePageSet);
      if (scene) {
        scene.destroy();
        controller.destroy();
      }
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/fontFamily.scss';
.c-typography {
  position: relative;
  width: 100%;
  overflow: hidden;
  &-btns {
    position: absolute;
    top: 60px;
    left: grid-width-m(1);
    z-index: 1;
    display: flex;
    flex-direction: column;
    > .e-site-button + .e-site-button {
      margin-top: 10px;
    }
  }
  &-des-btns {
    position: unset;
  }
  &-image-content {
    width: 100%;
    height: grid-width-m(16);
    position: relative;
    overflow: hidden;
  }
  &-image-inner-content {
    width: 100%;
    height: 100%;
    transform: scale(1.25);
  }
  &-pcimage {
    display: none;
  }
  &-mobileimage {
    display: block;
    width: 100%;
    height: 100%;
  }
  &-title-top-content {
    width: 100%;
    height: grid-width-m(8);
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
  &-title-white-content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: grid-width-m(3.5);
  }
  &-pc-white-title {
    display: none;
  }
  &-mobile-white-title {
    display: block;
    text-align: end;
    margin-right: grid-width-m(1);
    position: relative;
  }
  &-title-bottom-content {
    width: 100%;
    height: grid-width-m(3);
    top: 0;
    position: relative;
    overflow: hidden;
  }
  &-title-black-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: grid-width-m(-4.5);
    left: 0;
  }
  &-pc-black-title {
    display: none;
  }
  &-mobile-black-title {
    display: block;
    text-align: end;
    margin-right: grid-width-m(1);
    position: relative;
  }
  &-desc-content {
    padding: 0 grid-width-m(1);
    position: relative;
    width: grid-width-m(12);
    top: 0;
  }
  &-desc {
    position: relative;
  }
  @include tablet-landscape {
    &-btns {
      flex-direction: row;
      > .e-site-button + .e-site-button {
        margin-top: 0;
        margin-left: 30px;
      }
    }
    &-image-content {
      height: grid-width(10);
    }
    &-pcimage {
      display: block;
      width: 100%;
      height: 100%;
    }
    &-mobileimage {
      display: none;
    }
    &-title-top-content {
      height: grid-width(4);
    }
    &-title-white-content {
      top: grid-width(2);
    }
    &-mobile-white-title {
      display: none;
    }
    &-pc-white-title {
      display: block;
      text-align: end;
      position: relative;
    }
    &-title-bottom-content {
      height: grid-width(4);
    }
    &-title-black-content {
      top: grid-width(-2);
    }
    &-mobile-black-title {
      display: none;
    }
    &-pc-black-title {
      display: block;
      text-align: end;
      position: relative;
    }
    &-desc-content {
      padding-left: grid-width(2);
      width: auto;
      position: relative;
      top: 0;
    }
    &-desc {
      position: relative;
    }
  }
  @include desktop-large {
    &-title-top-content {
      height: grid-width(2.5);
    }
    &-title-white-content {
      top: grid-width(0.5);
    }
    &-title-bottom-content {
      height: grid-width(2.5);
    }
  }
}
html.rtl {
  .c-typography {
    &-mobile-white-title {
      margin-left: grid-width-m(1);
      margin-right: 0;
    }
  }
}
</style>
