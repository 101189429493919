<template>
  <OverallSettings v-bind="$props">
    <div class="c-single-map" v-if="fields">
      <div class="c-single-map__content" v-grid-layout="fields.gridLayout">
        <div class="c-single-map__map" ref="mapNode"></div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { toRefs, onMounted, nextTick, reactive, inject } from 'vue';
import { loadGMap } from '@/utils/site-utils';
import { isMobile } from '@/utils/dom-utils';
import api from '@/api/index';
import services from '@/services';
export default {
  name: 'SingleMap',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    const toast = inject('toast');
    const state = reactive({
      /**@type {HTMLElement}*/
      mapNode: null,
      mapFlag: false,
      winHeight: null,
      winWidth: null
    });
    let maps = null;
    let map = null;
    let storeList = [];
    let markers = [];
    let gallPetersMapType = null;
    const methods = {
      isMobile: isMobile,
      initComponent: async (_maps) => {
        if (state.mapNode) {
          maps = _maps;
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (e) => {
                methods
                  .initMap(e)
                  .then(() => {
                    state.mapFlag = true;
                  })
                  .catch();
              },
              (ex) => {
                methods
                  .initMap()
                  .then(() => {
                    state.mapFlag = true;
                  })
                  .catch();
              }
            );
          } else {
            methods
              .initMap()
              .then(() => {
                state.mapFlag = true;
              })
              .catch();
          }
        }
      },
      initMap: async () => {
        let mapOptions = {
          mapTypeId: maps.MapTypeId.ROADMAP,
          disableDefaultUI: true,
          center: { lat: 0, lng: 0 },
          zoom: 0,
          gestureHandling: 'none'
        };
        map = new maps.Map(state.mapNode, mapOptions);
        methods.initGallPeters();
        map.mapTypes.set('gallPeters', gallPetersMapType);
        map.setMapTypeId('gallPeters');
        storeList.forEach((t) => {
          let icon = '';
          if (t.storeType.indexOf('1') >= 0) {
            icon = 'https://lotus-cm.nttdatadm.com/-/media/Project/Portal-Site/Icons/Others/LOTUS%20CENTRE%20yellow.svg';
          } else {
            icon = 'https://lotus-cm.nttdatadm.com/-/media/Project/Portal-Site/Icons/Others/LOTUS%20WORKS%20yellow.svg';
          }
          let latLng = new maps.LatLng(t.latitude - 46, t.longitude - 10);
          if (isMobile()) {
            latLng = new maps.LatLng(t.latitude - 70, t.longitude - 20);
          }
          const marker = new maps.Marker({
            position: latLng,
            animation: maps.Animation.DROP,
            icon: icon,
            map: map
          });
          markers.push(marker);
        });
      },
      initGallPeters: () => {
        const GALL_PETERS_RANGE_X = state.winWidth;
        const GALL_PETERS_RANGE_Y = state.winHeight;
        gallPetersMapType = new maps.ImageMapType({
          getTileUrl: function(coord, zoom) {
            console.log(isMobile());
            if (isMobile()) {
              return 'https://lotus-cm.nttdatadm.com/-/media/Project/Portal-Site/App/map-M.png';
            } else {
              return 'https://lotus-cm.nttdatadm.com/-/media/Project/Portal-Site/App/map-PC.png';
            }
          },
          tileSize: new maps.Size(GALL_PETERS_RANGE_X, GALL_PETERS_RANGE_Y),
          minZoom: 0,
          maxZoom: 1,
          name: 'Gall-Peters'
        });

        gallPetersMapType.projection = {
          fromLatLngToPoint: function(latLng) {
            if (isMobile()) {
              return new maps.Point(GALL_PETERS_RANGE_X * (0.5 + latLng.lng() / 380), GALL_PETERS_RANGE_Y * (0.5 - latLng.lat() / 320));
            } else {
              return new maps.Point(GALL_PETERS_RANGE_X * (0.5 + latLng.lng() / 420), GALL_PETERS_RANGE_Y * (0.5 - latLng.lat() / 210));
            }
          },
          fromPointToLatLng: function(point, noWrap) {
            const x = point.x / GALL_PETERS_RANGE_X;
            const y = point.y / GALL_PETERS_RANGE_Y;
            if (isMobile()) {
              return new maps.LatLng(160 - 320 * y, -190 + 380 * x, noWrap);
            } else {
              return new maps.LatLng(105 - 210 * y, -210 + 420 * x, noWrap);
            }
          }
        };
      }
    };
    onMounted(async () => {
      const offset = state.mapNode.getBoundingClientRect();
      state.winHeight = offset.height;
      state.winWidth = offset.width;
      const [res, ex] = await api.store.query(null, { countryCode: '' });
      if (ex) {
        const msg = services.site.getSiteError(ex);
        toast.showError(msg);
      } else {
        storeList = res;
        await nextTick();
        loadGMap(props.page).then((maps) => {
          maps && methods.initComponent(maps).catch();
        });
      }
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-single-map {
  height: 375px;
  width: 100vw;
  &__content {
    height: 100%;
  }
  &__map {
    height: 100%;
  }
  @include tablet-landscape {
    @include grid-container;
    height: calc(100vh - 64px);
  }
}
</style>
