<template>
  <overall-settings v-bind="$props" class="c-simple-video-v2-overOut">
    <div class="c-simple-video-v2-video" ref="contentBLockImage" v-if="fields">
      <section ref="contentBLockImage_grid" class="c-simple-video-v2__grid--image" :style="pageStyle">
        <div class="media-content--spPin" :data-ratio="ratio" ref="spPin">
          <VideoPlayerV2 class="c-simple-video-v2__media--spPin" v-if="!$isNullOrEmpty(video.value)" :type="videoType" :options="videoOptions" ref="playerRef" />
        </div>
      </section>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, nextTick, computed, watch } from 'vue';
import { isDesktop, isTabletLandscape, isDesktopLarge, canUseDOM } from '@/utils/dom-utils';
import { settingValue } from '@/utils/site-utils';
import { equalString } from '@/utils/string-utils';
import useDevice from '@/hooks/useDevice';
import { debounce } from 'lodash';

/**
 * @typedef SimpleVideoV2Fields
 * @property {GlobalSettingEntry} theme
 * */
export default {
  name: 'SimpleVideoV2',
  props: {
    /**@type SimpleVideoV2Fields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const { deviceState } = useDevice();
    let ScrollMagic, controller, scene, tl;

    const state = reactive({
      addStatus: 0,
      contentBLockImage: null,
      contentBLockImage_grid: null,
      spPin: null,
      pageStyle: '',
      video: null,
      image: null,
      ratio: null
    });

    const computes = {
      videoType: computed(() => settingValue(props.fields.media.fields.videoType)),
      videoOptions: computed(() => {
        const { fields } = props.fields.media;
        return {
          controls: equalString(computes.videoType.value, 'full'),
          autoplay: fields.autoplay?.value ?? false,
          muted: fields.autoplay?.value ?? false,
          loop: fields.loop?.value ?? false,
          poster: state.image.value?.src ?? null,
          disableFullscreen: fields.videoDisablefullscreen?.value ?? false,
          disableUnmute: fields.videoDisableUnmute?.value ?? false,
          sources: [
            {
              type: 'video/mp4',
              src: state.video.value ?? null
            }
          ]
        };
      })
    };

    const methods = {
      isDesktop: isDesktop,
      isDesktopLarge: isDesktopLarge,
      isTabletLandscape: isTabletLandscape,
      judgeMedia() {
        const { mobileVideo, mobileImage, mobileRatio, tabletVideo, tabletImage, tabletRatio, desktopVideo, desktopImage, desktopRatio } = props.fields?.media.fields || {};
        let w, h;
        switch (deviceState.deviceType) {
          case 'mobile':
            {
              [w, h] = settingValue(mobileRatio, '9_16')
                .split('_')
                .map((x) => Number(x));
              state.video = mobileVideo;
              state.image = mobileImage;
            }
            break;
          case 'tablet':
            {
              [w, h] = settingValue(tabletRatio, '16_9')
                .split('_')
                .map((x) => Number(x));
              state.video = tabletVideo;
              state.image = tabletImage;
            }
            break;
          default:
            {
              [w, h] = settingValue(desktopRatio, '16_9')
                .split('_')
                .map((x) => Number(x));
              state.video = desktopVideo;
              state.image = desktopImage;
            }
            break;
        }
        state.ratio = w / h;
      },
      resizePageSet: debounce(async (e) => {
        controller.updateScene(scene, true);
      }, 300),
      pageInit() {
        let windowWidth = state.contentBLockImage.parentElement.clientWidth,
          windowHeight = state.contentBLockImage.parentElement.clientHeight;

        let gridWidth = state.contentBLockImage_grid.clientWidth,
          gridHeight = state.contentBLockImage_grid.clientHeight;

        let distancePercent = windowWidth / gridWidth,
          distancePercent2 = windowHeight / gridHeight;

        controller = new ScrollMagic.Controller();

        scene = new ScrollMagic.Scene({
          triggerElement: state.contentBLockImage,
          offset: 0,
          triggerHook: 0,
          duration: state.contentBLockImage.clientHeight
        })
          .setPin(state.contentBLockImage)
          .addTo(controller);

        scene.on('progress', (e) => {
          if (e.progress * 100 <= 1) {
            state.spPin.style['transform'] = 'scale(1)';
          }
          if (e.progress * 100 > 1 && e.progress * 100 < 100) {
            state.spPin.style['transform'] = 'scale(' + distancePercent + ')';
          }
        });
      }
    };

    watch(
      () => deviceState.deviceType,
      () => {
        methods.judgeMedia();
      },
      { immediate: true }
    );
    watch(
      () => props.field,
      () => {
        methods.judgeMedia();
      },
      {
        immediate: true,
        deep: true
      }
    );
    if (canUseDOM()) {
      methods.judgeMedia();
    }
    onMounted(() => {
      if (props.fields?.grid?.fields.phrase.value) {
        state.pageStyle =
          !isDesktop() && !isDesktopLarge() && !isTabletLandscape()
            ? 'grid-area: auto / ' +
              (parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) + 1) +
              ' / span ' +
              (parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) + 1) +
              ' / span ' +
              (12 - parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) * 2) +
              ';'
            : 'grid-area: auto / ' +
              (parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) + 1) +
              ' / span ' +
              (parseInt(props.fields.grid.fields.phrase.value.split('-')[0]) + 1) +
              ' / span ' +
              parseInt(props.fields.grid.fields.phrase.value.split('-')[1]) +
              ';';
      }

      nextTick(() => {
        window.addEventListener('resize', methods.resizePageSet);
      });
    });

    onUnmounted(() => {
      window.removeEventListener('resize', methods.resizePageSet);

      if (controller && scene) {
        controller.destroy();
        scene.destroy();
      }
    });

    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-simple-video-v2-overOut {
  overflow: hidden;
}
.c-simple-video-v2-video {
  position: relative;
  @include grid-container;

  .c-simple-video-v2__grid--image {
    position: relative;
    width: grid-width-m(12);
    height: grid-width-m(math.div(12 * 16, 9));
  }

  .media-content--spPin {
    width: 100%;
    height: 100%;
    transform: scale(1);
    transform-origin: center center;

    transition: transform 0.6s ease;
  }
  .c-simple-video-v2__media--spPin {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}

@include tablet-landscape {
  .c-simple-video-v2-video {
    .c-simple-video-v2__grid--image {
      width: grid-width(24);
      height: grid-width(math.div(24 * 9, 16));
    }
  }
}
</style>
