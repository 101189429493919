<script setup>
import { computed, inject, nextTick, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { canUseDOM, tryParseJson } from '@/utils/dom-utils';
import { appendQuery, getBetterUrl, getQueryStrings } from '@/utils/uri-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import api from '@/api';
import { sitecoreComponentProps } from '@/utils/constants';
import { destroySwiper, encodeData, getPageAlpha2Code, getPriceFromPcData } from '@/utils/site-utils';
import services from '@/services';
import { analyzeVehicleFeatures } from '@/utils/mhp-utils';
import Swiper from 'swiper';
import { debounce, merge } from 'lodash';
import { onWidthChanged } from '@/hooks/onWidthChanged';
import { equalString, ifEmpty } from '@/utils/string-utils';
import useAppStore from '@/store/appStore';
import { getDeliveryTime } from '@/services/siteService';
import { unWrapSitecoreItem } from '@/utils/sitecore-utils';
import { getCabItemPureVal } from '@/utils/finance-utils';
import utils from '@/utils';
let swiper, swiperThumb;
const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
/**@type AppStore*/
const appStore = useAppStore();
const loading = inject('loading');
const toast = inject('toast');
const rootEl = ref();
const swiperEl = ref();
const swiperThumbEl = ref();
const paginationEl = ref();
const vehicleRef = ref();
const optionModalRef = ref();
/**@type Ref<DealerSelectModalRef>*/
const dealerSelectModalRef = ref(null);
const state = reactive({
  /**@type {?CalculatorContainerRef}*/
  calculatorContainerRef: null,
  vin: null,
  alpha2Code: getPageAlpha2Code(props.page),
  showPriceDisclaimer: false,
  detail: null,
  inRcc: false,
  deliveryTime: '',
  /**@type {?CarConfiguration}*/
  configuration: null,
  priceInfo: [],
  totalPrice: null,
  /**@type {?number}*/
  retailPrice: null,
  /**@type {?number}*/
  basePrice: null,
  /**@type {?number}*/
  optionPrice: 0,
  /**@type {?number}*/
  vatAmount: null,
  currency: null,
  features: [],
  accessories: [],
  /**@type {?CarSeries}*/
  carSeries: null,
  /**@type {?CarModel}*/
  carModel: null,
  orderRule: null,
  store: null,
  specsOpen: true,
  energyLabelOpen: true,
  /**@type {?ModalRef}*/
  energyLabelModalRef: null,
  showBar: false,
  activeOp: null,
  /**@type {?PaymentOption}*/
  paymentOption: null,
  calculation: null,
  financeInitialized: false
});
const calculation = reactive({
  /**@type {SantanderQuote}*/
  santander: null,
  /**@type {CwFinanceQuote}*/
  codeWaver: null,
  /**@type {CabQuote}*/
  cab: null
});
const inRcc = computed(() => [1, 3].includes(state.detail?.organization));
const contactButtonProps = computed(() => getContactButton(props.fields?.contactButton));
const agentButtonProps = computed(() => getContactButton(props.fields?.agentButton));
const dockCode = computed(() => state.paymentOption?.dock?.code);
const buildContactUrl = (href) => {
  let params = {
    vin: state.vin,
    store_code: state.store?.storeCode
  };
  if (dockCode.value === 'santander') {
    const quote = calculation.santander?.selectedSimulation;
    if (!isNullOrEmpty(quote)) {
      params.finance = encodeData({
        term: quote.duration?.value,
        deposit: quote.downPayment?.value,
        mileage: quote.annualMileage?.value,
        finalPayment: quote.lastPayment?.value,
        loanType: calculation.santander?.selectedProduct.loanType
      });
    }
  }
  if (dockCode.value === 'code-waver') {
    const cwQuote = calculation.codeWaver?.result.quote.finance.quote;
    if (!isNullOrEmpty(cwQuote)) {
      params.finance = encodeData({
        quote: calculation.codeWaver?.reference,
        entity: calculation.codeWaver?.result.entity,
        term: cwQuote.term ?? cwQuote.contractLength,
        deposit: cwQuote.deposit.cash,
        mileage: cwQuote.annualMileage,
        advanceRental: cwQuote.advancedRentals
      });
    }
  }
  if (dockCode.value === 'ca-auto-bank') {
    if (!isNullOrEmpty(calculation.cab?.configId)) {
      params.finance = encodeData({
        configId: calculation.cab.configId
      });
    }
  }
  return appendQuery(getBetterUrl(href, props.page.itemLanguage, true), params);
};
const getContactButton = (btn) => {
  const { fields } = btn ?? {};
  if (isNullOrEmpty(fields)) return { fields: null };
  const { link } = fields;
  const result = { fields: { ...fields } };
  if (inRcc.value) {
    result.fields.link = null;
  } else {
    const href = link.value.href;
    result.fields.link.value.href = buildContactUrl(href);
  }
  return result;
};
const buildSwiper = () => {
  destroySwiper(swiper, true, true);
  destroySwiper(swiperThumb, true, true);
  const swiperOptions = {
    slidesPerView: 1,
    pagination: {
      el: paginationEl.value
    }
  };
  if (swiperThumbEl.value) {
    swiperThumb = new Swiper(swiperThumbEl.value, {
      slidesPerView: 'auto',
      spaceBetween: 16,
      watchSlidesVisibility: true,
      slideToClickedSlide: true
    });
    merge(swiperOptions, {
      thumbs: {
        swiper: swiperThumb
      }
    });
  }
  swiper = new Swiper(swiperEl.value, swiperOptions);
};
const getPrice = (name) => {
  return state.priceInfo?.find((x) => equalString(x.elementName, name));
};
const loadStoreInfo = async () => {
  if (!isNullOrEmpty(state.detail.storeCode)) {
    const [resStore] = await api.store.detail({
      storeCode: state.detail.storeCode
    });
    state.store = resStore;
  }
};
const onViewDetail = () => {
  vehicleRef.value?.scrollIntoView();
};
const onContact = async () => {
  if (inRcc.value) {
    state.store = await dealerSelectModalRef.value.open(null, false, true);
    if (isNullOrEmpty(state.store)) return;
    const { link } = props.fields?.contactButton.fields;
    const { href } = link.value ?? {};
    if (isNullOrEmpty(href)) return;
    window.location = buildContactUrl(href);
  }
};
if (canUseDOM()) {
  loading.show();
}
const openOption = (op) => {
  state.activeOp = op;
  optionModalRef.value.open();
};
const checkBar = () => {
  if (!rootEl.value) return;
  const $btn = rootEl.value.querySelector('.c-svd-v2__contact-btn');
  if (!$btn) state.showBar = true;
  const rect = $btn.getBoundingClientRect();
  state.showBar = rect.bottom < 10;
};
const onPriceMouseEnter = () => {
  state.showPriceDisclaimer = true;
};
const onPriceMouseLeave = () => {
  state.showPriceDisclaimer = false;
};
const onScroll = debounce(() => {
  checkBar();
}, 50);
const openCalculator = debounce(async () => {
  if (state.carSeries.financeLevel2) {
    if (!state.financeInitialized) {
      loading.show();
      const carImage = state.detail.images.productDisplay[0]?.externalUrl;
      state.calculatorContainerRef.ready(() => {
        if (dockCode.value !== 'ca-auto-bank') {
          state.calculatorContainerRef.open();
        }
        loading.hide();
      });
      await state.calculatorContainerRef.init({
        configuration: state.configuration,
        orderRule: state.orderRule,
        features: state.features,
        carImage: carImage,
        customerType: { code: state.alpha2Code === 'NL' ? 'business' : 'personal' },
        paymentOption: state.paymentOption,
        retailPrice: state.retailPrice,
        basePrice: state.basePrice,
        optionPrice: state.optionPrice,
        vatAmount: state.vatAmount,
        currency: state.currency,
        vehicleType: 'New',
        isStock: true
      });
      state.financeInitialized = true;
    } else {
      state.calculatorContainerRef.open();
    }
  }
}, 50);
const onFinanceCalculated = debounce(async (dock, payload) => {
  calculation[dock] = null;
  await nextTick();
  calculation[dock] = payload;
  console.log('calculated', dock, payload);
}, 200);
onWidthChanged({
  callback: debounce(() => {
    buildSwiper();
  }, 400)
});
onMounted(async () => {
  global.addEventListener('scroll', onScroll, { passive: true });
  [state.vin] = getQueryStrings('vin');
  state.alpha2Code = getPageAlpha2Code(props.page);
  if (isNullOrEmpty(state.vin)) return;
  loading.show();
  const detailBody = {
    country: state.alpha2Code,
    vin: state.vin
  };
  if (appStore.hasGeoLocation) {
    merge(detailBody, {
      longitude: appStore.geoLocation.longitude + '',
      latitude: appStore.geoLocation.latitude + ''
    });
  }
  const [res, ex] = await api.order.stockDetailByVin(null, detailBody);
  if (isNullOrEmpty(res)) {
    await toast.showEx(ex);
    return;
  }
  state.configuration = {
    filterName: res.filterName,
    seriesCode: res.seriesCode,
    modelCode: res.modelCode,
    series: res.series,
    model: res.vehicleModel,
    organization: res.organization,
    currency: res.currency,
    nextLevels: tryParseJson(res.configurationDetails, [])
  };
  state.priceInfo = tryParseJson(res.detailPrice, []);
  state.totalPrice = getPrice('Total Price');
  state.retailPrice = getPriceFromPcData(state.priceInfo, 'Vehicle Total Price', 'Total Price');
  state.basePrice = getPriceFromPcData(state.priceInfo, 'Base Retail Price', 'Base Price');
  state.optionPrice = utils.site.getPriceFromPcData(state.priceInfo, 'Option Price');
  state.vatAmount = utils.site.getPriceFromPcData(state.priceInfo, 'Incl. VAT');
  [state.features, state.accessories] = analyzeVehicleFeatures(state.configuration, true, true);
  state.currency = res.currency ?? utils.mhp.getFeatureCurrency(state.features) ?? 'EUR';
  state.carSeries = await services.site.getCarSeriesByCode(res.seriesCode);
  state.carModel = await services.site.getCarModelByCode(res.seriesCode, res.modelCode);
  const [resRule] = await api.order.rule.query(null, {
    items: [
      {
        orderType: 200,
        carSeriesCode: res.seriesCode,
        carModelCode: res.modelCode ?? '',
        country: state.alpha2Code
      }
    ]
  });
  if (resRule?.length > 0) {
    state.orderRule = resRule[0];
  }
  // res.storeCode = 'LD02';
  state.detail = res;
  state.inRcc = state.detail?.organization === 1;
  state.deliveryTime = await getDeliveryTime(state.inRcc ? 'rcc' : 'store');
  await loadStoreInfo();
  await nextTick();
  buildSwiper();
  await nextTick();
  checkBar();
  if (state.carSeries.financeLevel2) {
    const paymentOptions = unWrapSitecoreItem(state.carSeries.level2PaymentOptions);
    state.paymentOption = paymentOptions.find((x) => !!x.dock);
  }
  loading.hide();
});
onBeforeUnmount(() => {
  global.removeEventListener('scroll', onScroll);
});
</script>

<template>
  <div class="c-svd-v2" v-if="fields && state.detail" ref="rootEl">
    <div class="c-svd-v2__banner">
      <div class="c-svd-v2__banner-side">
        <div class="c-svd-v2__images">
          <div class="c-svd-v2__swiper swiper" ref="swiperEl">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in state.detail.images.productDisplay" :key="item.externalUrl">
                <img :src="item.externalUrl" alt="" />
              </div>
            </div>
          </div>
          <div class="c-svd-v2__swiper-thumb swiper" v-if="$deviceComputes.largeThanTablet.value" ref="swiperThumbEl">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in state.detail.images.productDisplay" :key="item.externalUrl">
                <img :src="item.externalUrl" alt="" />
              </div>
            </div>
          </div>
          <div class="c-svd-v2__swiper-pagination" ref="paginationEl" />
        </div>
      </div>
      <div class="c-svd-v2__basic">
        <div class="__ft-h3 mg-b-12 tl-mg-b-24">{{ $formatModel(state.detail.vehicleModel) }}</div>
        <div class="c-svd-v2__disclaimer-wrapper">
          <div class="c-svd-v2__disclaimer" v-html="fields.priceDisclaimer?.fields.text.value" v-if="!$isNullOrEmpty(fields.priceDisclaimer?.fields.text.value) && state.showPriceDisclaimer" />
        </div>
        <template v-if="state.totalPrice?.price > 0">
          <div class="hand" @mouseenter="onPriceMouseEnter" @mouseleave="onPriceMouseLeave" @touchstart="onPriceMouseEnter" @touchend="onPriceMouseLeave">
            <template-string :field="fields.priceText" :data="{ price: $formatMoney(state.totalPrice?.price, state.totalPrice?.currency) }" />
          </div>
          <div class="flex g4 ac mg-t-4 fs-12 hand" @click="openCalculator" v-if="state.carSeries.financeLevel2">
            <icon :field="fields.calculatorIcon" size="tiny" />
            <jss-text class="tdl" :field="fields.calculatorText" />
          </div>
        </template>
        <!--        <jss-rich-text class="tdl fs-12" :field="fields.summaryText" />-->
        <div class="c-svd-v2__performances" v-if="state.carModel?.performances?.length > 0">
          <div class="c-svd-v2__performance flex fdc" v-for="(p, i) in state.carModel?.performances" :key="i">
            <div class="__lh-1" v-html="p.number" />
            <div v-html="p.description" />
          </div>
        </div>
        <div class="c-svd-v2__delivery">
          <div v-html="state.deliveryTime" />
        </div>
        <div class="c-svd-v2__store">
          <icon name="location" size="tiny" />&nbsp;<jss-rich-text :field="fields.storeText" tag="span" />
          <span v-if="inRcc">&nbsp;{{ ifEmpty(fields.rccText?.value, 'any lotus centre') }}</span>
          <span class="fw-600 __c-black" v-else>&nbsp;{{ state.store?.storeName }}</span>
          <template v-if="appStore.hasGeoLocation && state.detail.distance > 0">
            <span class="s-svc-v2__line-vertical" />
            <template-string :field="fields.distanceText" :data="{ distance: $formatDistance(state.detail.distance, 'm', 0) }" tag="span" />
          </template>
        </div>
        <div class="c-svd-v2__buttons">
          <site-button class="c-svd-v2__contact-btn" v-bind="contactButtonProps" @click="onContact" />
          <site-button v-bind="fields.reserveButton" />
        </div>
        <div class="c-svd-v2__view-detail" @click="onViewDetail">
          <jss-rich-text :field="fields.viewDetailText" />
          <icon name="down" size="tiny" />
        </div>
      </div>
    </div>
    <div class="c-svd-v2__vehicle" ref="vehicleRef">
      <div class="c-svd-v2__vehicle-detail">
        <jss-rich-text class="fs-26 fw-500" :field="fields.detailTitle" />
        <div class="text-desc" v-html="state.carModel?.rccVehicleDesc" v-if="inRcc" />
        <div class="text-desc" v-html="state.detail?.description" v-else />
        <ul class="point-list">
          <li v-for="(accessory, i) in state.accessories" :key="i">{{ accessory.value }}</li>
        </ul>
      </div>
      <div class="c-svd-v2__vehicle-sections">
        <site-accordion :title="fields.specsTitle.value" v-model:active="state.specsOpen" v-if="state.carModel?.specs?.length > 0">
          <div class="c-svd-v2__specs">
            <div class="c-svd-v2__spec" v-for="(spec, i) in state.carModel?.specs" :key="i">
              <div v-html="spec.title" />
              <div v-html="spec.content" />
            </div>
          </div>
        </site-accordion>
        <template v-for="(item, i) in state.features" :key="i">
          <site-accordion :title="item.label" v-model:active="item.opened" v-if="!$isNullOrEmpty(item.label) && item.infos?.length > 0">
            <div class="c-svd-v2__features">
              <div class="c-svd-v2__feature" v-for="(info, j) in item.infos" :key="j">
                <div class="c-svd-v2__feature-main">
                  <div class="c-svd-v2__feature-image">
                    <img :src="$ifEmpty(info.phoneImg, info.pcImg, info.padImg)" alt="" v-if="!$isNullOrEmpty($ifEmpty(info.phoneImg, info.pcImg, info.padImg))" />
                  </div>
                  <div class="c-svd-v2__feature-pair">
                    <div class="c-svd-v2__feature-label">{{ info.label }}</div>
                    <div class="c-svd-v2__feature-value">{{ info.value }}</div>
                  </div>
                </div>
                <icon class="hand" name="warn" @click="openOption(info)" v-if="!$isNullOrEmpty(info.saleLongComment)" />
              </div>
            </div>
          </site-accordion>
        </template>
        <site-accordion
          :title="fields.energyLabelTitle.value"
          v-model:active="state.energyLabelOpen"
          v-if="!$isNullOrEmpty(fields.energyLabelTitle.value) && !$isNullOrEmpty(state.detail.energyLabelUrl)"
        >
          <img class="c-svd-v2__energy-label" :src="state.detail.energyLabelUrl" @click="state.energyLabelModalRef?.open()" />
        </site-accordion>
      </div>
    </div>
    <store-info v-bind="fields.storeInfo" :store="state.store" theme="black" v-if="!inRcc" />
    <store-map-view :store="state.store" :icon-field="fields.mapIcon" v-if="!inRcc" />
    <vehicle-paying :car-model="state.carModel" :title="$formatString(fields.payingTitle?.value, { model: $formatModel(state.detail.vehicleModel) })" theme="black" />
    <div class="c-svd-v2__footer">
      <jss-rich-text class="fs-32" :field="fields.footerTitle" />
      <jss-rich-text class="__c-grey-taubmans" :field="fields.footerBody" />
      <site-button v-bind="fields.footerButton" />
    </div>
    <transition name="bottom-slide-in">
      <div class="c-svd-v2__bar" v-if="state.showBar">
        <div class="flex fdc">
          <div class="fs-18">{{ state.carModel?.name }}</div>
          <div class="__c-grey-taubmans">{{ $formatMoney(state.totalPrice?.price, state.totalPrice?.currency) }}</div>
        </div>
        <div class="flex ac g32">
          <div class="flex ac g4" v-if="$deviceComputes.largeThanTablet.value && !inRcc && !$isNullOrEmpty(state.store)">
            <icon name="location" />
            <span>{{ state.store?.storeName }}</span>
          </div>
          <site-button v-bind="agentButtonProps" @click="onContact" />
        </div>
      </div>
    </transition>
    <dealer-select-modal v-bind="fields.dealerSelectModal" ref="dealerSelectModalRef" />
    <modal class="c-svd-v2__option-modal" animation="right-slide-in" ref="optionModalRef" closable :sticky="false">
      <div class="c-svd-v2__option-modal-content">
        <div class="c-svd-v2__option-modal-img" v-aspect-ratio="'16_9'" v-if="!$isNullOrEmpty($ifEmpty(state.activeOp.phoneImg, state.activeOp.pcImg, state.activeOp.padImg))">
          <img :src="$ifEmpty(state.activeOp.phoneImg, state.activeOp.pcImg, state.activeOp.padImg)" alt="" />
        </div>
        <div class="__ft-h4">
          {{ state.activeOp.value }}
        </div>
        <div>
          {{ state.activeOp.saleLongComment }}
        </div>
      </div>
    </modal>
    <modal class="c-svd-v2__energy-label-modal" :sticky="false" closable :ref="(e) => (state.energyLabelModalRef = e)">
      <img class="c-svd-v2__energy-label-modal-img" :src="state.detail.energyLabelUrl" alt="" />
    </modal>
    <calculator-container :ref="(e) => (state.calculatorContainerRef = e)" @calculated="onFinanceCalculated" />
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-svd-v2 {
  &__banner {
    margin-bottom: 40px;
  }
  &__images {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .swiper {
      width: 100%;
      overflow: hidden;
      .swiper-slide {
        aspect-ratio: 4/3;
        cursor: pointer;
        img {
          display: block;
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &__swiper {
    &-thumb {
      .swiper-slide {
        width: 80px;
        opacity: 0.4;
        &.swiper-slide-thumb-active {
          opacity: 1;
        }
      }
    }
  }
  &__swiper-pagination {
    height: 1px;
    background-color: $grey-89;
    display: flex;
    align-items: stretch;
    margin: 0 24px 32px 24px;
    .swiper-pagination-bullet {
      flex-grow: 1;
      flex-shrink: 1;
      &-active {
        background-color: $black;
      }
    }
  }
  &__basic {
    padding: 24px;
    display: flex;
    flex-direction: column;
  }
  &__disclaimer {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    padding: 24px;
    background-color: $grey-light;
    color: $grey-dark;
    &-wrapper {
      position: relative;
    }
  }
  &__delivery {
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $green;
      vertical-align: middle;
    }
  }
  &__store {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $grey-next;
    position: relative;
    left: -4px;
    margin-bottom: 48px;
    .e-icon {
      display: inline-flex;
      position: relative;
      top: -1.5px;
    }
  }
  &__performances {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px 8px;
    margin: 40px 0;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;
    .e-site-button {
      width: 100%;
      &.secondary-button {
        border-width: 2px;
      }
    }
  }
  &__view-detail {
    align-self: center;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    user-select: none;
    .e-icon {
      position: relative;
      top: -1px;
    }
  }
  .point-list {
    font-size: 16px;
    row-gap: 8px;
  }
  &__specs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
  }
  &__features {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__feature {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    &-image {
      flex-shrink: 0;
      width: 80px;
      height: 80px;
      background-color: $grey-light;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &-label {
      font-size: 12px;
      color: $grey-next;
    }
    &-pair {
      display: flex;
      flex-direction: column;
    }
    &-main {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  &__vehicle {
    padding: 24px;
    &-detail {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 16px;
    }
    &-sections {
      .e-label-value {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
        &__value {
          text-align: end;
        }
      }
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
    background-color: $black;
    color: $white;
    padding: 20px grid-width-m(1) 40px grid-width-m(1);
  }
  &__bar {
    position: sticky;
    bottom: 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    background: radial-gradient(60.33% 90% at 49.82% 140%, rgba(46, 46, 46, 0.64) 0%, rgba(0, 0, 0, 0.8) 100%);
    backdrop-filter: blur(4px);
    padding: 0 24px;
    .e-site-button {
      height: 40px;
    }
  }
  &__option-modal {
    &-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 0 24px 24px;
    }
    &-img {
      aspect-ratio: 16/9;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        font-size: 0;
        -webkit-text-size-adjust: none;
      }
    }
  }
  &__energy-label {
    width: 48%;
    height: auto;
    &-modal {
      &-img {
        width: 100%;
        height: auto;
      }
    }
  }
  @include tablet-landscape {
    &__banner {
      @include grid-container;
    }
    &__banner-side {
      @include grid-block(1, 12);
    }
    &__images {
      gap: 16px;
      position: sticky;
      top: 0;
      padding: 16px 0 0 16px;
    }
    &__swiper-pagination {
      margin: 0 24px;
    }
    &__basic {
      @include grid-block(15, 8);
      padding: 120px 0 0 0;
    }
    &__performances {
      grid-template-columns: repeat(4, 1fr);
    }
    &__vehicle {
      @include grid-container;
      padding: 40px 0;
      &-detail {
        @include grid-block(3, 10);
      }
      &-sections {
        @include grid-block(15, 8);
      }
    }
    &__footer {
      padding: 20px grid-width(6) 40px grid-width(6);
    }
    &__bar {
      height: 72px;
      padding: 0 grid-width(2);
      .e-site-button {
        height: 56px;
      }
    }
    &__energy-label {
      &-modal {
        .e-modal__content {
          width: grid-width(12);
        }
      }
    }
  }
  @include desktop-normal {
    &__footer {
      padding: 20px grid-width(8) 40px grid-width(8);
    }
  }
}
</style>
