<template>
  <OverallSettings v-bind="$props">
    <div class="c-stage-pro" v-if="fields">
      <div class="c-stage-pro__content">
        <div class="swiper-container" ref="swiperEl">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="slide in fields.slides" :key="slide.id">
              <AdaptiveMedia class="c-stage-pro__slide-image" :field="slide.fields.media" />
              <div class="c-stage-pro__slide-details" :class="[slide.fields.align?.fields.phrase.value, slide.fields.valign?.fields.phrase.value]">
                <div
                  :class="[
                    `c-stage-pro__detailswrap`,
                    slide.fields.align?.fields?.phrase?.value == 'center'
                      ? `c-stage-pro__details-text-center`
                      : slide.fields.textalign?.fields?.phrase?.value == 'left'
                      ? `c-stage-pro__details-text-left`
                      : slide.fields.textalign?.fields?.phrase?.value == 'right'
                      ? `c-stage-pro__details-text-right`
                      : slide.fields.textalign?.fields?.phrase?.value == 'center'
                      ? `c-stage-pro__details-text-center`
                      : ``
                  ]"
                >
                  <animated-content type="skew-in" :hide-content="false">
                    <JssRichText class="c-stage-pro__slide-details-title" :field="slide.fields.title" tag="div" />
                  </animated-content>
                  <animated-content type="skew-in" :hide-content="false">
                    <JssRichText class="c-stage-pro__slide-details-desc" :field="slide.fields.body" tag="div" />
                  </animated-content>
                  <animated-content type="bottom-fade-in" :hide-content="false">
                    <div :class="[`c-stage-pro__slide-details-btns`, `c-stage-pro__details-btns-${$settingValue(slide.fields.align, 'default')}`]">
                      <site-button class="c-stage-pro__slide-details-btn" v-for="btn in slide.fields.buttons" :key="btn.id" v-bind="btn" />
                    </div>
                  </animated-content>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-stage-pro__paginations" v-if="fields.slides.length > 1">
          <div class="c-stage-pro__pagination" ref="paginationEl" />
        </div>
      </div></div
  ></OverallSettings>
</template>

<script>
import { toRefs, onMounted, onUnmounted, nextTick, reactive, onBeforeUnmount } from 'vue';
import Swiper from 'swiper';
import { renderBullet } from '@/utils/site-utils';

export default {
  name: 'StagePro',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props, context) {
    let swiper;
    const state = reactive({
      /**@type {HTMLElement}*/
      swiperEl: null,
      /**@type {HTMLElement}*/
      paginationEl: null
    });
    const buildSwiper = () => {
      if (swiper) {
        swiper.destroy();
      }
      swiper = new Swiper(state.swiperEl, {
        observer: true,
        slidesPerView: 1,
        loop: false,
        allowTouchMove: false,
        pagination: {
          el: state.paginationEl,
          bulletElement: 'div',
          clickable: true,
          renderBullet(index, className) {
            return renderBullet(index, className, props.fields.slides.length);
          }
        }
      });
    };
    onMounted(() => {
      nextTick(() => {
        buildSwiper();
      });
    });
    onBeforeUnmount(() => {
      if (swiper) {
        swiper.destroy();
      }
    });
    return {
      ...toRefs(state),
      onClick() {}
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-stage-pro {
  @include grid-container;
  @include component-overall-settings;
  @include component-themes;
  &__content {
    @include grid-block(1, 24);
    position: relative;
    width: 100%;
    height: 100vh;
    .swiper-container {
      height: 100%;
      width: 100%;
      overflow: hidden;
      .swiper-wrapper {
        .swiper-slide {
          opacity: 0.9;
          &-active {
            opacity: 1;
          }
        }
      }
    }
  }

  &__paginations {
    position: absolute;
    bottom: 0;
    z-index: 22;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: grid-width(1);
    width: 100%;
    padding-top: $space-12;
    padding-bottom: $space-30;
  }
  &__pagination {
    @include svgBullet;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 $space-12;
  }

  &__slide-image {
    height: 100%;
  }
  &__detailswrap {
    width: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }
  &__details-text-center {
    align-items: center;
    text-align: center;
  }
  &__details-text-left {
    align-items: flex-start;
    text-align: start;
  }
  &__details-text-right {
    align-items: flex-end;
    text-align: end;
  }
  &__details-btns {
    align-items: stretch;
  }
  &__details-btns-center {
    align-items: center;
  }
  &__details-btns-left {
    align-items: flex-start;
  }
  &__details-btns-right {
    align-items: flex-end;
  }
  &__details-btns-default {
    justify-content: flex-end;
  }
  &__slide-details {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: grid-width(2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1;
    &.left {
      align-items: flex-start;
      text-align: start;
    }
    &.right {
      align-items: flex-end;
      text-align: end;
    }
    &.top {
      justify-content: flex-start;
    }
    &.bottom {
      justify-content: flex-end;
    }
    &-title {
      color: $white;
      user-select: none;
      margin-bottom: $space-12;
    }

    &-desc {
      color: $white;
      user-select: none;
      margin-bottom: $space-30;
    }

    &-btns {
      letter-spacing: 0;
      color: $white;
      display: flex;
      flex-direction: column;
      gap: $space-30;
    }

    @include tablet-landscape {
      &-title {
        margin-bottom: $space-16;
      }
      &-desc {
        margin-bottom: $space-56;
      }
      &-btns {
        letter-spacing: 0;
        color: $white;
        flex-direction: row;
        gap: $space-40;
      }
    }
  }
  &.theme- {
    &yellow {
      .c-stage-pro__paginations {
        .swiper-pagination-bullet {
          &-active {
            background: $white;
          }
        }
      }
    }
  }
  @include tablet-landscape {
    &__details-btns-default {
      justify-content: flex-end;
    }
  }
}
</style>
