<script setup>
/**
 * @typedef ReservationV2Fields
 * @property {{ fields: DealerSelectModalFields }} dealerSelectModal
 * */
import { computed, getCurrentInstance, inject, nextTick, onBeforeUnmount, onMounted, provide, reactive, ref, watchEffect, watch } from 'vue';
import { countryNeedDoubleOptin, getCarSeries, getShippingRegions } from '@/services/siteService';
import { useRoute } from 'vue-router';
import useAppStore from '@/store/appStore';
import { buildAddress, getPageAlpha2Code, isAppBrowser, isOnApp, scrollToTop, settingValue } from '@/utils/site-utils';
import api from '@/api';
import services from '@/services';
import { webStorage } from '@/utils/web-storage';
import { S_RESERVATION_DETAIL } from '@/utils/web-storage-keys';
import { formatDate } from '@/utils/date-utils';
import { CUSTOMER_TYPES, PAY_CHANNEL, sitecoreComponentProps, TRADE_PLATFORM } from '@/utils/constants';
import { appendQuery, getQueryStrings, redirectToLang } from '@/utils/uri-utils';
import { ifNullOrEmpty, isNullOrEmpty } from '@/utils/obj-utils';
import { gtmFormView, gtmFormStart, gtmFormStep, gtmFormSubmit } from '@/utils/gtm-utils';
import { useI18n } from 'vue-i18n';
import { qtUtils } from '@/utils/ali-tracker-utils';
import { merge } from 'lodash';
import { refreshPage } from '@/utils/dom-utils';
import { equalString } from '@/utils/string-utils';

const props = defineProps({
  /**@type ReservationV2Fields*/
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
const { proxy } = getCurrentInstance();
const { $nativeCommon } = proxy;
const { formType } = props.page.fields;
const route = useRoute();
const { t } = useI18n();
const toast = inject('toast');
const loading = inject('loading');
const appMethods = inject('appMethods');
/**@type AppStore*/
const appStore = useAppStore();
let token = null;
const carSeries = ref(null);
const loaded = ref(false);
const steps = ref([]);
const stepIndex = ref(1);
const formChanged = ref(false);

const stepsRef = ref();
const dealerRef = ref();
const powertrainRef = ref();
const accountRef = ref();
const detailsFormRef = ref();
const paymentRef = ref();
const dealerSelectModalRef = ref();
const marketSelectModalRef = ref();
const accountModalRef = ref();
const switchCountryModalRef = ref();
const geeCaptchaRef = ref();
const hideVerify = ref(false);
const dealerSearchType = ref('zipCode');
const dealerSearchKey = ref('');
const dealers = ref([]);
const dealer = ref(null);
const orderRules = ref([]);
const orderRule = ref(null);
const carModel = ref(null);
const downPayment = ref(2500);
const currency = ref('EUR');
const showSummary = ref(false);
const email = ref('');
const customerType = ref(null);
const personalInfo = ref(null);
const addressInfo = ref(null);
const billingInfo = ref(null);
const markets = ref([]);
const orderCreated = ref(false);
const payFailed = ref(false);
const bound = reactive({
  mobile: true,
  email: true
});
const trackedSteps = [];

const prevStep = computed(() => {
  const prevIndex = stepIndex.value - 2;
  if (prevIndex >= 0 && steps.value?.length >= prevIndex) {
    return steps.value[prevIndex];
  }
  return null;
});
const currentStep = computed(() => (steps?.value.length > stepIndex.value - 1 ? steps.value[stepIndex.value - 1] : null));
const nextStep = computed(() => {
  if (steps.value?.length >= stepIndex.value) {
    return steps.value[stepIndex.value];
  }
  return null;
});
const accountType = computed(() => CUSTOMER_TYPES[customerType.value?.fields?.code.value]);
const isStep = (name) => {
  return currentStep.value?.name === name;
};
const checkBind = async () => {
  const [bindRes] = await api.cidp.bindable();
  bound.phone = bindRes?.phone ?? true;
  bound.email = bindRes?.email ?? true;
};
const getOrderRules = async () => {
  const alpha2Code = getPageAlpha2Code(props.page);
  const [res] = await api.order.rule.query(null, {
    items: [
      {
        orderType: 100,
        carSeriesCode: carSeries.value?.code,
        country: alpha2Code
      }
    ]
  });
  return res ?? [];
};
const openSummary = () => {
  showSummary.value = true;
  document.documentElement.classList.add('reservation-summary-open');
};
const closeSummary = () => {
  showSummary.value = false;
  document.documentElement.classList.remove('reservation-summary-open');
};
const onPopState = async () => {
  switch (global.location.hash) {
    case '#change-market': {
      const { buttonCode } = await switchCountryModalRef.value.open();
      if (buttonCode === 'ok') {
        marketSelectModalRef.value.open();
      }
      break;
    }
    default:
      break;
  }
};
const trackView = () => {
  const stepName = currentStep.value?.name;
  if (!isNullOrEmpty(stepName)) {
    qtUtils.trackPv(`car_order_step_${stepName}_pg`);
  }
};
const onMarketChanged = async (market, language) => {
  await marketSelectModalRef.value.close();
  loading.show();
  redirectToLang(language.code.value);
};
const onDealerModalOpened = async () => {
  await nextTick();
  console.log('dealerSearchType.value, dealerSearchKey.value', dealerSearchType.value, dealerSearchKey.value);
  await dealerSelectModalRef.value.setKeyword(dealerSearchType.value, dealerSearchKey.value);
};
const reservationMethods = {
  async onPrev() {
    if (stepIndex.value > 1) {
      stepIndex.value -= 1;
      await nextTick();
      trackView();
      scrollToTop();
    }
  },
  async onNext() {
    if (stepIndex.value < steps.value.length) {
      stepIndex.value += 1;
      await nextTick();
      trackView();
      scrollToTop();
    }
  },
  async onExit() {
    const result = await toast.confirm(ifNullOrEmpty(props.fields.exitNotice.value, 'Do you want to exit the reservation?'));
    if (result) {
      global.location = `/${props.page.itemLanguage}`;
    }
  },
  fillZipCode(zipCode) {
    detailsFormRef.value?.fillAddress({ zipCode });
  },
  async resetAccount() {
    const result = await toast.confirm('Do you want to edit your email?');
    if (result) {
      accountRef.value.resetEmail();
      await reservationMethods.onPrev();
    }
  },
  async onDetailUpdate(name, value) {
    if (name === 'zipCode' || name === 'city') {
      dealerSearchKey.value = value;
      dealerSearchType.value = name;
    }
  },
  async openDealerModal() {
    const alpha2Code = getPageAlpha2Code(props.page);
    dealer.value = await dealerSelectModalRef.value.open(dealer.value, ['US'].includes(alpha2Code));
  },
  async submit(personalData, companyData, addressData, billingData, consentData, bindConsents, newsletterConsents, leadTypes) {
    loading.show();
    const alpha2Code = getPageAlpha2Code(props.page);
    personalInfo.value = personalData;
    addressInfo.value = addressData;
    billingInfo.value = billingData;
    const userBody = {
      email: email.value,
      firstName: personalData.firstName,
      lastName: personalData.lastName,
      mobileAreaCode: personalData.mobile?.area.code,
      phone: personalData.mobile?.number,
      terms: true,
      zipcode: addressData.zipCode,
      source: isOnApp(route) ? '102' : '202',
      password: '',
      title: '',
      middleName: '',
      address: addressData.address,
      address2: addressData.address2,
      city: addressData.city ?? '',
      province: addressData.state ?? '',
      country: addressData.country.code,
      contactsAddress: buildAddress(addressData.address, addressData.address2, addressData.city, addressData.country.text),
      birthday: '',
      preferLanguage: props.page.itemLanguage,
      communicatePreference: 0,
      businessEmail: accountType.value === 2 ? email.value : '',
      companyName: companyData?.companyName ?? '',
      vatNumber: companyData?.vatNumber ?? '',
      companyRegistrationNumber: companyData?.companyRegistrationNumber ?? '',
      accountType: accountType.value
    };
    if (!appStore?.hasLoggedIn || !props.fields?.accountModal?.fields) {
      const [loginRes, loginEx] = await api.cidp.bto.emailRegisterLogin(null, userBody);
      if (loginEx) {
        await toast.showEx(loginEx);
        loading.hide();
        return;
      }
      appStore.updateLoginInfo(loginRes);
    } else if (appStore?.hasLoggedIn) {
      const [updateRes, updateEx] = await api.cidp.user.update(null, userBody);
      if (updateEx) {
        await toast.showEx(updateEx);
        loading.hide();
        return;
      }
    }
    const [orderId, ex] = await services.order.createReservation(
      carSeries.value,
      carModel.value,
      orderRule.value,
      downPayment.value,
      currency.value,
      dealer.value,
      email.value,
      customerType.value,
      personalData,
      addressData,
      billingData,
      companyData
    );
    if (ex) {
      await toast.showEx(ex);
      loading.hide();
      return null;
    }
    const formDetails = {
      selected_model: carSeries?.value?.series ?? null,
      selected_location_name: null,
      selected_location_type: null,
      selected_location_date: null,
      selected_location_time: null,
      customer_type: customerType?.value?.fields?.code?.value ?? null,
      dealer_name: dealer?.value?.storeName
    };
    const leadsDetails = {};
    try {
      const [bindRes, bindEx] = await services.site.bindConsents(bindConsents, {
        countryRegion: personalData.mobile.area.data.alpha2Code,
        phone: personalData.mobile.number,
        firstName: personalData.firstName,
        lastName: personalData.lastName,
        email: email.value
      });
      if (leadTypes?.length) {
        leadTypes.forEach((l) => {
          leadsDetails[l.leadType] = bindRes ?? null;
        });
      }
    } catch (ex) {
      console.log('ex', ex);
    }
    if (consentData?.CPTC) {
      const cptcBody = merge(
        {
          firstName: personalData.firstName,
          middleName: personalData.middleName ?? '',
          lastName: personalData.lastName,
          email: personalData.email,
          countryRegion: alpha2Code,
          channel: 'Official Website'
        },
        personalData?.externalData,
        companyData?.externalData,
        addressData?.externalData,
        consentData?.externalData
      );
      if (!isNullOrEmpty(consentData.isAboveTwenty)) {
        merge(cptcBody, { isAboveTwenty: consentData.isAboveTwenty });
      }
      const [newsletterRes] = await api.lcms.crm.cptc.create(null, cptcBody);

      if (leadTypes?.length) {
        leadTypes.forEach((l) => {
          if (equalString(l.consentType, 'CPTC')) {
            leadsDetails[l.leadType] = newsletterRes?.leadId ?? null;
          }
        });
      }
    }
    orderCreated.value = true;
    if (isAppBrowser()) {
      $nativeCommon.goPage({ type: 1, params: { orderId } });
      loading.hide();
      return false;
    } else {
      const [resPay, exPay] = await api.reservation.prePay(null, {
        id: orderId,
        tradePlatform: TRADE_PLATFORM.website,
        payChannel: PAY_CHANNEL.other,
        storeId: dealer.value?.id ?? null
      });
      if (!resPay) {
        await toast.showEx(exPay);
        loading.hide();
        return false;
      }
      webStorage.set(S_RESERVATION_DETAIL, { ...resPay, orderId });
      await reservationMethods.onNext();
      await nextTick();
      await paymentRef.value.initStripe(resPay);
      loading.hide();
    }
    gtmFormSubmit(
      formType?.value,
      props.fields.dealerStep?.fields?.form?.id,
      {
        email: email.value,
        mobileAreaCode: personalData.mobile.area.code,
        phone: personalData.mobile.number
      },
      formDetails,
      leadsDetails
    );
  }
};
const showAccountNotice = async () => {
  if (!props.fields?.accountModal?.fields) return;
  const button = await accountModalRef.value?.open();
  if (button?.buttonCode === 'sign-out') {
    await appMethods.justLogout();
    refreshPage();
  } else {
    hideVerify.value = true;
    email.value = appStore?.loginInfo?.email;
  }
};
const validateGeeTest = async () => geeCaptchaRef.value.validate();
const returnButton = computed(() => ({
  fields: {
    type: { fields: { phrase: { value: 'tertiary-button' } } },
    theme: { fields: { phrase: { value: 'black' } } },
    text: { value: t('Return to home') },
    reverse: { value: true }
  }
}));
const contactButton = computed(() => {
  if (!props.fields?.contactButton?.fields) return null;
  const { link } = props.fields?.contactButton.fields;
  const href = link.value?.href;
  if (isNullOrEmpty(href)) return props.fields?.contactButton;
  return {
    fields: {
      ...props.fields?.contactButton.fields,
      link: {
        value: {
          ...link.value,
          href: appendQuery(href, {
            series: carSeries.value?.series,
            storeId: dealer.value?.id ?? ''
          })
        }
      }
    }
  };
});
provide('fields', props.fields);
provide('reservation', {
  methods: reservationMethods,
  fields: props.fields,
  prevStep: prevStep,
  currentStep: currentStep,
  nextStep: nextStep,
  returnButton,
  contactButton,
  validateGeeTest
});
watchEffect(() => {
  if (currentStep.value) {
    gtmFormStep(formType?.value, props.fields.accountStep?.fields?.accountForm.id, appStore?.loginInfo, stepIndex.value, currentStep.value.name, null);
  }
});
watch(
  [() => dealer, () => dealers, () => carSeries, () => carModel, () => email],
  () => {
    if (!formChanged.value) {
      formChanged.value = true;
      gtmFormStart(formType?.value, props.fields.accountStep?.fields?.accountForm.id);
    }
  },
  { deep: true }
);
onMounted(async () => {
  loading.show();
  [token] = getQueryStrings('access_token');
  if (isAppBrowser()) {
    const tkObj = await $nativeCommon.getTokenAsync();
    token = ifNullOrEmpty(tkObj.token, token);
    if (isNullOrEmpty(token)) {
      $nativeCommon.login();
      return;
    }
  }
  if (!isNullOrEmpty(token)) {
    const [userInfo] = await api.cidp.user.info(null, null, {
      headers: {
        authorization: token
      }
    });
    if (userInfo) {
      userInfo.token = token;
      await appStore.setLoginInfo(userInfo);
    }
  }
  window.addEventListener('popstate', onPopState);
  const [clientSecret, redirectStatus] = getQueryStrings('payment_intent_client_secret', 'redirect_status');
  let series = props.page.fields.carSeries?.fields?.series.value ?? route.params.series ?? route.query.series;
  carSeries.value = await getCarSeries(series);
  const storeId = route.params.storeId;
  dealers.value = await dealerRef.value.init();
  if (!isNullOrEmpty(storeId)) {
    dealer.value = dealers.value.find((x) => x.id === storeId);
  }
  const rules = await getOrderRules();
  const rule = rules.length > 0 ? rules[0] : null;
  orderRules.value = rules;
  orderRule.value = rule;
  if (rule?.orderAmountNumber > 0) {
    downPayment.value = rule.orderAmountNumber;
    currency.value = rule.currency;
  } else if (!isNaN(carSeries.value?.downPayment)) {
    downPayment.value = Number(carSeries.value.downPayment);
    currency.value = carSeries.value.currency;
  }
  markets.value = await getShippingRegions(props.page, carSeries.value?.series);
  dealerSelectModalRef.value.setDealers(dealers.value);
  if (!isNullOrEmpty(clientSecret)) {
    steps.value = await stepsRef.value.init(carSeries.value, hideVerify.value);
    stepIndex.value = steps.value.findIndex((x) => x.name === 'payment') + 1;
    await paymentRef.value.checkPayment(props.page, clientSecret, redirectStatus);
  } else {
    if (appStore?.hasLoggedIn) {
      await checkBind();
      if ((bound.email && !isNullOrEmpty(appStore.loginInfo?.email) && props.fields?.accountModal?.fields) || isOnApp(route)) {
        await showAccountNotice();
        hideVerify.value = true;
      }
    }
    steps.value = await stepsRef.value.init(carSeries.value, hideVerify.value);
    if (carSeries.value?.children?.length > 0 && steps.value?.some((x) => x.name === 'powertrain')) {
      carModel.value = carSeries.value.children[0];
    }
    qtUtils.trackPv(`car_order_step_${currentStep.value?.name}_pg`);
    await geeCaptchaRef.value.init();
    loading.hide();
  }
  gtmFormView(formType?.value, props.fields.accountStep?.fields?.accountForm.id);
  loaded.value = true;
});
onBeforeUnmount(() => {
  window.removeEventListener('popstate', onPopState);
});
</script>

<template>
  <div class="c-reservation-v2" v-show="loaded">
    <div class="c-reservation-v2__main">
      <reservation-steps ref="stepsRef" :page="page" :fields="fields" :steps="steps" v-model:step-index="stepIndex" />
      <div class="c-reservation-v2__content">
        <reservation-dealer ref="dealerRef" v-model:dealers="dealers" v-model:dealer="dealer" v-show="isStep('dealer')" />
        <reservation-powertrain ref="powertrainRef" :car-series="carSeries" v-model:car-model="carModel" v-show="isStep('powertrain')" />
        <reservation-account-verify ref="accountRef" v-model:email="email" v-show="isStep('account')" />
        <reservation-details-form ref="detailsFormRef" v-model:customer-type="customerType" :dealer="dealer" v-show="isStep('details')" />
        <reservation-payment ref="paymentRef" v-bind="fields.paymentStep" :car-series="carSeries" v-model:pay-failed="payFailed" v-show="isStep('payment')" />
      </div>
    </div>
    <transition name="bottom-slide-in">
      <div class="c-reservation-v2__side" v-if="!$deviceComputes.isMobileOrTablet.value || showSummary">
        <div class="c-reservation-v2__side-header" v-if="$deviceComputes.isMobileOrTablet.value">
          <icon class="c-reservation-v2__side-close" name="close" @click="closeSummary" />
        </div>
        <reservation-summary
          :fields="fields"
          :car-series="carSeries"
          :car-model="carModel"
          :down-payment="downPayment"
          :currency="currency"
          :customer-type="customerType"
          :personal-info="personalInfo"
          :address-info="addressInfo"
          :billing-info="billingInfo"
        />
      </div>
    </transition>
    <div class="c-reservation-v2__toolbar" v-if="$deviceComputes.isMobileOrTablet.value" @click="openSummary">
      <label-value :value="$formatMoney(downPayment, currency)">
        <template #label>
          <simple-disclaimer :title="$t('Reservation fee due')" :disclaimer="carSeries?.reservationDeliveryDisclaimer" />
        </template>
      </label-value>
      <div class="c-reservation-v2__trigger">
        <p>{{ $t('See details') }}</p>
        <icon name="caret-up" size="tiny" />
      </div>
    </div>
    <dealer-select-modal ref="dealerSelectModalRef" v-bind="fields.dealerSelectModal" @opened="onDealerModalOpened" />
    <market-select-modal ref="marketSelectModalRef" :markets="markets" @selected="onMarketChanged" />
    <notice-modal ref="accountModalRef" v-bind="fields.accountModal" />
    <notice-modal ref="switchCountryModalRef" v-bind="fields.switchCountryModal" />
    <gee-captcha ref="geeCaptchaRef" />
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-reservation-v2 {
  &__main {
    padding: 24px 24px 0 24px;
  }
  &__side {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    width: 100%;
    height: auto;
    background: $black;
    color: $white;
    &-header {
      display: flex;
      justify-content: flex-end;
      padding: 24px;
      position: sticky;
      top: 0;
      background: $black;
    }
    &-close {
      cursor: pointer;
    }
  }
  &__toolbar {
    position: sticky;
    z-index: 1;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background: $black;
    color: $white;
    padding: 0 16px 16px 16px;
  }
  &__trigger {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
  }
  @include tablet-landscape {
    @include grid-container;
    &__main {
      @include grid-block(1, 12);
      padding: 24px 0 0;
    }
    &__content {
      padding: 60px grid-width(2) 0 grid-width(2);
    }
    &__side {
      @include grid-block(13, 12);
      position: static;
      z-index: 0;
    }
    &__toolbar {
      display: none;
    }
  }
}
</style>
