<template>
  <OverallSettings v-bind="$props">
    <div class="c-video-swiper-content" v-if="fields && allItems?.length">
      <div class="c-video-swiper-content__swiper swiper" ref="swiperEl">
        <div class="swiper-wrapper">
          <div class="swiper-slide c-video-swiper-content-video" v-for="(item, index) in allItems" :key="item.id">
            <AdaptiveImage class="c-video-swiper-content__media" :field="item.fields.media" :isBackground="false" :lazy-load="false" v-if="imageFlag" />
            <VideoPlayer :options="videoOptions(index)" :showPlayButton="true" @ended="(e) => next(e)" ref="videoEl" />
            <video :src="item.fields.desktopVideo.value" preload="none" style="display: none" />
          </div>
        </div>
        <template v-if="largeThanTablet() && allItems?.length > 1">
          <div class="c-video-swiper-content__nav c-video-swiper-content__nav-left" @click.prevent="toPre"><Icon name="left" /></div>
          <div class="c-video-swiper-content__nav c-video-swiper-content__nav-right" @click.prevent="toNext"><Icon name="right" /></div>
        </template>
      </div>
      <div class="c-video-swiper-content__description-pc" :class="{ underLine: lineFlag }" v-if="largeThanTablet()">
        <JssRichText class="c-video-swiper-content__description-pc-title" :field="currentItem?.fields?.title" />
        <JssRichText class="c-video-swiper-content__description-pc-body" :field="currentItem?.fields?.body" />
        <div class="c-video-swiper-content__description-pc-pagination" v-if="allItems?.length > 1">
          <span class="c-video-swiper-content__description-pc-pagination-current">{{ String.prototype.padStart.call(currentIndex + 1, 1, '0') }}</span>
          <span class="c-video-swiper-content__description-pc-pagination-line"> / </span>
          <span class="c-video-swiper-content__description-pc-pagination-total">{{ String.prototype.padStart.call(allItems?.length, 1, '0') }}</span>
        </div>
      </div>
      <div class="c-video-swiper-content__description-mobile" :class="{ underLine: lineFlag }" v-else>
        <div class="c-video-swiper-content__description-mobile-top">
          <JssRichText class="c-video-swiper-content__description-mobile-title" :field="currentItem?.fields?.title" />
          <div class="c-video-swiper-content__description-mobile-pagination" v-if="allItems.length > 1">
            <span class="c-video-swiper-content__description-mobile-pagination-current">{{ String.prototype.padStart.call(currentIndex + 1, 1, '0') }}</span>
            <span class="c-video-swiper-content__description-mobile-pagination-line"> / </span>
            <span class="c-video-swiper-content__description-mobile-pagination-total">{{ String.prototype.padStart.call(allItems?.length, 1, '0') }}</span>
          </div>
        </div>
        <JssRichText class="c-video-swiper-content__description-mobile-body" :field="currentItem?.fields?.body" />
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { onMounted, onUnmounted, nextTick, toRefs, reactive, computed } from 'vue';
import { largeThanTablet } from '@/utils/dom-utils';
import useDevice from '@/hooks/useDevice';
import { settingValue } from '@/utils/site-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import Swiper from 'swiper';
export default {
  name: 'VideoSwiperContent',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const { deviceState } = useDevice();
    const state = reactive({
      swiperEl: null,
      swiper: null,
      currentIndex: 0,
      videoEl: null,
      fullSceen: false,
      timer: null
    });
    const computes = {
      allItems: computed(() => (props.fields?.items?.length ? props.fields?.items : props.fields?.videos)),
      currentItem: computed(() => computes.allItems.value[state.currentIndex]),
      imageFlag: computed(() => props.fields?.imageSwiper?.value ?? false),
      lineFlag: computed(() => props.fields?.hasLine?.value ?? false)
    };
    const methods = {
      largeThanTablet,
      buildSwiper: () => {
        state.swiper && state.swiper.destroy(true, true);
        state.swiper = new Swiper(state.swiperEl, {
          spaceBetween: largeThanTablet() ? 0 : 8,
          centeredSlides: true,
          on: {
            slideChange: (swiper) => {
              if (computes.imageFlag.value) {
                clearTimeout(state.timer);
              } else {
                state.videoEl[state.currentIndex].player.pause();
              }
              state.currentIndex = swiper.realIndex;
              if (computes.imageFlag.value) {
                methods.initProgress();
              } else {
                state.videoEl[swiper.realIndex].player.play();
              }
            }
          }
        });
      },
      videoOptions: (i) => {
        const { fields } = computes.allItems.value[i] || {};
        let poster = null,
          video = null;
        if (fields) {
          switch (deviceState.deviceType) {
            case 'mobile':
              {
                poster = isNullOrEmpty(fields.mobilePoster?.value?.src) ? fields.media?.fields.mobileImage?.value?.src : fields.mobilePoster?.value?.src;
                video = isNullOrEmpty(fields.mobileVideo?.value) ? fields.media?.fields.mobileVideo?.value : fields.mobileVideo?.value;
              }
              break;
            case 'tablet':
              {
                poster = isNullOrEmpty(fields.tabletPoster?.value?.src) ? fields.media?.fields.tabletImage?.value?.src : fields.tabletPoster?.value?.src;
                video = isNullOrEmpty(fields.tabletVideo?.value) ? fields.media?.fields.tabletVideo?.value : fields.tabletVideo?.value;
              }
              break;
            default:
              {
                poster = isNullOrEmpty(fields.desktopPoster?.value?.src) ? fields.media?.fields.desktopImage?.value?.src : fields.desktopPoster?.value?.src;
                video = isNullOrEmpty(fields.desktopVideo?.value) ? fields.media?.fields.desktopVideo?.value : fields.desktopVideo?.value;
              }
              break;
          }
        }
        return {
          muted: true,
          controls: true,
          fluid: true,
          loop: computes.allItems.value.length > 1 ? false : fields.media?.fields?.loop?.value,
          autoplay: i === state.currentIndex,
          poster: poster,
          disableFullscreen: fields.videoDisablefullscreen?.value ?? false,
          disableUnmute: fields.videoDisableUnmute?.value ?? false,
          sources: [
            {
              src: video,
              type: 'video/mp4'
            }
          ]
        };
      },
      toPre: () => {
        state.currentIndex === 0 ? state.swiper.slideToLoop(computes.allItems.value?.length - 1) : state.swiper.slideToLoop(state.currentIndex - 1);
      },
      toNext: () => {
        state.currentIndex + 1 >= computes.allItems.value?.length ? state.swiper.slideToLoop(0) : state.swiper.slideToLoop(state.currentIndex + 1);
      },
      initProgress: () => {
        state.timer = setTimeout(() => {
          methods.toNext();
        }, settingValue(props.fields.swipeDelay, 1500));
      },
      next: (e) => {
        if (!e.isFullscreen_) {
          methods.toNext();
        }
      }
    };
    onMounted(async () => {
      await nextTick();
      methods.buildSwiper();
    });
    onUnmounted(() => {
      state.swiper && state.swiper.destroy(true, true);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-video-swiper-content {
  $c: &;
  position: relative;
  width: 100%;
  min-height: 650px;
  &__swiper {
    height: grid-width-m(math.div(50, 4));
    padding: 0 grid-width-m(1);
    .swiper-wrapper {
      height: 100%;
      width: 100%;
      #{$c}__media {
        height: 100%;
        width: 100%;
        video,
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  &__description-mobile {
    width: grid-width-m(10);
    margin: 48px auto 0;
    padding-bottom: 60px;
    &.underLine {
      border-bottom: 1px solid $grey-taubmans;
    }
    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &-title {
      flex: 3;
      word-break: break-word;
    }
    &-pagination {
      flex: 1;
      text-align: end;
      margin-top: 10px;
      color: #727272;
    }
    &-body {
      word-break: break-word;
      margin-top: 20px;
      color: #757575;
    }
  }
  .e-video-player {
    .video-js {
      .vjs-control-bar {
        display: inline-block !important;
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        z-index: 3;
        background-color: transparent;
        .vjs-play-control {
          position: absolute;
          top: 0;
          left: 6px;
        }
        .vjs-remaining-time {
          display: none;
        }
        .vjs-current-time {
          width: fit-content;
          position: absolute;
          top: 0;
          left: 36px;
          display: block;
        }
        .vjs-volume-panel {
          width: 40px;
          position: absolute;
          top: 0;
          right: 36px;
        }
        .vjs-slider-horizontal .vjs-volume-level:before {
          display: none;
        }
        .vjs-volume-control .vjs-control .vjs-volume-horizontal {
          display: none;
        }
        .vjs-fullscreen-control {
          position: absolute;
          top: 0;
          right: 6px;
        }
        .vjs-picture-in-picture-control {
          display: none;
        }
        .vjs-progress-control {
          height: 4px;
          width: 100%;
          position: absolute;
          bottom: -12px;
          left: 0;
          pointer-events: auto !important;
          .vjs-progress-holder {
            height: 4px;
            width: 100%;
            margin: 0;
            background-color: $grey-light;
          }
        }
      }
      .vjs-play-progress {
        background-color: $yellow;
      }
      .vjs-load-progress div {
        background: transparent;
      }
    }
    &__play-btn {
      color: $white;
    }
  }
  @include tablet-landscape {
    width: 100%;
    &__swiper {
      position: relative;
      height: grid-width(math.div(20 * 9, 16));
      width: grid-width(20);
      margin: 0 auto;
      padding: 0;
      overflow: hidden;
      &:hover {
        #{$c}__nav.e-icon {
          display: block;
        }
      }
    }
    &__description-pc {
      width: grid-width(20);
      margin: 80px auto 0;
      display: flex;
      flex-direction: row;
      padding-bottom: 96px;
      &.underLine {
        border-bottom: 1px solid $grey-taubmans;
      }
      &-title {
        flex: 1;
        word-break: break-word;
      }
      &-body {
        font-size: 16px;
        color: $grey-neutral;
        width: grid-width(8);
        word-break: break-word;
      }
      &-pagination {
        flex: 1;
        text-align: end;
        color: #727272;
      }
    }
    &__nav {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $yellow;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      color: $white;
      cursor: pointer;
      &.e-icon {
        display: none;
      }
      > svg {
        margin: 0 auto;
        transform: translateY(50%);
      }
      &-left {
        left: 48px;
      }
      &-right {
        right: 48px;
      }
    }
    .e-video-player {
      .video-js {
        .vjs-control-bar {
          .vjs-play-control {
            left: 20px;
            display: none;
          }
          .vjs-remaining-time {
            display: none;
          }
          .vjs-current-time {
            left: 56px;
            display: none;
          }
          .vjs-volume-panel {
            right: 72px;
            display: none;
          }
          .vjs-slider-horizontal .vjs-volume-level:before {
            display: none;
          }
          .vjs-volume-control .vjs-control .vjs-volume-horizontal {
            display: none;
          }
          .vjs-fullscreen-control {
            right: 32px;
            display: none;
          }
          .vjs-picture-in-picture-control {
            display: none;
          }
        }
        .vjs-play-progress {
          background-color: $yellow;
        }
        .vjs-load-progress div {
          background: transparent;
        }
        &:hover {
          .vjs-control-bar {
            .vjs-play-control {
              display: block;
            }
            .vjs-current-time {
              display: block;
            }
            .vjs-volume-panel {
              display: block;
            }
            .vjs-fullscreen-control {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
