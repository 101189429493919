<template>
  <personal-center :fields="fields?.personalCenterBar?.fields" active-menu="payment-history">
    <div class="c-payment-history">
      <div class="c-payment-history__content">
        <div class="c-payment-history__title">
          <JssRichText :field="fields.title" />
        </div>
        <div class="c-payment-history__list">
          <div class="c-payment-history__list-item" v-for="item in orders" :key="item.id">
            <div class="c-payment-history__list-item-overview" @click="checkOrder(item)">
              <div class="c-payment-history__list-item-num">Order No. {{ item.orderId }}</div>
              <div class="c-payment-history__list-item-icon">
                <Icon :name="currentOrder && currentOrder.salesOrderInfo.orderId === item.orderId ? 'minus' : 'plus'" />
              </div>
            </div>
            <div class="c-payment-history__list-item-details" v-if="currentOrder && currentOrder.salesOrderInfo.orderId === item.orderId">
              <div class="c-payment-history__list-item-details-item" v-for="(item, index) in currentOrder.invoiceInfoList" :key="index">
                <div class="c-payment-history__list-item-details-item-date">
                  <div class="c-payment-history__list-item-details-item-label">Invoice Date</div>
                  <div>{{ item.invoiceDate }}</div>
                </div>
                <div class="c-payment-history__list-item-details-item-amount">
                  <div class="c-payment-history__list-item-details-item-label">Amount</div>
                  <div>{{ item.invoiceAmount }}</div>
                </div>
                <div class="c-payment-history__list-item-details-item-type">
                  <div class="c-payment-history__list-item-details-item-label">Type</div>
                  <div>{{ item.invoiceType }}</div>
                </div>
                <div class="c-payment-history__list-item-details-item-pdf">
                  <div class="c-payment-history__list-item-details-item-label">Invoice</div>
                  <div>
                    <a :href="item.pdfUrl" :download="item.pdfSn"> {{ $t('Download') }} <Icon name="download"/></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </personal-center>
</template>
<script>
import { onMounted, reactive, toRefs, inject } from 'vue';
import useAppStore from '@/store/appStore';
import api from '@/api';

export default {
  name: 'PaymentHistory',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    console.log(props.fields);
    const appStore = useAppStore();
    const loading = inject('loading');
    const toast = inject('toast');
    const state = reactive({
      orders: [],
      currentOrder: null
    });
    const methods = {
      getOrders: async () => {
        loading.show();
        const [res, ex] = await api.order.intactList(null, {
          customerId: appStore?.loginInfo?.lotusId,
          pageNum: 0,
          pageSize: 10000
        });
        if (ex) {
          await toast.showEx(ex);
          loading.hide();
          return;
        }
        state.orders = res.filter((r) => r.orderType !== '100' && r.orderStatus !== '100');
        loading.hide();
      },
      checkOrder: async (item) => {
        if (item.orderId === state.currentOrder?.salesOrderInfo.orderId) {
          state.currentOrder = null;
        } else {
          loading.show();
          const [res, ex] = await api.order.intactDetail(null, { id: item.id });
          if (ex) {
            await toast.showEx(ex);
            loading.hide();
            return;
          }
          state.currentOrder = res;
          loading.hide();
        }
      }
    };
    // if (canUseDOM()) {
    //   loading.show();
    // }
    onMounted(() => {
      methods.getOrders();
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-payment-history {
  $c: &;
  padding: 20px;
  &__list {
    margin-top: 40px;
    font-size: 16px;
    line-height: 32px;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0;
      border-bottom: 1px solid #b8b8b8;
      cursor: pointer;
      &-overview {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      &-details {
        width: 100%;
        padding-left: 24px;
        display: flex;
        flex-direction: column;
        &-item {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          > div {
            width: 50%;
            margin-top: 24px;
          }
          &:not(:first) {
            border-top: 1px solid rgba(#b8b8b8, 0.4);
          }
        }
      }
    }
  }
  @include tablet-landscape {
    padding-right: grid-width(6);
    #{$c}__list-item-details-item > div {
      flex: 1;
    }
  }
}
</style>
