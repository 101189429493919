<template>
  <overall-settings v-bind="$props">
    <div class="c-youtube-video">
      <youtube-video-field :field="fields.video" />
    </div>
  </overall-settings>
</template>

<script>
export default {
  name: 'YoutubeVideo',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  }
};
</script>

<style lang="scss"></style>
