<template>
  <overall-settings v-bind="$props">
    <div class="c-stock-vehicle-list" v-if="fields">
      <!--      pc-->
      <div class="c-stock-vehicle-list__header" v-if="!$deviceComputes.isMobileDevice.value">
        <div class="c-stock-vehicle-list__back" @click="handleBack">
          <Icon class="c-stock-vehicle-list__back-icon" name="back" />
          {{ fields?.returnText?.value }}
        </div>
        <Heading class="c-stock-vehicle-list__title" :field="fields.title" :type="fields.titleType" rich />
        <JssRichText class="c-stock-vehicle-list__des" :field="fields.body" />
        <div class="c-stock-vehicle-list__header__main">
          <button class="c-stock-vehicle-list__cta" @click="filterModalRef.open()" v-if="!$isNullOrEmpty(fields?.filterCta?.fields.text.value)">
            <span class="c-stock-vehicle-list__cta-text" v-html="fields?.filterCta?.fields.text.value" />
            <icon class="c-stock-vehicle-list__cta-icon" name="filter" />
          </button>
          <!-- <div class="c-stock-vehicle-list__header__center">
            <img class="c-result-grid__header__logo" :src="fields?.brandImage?.value?.src" alt="" />
          </div> -->
          <div class="c-stock-vehicle-list__header__right" style="visibility: hidden">
            <Icon class="c-stock-vehicle-list__header__grid-icon" :class="{ 'current-layout': layout === 'grid' }" :svg="fields?.gridIcon?.value?.svgCode" @click="changeLayout('grid')" />
            <span class="c-stock-vehicle-list__header__split-line">|</span>
            <Icon class="c-stock-vehicle-list__header__column-icon" :class="{ 'current-layout': layout === 'column' }" :svg="fields?.columnIcon?.value?.svgCode" @click="changeLayout('column')" />
            <!--            <SiteButton v-bind="fields?.sortCta" size="small" />-->
          </div>
        </div>
      </div>
      <div class="c-stock-vehicle-list__aside" v-if="!$deviceComputes.isMobileDevice.value">
        <div class="c-stock-vehicle-list__aside-item" v-if="fields.totalRecordsText?.value">
          <div class="c-stock-vehicle-list__stock" v-html="$formatString(fields.totalRecordsText?.value, { total })" />
        </div>
        <div class="c-stock-vehicle-list__aside-item">
          <span class="c-stock-vehicle-list__help">{{ fields?.help?.value }} &nbsp;</span>
          <JssRichText class="c-stock-vehicle-list__customer" :field="fields.helpText" tag="span" />
        </div>
      </div>
      <!--      mobile-->
      <div class="c-stock-vehicle-list__back" @click="handleBack" v-if="$deviceComputes.isMobileDevice.value">
        <Icon class="c-stock-vehicle-list__back-icon" name="back" />
        {{ fields?.returnText?.value }}
      </div>
      <Heading class="c-stock-vehicle-list__title" :field="fields.title" :type="fields.titleType" rich v-if="$deviceComputes.isMobileDevice.value" />
      <JssRichText class="c-stock-vehicle-list__des" :field="fields.body" v-if="$deviceComputes.isMobileDevice.value" />
      <div class="c-stock-vehicle-list__control" v-if="$deviceComputes.isMobileDevice.value">
        <div class="c-stock-vehicle-list__control__ctas">
          <button class="c-stock-vehicle-list__cta" @click="filterModalRef.open()" v-if="!$isNullOrEmpty(fields?.filterCta?.fields.text.value)">
            <span class="c-stock-vehicle-list__cta-text" v-html="fields?.filterCta?.fields.text.value" />
            <icon class="c-stock-vehicle-list__cta-icon" name="filter" />
          </button>
        </div>
        <div class="c-stock-vehicle-list__help-wrapper">
          <div>{{ fields?.help?.value }}</div>
          <JssRichText class="c-stock-vehicle-list__customer" :field="fields.helpText" tag="div" />
        </div>
      </div>
      <div class="c-stock-vehicle-list__static" v-if="$deviceComputes.isMobileDevice.value && fields.totalRecordsText?.value">
        <div class="c-stock-vehicle-list__stock" v-html="$formatString(fields.totalRecordsText.value, { total })" />
      </div>
      <div class="c-stock-vehicle-list__body" :class="{ 'grid-layout': layout === 'grid' }" ref="bodyEl" v-if="vehicles.length">
        <div class="c-stock-vehicle-list__body__item" v-for="item in vehicles" :key="item.vin">
          <stock-vehicle-card :data-source="item" :delivery-text="fields.deliveryText?.value" :orderCta="fields?.orderCta" :detailCta="fields?.detailCta" :layout="layout" :country="country" />
        </div>
      </div>
    </div>
    <modal class="c-stock-vehicle-list__modal" animation="left-slide-in" :removeOnHidden="false" closable ref="filterModalRef">
      <stock-vehicle-filter-menu @close="filterModalRef.close()" :fields="fields" :series="series" @handleFilter="onFilterUpdate" :key="componentIndex" ref="filterRef" />
    </modal>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, onMounted, inject, onUnmounted, nextTick } from 'vue';
import api from '@/api';
import { formatModel, getPageAlpha2Code } from '@/utils/site-utils';
import { gtmPush } from '@/utils/gtm-utils';
import { appendQuery, getQueryStrings } from '@/utils/uri-utils';
import { useRoute, useRouter } from 'vue-router';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { equalString } from '@/utils/string-utils';
import { canUseDOM } from '@/utils/dom-utils';
import { merge } from 'lodash';
import services from '@/services';

export default {
  name: 'StockVehicleList',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const loading = inject('loading');
    const toast = inject('toast');
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      bodyEl: null,
      /**@type ModalRef*/
      filterModalRef: null,
      /**@type FilterRef*/
      filterRef: null,
      carSeriesList: [],
      vehicles: [],
      total: 0,
      layout: 'grid',
      pagination: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
        loading: false
      },

      country: getPageAlpha2Code(props.page),
      model: '',
      series: route.query.series,
      componentIndex: 0
    });
    const _methods = {
      getPageSize() {
        let size = parseInt(props.fields.pageSize?.value);
        if (isNaN(size)) size = 20;
        return size;
      }
    };
    const methods = {
      /**
       * 获取库存车辆
       */
      async getStockVehicle(query, refresh = true) {
        await state.filterModalRef?.close();
        loading.show();
        if (refresh) {
          state.pagination.pageNum = 1;
          let url = global.location.href;
          console.log('state.model', state.model);
          if (!isNullOrEmpty(state.model?.code)) {
            url = appendQuery(global.location.href, {
              series: state.series,
              model: state.model.code
            });
          } else {
            url = appendQuery(global.location.href, {}, ['model']);
          }
          if (!equalString(url, global.location.href)) {
            global.history.pushState({}, 'query model', url);
          }
        }
        state.query = query;
        state.pagination.loading = true;
        const params = merge(
          {
            country: state.country,
            model: state.model?.code,
            series: state.series,
            pageNum: state.pagination.pageNum,
            pageSize: state.pagination.pageSize,
            webPublishStatus: 1
          },
          query
        );
        const [res, ex] = await api.order.stockVehiclePages(null, params);
        state.pagination.loading = false;
        if (ex) {
          await toast.showEx(ex);
          loading.hide();
          return;
        }
        state.pagination.total = res.total;
        for (let item of res.records) {
          item.showPrice = state.carSeriesList.find((x) => equalString(x.series, item.series))?.hideStockPrice !== '1';
        }
        if (refresh) {
          state.vehicles = res.records;
        } else {
          state.vehicles.push(...res.records);
        }
        let cars = [];
        let currency = 'EUR',
          region = 'GB',
          language = 'en';
        if (state.vehicles?.length > 0) {
          currency = state.vehicles[0].currency;
          state.vehicles.forEach((v, i) => {
            cars.push({
              model_name: v.model,
              version_name: v.series,
              filter_id: v.filterId,
              transfer_code: v.transferCode,
              vehicle_type: 'stock',
              price: v.price,
              index: i
            });
          });
        }
        if (props.page.itemLanguage !== 'en') {
          [language, region] = props.page.itemLanguage.split('-');
        }
        gtmPush({
          event: 'view_car_list',
          list_name: 'stock_car_lister',
          cars,
          language,
          region,
          currency
        });
        // state.total = res.total;
        state.total = res.records.length ?? 0;
        loading.hide();
      },
      onFilterUpdate(model, query) {
        state.model = model;
        methods.getStockVehicle(query, true);
      },
      /**
       * 返回上一页
       */
      handleBack() {
        if (!isNullOrEmpty(route.redirectedFrom?.fullPath)) {
          router.back();
        } else {
          router.replace({ path: `/${props.page.itemLanguage}` });
        }
      },
      /**
       * 布局方式
       * @param type
       */
      changeLayout(type) {
        state.layout = type;
      }
    };
    if (canUseDOM()) {
      loading.show();
    }
    onMounted(async () => {
      let [modelName] = getQueryStrings('model');
      modelName = formatModel(modelName);
      state.pagination.pageSize = _methods.getPageSize();
      state.series = route.query.series;
      state.carSeriesList = await services.site.getCarSeriesList();
      const model = await state.filterRef.init(modelName);
      state.model = model;
      await methods.getStockVehicle(state.query, true);
    });
    onUnmounted(() => {
      document.removeEventListener('click', () => {
        return null;
      });
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-stock-vehicle-list {
  $this: &;
  user-select: none;
  padding: 0 $space-24;
  .e-site-button.primary-link.bordered {
    border-width: 2px;
  }
  &__cta {
    height: 40px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    border: 2px solid $black;
    background: transparent;
    &-text {
      display: none;
    }
    &-icon {
      svg {
        width: 15px;
      }
    }
  }
  &__title {
    margin-top: $space-24;
  }
  &__des {
    color: $grey-next;
    margin-top: $space-24;
  }
  &__header {
    padding: $space-24 0 40px;
    border-bottom: 1px solid $grey-fade;
    margin: 0 24px;

    #{$this}__back {
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      &-icon {
        margin-right: $space-12;

        svg,
        path {
          height: 16px;
        }
      }
      &:active {
        background: transparent;
      }
    }

    &__main {
      margin-top: $space-24;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__center {
      display: flex;
      align-items: center;
    }

    &__right {
      display: flex;
      align-items: center;
    }

    &__logo {
      width: 218px;
    }

    &__check {
      position: relative;
      margin-left: 14px;
      cursor: pointer;
      svg,
      path {
        width: 24px;
        height: 24px;
      }

      &__icon {
        display: flex;
        align-items: center;
      }
    }

    &__grid-icon {
      cursor: pointer;

      svg,
      path {
        width: 12px;
        height: 12px;
        fill: $grey-fade;
      }

      &.current-layout {
        svg,
        path {
          fill: $black;
        }
      }
    }

    &__column-icon {
      cursor: pointer;
      //margin-right: $space-18;

      svg,
      path {
        width: 10px;
        height: 10px;
        fill: $grey-fade;
      }

      &.current-layout {
        svg,
        path {
          fill: $black;
        }
      }
    }

    &__split-line {
      margin: 0 $space-12;
      transform: scale(0.5);
      color: $grey-fade;
    }
  }

  &__aside {
    margin: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding: 40px 0 60px 0;

    #{$this}__customer {
      color: $grey-next;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__back {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-top: $space-24;

    &-icon {
      margin-right: $space-12;

      svg,
      path {
        height: 16px;
      }
    }
  }

  &__brand {
    text-align: center;
    margin-top: $space-32;
    display: flex;
    align-content: center;
    justify-content: center;

    &__logo {
      width: 218px;
    }

    &__check {
      position: relative;

      &__icon {
        svg,
        path {
          width: 24px;
          height: 24px;
          margin-left: 14px;
        }
      }
    }
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $space-42;

    &__ctas {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  &__static {
    margin-top: $space-48;
    font-size: 16px;
    line-height: 24px;
  }

  &__change-location {
    color: $grey-next;
  }
  &__body {
    $c: &;
    margin: 0;

    &__item {
      margin-bottom: $space-20;
      width: 100%;
      overflow: hidden;
    }
  }
  &__modal {
    .e-modal__content {
      width: 100%;
      border-top: 1px solid $grey-fade;
    }
  }
  @include tablet-landscape {
    width: 992px;
    margin: 0 auto;
    &__des {
      width: grid-width(10);
      margin-bottom: $space-100;
    }
    &__cta {
      &-text {
        display: block;
      }
    }
    &__body {
      margin: 0;

      &.grid-layout {
        display: grid;
        grid-column-gap: $space-24;
        grid-row-gap: $space-24;
        grid-template-columns: repeat(3, 1fr);
      }
    }
    // &__aside {
    //   margin: 0;
    // }
    &__modal {
      .e-modal__content {
        max-width: unset;
        width: 520px;
      }
    }
    &__body {
      margin: 0 24px 0;
    }
  }
  @include desktop-normal {
    width: 1318px;
    padding: 0;
    margin: 0 auto;
  }
}
</style>
