<template>
  <overall-settings v-bind="$props">
    <div class="c-road-side-slider">
      <div class="c-road-side-slider__container">
        <div class="c-road-side-slider__imagediv">
          <BackgroundImage class="c-road-side-slider__image" :image="isMobile ? fields.imagem : isTablet ? fields.imaget : fields.image" />
        </div>
        <div class="c-road-side-slider__content">
          <div class="c-road-side-slider-swiper" ref="rootEl">
            <div class="c-road-side-slider-swiper__main">
              <div class="swiper" ref="swiperEl">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="slider in fields.sliders" :key="slider.id">
                    <!-- <div class="swiper-slide__text" :style="slider.displayName?.slice(13) == activeIndex + 1 ? '' : isPC ? `margin-left: -12.4rem` : `margin-left: 0rem`"> -->
                    <div class="swiper-slide__text" :style="activeIndex == fields.sliders?.length - 1 && isPC ? `margin-left: -6.5rem` : ''">
                      <JssRichText class="swiper-slide__text-time" :field="slider.fields?.time" />
                      <JssRichText class="swiper-slide__text-title" :field="slider.fields?.title" />
                      <JssRichText class="swiper-slide__text-body" :field="slider.fields?.body" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-road-side-slider__controls">
              <div class="c-road-side-slider__arrows">
                <Icon class="c-road-side-slider__left" :svg="activeIndex != 0 ? fields.prevIcon?.value?.svgCode : fields.prevIconGrey?.value?.svgCode"></Icon>
                <Icon class="c-road-side-slider__right" :svg="activeIndex != fields.sliders?.length - 1 ? fields.nextIcon?.value?.svgCode : fields.nextIconGrey?.value?.svgCode"></Icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </overall-settings>
</template>
<script>
import { onBeforeUnmount, onMounted, reactive, toRefs, watch } from 'vue';
import useDevice from '@/hooks/useDevice';
import { isMobile, isTablet, isTabletLandscape } from '@/utils/dom-utils';
import Swiper from 'swiper';
export default {
  name: 'RoadSideSlider',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const { deviceState } = useDevice();
    const state = reactive({
      swiperEl: null,
      paginationEl: null,
      rootEl: null,
      swiper: null,
      activeIndex: 0,
      swiperMarginLeft: false
    });
    const computes = {
      isPC: deviceState.deviceType === 'mobile' ? false : true
    };
    const methods = {
      isMobile: isMobile,
      isTablet: isTablet,
      isTabletLandscape: isTabletLandscape,
      buildSwiper: () => {
        state.swiper && state.swiper.destroy(true, true);
        state.swiper = new Swiper(state.swiperEl, {
          mousewheelControl: false,
          keyboardControl: false,
          speed: 1500,
          // slidesPerView: '1.5',
          spaceBetween: isMobile() ? 0 : isTabletLandscape() ? -40 : -140,
          freeMode: true,
          slidesPerView: isMobile() ? '1' : '1.5',
          navigation: {
            prevEl: state.rootEl.querySelector('.c-road-side-slider__left'),
            nextEl: state.rootEl.querySelector('.c-road-side-slider__right')
          },
          on: {
            slideChange(_swiper) {
              state.activeIndex = _swiper.realIndex;
            }
          }
        });
      }
    };
    watch(
      () => deviceState.deviceType,
      () => {
        methods.buildSwiper();
      }
    );
    onMounted(() => {
      methods.buildSwiper();
    });
    onBeforeUnmount(() => {
      state.swiper && state.swiper.destroy(true, true);
    });
    return {
      ...toRefs(state),
      ...computes
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-road-side-slider {
  width: grid-width-m(12);
  &__image {
    width: grid-width-m(10);
    height: 243px;
  }
  &__imagediv {
    width: grid-width-m(10);
    margin: 0 auto auto auto;
  }
  &__content {
    width: grid-width-m(10);
    margin: 40px auto auto auto;
  }
  &__controls {
    width: grid-width-m(10);
    margin: 0 auto 100px auto;
    display: flex;
    position: relative;
    justify-content: center;
    z-index: 5;
  }
  &__right {
    position: relative;
    margin-left: 32px;
  }
  &__pagination {
    font-size: 24px;
    :first-child {
      font-size: 38px;
    }
  }
  .swiper-slide {
    flex-direction: row;
    &__text {
      width: grid-width(10);
      margin-top: 0px;
      padding: 0 grid-width(1);
      &-time {
        margin-top: 0px;
        width: 280px;
        height: 16px;
      }
      &-title {
        position: relative;
        margin-top: 30px;
        width: 280px;
        height: 18px;
      }
      &-body {
        position: relative;
        margin-top: 21px;
        width: 280px;
        height: 200px;
        border: 0px solid black;
      }
    }
  }
  @include tablet-landscape {
    width: grid-width(24);
    height: 546px;
    @include grid-container;
    &__container {
      height: 546px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 20px;
      overflow: hidden;
      border: 1px;
      @include grid-block(2, 24);
    }
    &__image {
      width: grid-width(12);
      height: 546px;
    }
    &__imagediv {
      width: grid-width(12);
      height: 546px;
    }
    &__content {
      width: grid-width(12);
      height: 546px;
      margin-left: 84px;
      margin-top: -10px;
    }
    .swiper-slide {
      flex-direction: row;
      &__text {
        width: auto;
        margin-top: 150px;
        padding: 0 grid-width(1);
        &-time {
          margin-top: 100px;
          width: 280px;
          height: 16px;
        }
        &-title {
          position: relative;
          margin-top: 28px;
          width: 255px;
          height: 24px;
        }
        &-body {
          position: relative;
          margin-top: 21px;
          width: 280px;
          height: 200px;
          // border: 1px solid black;
        }
      }
    }
    &__main {
      // transition: all 0.3s ease-out;
      height: 100%;
      .swiper {
        height: 100%;
      }
    }
    &__controls {
      width: grid-width(24);
      margin: 0 auto auto grid-width(1.1);
      position: relative;
      z-index: 5;
      padding: 0;
      display: flex;
      justify-content: left;
    }
    &__right {
      position: relative;
      margin-left: 32px;
    }
    &__pagination {
      font-size: 24px;
      :first-child {
        font-size: 38px;
      }
    }
  }
}
</style>
