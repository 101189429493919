<template>
  <overall-settings v-bind="$props">
    <div class="c-uspSlide" ref="uspSlide" v-if="fields?.tagList?.length">
      <div class="c-uspSlide__content">
        <section class="c-uspSlide__tags" v-if="tagList && tagList.length > 0" ref="swiperTag">
          <div class="swiper-wrapper">
            <span
              class="swiper-slide"
              v-for="(item, index) in tagList"
              :key="'uspTagList' + index"
              :class="{ active: item.selected }"
              @click="tagClick(index, 'uspTagList' + index)"
              :ref="'uspTagList' + index"
            >
              <em>{{ item.fields.title.value }}</em>
            </span>
          </div>

          <div class="c-uspSlide__tags__line" :style="isDesktop() || isDesktopLarge() || isTabletLandscape() ? 'top:' + activeTop + 'px; height:' + activeHeight + ';' : ''"></div>
        </section>

        <section class="c-uspSlide__slideContent" ref="swiperEl" v-if="fields.contentList && fields.contentList.length > 0">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in fields.contentList" :key="'uspSlide' + index">
              <section class="c-uspSlide__slideContent__image">
                <!-- <kv-slide-bg :image="item.fields.image" animate="none" :has-animation="!fields.turnOffImageAnimation?.value" :parallax="!fields.turnOffParallax?.value" /> -->
                <!--                <kv-slide-bg :image="item.fields.image" animate="none" />-->
                <background-image :image="$adaptiveImageField(item.fields.image.fields.desktopImage, item.fields.image.fields.mobileImage)" />
              </section>

              <div class="c-uspSlide__article">
                <!-- <animated-content type="fade-in-top-left" v-if="item.fields.icon && item.fields.icon.value && item.fields.icon.value.svgCode" :turn-off="fields.turnOffTextAnimation?.value"> -->
                <animated-content type="fade-in-top-left" v-if="item.fields.icon && item.fields.icon.value && item.fields.icon.value.svgCode">
                  <div class="c-uspSlide__article__icon">
                    <Icon :svg="item.fields.icon.value.svgCode" :style="item.fields.iconColor ? 'color:' + item.fields.iconColor.value : ''" />
                  </div>
                </animated-content>

                <!-- <animated-content type="skew-in" :hide-content="false" :turn-off="fields.turnOffTextAnimation?.value"> -->
                <animated-content type="skew-in" :hide-content="false">
                  <jss-rich-text v-if="item.fields.remarksTitle" class="c-uspSlide__remarksTitle" :field="item.fields.remarksTitle" tag="div" />
                </animated-content>

                <!-- <animated-content type="fade-in" :hide-content="false" :turn-off="fields.turnOffTextAnimation?.value"> -->
                <animated-content type="fade-in" :hide-content="false">
                  <jss-rich-text v-if="item.fields.remarksWord" class="c-uspSlide__remarksWord" :field="item.fields.remarksWord" tag="div" />
                </animated-content>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, nextTick, onMounted, onUnmounted, toRefs, ref } from 'vue';
import Swiper from 'swiper';
import { isDesktop, isDesktopLarge, isTabletLandscape } from '@/utils/dom-utils';
import { debounce } from 'lodash';
/**
 * @typedef UspSlideFields
 * @property {GlobalSettingEntry} theme
 * */
export default {
  name: 'UspSlide',
  props: {
    /**@type UspSlideFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const uspSlide = ref(null);

    let swiper = null,
      swiperTag = null,
      sst,
      sst_in;

    const state = reactive({
      /**@type {HTMLElement}*/
      swiperEl: null,
      /**@type {HTMLElement}*/
      swiperTag: null,
      tagList: [],
      activeTop: 0,
      activeHeight: 0,
      iconTitleContent: null
    });

    props.fields?.tagList.forEach((val, e) => {
      state['uspTagList' + e] = null;
    });

    const buildSwiper = () => {
      if (swiper) {
        swiper.destroy(true, true);
      }

      if (swiperTag) {
        swiperTag.destroy(true, true);
      }

      if (!isDesktop() && !isDesktopLarge() && !isTabletLandscape()) {
        swiperTag = new Swiper(state.swiperTag, {
          slidesPerView: 'auto',
          on: {
            slideChange: () => {
              swiper.slideTo(swiperTag.activeIndex, 1000);
            }
          }
        });

        swiper = new Swiper(state.swiperEl, {
          slidesPerView: 1.005,
          spaceBetween: 20,
          on: {
            slideChange() {
              swiperTag.slideTo(swiper.activeIndex, 1000);
            }
          }
        });
      } else {
        state.activeHeight = state.swiperTag?.clientHeight / state.tagList.length + 'px';

        swiper = new Swiper(state.swiperEl, {
          direction: 'vertical',
          on: {
            slideChangeTransitionEnd: () => {
              state.tagList.forEach((val, e) => {
                if (e === swiper.activeIndex) {
                  val.selected = true;

                  let slideTop = state['uspTagList' + swiper.activeIndex][0].offsetTop;

                  state.activeTop = slideTop;
                } else {
                  val.selected = false;
                }
              });

              let windowTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;

              window.scrollTo(0, windowTop + 1);
            }
          }
        });
      }
    };

    const methods = {
      isDesktop: isDesktop,
      isDesktopLarge: isDesktopLarge,
      isTabletLandscape: isTabletLandscape,
      resizePageSet: debounce(async (e) => {
        state.activeHeight = state.swiperTag.clientHeight / state.tagList?.length + 'px';
        state.activeTop = state['uspTagList' + swiper.activeIndex][0].offsetTop;
      }, 300),
      tagClick(index, id) {
        state.tagList.forEach((val, e) => {
          if (e == index) {
            val.selected = true;

            swiper.slideTo(e, 1000);

            if (isDesktop() || isDesktopLarge() || isTabletLandscape()) {
              let slideTop = state[id][0].offsetTop;

              state.activeTop = slideTop;
            }
          } else {
            val.selected = false;
          }
        });
      }
    };

    onMounted(() => {
      nextTick(() => {
        buildSwiper();
        window.addEventListener('resize', methods.resizePageSet);
      });

      state.tagList = props.fields?.tagList || [];
      if (state.tagList.length > 0) {
        state.tagList[0].selected = true;
      }
    });
    onUnmounted(() => {
      window.removeEventListener('resize', methods.resizePageSet);

      if (swiper) {
        swiper.destroy(true, true);
      }

      if (swiperTag) {
        swiperTag.destroy(true, true);
      }
    });

    return {
      uspSlide,
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-uspSlide {
  width: 100%;
  padding: 28px 0;
  @include grid-container;
  overflow: hidden;

  .c-uspSlide__content {
    @include grid-block(2, 10);
  }
}

.c-uspSlide__tags {
  position: relative;
  overflow: hidden;

  .c-uspSlide__tags__line {
    content: '';
    display: block;
    width: 63px;
    height: 3px;
    overflow: hidden;
    background: #000;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    transition: left 1s ease;
  }

  .swiper-slide {
    position: relative;
    display: inline-block;
    height: 48px;
    border-bottom: 1px solid #7f7f7f;
    cursor: pointer;

    em {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
    }
    &.swiper-slide-active {
      em {
        font-weight: bold;
      }
    }
  }
}

.c-uspSlide__slideContent__image {
  position: relative;
  width: 100%;
  height: 52vw;
  overflow: hidden;
  margin: 40px 0 24px 0;
  .e-background-image {
    width: 100%;
    height: 100%;
  }
}

.c-uspSlide__article {
  width: 100%;

  .c-uspSlide__article__icon {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0 0 16px 0;

    .e-icon {
      color: #000;
      > svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .c-uspSlide__remarksTitle {
    position: relative;
    width: 100%;
    font-size: 24px;
    color: #000;
    line-height: 1.1;
    padding-left: 20px;
  }
  .c-uspSlide__remarksWord {
    width: 100%;
    font-size: 16px;
    color: $grey-neutral;
    line-height: 1.4;
    padding-top: 16px;
    padding-left: 20px;
  }
}

@include tablet-landscape {
  .c-uspSlide {
    .c-uspSlide__content {
      position: relative;
      @include grid-block(3, 20);
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .c-uspSlide__tags {
    flex-basis: 100%;
    overflow: hidden;
    padding: 0 0 0 50px;
    margin: 0 0 0 50px;
    border-left: 1px solid #7f7f7f;

    .c-uspSlide__tags__line {
      content: '';
      display: block;
      width: 4px;
      //height: 100px;
      left: 0;
      top: 0;
      bottom: auto;
      transition: top 1s ease, height 1s ease;
    }

    .swiper-wrapper {
      display: flex;
      flex-direction: column;
    }

    .swiper-slide {
      position: relative;
      display: flex;
      width: 100%;
      height: auto;
      padding: 0;
      border: 0;
      flex-grow: 1;
      line-height: 1;
      align-items: center;

      /*&:first-child {
        align-items: flex-start;
        flex-grow: 0.5;
      }
      &:last-child {
        align-items: flex-end;
        flex-grow: 0.5;
      }*/

      &.active {
        em {
          font-weight: bold;
        }
      }
    }
  }

  .c-uspSlide__slideContent {
    width: 70%;
    height: auto;
    min-height: 500px;
    max-height: 88vh;
    overflow: hidden;
    flex-shrink: 0;
  }
  .c-uspSlide__slideContent__image {
    margin: 1px 0 34px 0;
    height: 59vh;
  }

  .c-uspSlide__article {
    .c-uspSlide__remarksTitle {
      font-size: 32px;
    }
  }
}

@include desktop-large {
  .c-uspSlide__article {
    .c-uspSlide__remarksWord {
      width: 70%;
    }
  }
}
html.rtl {
  .c-uspSlide__tags {
    @include tablet-landscape {
      border-left: 0;
      border-right: 1px solid #7f7f7f;
      margin: 0 50px 0 0;
      padding: 0 50px 0 0;
    }
    &__line {
      right: 0;
    }
  }
}
</style>
