<script setup>
/**
 * @typedef {NewsletterFields} KmiUsFields
 * */
import { ref, computed, reactive, nextTick, inject, onMounted } from 'vue';
import { getGlobalConfigs, getPageAlpha2Code, scrollElToTop, settingValue } from '@/utils/site-utils';
import api from '@/api';
import { merge, debounce } from 'lodash';
import { countryNeedDoubleOptin } from '@/services/siteService';
import { formatDate, parseDate } from '@/utils/date-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { qtUtils } from '@/utils/ali-tracker-utils';
import { scrollToFirstFormError } from '@/utils/dom-utils';
import { getQueryStrings } from '@/utils/uri-utils';
import { ERROR_CODES, sitecoreComponentProps } from '@/utils/constants';
import { gtmFormView, gtmFormStart, gtmFormSubmit, gtmPush } from '@/utils/gtm-utils';
import services from '@/services';
import { equalString } from '@/utils/string-utils';
import useAppStore from '@/store/appStore';
import onScrollToView from '@/hooks/onScrollToView';

const props = defineProps({
  /**@type KmiUsFields*/
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
const loading = inject('loading');
const toast = inject('toast');
const result = ref(null);
const agent = ref(null);
const overallRef = ref();
const layoutRef = ref();
/**@type Ref<DynamicForm>*/
const formRef = ref();
/**@type Ref<DynamicForm>*/
const agentServiceTime = ref([]);
const needDoubleOptin = ref(false);
const submittedFormData = ref(null);
const submitted = ref(false);
const submitDisabled = ref(true);
const appStore = useAppStore();
const { formType } = props.page.fields;
const formChanged = ref(false);
const formConfig = reactive({
  preferredDate: {
    trigger: 'blur'
  }
});
const countryCode = ref(getPageAlpha2Code(props.page));
const zipcode = ref(null);
const selectedDealer = ref(null);
const customErrors = ref([]);
const [gmapKey, contactUsLink] = getGlobalConfigs(props.page, 'gmapKey', 'contactUsLink');
const layoutFields = computed(() => (submitted.value ? props.fields.successLayout?.fields : props.fields.layout?.fields) ?? props.fields.layout?.fields);

const _qt = {
  trackSubmit(isSucceed, isSubscribed, formData) {
    const eventParams = {
      is_success: isSucceed,
      is_subscribe: isSubscribed
    };
    if (!isNullOrEmpty(formData)) {
      const { firstName, lastName, email, country } = formData;
      merge(eventParams, {
        email,
        first_name: firstName,
        last_name: lastName,
        country: countryCode.value
      });
    }
    qtUtils.trackBtnClickWithCode(props.page, props.fields.submitButton, 'keep_me_informed_pg_clk', eventParams);
  }
};

const pushError = (name, errorMsg) => {
  const errors = customErrors.value;
  let errorItem = errors?.find((x) => equalString(x.name, name));
  if (!errorItem) {
    errorItem = { name };
    errors.push(errorItem);
  }
  errorItem.errorMsg = errorMsg;
  customErrors.value = errors;
};
const removeError = (name) => {
  const errors = customErrors.value;
  let index = errors?.findIndex((x) => equalString(x.name, name));
  if (index < 0) return;
  errors.splice(index, 1);
  customErrors.value = errors;
};
const setWorkTimes = async () => {
  agentServiceTime.value = [];
  formRef.value.setItemMsg('agent', null);
  removeError('agent');
  formRef.value.updateItem('preferredTime', {
    controlProps: {
      options: [],
      selectedOption: null
    }
  });
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const dateVal = formRef.value.getItemValueByName('preferredDate');
  if (dateVal?.length !== 10) return;
  const appointmentTime = parseDate(`${dateVal} 24:59:59`);
  if (appointmentTime <= now) {
    const errorMsg = formRef.value.getItemRequiredMsg('preferredDate');
    pushError('preferredDate', errorMsg);
    formRef.value.setItemErrorMsg('preferredDate', errorMsg);
    return;
  }
  const dayOfWeek = appointmentTime.getDay();
  const { businessHoursSalesObj } = agent.value;
  if (!businessHoursSalesObj?.length) {
    const errorMsg = await services.site.getSiteError({ code: ERROR_CODES.storeNotAvailable });
    pushError('agent', errorMsg);
    formRef.value.setItemErrorMsg('agent', errorMsg);
    return;
  }
  const workTime = businessHoursSalesObj.find((x) => x.week === dayOfWeek);
  if (!workTime) {
    const errorMsg = await services.site.getSiteError({ code: ERROR_CODES.storeNotAvailable });
    pushError('agent', errorMsg);
    formRef.value.setItemErrorMsg('agent', errorMsg);
    return;
  }
  const { workStartTime, workEndTime } = workTime;
  if (isNullOrEmpty(workStartTime) || isNullOrEmpty(workEndTime)) {
    const errorMsg = await services.site.getSiteError({ code: ERROR_CODES.storeNotAvailable });
    pushError('agent', errorMsg);
    formRef.value.setItemErrorMsg('agent', errorMsg);
    return;
  }
  const [startHour, startMin] = workStartTime.split(':');
  const [endHour, endMin] = workEndTime.split(':');
  const startTime = new Date(),
    endTime = new Date();
  startTime.setHours(startHour, startMin, 0, 0);
  endTime.setHours(endHour, endMin, 0, 0);
  const serviceTime = [];
  for (let i = 10; i < 20; i++) {
    const time = new Date();
    time.setHours(i, 0, 0, 0);
    if (time >= startTime && time <= endTime) {
      serviceTime.push({
        code: `${i}:00:00`,
        text: `${i}: 00${i > 12 ? 'PM' : 'AM'}`
      });
    }
  }
  agentServiceTime.value = serviceTime;
  formRef.value.updateItem('preferredTime', {
    controlProps: {
      options: serviceTime,
      selectedOption: null
    }
  });
};
const onFormChange = async (name, value) => {
  if (!formChanged.value) {
    formChanged.value = true;
    gtmFormStart(formType?.value, props.fields.form.id);
  }
  submitDisabled.value = !formRef.value.hasAllMandatoryCheckboxChecked();
  removeError(name);
  if (name === 'agent') {
    await getAgent(value?.code);
  }
  if (name === 'preferredDate') {
    await setWorkTimes();
  }
};

const getAgent = async (storeCode) => {
  const [_agent, ex] = await api.store.detail({ storeCode });
  if (!_agent) {
    agent.value = null;
    const errorMsg = await services.site.getSiteError({ code: ERROR_CODES.storeNotExist });
    pushError('agent', errorMsg);
    formRef.value.setItemErrorMsg('agent', errorMsg);
    return null;
  }
  agent.value = _agent;
  formRef.value.setSelectedOption('agent', storeCode);
  await setWorkTimes();
  return agent;
};
const onSubmit = debounce(async () => {
  if (customErrors.value?.length > 0) {
    formRef.value.showFormErrorToast();
    return;
  }
  const { channel } = props.fields;
  const alpha2Code = getPageAlpha2Code(props.page);
  const [formValid, formData] = await formRef.value.validate();
  const leadTypes = formRef.value.getLeadTypes();
  loading.show();
  if (formValid) {
    submittedFormData.value = formData;
    const { firstName, middleName, lastName, email, mobile, agent, preferredDate, preferredTime, vehicleModelOfInterest, whatToDiscuss, specificRequest, isAboveTwenty, externalData } = formData;
    const consentNos = formRef.value.getVerifiedConsents();
    needDoubleOptin.value = await countryNeedDoubleOptin(alpha2Code);
    const body = merge(
      {
        vehicleModelOfInterest: vehicleModelOfInterest?.code,
        firstName: firstName,
        middleName: middleName ?? '',
        lastName: lastName,
        email: email,
        phone: `${mobile?.area.code} ${mobile?.number}`,
        company: '',
        title: '',
        countryRegion: countryCode.value,
        zipCode: '',
        storeCode: agent.code,
        preferredTime: formatDate(`${preferredDate} ${preferredTime.code}`, 'yyyy-MM-ddTHH:mm:ss.000+0000'),
        discussContent: whatToDiscuss?.code,
        description: specificRequest,
        needDoubleOptin: false,
        termsAndConditions: consentNos.map((x) => ({
          revisionNumber: x,
          title: window.document.title,
          formUrl: window.location.href,
          effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
        })),
        channel: settingValue(channel, 'Official Website'),
        formScId: formData.formScId,
        gRecaptchaToken: formData.gRecaptchaToken ?? null
      },
      externalData
    );
    if (!isNullOrEmpty(isAboveTwenty)) {
      merge(body, { isAboveTwenty });
    }
    const [res, ex] = await api.lcms.crm.newsletter.subscribe(null, body);
    loading.hide();
    if (ex) {
      await toast.showEx(ex);
      _qt.trackSubmit(false, formData.checkbox, formData);
      return;
    }
    const formDetails = {
      selected_model: vehicleModelOfInterest?.code ?? null,
      selected_location_name: null,
      selected_location_type: null,
      selected_location_date: null,
      selected_location_time: null,
      customer_type: null,
      dealer_name: agent.text
    };
    const leadsDetails = {};
    if (leadTypes?.length) {
      leadTypes.forEach((l) => {
        leadsDetails[l.leadType] = res ?? null;
      });
    }
    gtmFormSubmit(
      formType?.value,
      props.fields.form.id,
      {
        email: email,
        mobileAreaCode: mobile.area.code,
        phone: mobile.number
      },
      formDetails,
      leadsDetails
    );
    gtmPush({
      event: 'newsletter_subscribe',
      lead_id: res.leadId
    });
    result.value = res;
    submitted.value = true;
    await nextTick();
    scrollElToTop(overallRef.value?.rootEl);
    _qt.trackSubmit(true, formData.checkbox, formData);
  } else {
    await scrollToFirstFormError();
    loading.hide();
  }
}, 100);
onScrollToView({
  getContainer() {
    return layoutRef.value.rootEl;
  },
  callback() {
    gtmFormView(formType?.value, props.fields.form.id);
  }
});
onMounted(async () => {
  countryCode.value = getPageAlpha2Code(props.page);
  submitDisabled.value = !formRef.value.hasAllMandatoryCheckboxChecked();
  const [storeCode] = getQueryStrings('storeCode');
  if (!isNullOrEmpty(storeCode)) {
    await getAgent(storeCode);
  }
});
</script>

<template>
  <overall-settings :fields="fields" ref="overallRef">
    <form-layout :fields="props.fields.layout.fields" :class="['c-sales-appointment__layout', { submitted }]" ref="layoutRef">
      <template v-slot:image-content>
        <icon class="c-sales-appointment__logo" :field="fields.logo" />
        <div class="c-sales-appointment__image-content" v-if="!submitted">
          <heading class="c-sales-appointment__image-title" :field="fields.imageTitle" rich />
          <jss-rich-text class="c-sales-appointment__image-body" :field="fields.imageBody" />
        </div>
      </template>
      <div class="c-sales-appointment">
        <div v-show="!submitted">
          <div class="c-sales-appointment__section">
            <jss-rich-text class="c-sales-appointment__title" :field="fields.layout?.fields.title" />
            <jss-rich-text class="c-sales-appointment__body text-desc" :field="fields.body" tag="div" />
            <dynamic-form :form="fields.form" :data="formConfig" @change="onFormChange" ref="formRef">
              <template #dealerOption="scope">
                <div class="c-sales-appointment__dealer-option" :class="[{ 'with-mile': !!scope.option.data.metre }]">
                  <div class="c-sales-appointment__dealer-option-name">{{ scope.option.text }}</div>
                  <div class="c-sales-appointment__dealer-option-metre" v-if="scope.option.data.metre">
                    {{ Math.floor(scope.option.data.metre / (scope.option.data.metre > 1000 ? 1000 : 1)) }}{{ scope.option.data.metre > 1000 ? 'km' : 'm' }}
                  </div>
                </div>
              </template>
            </dynamic-form>
          </div>
          <div class="c-sales-appointment__toolbar">
            <site-button class="c-sales-appointment__submit-btn" v-bind="fields.submitButton" :disabled="submitDisabled" @click="onSubmit" />
          </div>
        </div>

        <div class="c-sales-appointment__result" v-show="submitted">
          <heading class="c-sales-appointment__result-title" :field="fields.successTitle" rich />
          <jss-rich-text class="c-sales-appointment__result-text mg-b-32" :field="fields.successBody" tag="div" />
          <div class="c-sales-appointment__result-sections">
            <div class="c-sales-appointment__result-section">
              <jss-rich-text class="fs-26 fw-500" :field="fields.locationTitle" tag="div" />
              <div class="c-sales-appointment__result-store" v-if="agent">
                <div class="c-sales-appointment__result-store-name">{{ agent?.storeName }}</div>
                <div class="c-sales-appointment__result-store-info">
                  <div class="c-sales-appointment__result-store-item">
                    <icon name="pin" size="tiny" />
                    <span>{{ agent?.addressDetail }}</span>
                  </div>
                  <div class="c-sales-appointment__result-store-item" v-if="!$isNullOrEmpty(agent?.serviceCall)">
                    <icon name="phone-l" size="tiny" />
                    <span>{{ agent?.serviceCall }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-sales-appointment__result-section">
              <jss-rich-text class="fs-26 fw-500" :field="fields.detailsTitle" tag="div" />
              <div class="c-sales-appointment__result-details">
                <p>{{ submittedFormData?.firstName }} {{ submittedFormData?.lastName }}</p>
                <p>{{ submittedFormData?.email }}</p>
                <p>+{{ submittedFormData?.mobile?.area?.code }} {{ submittedFormData?.mobile?.number }}</p>
              </div>
            </div>
          </div>
          <site-button class="c-sales-appointment__result-btn" v-bind="fields.successButton" />
        </div>
      </div>
    </form-layout>
  </overall-settings>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-sales-appointment {
  $c: &;
  $l: s-form-layout;
  &__logo {
    margin-left: grid-width-m(1);
    margin-top: grid-width-m(1);
  }
  &__image-content {
    position: absolute;
    bottom: grid-width-m(1);
    left: grid-width-m(1);
  }
  &__image-body {
    margin-top: $space-40;
  }
  &__title {
    @include h6;
    margin-bottom: 16px;
  }
  &__body {
    margin-bottom: 40px;
  }
  &__toolbar {
    height: 60px;
    margin-top: -10px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__consent-title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 8px;
  }
  &__result {
    &-title {
      @include h5;
      margin-bottom: 48px;
    }
    &-subtitle {
      @include h6;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $grey-89;
    }
    &-btn {
      margin-bottom: 80px;
    }
    &-sections {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 48px;
    }
    &-section {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &-store {
      display: flex;
      flex-direction: column;
      color: $grey-neutral;
      gap: 24px;
      &-name {
        font-size: 18px;
        font-weight: 500;
      }
      &-info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-size: 12px;
      }
      &-item {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    &-details {
      display: flex;
      flex-direction: column;
      gap: 16px;
      color: $grey-neutral;
      font-size: 12px;
    }
    &-btn {
      margin-top: 40px;
    }
  }
  &__layout {
    .#{$l}__side-image {
      display: block;
      color: $white;
    }
    .s-form-layout__image {
      .e-background-image__content {
        height: 100%;
      }
    }
    &.submitted.s-form-layout {
      position: relative;
      .s-form-layout__image-wrapper {
        height: 100%;
      }
      .s-form-layout__image {
        height: 100%;
      }
      .s-form-layout__main {
        position: relative;
        z-index: 1;
        padding-top: 56px;
      }
      .s-form-layout__side-image {
        .e-background-image__img {
          height: auto;
          background-color: #f8ed6e;
          background-position: bottom center !important;
          background-size: 100% auto;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .selected {
    #{$c}__dealer-option {
      &-metre {
        color: $white;
      }
    }
  }
  @include tablet-landscape {
    &__logo {
      margin-left: grid-width(2);
      margin-top: grid-width(1);
    }
    &__image-content {
      position: absolute;
      bottom: grid-width(2);
      left: grid-width(2);
    }
    &__layout {
      &.submitted.s-form-layout {
        position: static;
        .s-form-layout__side-image {
          position: static;
          height: auto;
          .e-background-image__img {
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
