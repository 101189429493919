<template>
  <overall-settings v-bind="$props">
    <div class="c-textOverlapImage" ref="textOverlapImage">
      <div class="c-textOverlapImage__trigger" ref="textOverlapImage_trigger"></div>
      <div class="c-textOverlapImage__content" ref="wrapperEl">
        <div class="c-textOverlapImage__tbContent--new" ref="textInEl">
          <section class="c-textOverlapImage__tbContent--blank" ref="textIn_blank"></section>
          <section class="c-textOverlapImage__tbContent" ref="textInEl_new">
            <JssRichText id="titleObj" class="c-textOverlapImage__title" :field="fields.titleOut" />
            <div
              v-if="fields.showTopButton?.value && !isMobile() && !isTablet()"
              :style="`top:` + buttonTop + 'px'"
              class="c-textOverlapImage__buttonLine1"
              :class="fields.buttonsLayout?.fields.phrase.value ?? 'vertical'"
              ref="buttonLineEl"
            >
              <animated-content type="bottom-fade-in" :hide-content="false">
                <SiteButton v-bind="fields.buttonPrimary" />
              </animated-content>
              <animated-content type="bottom-fade-in" :hide-content="false">
                <SiteButton v-bind="fields.buttonWord" />
              </animated-content>
            </div>
          </section>
        </div>
        <section class="c-textOverlapImage__imgContent" :class="`ratio-${imageRatio}`" v-aspect-ratio="imageRatio" ref="img_out_content">
          <div class="imgScroll-mb" ref="imgContentEl" :class="{ 'imgScroll-mb--active': thisCount === 1 }">
            <div class="c-textOverlapImage__title c-textOverlapImage__title--in" ref="textOutEl">
              <section class="c-textOverlapImage__inNew__overContent--outside" ref="inNew_overContent_outside">
                <section class="c-textOverlapImage__inNew__overContent" ref="inNew_overContent">
                  <div class="c-textOverlapImage__title--in--new" ref="textOutEl_new">
                    <JssRichText :field="fields.titleIn" />
                  </div>
                </section>
              </section>
            </div>
            <div class="c-textOverlapImage__img" ref="imgEl">
              <adaptive-image :field="fields.adaptiveImage" v-if="fields.adaptiveImage" />
              <background-image :image="fields.image" v-else />
            </div>
          </div>
        </section>
        <section class="c-textOverlapImage__bottomBlockLine bottomBlockLine1"></section>
        <div
          v-if="(!fields.showTopButton?.value && !isMobile() && !isTablet()) || isMobile() || isTablet()"
          class="c-textOverlapImage__buttonLine"
          :class="fields.buttonsLayout?.fields.phrase.value ?? 'vertical'"
          ref="buttonLineEl"
        >
          <animated-content type="bottom-fade-in" :hide-content="false">
            <SiteButton v-bind="fields.buttonPrimary" />
          </animated-content>
          <animated-content type="bottom-fade-in" :hide-content="false">
            <SiteButton v-bind="fields.buttonWord" />
          </animated-content>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, watch, computed, nextTick } from 'vue';
import { settingValue, loadScrollMagic } from '@/utils/site-utils';
import { isMobile, isTablet, isDesktop, isDesktopLarge, isTabletLandscape } from '@/utils/dom-utils';
import gsap from 'gsap';
import { debounce } from 'lodash';
/**
 * @typedef TextOverlapImageFields
 * @property {GlobalSettingEntry} theme
 * */

export default {
  name: 'TextOverlapImage',
  props: {
    /**@type TextOverlapImageFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    let ScrollMagic, controller, scene, scene2, tl;

    const state = reactive({
      wrapperEl: null,
      textInEl: null,
      textOutEl: null,
      imgContentEl: null,
      buttonLineEl: null,
      imageHeight: 0,

      thisCount: 0,
      imgEl: null,
      textOverlapImage: null,
      textOutEl_new: null,
      textInEl_new: null,

      windowHeight: 0,
      windowPercentHeight: 0,
      thisOfHeight: 0,
      buttonLineHeight: 0,
      titleHeight: 0,
      distanceHeight: 0,
      buttonTop: 0,

      wordOffTop: 0,
      textIn_blank: null,
      inNew_overContent_outside: null,
      inNew_overContent: null,

      img_out_content: null
    });
    const computes = {
      imageRatio: computed(() => settingValue(props.fields.imageRatio, '3_4'))
    };
    watch(
      () => state.textInEl_new,
      (v) => {
        state.textIn_blank.style.height = v.clientHeight + 'px';
      },
      { deep: true }
    );
    const methods = {
      isDesktop: isDesktop,
      isDesktopLarge: isDesktopLarge,
      isTabletLandscape: isTabletLandscape,
      isMobile,
      isTablet,
      widthInit() {
        if (!isDesktop() && !isDesktopLarge() && !isTabletLandscape()) {
          state.windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
          state.windowPercentHeight = state.windowHeight * 0.4;
          state.thisOfHeight = state.textOverlapImage.clientHeight;
          state.buttonLineHeight = state.buttonLineEl.clientHeight + 40;
          state.titleHeight = state.textInEl.clientHeight;
          state.distanceHeight = state.thisOfHeight - state.buttonLineHeight + 50 - state.titleHeight;

          state.wordOffTop = state.windowPercentHeight;
          state.textIn_blank.style.height = state.textInEl_new.clientHeight + 'px';

          state.inNew_overContent_outside.style.height = state.textOutEl_new.clientHeight + 'px';

          state.textInEl_new.style.width = state.imgContentEl.clientWidth + 'px';
          state.inNew_overContent_outside.style.width = state.imgContentEl.clientWidth - 8 + 'px';

          state.imgEl.style.height = state.img_out_content.clientHeight + 'px';
        } else {
          state.textOutEl_new.style.width = state.textInEl_new.clientWidth + 'px';
          state.textOutEl.style.width = state.imgEl.clientWidth + 'px';

          state.inNew_overContent_outside.style.height = 'auto';
          state.buttonTop = document.getElementById('titleObj').offsetHeight + 60;
        }
      },
      onScroll() {
        if (props.fields.turnOffTextAnimation?.value) return;
        if (!isDesktop() && !isDesktopLarge() && !isTabletLandscape()) {
          let thisOfTop = state.textOverlapImage?.getBoundingClientRect().top;

          if (thisOfTop <= state.windowPercentHeight) {
            let bidis = state.distanceHeight;

            if (state.windowPercentHeight - thisOfTop <= bidis) {
              state.thisCount = 0;
              /*state.textInEl.style['transform'] = 'translateY(' + parseFloat(state.windowPercentHeight - thisOfTop) + 'px)';

              state.textOutEl.style['margin-top'] = parseFloat(state.windowPercentHeight - thisOfTop) + 'px';*/

              state.textInEl_new.style['position'] = 'fixed';
              state.textInEl_new.style['transform'] = 'translateY(' + state.wordOffTop + 'px)';
              state.textInEl_new.style['left'] = 'auto';

              state.textOutEl.style['position'] = 'fixed';
              state.textOutEl.style['transform'] = 'none';
              state.textOutEl.style['margin-top'] = state.wordOffTop - 104 + 'px';
              state.textOutEl.style['left'] = 'auto';

              state.inNew_overContent.style.height = -thisOfTop + state.windowPercentHeight + 64 + 'px';
            } else {
              state.textInEl_new.style['position'] = 'absolute';
              state.textInEl_new.style['transform'] = 'translateY(' + bidis + 'px)';
              state.textInEl_new.style['left'] = '0';

              state.textOutEl.style['position'] = 'absolute';
              state.textOutEl.style['margin-top'] = parseFloat(bidis) + 'px';
              state.textOutEl.style['left'] = '0';
              state.textOutEl.style['transform'] = 'translateY(-100%)';

              state.inNew_overContent.style.height = state.textOutEl_new.clientHeight + 'px';

              state.thisCount = 1;
            }
          } else {
            state.textInEl_new.style['position'] = 'absolute';
            state.textInEl_new.style['transform'] = 'translateY(0)';
            state.textInEl_new.style['left'] = '0';

            state.textOutEl.style['position'] = 'absolute';
            state.textOutEl.style['margin-top'] = '0px';
            state.textOutEl.style['left'] = '0';
            state.textOutEl.style['transform'] = 'translateY(-100%)';

            state.inNew_overContent.style.height = 64 + 'px';

            state.thisCount = 0;
          }
        }
      },

      scrollPC: () => {
        if (props.fields.turnOffTextAnimation?.value) return;
        controller = new ScrollMagic.Controller();

        scene = new ScrollMagic.Scene({
          triggerElement: state.wrapperEl,
          offset: '150%',
          duration: state.wrapperEl.offsetHeight - state.textOutEl.offsetHeight - 180
          // duration: state.wrapperEl.offsetHeight - 500
        })
          .setPin(state.textOutEl)
          .addTo(controller);

        scene2 = new ScrollMagic.Scene({
          triggerElement: state.wrapperEl,
          offset: '150%',
          duration: state.wrapperEl.offsetHeight - state.textOutEl.offsetHeight - 180
          // duration: state.wrapperEl.offsetHeight - 500
        })
          .setPin(state.textInEl)
          .addTo(controller);
      },

      resizePageSet: debounce(async () => {
        methods.widthInit();
        if (props.fields.turnOffTextAnimation?.value) return;
        if (isDesktop() || isDesktopLarge() || isTabletLandscape()) {
          setTimeout(() => {
            controller.updateScene(scene, true);
            controller.updateScene(scene2, true);
          }, 100);
        }
      }, 300)
    };

    onMounted(() => {
      methods.widthInit();
      methods.onScroll();

      nextTick(() => {
        ScrollMagic = loadScrollMagic();
        tl = gsap.timeline();

        if (isDesktop() || isDesktopLarge() || isTabletLandscape()) {
          methods.scrollPC();
        }
      });

      window.addEventListener('scroll', methods.onScroll);
      window.addEventListener('resize', methods.resizePageSet);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', methods.onScroll);

      if (isDesktop() || isDesktopLarge() || isTabletLandscape()) {
        controller.destroy();
        scene.destroy();
        scene2.destroy();
      }

      window.removeEventListener('resize', methods.resizePageSet);
    });

    return {
      ...computes,
      ...methods,
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/color.scss';
@import '../styles/rte/fontFamily.scss';

.c-textOverlapImage__inNew__overContent--outside {
  position: relative;
  width: 100%;
  height: 100%;
}

.c-textOverlapImage__inNew__overContent {
  width: 100%;
  height: 64px;
}

.c-textOverlapImage__title--in--new {
  width: 100%;
}

.c-textOverlapImage {
  position: relative;
  width: 100vw;
  @include grid-container;
  overflow: hidden;

  .c-textOverlapImage__content {
    position: relative;
    height: auto;
    overflow: hidden;
    @include grid-block(2, 10);
  }
}
.c-textOverlapImage__trigger {
  display: none;
  width: 100%;
  height: 140%;
  bottom: 0;
  left: 0;
  opacity: 0;
}
.c-textOverlapImage__tbContent {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.c-textOverlapImage__tbContent--blank {
  width: 100%;
  height: 100%;
  background: transparent;
}
.c-textOverlapImage__title {
  position: relative;
  width: 100%;
  padding: $space-40 0 $space-40 18px;
  letter-spacing: -12px;
  @include h2;

  &.c-textOverlapImage__title--in {
    position: absolute;
    left: 0;
    z-index: 2;
    font-weight: 200;
    line-height: 1;
    color: yellow;
    top: 104px;
    transform: translateY(-100%);
  }
}

.c-textOverlapImage__imgContent {
  position: relative;
  width: 100%;
  min-height: 500px;
  overflow: hidden;
  z-index: 1;
  margin-top: -174px;
  // transition: margin-top 1s ease;

  &.ratio {
    &-1_1 {
      aspect-ratio: 1/1;
    }
    &-3_2 {
      aspect-ratio: 3/2;
    }
    &-3_4 {
      aspect-ratio: 3/4;
    }
    &-4_3 {
      aspect-ratio: 4/3;
    }
    &-16_9 {
      aspect-ratio: 16/9;
    }
  }

  .imgScroll-mb {
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: height 1s ease;

    &.imgScroll-mb--active {
      height: 65%;
    }
  }
}
.c-textOverlapImage__buttonLine {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;

  &.horizontal {
    flex-direction: row;
    align-items: center;
    gap: $space-10;
  }
  &.vertical {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $space-10;
    .e-site-button {
      width: grid-width-m(8);
    }
  }

  .e-site-button {
    .e-icon {
      padding: 0;
      svg {
        width: 16px;
      }
    }
  }
}
.c-textOverlapImage__img {
  position: relative;
  width: 100%;
  height: 100%;
  background: $grey-taubmans;
  overflow: hidden;
  .e-background-image {
    height: 100%;
  }
}
.c-textOverlapImage__bottomBlockLine {
  clear: both;
  width: 100%;
  overflow: hidden;

  &.bottomBlockLine1 {
    height: 40px;
  }
  &.bottomBlockLine2 {
    height: 140px;
  }
}

@include mobile {
  .c-textOverlapImage__buttonLine {
    flex-direction: column !important;

    .e-site-button {
      width: grid-width-m(8) !important;
    }
  }
}

@include tablet-landscape {
  .c-textOverlapImage {
    display: block;

    .c-textOverlapImage__content {
      width: 100%;
      height: 100%;
      @include grid-container;
    }
  }
  .c-textOverlapImage__tbContent--new {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    left: 0;
    top: 0;
    @include grid-container;
  }
  .c-textOverlapImage__tbContent {
    position: relative;
    @include grid-block(3, 21);
    height: 100%;
    overflow: hidden;
    transition: none;
  }
  .c-textOverlapImage__tbContent--blank {
    display: none;
  }
  .c-textOverlapImage__title {
    position: absolute;
    left: 0;
    top: 60px;
    transition: none;
    padding: 0;
    margin-left: 0;
    letter-spacing: -10px;

    &.c-textOverlapImage__title--in {
      position: absolute;
      width: 100%;
      top: 60px;
      right: 0;
      z-index: 4;
      transform: none;
      overflow: hidden;
    }
  }
  .c-textOverlapImage__inNew__overContent--outside {
    position: relative;
    width: 100%;
    height: auto;
  }

  .c-textOverlapImage__inNew__overContent {
    position: relative;
    width: 100%;
    height: auto;
    left: auto;
    bottom: auto;
  }
  .c-textOverlapImage__title--in--new {
    position: relative;
    left: auto;
    bottom: auto;
    float: right;
    margin-left: -10px;
  }
  .c-textOverlapImage__buttonLine {
    position: absolute;
    overflow: hidden;
    left: grid-width(2);
    right: grid-width(2);
    bottom: 60px;
    z-index: 4;
    align-items: flex-start !important;
    gap: 15px;

    .e-site-button {
      max-width: grid-width(4);
      overflow: hidden;
    }
  }
  .c-textOverlapImage__buttonLine1 {
    position: absolute;
    overflow: hidden;
    top: 360px;
    z-index: 4;
    align-items: flex-start !important;
    gap: 15px;

    .e-site-button {
      width: grid-width(3) !important;
    }
  }
  .c-textOverlapImage__imgContent {
    @include grid-block(11, 13);
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin-top: 0;

    .imgScroll-mb {
      &.imgScroll-mb--active {
        height: 100%;
      }
    }
  }
  .c-textOverlapImage__img {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .c-textOverlapImage__bottomBlockLine {
    height: 0 !important;
  }
}
html.rtl {
  .c-textOverlapImage {
    @include tablet-landscape {
      &__title--in--new {
        float: left;
      }
    }
  }
}
</style>
