<template>
  <overall-settings v-bind="$props">
    <div class="c-simple-image-new" :class="[{ 'has-padding': header && menuBar && isFirstComponent }]" v-if="fields">
      <AdaptiveImage class="c-simple-image-new__content" :class="`align-${$settingValue(fields.align, 'left')}`" :field="fields.image" :isBackground="false" />
    </div>
  </overall-settings>
</template>

<script>
import { sitecoreComponentProps } from '@/utils/constants';
import { computed } from 'vue';
import { equalString } from '@/utils/string-utils';
import utils from '@/utils';

export default {
  name: 'SimpleImageNew',
  props: {
    fields: {
      type: Object
    },
    ...sitecoreComponentProps
  },
  setup(props) {
    const computes = {
      header: computed(() => utils.sitecore.findComponent(props.page, 'Header', 'jss-header')),
      menuBar: computed(() => utils.sitecore.findComponent(props.page, 'MenuBar', null)),
      isFirstComponent: computed(
        () =>
          props.page.placeholders['jss-main'].findIndex((x) => equalString(x.dataSouce, props.rendering.dataSouce)) === 0 ||
          props.page.placeholders['jss-header'].findIndex((x) => equalString(x.dataSouce, props.rendering.dataSouce)) >= 0
      )
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-simple-image-new {
  width: 100%;
  $img: '.e-background-image';
  &__content {
    display: flex;
    padding: 10px;
    background: $white;
    &.e-background-image {
      #{$img}__img {
        width: auto;
        max-width: 100%;
      }
    }
    &.align-center {
      justify-content: center;
    }
    &.align-right {
      justify-content: flex-end;
    }
  }
  &.has-padding {
    padding-top: 108px;
    background: $black;
  }
  @include desktop {
    &.has-padding {
      padding-top: 120px;
    }
  }
}
</style>
