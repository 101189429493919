<template>
  <OverallSettings v-bind="$props">
    <div class="c-store-locator-store-list" v-if="fields">
      <SiteButton class="c-store-locator-store-list__prev" v-bind="fields.back" @click="onPrev" />
      <Heading class="c-store-locator-store-list__title" :field="{ value: title }" :type="fields.titleType" rich />
      <div class="c-store-locator-store-list__items" v-if="storeList?.length">
        <div class="c-store-locator-store-list__item" v-for="store in storeList" :key="store.id" @click="jump(store)">
          <div class="c-store-locator-store-list__item-left">
            <div class="c-store-locator-store-list__item-name">{{ store.storeName }}</div>
            <div class="c-store-locator-store-list__item-address">{{ store.addressDetail }}</div>
            <div class="c-store-locator-store-list__item-address">{{ store.cityName }}, {{ store.countryName }}</div>
            <div class="c-store-locator-store-list__item-types">
              <template v-if="store.storeType.indexOf('1') >= 0 || store.storeType.indexOf('4') >= 0">
                <div class="c-store-locator-store-list__item-type">
                  {{ storeTypes.find((t) => t.code.indexOf('1') >= 0)?.name }}
                </div>
              </template>
              <template v-if="store.storeType.indexOf('2') >= 0">
                <div class="c-store-locator-store-list__item-type">{{ storeTypes.find((t) => t.code.indexOf('2') >= 0)?.name }}</div>
              </template>
            </div>
          </div>
          <div class="c-store-locator-store-list__item-right" v-html="fields.icon.value.svgCode" />
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { computed, inject, onMounted, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { getStoreTypes } from '@/services/siteService';
import { getGlobalConfigs } from '@/utils/site-utils';
import { equalString, formatString } from '@/utils/string-utils';
import { redirectTo, appendQuery } from '@/utils/uri-utils';
import { getWebLinkStores } from '@/services/agentService';
import { setMetaData } from '@/utils/dom-utils';
import { sortByChar } from '@/utils/array-utils';
import { merge } from 'lodash';
export default {
  name: 'StoreLocatorStoreList',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const loading = inject('loading');
    const router = useRouter();
    const [areas, storeLocatorOverviewLink] = getGlobalConfigs(props.page, 'areas', 'storeLocatorOverviewLink');
    const state = reactive({
      storeList: [],
      storeTypes: null,
      currentCountry: null
    });
    const computes = {
      title: computed(() => formatString(props.fields.title?.value, { country: state.currentCountry?.name.toUpperCase() }))
    };
    const methods = {
      init: async () => {
        if (state.storeList.length > 0) {
          state.storeList.forEach((i) => {
            let temp = {
              '@context': 'https://schema.org',
              '@type': 'AutomotiveBusiness',
              name: i.storeName,
              image: i.storeImgDtoList[0]?.externalUrl,
              '@id': window.location.href,
              url: i.extra?.officialWebsiteUrl,
              telephone: i.serviceCall,
              address: { '@type': 'PostalAddress', streetAddress: i.addressDetail, addressLocality: i.countryName, postalCode: i.extra?.storeZipCode, addressCountry: i.country2Code },
              geo: {
                '@type': 'GeoCoordinates',
                latitude: i.latitude,
                longitude: i.longitude
              },
              openingHoursSpecification: [
                {
                  '@type': 'OpeningHoursSpecification',
                  dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                  opens: i.workStartTime,
                  closes: i.workEndTime
                }
              ]
            };
            const script = document.createElement('script');
            script.setAttribute('type', 'application/ld+json');
            script.textContent = JSON.stringify(temp);
            document.head.appendChild(script);
          });
        }
        loading.hide();
      },
      onPrev: () => {
        redirectTo({ url: storeLocatorOverviewLink?.value?.href, router });
      },
      jump: (s) => {
        const reg = /[`~!@#$^&*()=|{}':;',\\\\[\]\\.<>\\/?~@#……&*——|{}'""'\s]/g;
        if (s.detailsLink?.url) {
          window.open(s.detailsLink.url);
        } else {
          const detailsLink = appendQuery(`${storeLocatorOverviewLink?.value.href}/${s.countryName.replace(reg, '')}/${s.storeName.replace(reg, '')}`);
          redirectTo({ url: detailsLink, router });
        }
      }
    };
    onMounted(async () => {
      loading.show();
      const [hiddenStores] = getGlobalConfigs(props.page, 'hiddenStores');
      const hiddenStoreCodes = (hiddenStores?.value ?? '').split(';');
      const webLinkStores = await getWebLinkStores();
      state.storeTypes = await getStoreTypes();
      const currentArea = areas.find((a) => equalString(a.fields.code.value, props.page.fields.areaCode.value));
      state.currentCountry = currentArea?.fields?.regions.find((r) => equalString(r.fields.alpha2Code.value, props.page.fields.storeCountry2Code.value));
      const storeList = webLinkStores.find((i) => i.children?.length && equalString(JSON.parse(i.children[0].details).country2Code, props.page.fields.storeCountry2Code.value));
      storeList?.children.forEach((s) => {
        if (!s.disabled && !hiddenStoreCodes.some((y) => equalString(y, s.storeCode))) {
          state.storeList.push(merge(s, JSON.parse(s.details)));
        }
      });
      state.storeList = sortByChar(Array.from(new Set(state.storeList)), (e) => e.storeName);
      setMetaData('title', formatString(props.fields?.metaTitle?.value, { countryName: state.currentCountry.name }));
      setMetaData('description', formatString(props.fields?.metaDescription?.value, { countryName: state.currentCountry.name }));
      methods.init();
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-store-locator-store-list {
  padding: 36px grid-width-m(1) 80px;
  &__prev {
    width: fit-content;
    &.e-site-button.tertiary-button.theme-black {
      color: $grey-neutral;
    }
  }
  &__title {
    margin-top: 20px;
  }
  &__items {
    margin-top: 40px;
  }
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
    padding: 24px 0;
    border-bottom: 1px solid $grey-light;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border: 0;
    }
    // &-left {
    //   width: grid-width-m(6);
    // }
    &-name {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
    &-address {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $grey-next;
      margin-top: 6px;
      opacity: 0.8;
    }
    &-types {
      display: flex;
      flex-direction: row;
      gap: 6px;
      flex-wrap: wrap;
      margin-top: 12px;
    }
    &-type {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 8px;
      padding: 8px 12px;
      border: 1px solid $black;
    }
    &-right {
      > svg {
        width: 16px;
      }
    }
  }
  @include tablet-landscape {
    padding: 80px grid-width(2) 160px;
    &__title {
      max-width: grid-width(8);
    }
    &__items {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    &__item {
      width: grid-width(12);
      cursor: pointer;
    }
  }
}
</style>
