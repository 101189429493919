<template>
  <OverallSettings v-bind="$props">
    <div class="c-simple-test-drive">
      <div class="c-simple-test-drive__greeting" v-if="step === 'greeting'">
        <div class="c-simple-test-drive__greeting-img">
          <AdaptiveImage :field="fields.greetingImage">
            <div class="c-simple-test-drive__greeting-img-text">
              <!-- <AnimatedContent type="fade-in" :duration="1200" :delay="400" :turn-off="!largeThanTablet() || fields.turnOffAnimation?.value"> -->
              <AnimatedContent type="fade-in" :duration="1200" :delay="400">
                <JssRichText :field="fields.greetingImageTitle" class="c-simple-test-drive__greeting-img-text-title" />
              </AnimatedContent>
              <!-- <AnimatedContent type="fade-in" :duration="1200" :delay="600" :turn-off="!largeThanTablet() || fields.turnOffAnimation?.value"> -->
              <AnimatedContent type="fade-in" :duration="1200" :delay="600">
                <JssRichText :field="fields.greetingImageBody" class="c-simple-test-drive__greeting-img-text-body" />
              </AnimatedContent>
            </div>
          </AdaptiveImage>
        </div>
        <div class="c-simple-test-drive__greeting-main">
          <template v-if="!selectedStoreCode">
            <div class="c-simple-test-drive__header">
              <SiteButton class="c-simple-test-drive__header-back" v-bind="fields.backButton" @click="back" />
              <JssRichText class="c-simple-test-drive__header-step" :field="fields.agentsStepText" />
            </div>
            <!-- <AnimatedContent type="fade-in" :duration="800" :delay="1800" :turn-off="!largeThanTablet() || fields.turnOffAnimation?.value"> -->
            <AnimatedContent class="c-simple-test-drive__main" type="fade-in" :duration="800" :delay="1800">
              <JssRichText :field="fields.agentsTitle" class="c-simple-test-drive__main-title" />
              <JssRichText :field="fields.agentsBody" class="c-simple-test-drive__main-body" />
            </AnimatedContent>
            <div class="c-simple-test-drive__agent-main">
              <JssRichText class="c-simple-test-drive__agent-main-subtitle" :field="fields.agentsSubtitle" />
              <JssRichText class="c-simple-test-drive__agent-main-description" :field="fields.agentsDescription" />
              <DynamicForm class="c-simple-test-drive__agent-main-form" :form="fields.agentsForm" :data="agentFormData" :carModel="fields.carModel" ref="agentsFormRef" @change="onAgentsFormChange" />
              <div class="c-simple-test-drive__agent-main-list" v-if="storeList?.length">
                <div class="c-simple-test-drive__agent-main-list-item" v-for="item in storeList" :key="item.id" @click="selectAgent(item)">
                  <div class="c-simple-test-drive__agent-main-list-item-left">
                    <div class="c-simple-test-drive__agent-main-list-item-name">{{ item.data.storeName }}</div>
                    <div class="c-simple-test-drive__agent-main-list-item-des">{{ item.data.addressDetail }}</div>
                  </div>
                  <div class="c-simple-test-drive__agent-main-list-item-right">
                    <Icon name="back" />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="c-simple-test-drive__header">
              <SiteButton class="c-simple-test-drive__header-back" v-bind="fields.backButton" @click="back" />
              <JssRichText class="c-simple-test-drive__header-step" :field="fields.formStepText" />
            </div>
            <!-- <AnimatedContent type="fade-in" :duration="800" :delay="1800" :turn-off="!largeThanTablet() || fields.turnOffAnimation?.value"> -->
            <AnimatedContent class="c-simple-test-drive__main" type="fade-in" :duration="800" :delay="1800">
              <JssRichText :field="formTitle" class="c-simple-test-drive__main-title" />
              <JssRichText :field="formBody" class="c-simple-test-drive__main-body" />
            </AnimatedContent>
            <div class="c-simple-test-drive__form-main">
              <JssRichText class="c-simple-test-drive__form-main-subtitle" :field="fields.formSubtitle" />
              <JssRichText class="c-simple-test-drive__form-main-description" :field="fields.formDescription" />
              <DynamicForm class="c-simple-test-drive__form-main-form" :form="fields.form" :data="mainFormData" ref="mainFormRef" @change="onMainFormChange" />
              <SiteButton class="c-simple-test-drive__form-main-btn" v-bind="fields.formButton" @click="submit" />
              <JssRichText class="c-simple-test-drive__form-main-policy" :field="fields.formPolicy" />
              <div class="c-simple-test-drive__help">
                <JssRichText :field="fields.helpTitle" class="c-simple-test-drive__help-title" />
                <JssRichText :field="fields.helpBody" class="c-simple-test-drive__help-body" />
                <div class="c-simple-test-drive__help-btns">
                  <SiteButton class="c-simple-test-drive__help-btn" v-for="btn in fields.helpButtons" :key="btn.id" v-bind="btn" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="c-simple-test-drive__summary" v-if="step === 'summary'">
        <AdaptiveImage class="c-simple-test-drive__summary-img" :field="fields.carModel.fields.summaryImage">
          <div class="c-simple-test-drive__summary-img-title">
            <JssRichText :field="fields.summaryTitle" />
          </div>
          <div class="c-simple-test-drive__summary-img-text">
            <JssRichText :field="fields.summaryBody" class="c-simple-test-drive__summary-img-text-body" />
            <div class="c-simple-test-drive__summary-img-text-list">
              <div class="c-simple-test-drive__summary-img-text-list-item" v-for="item in fields.summaryList" :key="item.id">
                <Icon name="skew-arrow" class="c-simple-test-drive__summary-img-text-list-item-icon" />
                <JssRichText :field="item.fields.text" class="c-simple-test-drive__summary-img-text-list-item-text" />
              </div>
            </div>
          </div>
        </AdaptiveImage>
        <div class="c-simple-test-drive__summary-main">
          <AdaptiveImage class="c-simple-test-drive__summary-main-img" :field="fields.carModel.fields.summaryPoster" />
          <div class="c-simple-test-drive__summary-main-detail">
            <AdaptiveImage class="c-simple-test-drive__summary-main-detail-img" :field="fields.carModel.fields.carImage" :isBackground="false" />
            <JssRichText class="c-simple-test-drive__summary-main-detail-title" :field="fields.carModel.fields.summaryDetailsTitle" />
            <div class="c-simple-test-drive__summary-main-detail-text">
              <JssRichText class="c-simple-test-drive__summary-main-detail-text-subtitle" :field="fields.summaryDetailsSubtitle1" />
              <div class="c-simple-test-drive__summary-main-detail-text-location">
                <div class="c-simple-test-drive__summary-main-detail-text-location-row">{{ selectedStore.data.storeName }}</div>
                <div class="c-simple-test-drive__summary-main-detail-text-location-row">
                  <div class="c-simple-test-drive__summary-main-detail-text-location-row-item">
                    <Icon name="location" class="c-simple-test-drive__summary-main-detail-text-location-row-item-icon" />
                    <div class="c-simple-test-drive__summary-main-detail-text-location-row-item-text">{{ selectedStore.data.addressDetail }}</div>
                  </div>
                  <div class="c-simple-test-drive__summary-main-detail-text-location-row-item">
                    <Icon name="mobile" class="c-simple-test-drive__summary-main-detail-text-location-row-item-icon" />
                    <div class="c-simple-test-drive__summary-main-detail-text-location-row-item-text">{{ selectedStore.data.extra.salesLine }}</div>
                  </div>
                </div>
              </div>
              <JssRichText class="c-simple-test-drive__summary-main-detail-text-subtitle" :field="fields.summaryDetailsSubtitle2" />
              <div class="c-simple-test-drive__summary-main-detail-text-info">
                <div>{{ userInfo.firstName }} {{ userInfo.surname }}</div>
                <div>{{ userInfo.email }}</div>
                <div>{{ userInfo.phoneNum }}</div>
                <div>{{ userInfo.birthday }}</div>
                <div v-if="userInfo.type === 'business'">{{ userInfo.companyName }}</div>
              </div>
            </div>
            <SiteButton class="c-simple-test-drive__summary-main-btn" v-bind="fields.completeButton" />
          </div>
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { reactive, toRefs, inject, onMounted, computed, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { getShippingCountryOptions } from '@/services/siteService';
import { getCurrentAlpha2Code, getPageAlpha2Code } from '@/utils/site-utils';
import { largeThanTablet } from '@/utils/dom-utils';
import { formatDate } from '@/utils/date-utils';
import { equalString, formatString } from '@/utils/string-utils';
import { deepCopy, isNullOrEmpty } from '@/utils/obj-utils';
import { getQueryStrings } from '@/utils/uri-utils';
import { gtmFormView, gtmFormStart, gtmFormStep, gtmFormSubmit } from '@/utils/gtm-utils';
import { debounce, merge } from 'lodash';
import api from '@/api';
import services from '@/services';
import useAppStore from '@/store/appStore';
export default {
  name: 'SimpleTestDrive',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const appStore = useAppStore();
    const loading = inject('loading');
    const toast = inject('toast');
    const router = useRouter();
    const { formType } = props.page.fields;
    const state = reactive({
      carModel: null,
      step: 'greeting',
      agentsFormRef: null,
      agentFormData: {
        countryRegion: {
          controlProps: {
            selectedOption: null
          }
        }
      },
      agentList: [],
      storeList: null,
      selectedStore: null,
      selectedStoreCode: null,
      mainFormRef: null,
      mainFormData: {
        firstName: {
          controlProps: {
            value: ''
          }
        },
        surname: {
          controlProps: {
            value: ''
          }
        },
        email: {
          controlProps: {
            value: '',
            disabled: false
          }
        },
        company: {
          visible: false,
          controlProps: {
            value: ''
          }
        }
      },
      userInfo: null,
      formChanged: false
    });

    const computes = {
      formTitle: computed(() => {
        let tmp = deepCopy(props.fields.formTitle);
        tmp.value = formatString(tmp.value, { storeName: state.selectedStore.data.storeName });
        return tmp;
      }),
      formBody: computed(() => {
        let tmp = deepCopy(props.fields.formBody);
        tmp.value = formatString(tmp.value, { storeName: state.selectedStore.data.storeName });
        return tmp;
      })
    };

    const methods = {
      largeThanTablet,
      back: () => {
        if (isNullOrEmpty(state.selectedStoreCode)) {
          router.back();
        } else {
          methods.initStoreList();
          state.selectedStore = null;
          state.selectedStoreCode = null;
        }
      },
      selectAgent: (i) => {
        state.selectedStore = i;
        state.selectedStoreCode = i.data.storeCode;
        if (!state.formChanged) {
          state.formChanged = true;
          gtmFormStart(formType?.value, props.fields.form.id);
        }
        gtmFormStep(formType?.value, props.fields.form.id, appStore?.loginInfo, 2, 'personal_details', null);
      },
      onAgentsFormChange: async (key, value) => {
        if (!state.formChanged) {
          state.formChanged = true;
          gtmFormStart(formType?.value, props.fields.form.id);
        }
        if (value?.code) {
          state.storeList = await services.agent.getSalesAgentOptions(props.page, value?.code, null, state.carModel);
        } else {
          state.storeList = [];
        }
      },
      onMainFormChange: (key, value) => {
        if (equalString(key, 'type')) {
          if (equalString(value.code, 'business')) {
            state.mainFormData.company.visible = true;
          } else {
            state.mainFormData.company.visible = false;
            state.mainFormData.company.controlProps.value = '';
          }
        }
      },
      submit: debounce(async () => {
        loading.show();
        const alpha2Code = getPageAlpha2Code(props.page);
        const [valid, formData] = await state.mainFormRef.validate();
        const consents = state.mainFormRef.getVerifiedConsents();
        const leadTypes = state.mainFormRef.getLeadTypes();
        const formDetails = {
          selected_model: state.carModel ?? null,
          selected_location_name: 'agent',
          selected_location_type: state.selectedStore.text,
          selected_location_date: null,
          selected_location_time: null,
          customer_type: formData.type.code ?? null,
          dealer_name: state.selectedStore.text
        };
        let leadsDetails = {};
        if (valid) {
          const body = merge(
            {
              termsAndConditions: consents.map((x) => ({
                revisionNumber: x,
                title: window.document.title,
                formUrl: window.location.href,
                effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
              })),
              // type: formData.type.code ?? '',
              vehicleModelOfInterest: state.carModel,
              storeCode: state.selectedStoreCode ?? '',
              firstName: formData.firstName ?? '',
              lastName: formData.surname ?? '',
              birthday: !isNullOrEmpty(formData.brithday) ? formatDate(formData.brithday) : '',
              email: formData.email ?? '',
              company: formData.company ?? '',
              phone: !isNullOrEmpty(formData.phone) ? `+${formData.phone.area.code} ${formData.phone.number}` : '',
              countryRegion: alpha2Code ?? '',
              language: props.page.itemLanguage ?? '',
              testDriveRequest: true,
              formScId: formData.formScId ?? '',
              gRecaptchaToken: formData.gRecaptchaToken ?? null
            },
            formData?.externalData
          );
          if (!isNullOrEmpty(formData.isAboveTwenty)) {
            merge(body, { isAboveTwenty: formData.isAboveTwenty });
          }
          const [res, ex] = await api.testDrive.reservation(null, body);

          if (ex) {
            loading.hide();
            await toast.showEx(ex);
            return;
          }
          if (formData?.CPTC) {
            const cptcBody = merge(
              {
                firstName: formData.firstName,
                middleName: formData.middleName ?? '',
                lastName: formData.lastName,
                email: formData.email,
                countryRegion: alpha2Code,
                channel: 'Official Website'
              },
              formData?.externalData
            );
            if (!isNullOrEmpty(formData?.isAboveTwenty)) {
              merge(cptcBody, { isAboveTwenty: formData?.isAboveTwenty });
            }
            const [cptcRes] = await api.lcms.crm.cptc.create(null, cptcBody);

            if (leadTypes?.length && cptcRes) {
              leadTypes.forEach((l) => {
                if (equalString(l.consentType, 'CPTC')) {
                  leadsDetails[l.leadType] = cptcRes.leadId ?? null;
                }
              });
            }
          }
          state.userInfo = {
            type: formData.type.code,
            firstName: formData.firstName ?? '',
            surname: formData.surname ?? '',
            email: formData.email,
            phoneNum: '',
            mobileAreaCode: '',
            phone: '',
            birthday: !isNullOrEmpty(formData.brithday) ? formatDate(formData.brithday) : '',
            companyName: formData.company ?? ''
          };
          if (!isNullOrEmpty(formData.phone)) {
            state.userInfo.phoneNum = `+${formData.phone.area.code} ${formData.phone.number}`;
            state.userInfo.mobileAreaCode = formData.phone.area.code;
            state.userInfo.phone = formData.phone.number;
          }
          if (leadTypes?.length) {
            leadTypes.forEach((l) => {
              leadsDetails[l.leadType] = res.leadId ?? null;
            });
          }
          gtmFormSubmit(formType?.value, props.fields.form.id, state.userInfo, formDetails, leadsDetails);
          state.step = 'summary';
          loading.hide();
        }
        loading.hide();
      }, 20),
      initStoreList: async () => {
        const alpha2Code = getCurrentAlpha2Code();
        const shippingCountries = await getShippingCountryOptions(props.fields.carModel?.code);
        const currentCountry = shippingCountries.find((x) => equalString(x.code, alpha2Code));
        const { countryRegion } = state.agentFormData;
        const { options } = countryRegion.controlProps;
        let selectedOption = null;
        if (currentCountry) {
          selectedOption = currentCountry;
        } else {
          selectedOption = options?.length ? options[0] : null;
        }
        if (selectedOption) {
          state.agentsFormRef.updateItem('countryRegion', {
            controlProps: {
              selectedOption
            }
          });
          state.storeList = await services.agent.getSalesAgentOptions(props.page, selectedOption.code, null, state.carModel);
        }
        loading.hide();
      },
      getUserInfo: () => {
        const [firstname, lastname, email] = getQueryStrings('firstname', 'lastname', 'email');
        state.mainFormData.firstName.controlProps.value = firstname;
        state.mainFormData.surname.controlProps.value = lastname;
        state.mainFormData.email.controlProps.value = email;
        state.mainFormData.email.controlProps.disabled = !!email;
      }
    };
    onMounted(async () => {
      loading.show();
      state.carModel = props.fields.carModel?.fields?.code?.value ?? '';
      const [store] = getQueryStrings('store');
      if (store) {
        state.agentList = await services.agent.getAgentList(props.page, null, null, state.carModel);
        const agent = state.agentList.find((r) => r.storeCode === store);
        if (agent) {
          state.selectedStore = {
            code: agent.id,
            data: agent,
            text: agent.storeName
          };
          state.selectedStoreCode = store;
          // nextTick(() => {
          //   methods.getUserInfo();
          // });
          loading.hide();
          gtmFormStart(formType?.value, props.fields.form.id);
          gtmFormStep(formType?.value, props.fields.form.id, appStore?.loginInfo, 2, 'personal_details', null);
        } else {
          await nextTick();
          methods.initStoreList();
          gtmFormStep(formType?.value, props.fields.form.id, appStore?.loginInfo, 1, 'agent_selection', null);
        }
      } else {
        await nextTick();
        methods.initStoreList();
        gtmFormStep(formType?.value, props.fields.form.id, appStore?.loginInfo, 1, 'agent_selection', null);
      }
      gtmFormView(formType?.value, props.fields.form.id);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-simple-test-drive {
  $c: &;
  &__greeting {
    &-img {
      height: grid-width-m(math.div(12 * 9, 16));
      .e-background-image {
        height: 100%;
      }
      &-text {
        position: absolute;
        bottom: 50px;
        left: 24px;
        right: 24px;
        &-body {
          margin-top: 20px;
        }
      }
    }
    &-main {
      padding: 40px 32px 30px;
      &-body {
        margin-top: 15px;
      }
      &-list {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        &-item {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 15px;
          &-icon {
            transform: rotate(-90deg);
            > svg {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
      &-btn {
        margin-top: 40px;
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__main {
    margin-top: 48px;
    &-body {
      margin-top: 16px;
    }
  }
  &__agent {
    &-main {
      margin-top: 48px;
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
      &-title {
        margin-top: 40px;
      }
      &-form {
        margin-top: 8px;
      }
      &-list {
        display: flex;
        flex-direction: column;
        &-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 30px 0;
          border-bottom: 1px solid $grey-taubmans;
          &-left {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }
          &-name {
            font-size: 18px;
            line-height: 32px;
            color: $black;
          }
          &-des {
            font-size: 16px;
            line-height: 24px;
            color: $grey-neutral;
            opacity: 0.8;
          }
          &-right {
            .e-icon {
              transform: rotate(180deg);
            }
          }
          &:first-child {
            padding-top: 0;
          }
        }
      }
    }
  }
  &__form {
    &-main {
      margin-top: 48px;
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
      &-title {
        margin-top: 28px;
      }
      &-body {
        margin-top: 14px;
      }
      &-form {
        margin-top: 24px;
      }
      &-policy {
        margin-top: 20px;
      }
    }
  }
  &__summary {
    &-img {
      height: 700px;
      .e-background-image {
        height: 100%;
        &__content {
          padding: 80px 32px 0;
        }
      }
      &-text {
        margin-top: 48px;
        &-list {
          margin-top: 48px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          &-item {
            display: flex;
            flex-direction: row;
            gap: 20px;
            &-icon {
              color: $white;
              transform: rotateY(180deg);
            }
          }
        }
      }
    }
    &-main {
      display: flex;
      flex-direction: column;
      margin-top: -100px;
      padding: 0 32px 32px;
      &-img {
        width: 100%;
        height: calc(100vw - 64px);
      }
      &-detail {
        margin-top: 30px;
        &-img {
          height: 136px;
          > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        &-title {
          margin-top: 50px;
        }
        &-text {
          margin-top: 50px;
          padding-top: 10px;
          border-top: 1px solid $grey-taubmans;
          &-subtitle {
            margin-top: 40px;
          }
          &-location {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;
            &-row {
              display: inline-flex;
              flex-direction: column;
              gap: 8px;
              font-size: 18px;
              line-height: 28px;
              color: $grey-neutral;
              &-item {
                display: inline-flex;
                flex-direction: row;
                gap: 8px;
                &-icon {
                  color: $grey-neutral;
                  > svg {
                    width: 16px;
                    height: 16px;
                  }
                }
                &-text {
                  font-size: 12px;
                  line-height: 20px;
                  color: $grey-neutral;
                }
              }
            }
          }
          &-info {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            > div {
              font-size: 16px;
              line-height: 24px;
              color: $grey-neutral;
            }
          }
        }
      }
      &-btn {
        margin-top: 24px;
      }
    }
  }
  &__help {
    margin-top: 50px;
    &-title {
      margin-top: 48px;
    }
    &-body {
      margin-top: 16px;
    }
    &-btns {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      > .e-site-button {
        height: auto;
      }
    }
  }
  @include tablet-landscape {
    &__greeting {
      display: flex;
      flex-direction: row-reverse;
      &-img {
        position: relative;
        height: 100vh;
        animation: draw-in 3s forwards;
        z-index: 2;
        &-text {
          left: 80px;
          right: 80px;
          bottom: 116px;
        }
      }
      &-main {
        height: 100vh;
        width: 50%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: auto;
        padding: 48px 100px 80px;
        &-list {
          margin-top: 60px;
        }
        &-btns {
          margin-top: 60px;
        }
      }
    }
    &__agent {
      display: flex;
      flex-direction: row-reverse;
      &-main {
        margin-top: 80px;
        flex: 1;
        &-title {
          margin-top: 48px;
        }
        &-list {
          &-item {
            cursor: pointer;
          }
        }
      }
    }
    &__form {
      display: flex;
      flex-direction: row-reverse;
      &-main {
        margin-top: 80px;
        flex: 1;
        &-description {
          margin-top: 16px;
        }
        &-form {
          margin-top: 24px;
        }
      }
    }
    &__summary {
      &-img {
        height: grid-width(math.div(24 * 9, 16));
        .e-background-image {
          &__content {
            padding: 200px grid-width(2) 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            > div {
              width: grid-width(8);
            }
          }
        }
      }
      &-main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 90px;
        margin: 0 108px;
        padding-bottom: 120px;
        &-img {
          width: grid-width(10);
          height: grid-width(12);
          margin-top: -200px;
        }
        &-detail {
          width: grid-width(9);
          margin-top: 10px;
          &-img {
            height: 180px;
          }
        }
      }
    }
  }
}
@keyframes draw-in {
  0% {
    width: 100vw;
  }
  40% {
    width: 100vw;
  }
  100% {
    width: 50%;
  }
}
html.rtl {
  .c-simple-test-drive__greeting-main {
    left: unset;
    right: 0;
  }
}
</style>
