<template>
  <overall-settings v-bind="$props">
    <div class="c-supercharged" v-if="fields">
      <div
        :class="[
          !isMobile() && !isTablet() && fields.showColumn?.value ? `c-supercharged-left-column` : `c-supercharged-left`,
          $settingValue(fields.position) === 'right' ? 'c-supercharged-left-active' : '',
          !isMobile() && !isTablet() && fields.showColumn?.value && fields.pcTitle?.value?.indexOf('<br') != -1
            ? `c-supercharged-left-columnTop1`
            : !isMobile() && !isTablet() && fields.showColumn?.value && fields.pcTitle?.value?.indexOf('<br') == -1
            ? `c-supercharged-left-columnTop`
            : ``
        ]"
      >
        <!-- <AnimatedContent type="skew-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)" :turn-off="fields.turnOffTextAnimation?.value"> -->
        <AnimatedContent type="skew-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)">
          <jss-rich-text class="c-supercharged-desc" :field="fields.title" tag="div" />
        </AnimatedContent>
        <!-- <AnimatedContent type="bottom-fade-in" class="c-supercharged-desctiption" :turn-off="fields.turnOffTextAnimation?.value"> -->
        <AnimatedContent type="bottom-fade-in" class="c-supercharged-desctiption">
          <jss-rich-text :field="fields.description" tag="div" />
        </AnimatedContent>
        <!-- <AnimatedContent type="bottom-fade-in" class="c-supercharged-animate" :turn-off="fields.turnOffTextAnimation?.value"> -->
        <AnimatedContent type="bottom-fade-in" class="c-supercharged-animate">
          <div class="c-supercharged-sub-item" @click="isMobile && toggleDes(1)" @mouseover="!isMobile && toggleDes(1)" @mouseout="!isMobile && toggleDes(1)" v-if="fields.firstSub?.value">
            <jss-rich-text class="c-supercharged-sub" :field="fields.firstSub" tag="div" />
          </div>
          <div class="c-supercharged-sub-item" @click="isMobile && toggleDes(2)" @mouseover="!isMobile && toggleDes(2)" @mouseout="!isMobile && toggleDes(2)" v-if="fields.secondSub?.value">
            <jss-rich-text class="c-supercharged-sub" :field="fields.secondSub" tag="div" />
          </div>
        </AnimatedContent>
        <!-- <AnimatedContent type="bottom-fade-in" class="c-supercharged-bottom-animate" v-if="fields.thirdSub?.value" :turn-off="fields.turnOffTextAnimation?.value"> -->
        <AnimatedContent type="bottom-fade-in" class="c-supercharged-bottom-animate" v-if="fields.thirdSub?.value">
          <div class="c-supercharged-sub-item" @click="isMobile && toggleDes(3)" @mouseover="!isMobile && toggleDes(3)" @mouseout="!isMobile && toggleDes(3)">
            <jss-rich-text class="c-supercharged-sub" :field="fields.thirdSub" tag="div" />
          </div>
        </AnimatedContent>
        <!-- <AnimatedContent
          type="bottom-fade-in"
          @click="isMobile && toggleDes(4)"
          @mouseover.prevent="!isMobile && toggleDes(4)"
          @mouseout.prevent="!isMobile && toggleDes(4)"
          :turn-off="fields.turnOffTextAnimation?.value"
        > -->
        <AnimatedContent type="bottom-fade-in" @click="isMobile && toggleDes(4)" @mouseover.prevent="!isMobile && toggleDes(4)" @mouseout.prevent="!isMobile && toggleDes(4)">
          <div :class="[!isMobile() && !isTablet() && fields.showColumn?.value ? `c-supercharged-subtitle-column` : `c-supercharged-subtitle`]">
            <jss-rich-text class="c-supercharged-subtitle-item" :field="fields.speed" tag="div" />
            <jss-rich-text class="c-supercharged-subtitle-item c-supercharged-subtitle-acceleratedSpeed" :field="fields.acceleratedSpeed" tag="div" />
            <jss-rich-text class="c-supercharged-subtitle-item c-supercharged-subtitle-acceleratedSpeedUnit" :field="fields.acceleratedSpeedUnit" tag="div" />
            <jss-rich-text class="c-supercharged-subtitle-item" :field="fields.kilowatt" tag="div" />
            <jss-rich-text class="c-supercharged-subtitle-item" :field="fields.airram" tag="div" />
          </div>
        </AnimatedContent>
      </div>
      <div
        class="c-supercharged-sub-dialog"
        :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-sub-dialog-active' : ''"
        v-if="showIndex === 1 && fields.firstDialog?.value"
        @click="isMobile && hideDialog()"
      >
        <JssRichText :field="fields.firstDialog" />
      </div>
      <div
        class="c-supercharged-sub-dialog"
        :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-sub-dialog-active' : ''"
        v-if="showIndex === 2 && fields.secondDialog?.value"
        @click="isMobile && hideDialog()"
      >
        <JssRichText :field="fields.secondDialog" />
      </div>
      <div
        class="c-supercharged-sub-dialog"
        :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-sub-dialog-active' : ''"
        v-if="showIndex === 3 && fields.thirdDialog?.value"
        @click="isMobile && hideDialog()"
      >
        <JssRichText :field="fields.thirdDialog" />
      </div>
      <div class="c-supercharged-right" :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-right-active' : ''">
        <div class="c-supercharged-image-content">
          <!-- <BackgroundImage class="c-supercharged-pcimage" :image="fields.image" :has-animation="!fields.turnOffImageAnimation?.value" :parallax="!fields.turnOffParallax?.value" /> -->
          <BackgroundImage class="c-supercharged-pcimage" :image="fields.image" />
        </div>
        <div class="c-supercharged-pc-yellow-title-content" :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-pc-yellow-title-content-active' : ''">
          <jss-rich-text class="c-supercharged-pc-yellow-title" :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-pc-yellow-title-active' : ''" :field="fields.pcTitle" tag="div" />
        </div>
        <div class="c-supercharged-mobile-yellow-title-content" :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-mobile-yellow-title-content-active' : ''">
          <jss-rich-text
            class="c-supercharged-mobile-yellow-title"
            :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-mobile-yellow-title-active' : ''"
            :field="fields.mobileTitle"
            tag="div"
          />
        </div>
      </div>

      <div class="c-supercharged-pc-title-content" :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-pc-title-content-active' : ''">
        <jss-rich-text class="c-supercharged-pc-title" :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-pc-title-active' : ''" :field="fields.pcTitle" tag="div" />
      </div>

      <div class="c-supercharged-mobile-title-content" :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-pc-title-content-active' : ''">
        <jss-rich-text class="c-supercharged-mobile-title" :class="$settingValue(fields.position) === 'right' ? 'c-supercharged-mobile-title-active' : ''" :field="fields.mobileTitle" tag="div" />
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { toRefs, reactive } from 'vue';
import { isMobile, isTablet } from '@/utils/dom-utils';
export default {
  name: 'SuperCharged',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      isMobile: isMobile(),
      showIndex: 0,
      oldIndex: 0
    });
    const methods = {
      isMobile,
      isTablet,
      toggleDes(index) {
        let activeIndex = 0;
        if (index === 4 && props.fields.thirdSub) {
          activeIndex = 3;
        } else if (index === 4 && props.fields.second) {
          activeIndex = 2;
        } else {
          activeIndex = 1;
        }
        if (state.showIndex && activeIndex === state.oldIndex) {
          state.showIndex = 0;
        } else {
          state.showIndex = activeIndex;
        }
        state.oldIndex = state.showIndex;
      },
      hideDialog() {
        state.showIndex = 0;
      }
    };
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/fontFamily.scss';
.c-supercharged {
  width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  padding: 0 grid-width-m(1);
  overflow: hidden;
  &-icon-content {
    width: grid-width-m(0.4);
    height: grid-width-m(0.4);
    position: absolute;
    left: grid-width-m(-0.4);
    top: grid-width-m(1);
  }
  &-subtitle {
    margin-top: 10px;
  }
  &-subtitle-item {
    margin-right: 15px;
  }
  &-subtitle-acceleratedSpeed {
    margin-right: 0;
  }
  &-icon {
    width: grid-width-m(0.4);
    height: grid-width-m(0.4);
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &-desctiption {
    margin-bottom: 20px;
    line-height: 1.5;
  }
  &-animate .e-animated-content__main {
    display: inline-block;
  }
  &-left {
    order: 2;
    display: flex;
    flex-direction: column;
    margin-left: grid-width-m(0.5);
    position: relative;
  }
  &-mobile-yellow-title-content {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
  }
  &-sub-item {
    display: flex;
    cursor: pointer;
    line-height: 1.5;
  }
  &-sub-icon {
    display: flex !important;
    align-items: flex-end !important;
    margin-left: 5px;
  }
  &-sub-dialog {
    display: inline-block;
    width: grid-width-m(10);
    overflow: auto;
    position: absolute;
    left: grid-width-m(1);
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    z-index: 30;
    color: #000;
    background-color: #f7f6f6;
    padding: $space-40 grid-width-m(1) $space-40 grid-width-m(1);
    font-size: 14px;
    line-height: 20px;
    .e-background-image {
      height: 100%;
    }
  }
  &-subtitle {
    @include h9;
    color: #000;
    line-height: 1.5;
    display: flex;
    flex-wrap: wrap;
  }
  &-subtitle-item {
    margin-right: 20px;
  }
  &-subtitle-acceleratedSpeed {
    margin-right: 0;
  }
  &-subtitle-acceleratedSpeedUnit {
    display: flex;
    flex-direction: column;
    span {
      line-height: 12px;
    }
  }
  &-desc {
    @include h9;
    margin-top: grid-width-m(1.5);
    display: block;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  &-right {
    order: 1;
    width: grid-width-m(7);
    height: grid-width-m(10.5);
    position: relative;
    margin-left: grid-width-m(4);
    overflow: hidden;
    z-index: 1;
  }
  &-right-active {
    margin-left: grid-width-m(-1);
  }
  &-pcimage {
    width: 100%;
    height: 100%;
  }
  &-pc-title {
    display: none;
  }
  &-mobile-title {
    @include h3;
    position: absolute;
    top: grid-width-m(5);
    left: grid-width-m(-2);
  }
  &-mobile-title-active {
    text-align: end;
    left: grid-width-m(-3);
    word-break: keep-all;
  }
  &-pc-yellow-title {
    display: none;
  }
  &-mobile-yellow-title {
    display: block;
    @include h3;
    color: #fff200;
    position: absolute;
    top: grid-width-m(5);
    left: grid-width-m(-2);
  }
  &-mobile-yellow-title-active {
    text-align: end;
    left: grid-width-m(-3);
    word-break: keep-all;
  }
  &-pc-title-content-active {
    left: grid-width-m(-3);
  }
  &-image-content {
    height: grid-width-m(10.5);
  }
  &-pc-title-content {
    display: none;
  }
  &-mobile-title-content {
    position: absolute;
    display: block;
    height: grid-width-m(10.5);
    left: grid-width-m(4);
    margin-left: -8px;
    width: inherit;
  }
  &-bottom-animate {
    margin-top: 20px;
  }
  &-bottom-animate .e-animated-content__main {
    display: inline-block;
  }
  @include tablet-landscape {
    min-height: 560px;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 grid-width(2);
    position: relative;
    &-mobile-title-content {
      display: none;
    }
    &-icon-content {
      width: grid-width(0.8);
      height: grid-width(0.8);
      position: relative;
      left: grid-width(-0.4);
      top: grid-width(0.4);
    }
    &-icon {
      width: grid-width(0.4);
      height: grid-width(0.4);
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &-left {
      order: 1;
      width: grid-width(11);
      min-height: grid-width(9);
      display: flex;
      flex-direction: column;
      margin-left: 0;
      position: relative;
      justify-content: flex-end;
    }
    &-left-column {
      order: 1;
      width: grid-width(11);
      min-height: grid-width(9);
      display: flex;
      flex-direction: column;
      margin-left: 0;
      position: relative;
      justify-content: flex-start;
    }
    &-left-columnTop {
      margin-top: 160px;
    }
    &-left-columnTop1 {
      margin-top: 248px;
    }
    &-left-active {
      order: 2;
    }
    &-subtitle-acceleratedSpeedUnit {
      span {
        line-height: 16px;
      }
    }
    &-sub-dialog {
      width: grid-width(10);
      top: unset;
      bottom: 0;
      left: grid-width(6);
      padding: 40px;
      transform: translateY(0);
    }
    &-sub-dialog-active {
      left: auto;
      right: grid-width(1);
    }
    &-subtitle {
      @include h8;
      color: #000;
      line-height: 1.5 !important;
    }
    &-subtitle-column {
      @include h8;
      color: #000;
      line-height: 1.5 !important;
      display: flex;
      flex-direction: column;
    }
    &-desc {
      @include h9;
      display: block;
      margin-top: 0;
      line-height: 1.5 !important;
    }
    &-pc-title {
      display: block;
      @include h3;
      position: absolute;
      top: 0;
      left: grid-width(-13);

      p {
        white-space: nowrap;
      }
    }
    &-mobile-title {
      display: none;
    }
    &-mobile-yellow-title {
      display: none;
    }
    &-right {
      order: 2;
      width: grid-width(7);
      height: grid-width(9.6);
      position: relative;
      overflow: hidden;
      margin-left: 0;
      z-index: 1;
      margin-top: grid-width(0.4);
    }
    &-right-active {
      order: 1;
    }
    &-pc-title-content {
      display: block;
      position: absolute;
      width: grid-width(7);
      height: grid-width(9.6);
      right: grid-width(2);
      margin-top: grid-width(0.4);
    }
    &-pc-title-content-active {
      left: grid-width(2);
    }
    &-pc-title-active {
      text-align: end;
      right: grid-width(-13);
    }
    &-image-content {
      width: grid-width(7);
      height: grid-width(9.6);
    }
    &-pcimage {
      width: 100%;
      height: 100%;
    }
    &-pc-yellow-title {
      display: block;
      @include h3;
      color: #fff200;
      position: absolute;
      top: 0;
      left: grid-width(-13);
      p {
        white-space: nowrap;
      }
      &-content {
        display: block;
        position: absolute;
        width: grid-width(7);
        height: grid-width(9.6);
        top: 0;
        left: 0;
        &-active {
          left: 0;
          margin-left: 0;
        }
      }
    }
    &-pc-yellow-title-active {
      text-align: end;
      right: grid-width(-13);
    }
    &-bottom-animate {
      margin-top: 20px;
    }
  }
  @include desktop {
    &-subtitle {
      @include h8;
    }
    &-subtitle-column {
      @include h7;
      display: flex;
      flex-direction: column;
    }
    &-desc {
      @include h9;
    }
    &-pc-title {
      @include h4;
    }
    &-pc-yellow-title {
      @include h4;
    }
  }
  @include desktop-middle {
    &-subtitle {
      @include h7;
    }
    &-subtitle-column {
      @include h7;
      display: flex;
      flex-direction: column;
    }
    &-desc {
      @include h8;
    }
    &-pc-title {
      @include h2;
    }
    &-pc-yellow-title {
      @include h2;
    }
    &-sub-dialog {
      left: grid-width(5.5);
    }
    &-sub-dialog-active {
      left: grid-width(14);
    }
  }
  // @include desktop-large {
  //   &-pc-title {
  //     margin-top: 24px;
  //     margin-right: 338px;
  //   }
  // }
}
</style>
