<template>
  <overall-settings v-bind="$props">
    <div class="c-simple-image">
      <div class="c-simple-image__content" v-grid-layout="fields.grid">
        <background-image :is-background="false" :image="$adaptiveImageField(fields.image?.fields?.desktopImage, fields.image?.fields?.mobileImage, fields.image?.fields?.tabletImage)">
          <jss-rich-text class="c-simple-image__title" :field="fields.title" tag="div" />
        </background-image>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { computed } from 'vue';

import useGridLayout from '@/hooks/useGridLayout';
/**
 * @typedef SimpleImageFields
 * @property {GlobalSettingEntry} theme
 * */

export default {
  name: 'SimpleImage',
  props: {
    /**@type SimpleImageFields*/
    fields: {
      type: Object
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-simple-image {
  width: 100%;
  @include grid-container;
  .e-background-image {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 16px grid-width-m(1);
    }
  }
  &__title {
    width: 100%;
    font-size: 40px;
  }
  @include tablet-landscape {
    .e-background-image {
      &__content {
        padding: grid-width(2);
      }
    }
  }
}
</style>
