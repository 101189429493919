<template>
  <OverallSettings v-bind="$props">
    <div class="c-timeline-slider" v-if="fields">
      <div class="c-timeline-slider__paginations-mobile" v-if="fields.slides.length > 1">
        <JssText
          class="c-timeline-slider__paginations-mobile-item"
          ref="mobilePaginationsEL"
          v-for="(slide, index) in fields.slides"
          :class="{ active: index === activeIndex }"
          :key="slide.id"
          :field="slide.fields.year"
          @click="changePagination(slide)"
        />
      </div>
      <div class="swiper-container" ref="swiperEl">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in sliderItems" :key="item.id">
            <div class="c-timeline-slider__slide-image-group">
              <BackgroundImage class="c-timeline-slider__slide-image" :image="item.fields.image" />
              <JssText class="c-timeline-slider__slide-image-year" :field="item.fields.year" tag="div" />
            </div>
            <div class="c-timeline-slider__slide-details">
              <JssRichText class="c-timeline-slider__slide-details-title" :field="item.fields.title" tag="div" />
              <JssRichText class="c-timeline-slider__slide-details-desc" :field="item.fields.body" tag="div" />
            </div>
          </div>
        </div>
      </div>
      <div class="c-timeline-slider__paginations-desktop" v-if="fields.slides.length > 1">
        <div class="c-timeline-slider__paginations-desktop-sides">{{ fields.slides[0].fields.year.value }}</div>
        <div class="c-timeline-slider__paginations-desktop-items">
          <template v-for="(slide, index) in fields.slides" :key="slide.id">
            <template v-if="index === fields.slides.length - 1">
              <div class="swiper-pagination-customs" :class="{ active: index === activeIndex }" @click="changePagination(slide)"></div>
            </template>
            <template v-else>
              <div class="swiper-pagination-customs" :class="{ active: index === activeIndex }" @click="changePagination(slide)"></div>
              <template v-for="i in 10" :key="i">
                <div class="dot"></div>
              </template>
            </template>
          </template>
        </div>
        <div class="c-timeline-slider__paginations-desktop-sides">{{ fields.slides[fields.slides.length - 1].fields.year.value }}</div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { toRefs, onMounted, onUnmounted, nextTick, reactive, computed } from 'vue';
import Swiper from 'swiper';
export default {
  name: 'TimelineSlider',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let swiper, paginations;
    const state = reactive({
      /**@type {HTMLElement}*/
      swiperEl: null,
      /**@type {HTMLElement}*/
      paginationEl: null,
      /**@type {HTMLElement}*/
      mobilePaginationsEL: null,
      /**@type {Number}*/
      activeIndex: 0
    });
    const computes = {
      sliderItems: computed(() => {
        let tmp = [];
        props.fields.slides.forEach((s) => {
          tmp = tmp.concat(s.fields.slides);
        });
        return tmp;
      })
    };
    const methods = {
      buildSwiper: () => {
        if (swiper) {
          swiper.destroy(true, true);
        }
        swiper = new Swiper(state.swiperEl, {
          slidesPerView: 'auto',
          centeredSlides: true,
          on: {
            slideChangeTransitionStart: function() {
              const id = computes.sliderItems.value[this.activeIndex].id;
              props.fields.slides.forEach((s, index) => {
                const f = s.fields.slides.some((i) => i.id === id);
                if (f) state.activeIndex = index;
              });
            }
          }
        });
      },
      buildPaginations: () => {
        if (paginations) {
          paginations.destroy(true, true);
        }
        paginations = new Swiper(state.paginationEl, {
          slidesPerView: 'auto'
        });
      },
      changePagination: (s) => {
        state.activeIndex = props.fields.slides.indexOf(s);
        const index = computes.sliderItems.value.indexOf(s.fields.slides[0]);
        swiper.slideTo(index, 500, false);
      }
    };
    onMounted(() => {
      window.addEventListener('resize', () => {
        methods.buildSwiper();
        methods.buildPaginations();
      });
      nextTick(() => {
        methods.buildSwiper();
        methods.buildPaginations();
      });
    });
    onUnmounted(() => {
      swiper.destroy(true, true);
      paginations.destroy(true, true);
      window.removeEventListener('resize', methods.buildSwiper);
      window.removeEventListener('resize', methods.buildPaginations);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-timeline-slider {
  @include grid-container;
  &__slide-image {
    width: 100%;
    height: grid-width(20);
    &-group {
      position: relative;
    }
    &-year {
      @include h2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .swiper-container {
    @include grid-block(3, 20);
    overflow: hidden;
  }
  &__paginations-mobile {
    @include grid-block(3, 20);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    &-item {
      @include h7;
      padding: 0 $space-12 $space-30;
      &.active {
        color: $yellow;
      }
    }
  }
  &__paginations-desktop {
    display: none;
  }
  &__slide-image-group {
    padding-bottom: $space-20;
  }
  &__slide-details {
    padding-bottom: $space-20;
    &-title {
      @include h4;
      padding-bottom: $space-18;
    }
    &-desc {
      @include h9;
    }
  }
  @include tablet-landscape {
    &__paginations-mobile {
      display: none;
    }
    .swiper-slide {
      width: grid-width(6);
      margin-right: grid-width(2);
      transition: 300ms;
      transform: scale(0.8);
      &-active {
        transform: scale(1);
      }
    }
    &__slide-image {
      width: 100%;
      height: grid-width(6);
    }
    &__slide-image-group {
      padding-bottom: $space-24;
    }
    &__slide-details {
      &-title {
        padding-bottom: $space-24;
      }
    }
    &__paginations-desktop {
      @include grid-block(3, 20);
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      &-sides {
        cursor: default;
      }
      &-items {
        width: grid-width(13);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .dot {
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background: $grey-neutral;
        }
      }
      .swiper-pagination-customs {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: $grey-neutral;
        cursor: pointer;
        &.active {
          background: $yellow;
        }
      }
    }
  }
}
</style>
