<script setup>
import { inject, onMounted, reactive, provide, computed, nextTick, toRefs } from 'vue';
import { appendQuery, concatUrls, getQueryStrings, getRedirectFromUrl, getUrls } from '@/utils/uri-utils';
import api from '@/api';
import { canUseDOM, tryParseJson } from '@/utils/dom-utils';
import { analyzeVehicleFeatures, buildModelImgUrl, getFeaturePriceIds } from '@/utils/mhp-utils';
import services from '../services';
import { sitecoreComponentProps } from '@/utils/constants';
import { decodeData, findCwBlockDetail, getGlobalCfgValues, getPageAlpha2Code, scrollToTop } from '@/utils/site-utils';
import { concatString, equalString, ifEmpty, toCapitalize } from '@/utils/string-utils';
import { formatDate } from '@/utils/date-utils';
import { debounce, merge } from 'lodash';
import { countryNeedDoubleOptin } from '@/services/siteService';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { gtmFormView, gtmFormSubmit } from '@/utils/gtm-utils';
import { unWrapSitecoreItem } from '@/utils/sitecore-utils';
import { useRoute, useRouter } from 'vue-router';
import utils from '@/utils';
import useAppStore from '@/store/appStore';
let transferCode, filterId, configurationCode, storeCode;
const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
const route = useRoute();
const router = useRouter();
const $bus = inject('$bus');
const loading = inject('loading');
const toast = inject('toast');
const { formType } = props.page.fields;
/**@type AppStore*/
const appStore = useAppStore();
const state = reactive({
  formRef: null,
  /**@type CalculatorContainerRef*/
  calculatorContainerRef: null,
  alpha2Code: getPageAlpha2Code(props.page),
  /**@type UsedCarQuery*/
  usedCarQuery: null,
  usedCarDetail: null,
  formulaNo: null,
  paymentFormulaNo: null,
  configuration: null,
  isStock: false,
  currency: null,
  priceIds: [],
  accePriceIds: [],
  buttonFeatureTypes: [],
  /**@type {?number}*/
  basePrice: null,
  /**@type {?number}*/
  retailPrice: null,
  /**@type {?number}*/
  vatAmount: null,
  /**@type {?number}*/
  optionPrice: 0,
  /**@type {?CarSeries}*/
  carSeries: null,
  /**@type {?CarModel}*/
  carModel: null,
  customerTypes: [],
  customerType: null,
  /**@type {Array<PaymentOption>}*/
  paymentOptions: [],
  /**@type {?PaymentOption}*/
  paymentOption: null,
  cwConfig: null,
  dealer: null,
  features: [],
  accessories: [],
  image: null,
  formData: null,
  financeInitialized: false,
  financeCalculating: false,
  submitted: false,
  backButtonProps: null
});
const calculation = reactive({
  /**@type {SantanderQuote}*/
  santander: null,
  /**@type {CwFinanceQuote}*/
  codeWaver: null,
  /**@type {CabQuote}*/
  cab: null
});
const computes = {
  dockCode: computed(() => state.paymentOption?.dock.code)
};
const buildBackButton = () => {
  if (!props.fields?.backButton?.fields) return null;
  const [redirectFrom] = getQueryStrings('redirect_from');
  let href = getRedirectFromUrl(router);
  if (!isNullOrEmpty(redirectFrom)) href = redirectFrom;
  if (isNullOrEmpty(href)) {
    const [mhpLink] = getGlobalCfgValues(props.page, ['mhpLink']);
    const [stockDetailHref] = getUrls(props.page, 'stockDetailLink');
    const mhpHost = ifEmpty(mhpLink?.split('##')[0], 'https://www.lotuscars.com');
    href = isNullOrEmpty(state.vin)
      ? concatUrls(mhpHost, `code/${route.query.configuration_code}?shortUrl=true`)
      : appendQuery(stockDetailHref, {
          vin: state.vin
        });
  }
  return merge({}, props.fields?.backButton.fields, {
    link: {
      value: {
        href
      }
    }
  });
};
const buildPayOptions = () => {
  if (isNullOrEmpty(state.usedCarDetail)) {
    if (state.carSeries?.financeLevel2) {
      state.paymentOptions = unWrapSitecoreItem(state.carSeries?.level2PaymentOptions).filter((x) => !equalString(x.name, 'cash')) ?? [];
    } else {
      state.paymentOptions = [];
      state.financeCalculating = false;
    }
  } else {
    if (state.carSeries?.usedCarL2) {
      state.paymentOptions = unWrapSitecoreItem(state.carSeries?.usedCarL2PayOptions).filter((x) => !equalString(x.name, 'cash')) ?? [];
    } else {
      state.paymentOptions = [];
      state.financeCalculating = false;
    }
  }
  if (state.paymentOptions.length > 0) {
    state.paymentOption = state.paymentOptions[0];
  }
};
const analyzeCfg = async (res, isStock = false) => {
  state.configuration = res;
  [state.features, state.accessories] = analyzeVehicleFeatures(res, isStock);
  [state.formulaNo, state.paymentFormulaNo] = await services.site.getFormulaNosByCode(props.page, res.seriesCode);
  state.priceIds = getFeaturePriceIds(state.features);
  state.buttonFeatureTypes = utils.mhp.getButtonFeatureTypes(state.features, state.accessories);
  state.accePriceIds = utils.mhp.getAccessoryPriceIds(state.accessories);
  state.carSeries = await services.site.getCarSeriesByCode(res.seriesCode);
  state.carModel = await services.site.getCarModelByCode(res.seriesCode, res.modelCode);
  state.currency = res.currency ?? utils.mhp.getFeatureCurrency(state.features) ?? 'EUR';
  buildPayOptions();
};
const loadUsedCarDetail = async () => {
  const [res, ex] = await api.usedCar.detail(null, {
    id: state.usedCarQuery.id,
    longitude: appStore.geoLocation?.longitude ?? null,
    latitude: appStore.geoLocation?.latitude ?? null
  });
  state.usedCarDetail = res;
  const { basicResp, picList, priceResp } = res ?? {};
  const { vehicleConfigInfo, vin, series, model, saleStore } = basicResp ?? {};
  const { allInPrice, currency } = priceResp ?? {};
  state.retailPrice = allInPrice;
  state.basePrice = allInPrice;
  state.carSeries = await services.site.getCarSeries(series);
  state.carModel = await services.site.getCarModel(series, model);
  state.currency = currency;
  if (!isNullOrEmpty(saleStore)) {
    [state.dealer] = await api.store.detail({
      storeCode: saleStore
    });
  }
  const configuration = {
    filterId: state.carModel?.filterId,
    filterName: model,
    seriesCode: state.carSeries?.code,
    modelCode: state.carModel?.code,
    series: series,
    model: model,
    currency: currency,
    vin: vin,
    children: utils.dom.tryParseJson(vehicleConfigInfo, [])
  };
  if (picList?.length > 0) {
    state.image = picList[0]?.url;
  }
  state.configuration = configuration;
  buildPayOptions();
};
const loadStockDetail = async () => {
  const [res, ex] = await api.order.stockDetailByVin(null, {
    country: state.alpha2Code,
    vin: state.vin
  });
  if (!res?.configurationDetails || !res?.detailPrice) {
    await toast.showEx(ex);
    return;
  }
  transferCode = res.transferCode;
  filterId = res.filterId;
  state.vid = res.vid;
  state.isStock = true;
  console.log('vid', res.vid);
  const configuration = {
    filterName: res.filterName,
    seriesCode: res.seriesCode,
    modelCode: res.modelCode,
    series: res.series,
    model: res.vehicleModel,
    organization: res.organization,
    currency: res.currency,
    nextLevels: utils.dom.tryParseJson(res.configurationDetails, [])
  };
  state.currency = res.currency;
  state.priceInfo = tryParseJson(res.detailPrice, []);
  state.retailPrice = utils.site.getPriceFromPcData(state.priceInfo, 'Vehicle Total Price', 'Total Price');
  state.basePrice = utils.site.getPriceFromPcData(state.priceInfo, 'Base Retail Price', 'Base Price');
  state.optionPrice = utils.site.getPriceFromPcData(state.priceInfo, 'Option Price');
  state.vatAmount = utils.site.getPriceFromPcData(state.priceInfo, 'Incl. VAT');
  await analyzeCfg(configuration, true);
  state.image = res?.images?.productDisplay[0]?.externalUrl;
  if (![1, 3].includes(res.organization)) {
    storeCode = res.storeCode;
  }
};
const loadConfiguration = async () => {
  const [res, ex] = await api.configuration.get(null, {
    filterId,
    transferCode,
    engineeringCode: 1,
    showPrice: 1
  });
  if (!res) {
    await toast.showEx(ex);
    loading.hide();
    return;
  }
  state.configuration = res;
  await analyzeCfg(res, false);
  state.image = buildModelImgUrl(props.page, filterId, res.series, state.buttonFeatureTypes, state.carModel, 'Mountain', {
    camera: {
      id: 'BS_Config_Ext_Front_34_measurements',
      options: []
    }
  });
};
const loadPrice = async () => {
  state.priceInfo = await services.price.getCarPrices(filterId, transferCode, state.formulaNo, state.alpha2Code, state.configuration?.modelCode, state.priceIds, [], state.accePriceIds);
  state.retailPrice = utils.site.getPriceFromPcData(state.priceInfo, 'Vehicle Total Price', 'Total Price');
  state.basePrice = utils.site.getPriceFromPcData(state.priceInfo, 'Base Retail Price', 'Base Price');
  state.optionPrice = utils.site.getPriceFromPcData(state.priceInfo, 'Option Price');
  state.vatAmount = utils.site.getPriceFromPcData(state.priceInfo, 'Incl. VAT');
};
const initFinanceInfo = async () => {
  state.calculatorContainerRef.init({
    configuration: state.configuration,
    features: state.features,
    carImage: state.image,
    customerType: state.customerType,
    paymentOption: state.paymentOption,
    retailPrice: state.retailPrice,
    basePrice: state.basePrice,
    optionPrice: state.optionPrice,
    vatAmount: state.vatAmount,
    currency: state.currency,
    preSelection: state.financeData,
    vehicleType: isNullOrEmpty(state.usedCarDetail) ? 'New' : 'Used',
    isStock: state.isStock,
    registrationTime: state.usedCarDetail?.basicResp?.registrationTime
  });
  state.financeInitialized = true;
};
const onFinanceCalculated = debounce(async (dock, payload) => {
  console.log('calculated', dock, payload);
  calculation[dock] = null;
  await nextTick();
  calculation[dock] = payload;
  state.financeCalculating = false;
}, 200);
const onSubmit = async (formData, consentNos, leadTypes) => {
  loading.show();
  const alpha2Code = getPageAlpha2Code(props.page);
  const needDoubleOptin = await countryNeedDoubleOptin(alpha2Code);
  state.formData = formData;
  const { isAboveTwenty, externalData } = formData ?? {};
  const series = toCapitalize(state.configuration?.series);
  const formDetails = {
    selected_model: series,
    transfer_code: transferCode,
    customer_type: null,
    dealer_name: state.dealer?.storeName
  };
  const leadsDetails = {};
  const body = merge(
    {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: concatString([formData?.mobile.area.code, formData.mobile?.number], ''),
      storeCode: state.dealer?.storeCode,
      description: formData.comments,
      termsAndConditions: consentNos.map((x) => ({
        revisionNumber: x,
        title: window.document.title,
        formUrl: window.location.href,
        effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
      })),
      transferCode: transferCode,
      configurationFilterId: filterId,
      configurationCode: configurationCode,
      countryRegion: alpha2Code,
      channel: 'Official Website',
      needDoubleOptin: false,
      financeInterest: formData.financeInterest,
      vehicleModelOfInterest: series
    },
    externalData
  );
  const cptcBody = {
    firstName: formData.firstName,
    middleName: formData.middleName ?? '',
    lastName: formData.lastName,
    email: formData.email,
    countryRegion: alpha2Code,
    channel: externalData?.channel ?? 'Official Website'
  };
  if (state.carSeries?.financeLevel2 && formData.financeInterest) {
    utils.finance.mapCalculationToFormBody(computes.dockCode.value, calculation, body, state);
  }
  if (!isNullOrEmpty(state.vin)) {
    merge(body, {
      stockVehicleVin: state.vin,
      stockVehicleImageURL: state.image,
      stockVehicleModel: series
    });
  } else {
    merge(body, {
      configurationImageUrl: state.image,
      configurationModel: series
    });
  }
  if (!isNullOrEmpty(isAboveTwenty)) {
    merge(body, { isAboveTwenty });
    merge(cptcBody, { isAboveTwenty });
  }
  const [res, ex] = await api.lcms.crm.newsletter.subscribe(null, body);
  if (formData?.CPTC) {
    await api.lcms.crm.cptc.create(null, cptcBody);
  }
  if (leadTypes?.length) {
    leadTypes.forEach((l) => {
      leadsDetails[l.leadType] = res ?? null;
    });
  }
  gtmFormSubmit(
    formType?.value,
    props.fields.form?.id,
    {
      email: formData.email,
      mobileAreaCode: formData.mobile.area.code,
      phone: formData.mobile.number
    },
    formDetails,
    leadsDetails
  );
  loading.hide();
  if (ex) {
    await toast.showEx(ex);
    return;
  }
  scrollToTop();
  state.submitted = true;
};
const methods = {
  async onFormChange(name, value) {
    if (equalString(name, 'financeInterest') && value) {
      if (!state.financeInitialized) {
        await initFinanceInfo();
      }
    }
    if (equalString(name, 'dealer')) {
      state.dealer = value;
    }
  },
  setCustomerType(ct) {
    state.customerType = ct;
    state.calculatorContainerRef.updateCustomerType(ct);
  },
  setPaymentOption(po) {
    state.paymentOption = po;
    state.calculatorContainerRef.updatePayOption(po);
  },
  openFinanceCalculator: debounce(() => {
    state.calculatorContainerRef.open();
  }, 50)
};
provide('send-to-dealer-methods', methods);
provide('send-to-dealer-state', state);
provide('send-to-dealer-calculation', calculation);
provide('send-to-dealer-computes', computes);
if (canUseDOM()) {
  loading.show();
}
onMounted(async () => {
  loading.show();
  gtmFormView(formType?.value, props.fields.form.id);
  let finance = '';
  [transferCode, filterId, configurationCode, state.vin, storeCode, finance] = getQueryStrings('transfer_code', 'filter_id', 'configuration_code', 'vin', 'store_code', 'finance');
  [state.usedCarQuery] = utils.uri.getQueryDataList('used_car');
  state.backButtonProps = buildBackButton();
  state.alpha2Code = getPageAlpha2Code(props.page);
  state.customerTypes =
    props.fields.customerTypes?.map((x) => ({
      code: x.fields.code.value,
      name: x.fields.name.value,
      icon: x.fields.icon
    })) ?? [];
  const defaultCtCode = state.alpha2Code === 'NL' ? 'business' : 'personal';
  state.customerType = state.customerTypes.find((x) => x.code === defaultCtCode);
  const dealers = await services.agent.getAgentListByTypes(props.page, null, null, null, [1]);
  if (!isNullOrEmpty(storeCode)) {
    [state.dealer] = await api.store.detail({
      storeCode
    });
  }
  if (!isNullOrEmpty(state.usedCarQuery)) {
    await loadUsedCarDetail();
  } else if (!isNullOrEmpty(state.vin)) {
    await loadStockDetail();
  } else {
    await loadConfiguration();
    await loadPrice();
  }
  state.formRef.init(dealers, state.dealer);
  if (!isNullOrEmpty(finance)) {
    state.financeData = decodeData(finance);
    console.log('state.financeData', state.financeData);
    if (!isNullOrEmpty(state.financeData)) {
      state.formRef.updateFormItems({
        financeInterest: {
          controlProps: {
            value: true
          }
        }
      });
      await nextTick();
      await initFinanceInfo();
    }
  }
  if (!isNullOrEmpty(state.configuration) && !isNullOrEmpty(state.image)) {
    loading.hide();
  }
});
</script>

<template>
  <div class="c-save-car-cfg" v-if="fields">
    <send-to-dealer-form :fields="fields" :page="page" :image="state.image" @submit="onSubmit" :ref="(e) => (state.formRef = e)" v-if="!state.submitted" />
    <send-to-dealer-cfm :fields="fields" :page="page" v-else />
    <calculator-container :ref="(e) => (state.calculatorContainerRef = e)" @calculated="onFinanceCalculated" v-model:calculating="state.financeCalculating" />
  </div>
</template>

<style lang="scss"></style>
