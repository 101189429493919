<template>
  <div class="c-simple-text" v-if="fields" ref="rootEl">
    <jss-rich-text class="c-simple-text__content" :field="fields.text" tag="div" />
  </div>
</template>

<script>
/**
 * @typedef SimpleTextFields
 * @property {SimpleField} text
 * @property {CheckField} hasUkApplication
 * @property {CheckField} mapRequired
 * @property {String} properties
 * */
import { nextTick, onMounted, reactive, toRefs } from 'vue';
import { createApp } from 'vue/dist/vue.esm-bundler';
import { getGlobalConfigs, loadGMap } from '@/utils/site-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import querystring from 'querystring';
import { merge } from 'lodash';

export default {
  name: 'SimpleText',
  props: {
    page: {
      type: Object
    },
    /**@type SimpleTextFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    let ukApp = null;
    const _methods = {
      async initUkApplication() {
        const [apiKey, apiUrl] = getGlobalConfigs(props.page, 'ukApiKey', 'ukApiUrl');
        const { properties } = props.fields;
        const parameters = {
          locale: props.page.itemLanguage,
          apikey: apiKey.value,
          apiurl: apiUrl.value
        };
        if (!isNullOrEmpty(properties.value)) {
          try {
            const _parameters = querystring.parse(properties.value);
            merge(parameters, _parameters);
          } catch (ex) {
            console.log(ex);
          }
        }
        const container = state.rootEl?.querySelector('.c-simple-text__content');
        if (window.buildLRMApp && container) {
          ukApp = window.buildLRMApp(createApp, parameters);
          ukApp.mount(container);
        }
      },
      async setUpUkApplication() {
        const { hasUkApplication, mapRequired } = props?.fields;
        if (hasUkApplication?.value) {
          if (mapRequired.value) {
            await loadGMap(props?.page);
          }
          await _methods.initUkApplication();
        }
      }
    };
    const state = reactive({
      rootEl: null
    });
    onMounted(() => {
      nextTick(() => {
        setTimeout(() => {
          _methods.setUpUkApplication();
        }, 500);
      });
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>
<style lang="scss">
.c-simple-text {
  p {
    line-height: 1.6em;
    margin-bottom: 1em;
  }
}
</style>
