<template>
  <OverallSettings v-bind="$props">
    <div class="c-stage" v-if="fields">
      <div class="swiper-container" ref="swiperEl">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="slide in fields.slides" :key="slide.id">
            <AdaptiveMedia class="c-stage__slide-image" :field="slide.fields.media" />
            <div
              class="c-stage__slide-details"
              :class="[`align-${slide.fields.align?.fields.phrase.value ?? 'center'}`, `valign-${slide.fields.valign?.fields.phrase.value ?? 'center'}`, { 'big-title': slide.fields.bigTitle?.value }]"
            >
              <!-- <animated-content type="skew-in" :turn-off="fields.turnOffTextAnimation?.value"> -->
              <animated-content type="skew-in">
                <Heading class="c-stage__slide-details-title" :field="isMobile() ? slide.fields.titleMobil : slide.fields.title" :type="fields.titleType" rich />
              </animated-content>
              <div class="c-stage__slide-details-btns" :class="slide.fields.buttonsLayout?.fields.phrase.value">
                <!-- <animated-content type="bottom-fade-in" :delay="index * 200" v-for="(btn, index) in slide.fields.buttons" :key="btn.id" :turn-off="fields.turnOffTextAnimation?.value"> -->
                <animated-content type="bottom-fade-in" :delay="index * 200" v-for="(btn, index) in slide.fields.buttons" :key="btn.id">
                  <SiteButton class="c-stage__slide-details-btn" v-bind="btn" />
                </animated-content>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-stage-pro__paginations" v-if="fields.slides.length > 1">
        <div class="c-stage-pro__pagination" ref="paginationEl" />
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from 'vue';
import { isMobile } from '@/utils/dom-utils';
import Swiper from 'swiper';
import { renderBullet } from '@/utils/site-utils';
export default {
  name: 'Stage',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let swiper;
    const state = reactive({
      /**@type {HTMLElement}*/
      swiperEl: null,
      /**@type {HTMLElement}*/
      paginationEl: null
    });
    const methods = {
      isMobile,
      buildSwiper() {
        if (swiper) {
          swiper.destroy(true, true);
        }
        swiper = new Swiper(state.swiperEl, {
          slidesPerView: 1,
          loop: false,
          allowTouchMove: false,
          pagination: {
            el: state.paginationEl,
            bulletElement: 'div',
            clickable: true,
            renderBullet(index, className) {
              return renderBullet(index, className, props.fields.slides.length);
            }
          }
        });
      },
      buildVideoOps(video) {
        return {
          controls: false,
          autoplay: true,
          muted: true,
          loop: true,
          poster: props.fields.videoImage?.value?.src,
          sources: [
            {
              type: 'video/mp4',
              src: video.value
            }
          ]
        };
      }
    };
    onMounted(() => {
      window.addEventListener('resize', methods.buildSwiper);
      methods.buildSwiper();
    });
    onBeforeUnmount(() => {
      swiper.destroy(true, true);
      window.removeEventListener('resize', methods.buildSwiper);
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/fontFamily.scss';
.c-stage {
  position: relative;
  width: 100%;
  height: 100vh;
  .swiper-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    .swiper-wrapper {
      .swiper-slide {
        @include grid-container;
        opacity: 0.9;
        &-active {
          opacity: 1;
        }
      }
    }
  }

  &__paginations {
    position: absolute;
    bottom: 0;
    z-index: 22;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: grid-width(1);
    width: 100%;
    padding-top: $space-12;
    padding-bottom: $space-30;
  }
  &__pagination {
    @include svgBullet;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 $space-12;
  }

  &__slide-image {
    @include grid-block(1, 12);
    height: 100%;
  }

  &__slide-details {
    @include grid-block(2, 10);
    padding: 112px 0 $space-60 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    &.align {
      &-left {
        align-items: flex-start;
        text-align: start;
        .c-stage__slide-details-title {
          justify-content: flex-start;
        }
        .c-stage__slide-details-btns {
          justify-content: flex-start;
        }
      }
      &-right {
        align-items: flex-end;
        text-align: end;
        .c-stage__slide-details-title {
          justify-content: flex-end;
        }
        .c-stage__slide-details-btns {
          justify-content: flex-end;
        }
      }
    }
    &.valign {
      &-top {
        justify-content: flex-start;
      }
      &-middle {
        justify-content: center;
      }
      &-bottom {
        justify-content: flex-end;
      }
    }
    &-title {
      line-height: 1;
      user-select: none;
      margin-bottom: $space-12;
      padding-right: grid-width-m(1);
    }
    &-btns {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: $space-10;
    }
    &-btn {
      width: 100%;
      text-align: center;
    }
    &.big-title {
      width: grid-width-m(10);
      margin: 0 auto;
      justify-content: space-between;
    }
  }
  &.theme- {
    &yellow {
      .c-stage__paginations {
        .swiper-pagination-bullet {
          &-active {
            background: $white;
          }
        }
      }
    }
  }

  @include tablet-landscape {
    &__slide-image {
      @include grid-block(1, 24);
    }
    &__slide-details {
      @include grid-block(2, 22);
      padding: grid-width(2) 0;
      &-title {
        margin-bottom: $space-16;
      }
      .horizontal {
        flex-direction: row;
        align-items: center;
        gap: $space-30;
      }
      .vertical {
        flex-direction: column;
        justify-content: center;
        gap: $space-10;
      }
      &-btn {
        width: auto;
      }
      &.big-title {
        letter-spacing: -30px;
        padding: 0;
        padding-top: 160px;
        padding-bottom: 60px;
        justify-content: space-between;
        .c-stage__slide-details-btns {
          padding: 0;
          align-items: flex-end;
        }
      }
    }
  }
}
</style>
