<template>
  <overall-settings v-bind="$props">
    <form-layout
      :fields="layoutFields"
      :class="['c-tell-me-more__layout', { submitted, 'show-image-on-mobile': deviceState.deviceType === 'mobile' && fields.showImageOnMobile?.value }]"
      ref="layoutRef"
    >
      <div class="c-tell-me-more" v-if="!submitted">
        <heading class="c-tell-me-more__title" :field="fields.title" :type="fields.titleType" />
        <jss-rich-text class="c-tell-me-more__subtitle" :field="fields.subtitle" />
        <dynamic-form :form="fields.form" :data="formConfig" @change="onFormChange" ref="formRef" />
        <site-button class="c-tell-me-more__submit-btn" v-bind="fields.submitButton" @click="onSubmit" />
      </div>
      <div class="c-tell-me-more__success" v-if="submitted">
        <heading class="c-tell-me-more__success-title" :field="fields.successTitle" rich />
        <jss-rich-text class="c-tell-me-more__success-subtitle" :field="fields.successSubtitle" rich />
        <site-button class="c-tell-me-more__success-btn" v-bind="fields.successButton" />
      </div>
    </form-layout>
  </overall-settings>
</template>

<script>
/**
 * @typedef ContactUsFormFields
 * @property {{
 *   fields: AdaptiveImageFields
 * }} image
 * @property {SimpleField} title
 * @property {GlobalSettingEntry} titleType
 * @property {CheckField} reverse
 * @property {CheckField} fullWidthImage
 * @property {SimpleField} subtitle
 * @property {Form} form
 * @property {ButtonField} submitButton
 * @property {CheckField} showImageOnMobile
 * @property {SimpleField} successTitle
 * @property {SimpleField} successSubtitle
 * @property {CheckField} successReverse
 * @property {ButtonField} successButton
 * @property {{
 *   fields: AdaptiveImageFields
 * }} successImage
 * @property {CheckField} successFullWidthImage
 * */
import { computed, inject, nextTick, reactive, toRefs } from 'vue';
import api from '@/api';
import { merge, debounce } from 'lodash';
import { gtmFormView, gtmFormStart, gtmFormSubmit } from '@/utils/gtm-utils';
import useDevice from '@/hooks/useDevice';
import { getOffsetTop } from '@/utils/dom-utils';
import { formatDate } from '@/utils/date-utils';
import { getPageAlpha2Code, settingValue } from '@/utils/site-utils';
import { countryNeedDoubleOptin } from '@/services/siteService';
import onScrollToView from '@/hooks/onScrollToView';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { equalString } from '@/utils/string-utils';

export default {
  name: 'ContactUsForm',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const loading = inject('loading');
    const toast = inject('toast');
    const { deviceState } = useDevice();
    const { formType } = props.page.fields;
    const state = reactive({
      /**@type FormLayout*/
      layoutRef: null,
      /**@type DynamicForm*/
      formRef: null,
      formConfig: {},
      submitted: false,
      formChanged: false
    });
    const computes = {
      layoutFields: computed(() => (state.submitted ? props.fields.successLayout?.fields : props.fields.layout?.fields) ?? props.fields.layout?.fields)
    };
    const methods = {
      onFormChange: async () => {
        if (!state.formChanged) {
          state.formChanged = true;
          gtmFormStart(formType?.value, props.fields.form.id);
        }
      },
      onSubmit: debounce(async (e) => {
        const alpha2Code = getPageAlpha2Code(props.page);
        const [valid, formData] = await state.formRef.validate();
        const leadTypes = state.formRef.getLeadTypes();
        if (valid) {
          const { firstName, middleName, lastName, email, phone, company, companySize, title, isAboveTwenty, externalData } = formData;
          const { channel } = props.fields;
          const consents = state.formRef.getVerifiedConsents();
          loading.show();
          // const needDoubleOptin = await countryNeedDoubleOptin(alpha2Code);
          const leadsDetails = {};
          const body = merge(
            {
              vehicleModelOfInterest: 'Eletre',
              countryRegion: phone.area.data.alpha2Code,
              language: props.page.itemLanguage,
              needDoubleOptin: false,
              termsAndConditions: consents.map((x) => ({
                revisionNumber: x,
                title: window.document.title,
                formUrl: window.location.href,
                effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
              })),
              phone: `${phone?.area.code} ${phone?.number}`,
              firstName,
              middleName: middleName ?? '',
              lastName,
              email,
              channel: settingValue(channel, 'Official Website'),
              company: company ?? '',
              fleetCompanySize: companySize?.code ?? '',
              title: title ?? '',
              leadFormName: 'Fleet Eletre KMI',
              leadProvider: 'Global Website',
              leadform_Name__c: 'Fleet Eletre KMI',
              lead_Provider__c: 'Global Website',
              PreferredModel__c: 'Eletre',
              formScId: formData.formScId,
              gRecaptchaToken: formData.gRecaptchaToken ?? null
            },
            externalData ?? {}
          );
          if (!isNullOrEmpty(isAboveTwenty)) {
            merge(body, { isAboveTwenty });
          }
          const [resNewsletter, exNewsletter] = await api.lcms.crm.newsletter.subscribe(null, body);
          loading.hide();
          if (exNewsletter) {
            await toast.showEx(exNewsletter);
            return;
          }
          if (formData?.CPTC) {
            const cptcBody = merge(
              {
                firstName: firstName,
                middleName: middleName ?? '',
                lastName: lastName,
                email: email,
                countryRegion: alpha2Code,
                channel: 'Official Website'
              },
              externalData
            );
            if (!isNullOrEmpty(isAboveTwenty)) {
              merge(cptcBody, { isAboveTwenty });
            }
            const [cptcRes] = await api.lcms.crm.cptc.create(null, cptcBody);

            if (leadTypes?.length && cptcRes) {
              leadTypes.forEach((l) => {
                if (equalString(l.consentType, 'CPTC')) {
                  leadsDetails[l.leadType] = cptcRes.leadId ?? null;
                }
              });
            }
          }
          if (leadTypes?.length) {
            leadTypes.forEach((l) => {
              leadsDetails[l.leadType] = resNewsletter ?? null;
            });
          }
          gtmFormSubmit(
            formType?.value,
            props.fields.form.id,
            {
              email: email,
              mobileAreaCode: phone.area.code,
              phone: phone.number
            },
            null,
            leadsDetails
          );

          state.submitted = true;
          await nextTick();
          const offsetTop = getOffsetTop(state.layoutRef.rootEl);
          window.scrollTo(0, offsetTop);
        }
      }, 100)
    };
    onScrollToView({
      getContainer() {
        return state.layoutRef.rootEl;
      },
      callback() {
        gtmFormView(formType?.value, props.fields.form.id);
      }
    });
    return {
      ...toRefs(state),
      deviceState,
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-tell-me-more {
  $l: '.s-form-layout';
  padding-bottom: $space-40;
  &__layout {
    &.submitted {
      #{$l}__image-wrapper {
        z-index: 1;
      }
    }
    &.show-image-on-mobile {
      #{$l}__side-image {
        display: block;
        @include grid-block(1, 12, 1);
        height: 100vw;
      }
      #{$l}__image-wrapper {
        height: 100vw;
      }
      #{$l}__image {
        height: 100vw;
      }
      #{$l}__main {
        @include grid-block(2, 10, 2);
      }
    }
  }
  &__title {
    @include h5;
    margin-bottom: $space-24;
  }
  &__subtitle {
    margin-bottom: $space-40;
  }
  &__submit-btn {
    width: 100%;
  }
  &__success-title {
    @include h5;
    padding-top: $space-56;
    margin-bottom: $space-24;
  }
  &__success-subtitle {
    padding-top: $space-16;
    margin-bottom: $space-24;
  }
  &__success-btn {
    @include h5;
  }
  @include tablet-landscape {
    &__submit-btn {
      width: auto;
      min-width: 100px;
    }
  }
}
</style>
