<template>
  <overall-settings v-bind="$props">
    <div class="c-stock-vehicle-detail" v-if="fields">
      <div class="c-stock-vehicle-detail__header">
        <div class="c-stock-vehicle-detail__header__back" @click="handleBack">
          <Icon name="back" class="c-stock-vehicle-detail__header__back__icon" />
          <span>{{ $tu('back') }}</span>
        </div>
      </div>
      <div class="c-stock-vehicle-detail__content">
        <div class="c-stock-vehicle-detail__swiper" ref="swiperEl" :class="{ grow: fullScreen }">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in vehicleDetail?.images?.productDisplay" :key="index">
              <img :src="item?.externalUrl" alt="" />
            </div>
          </div>
          <div class="c-stock-vehicle-detail__pagination" ref="paginationEl"></div>
          <icon class="c-stock-vehicle-detail__screen" name="screen" @click="fullScreen = true" v-if="!fullScreen" />
          <icon class="c-stock-vehicle-detail__close" name="close" @click="fullScreen = false" v-if="fullScreen" />
          <icon class="c-stock-vehicle-detail__nav-left" name="left" ref="navLeftEl" v-show="fullScreen" />
          <icon class="c-stock-vehicle-detail__nav-right" name="right" ref="navRightEl" v-show="fullScreen" />
        </div>
        <div class="c-stock-vehicle-detail__detail" :class="{ lessen: fullScreen && !deviceComputes.isMobileOrTablet.value }">
          <div class="c-stock-vehicle-detail__model" v-if="!deviceComputes.isMobileOrTablet.value && vehicleDetail?.vehicleModel">{{ $formatModel(vehicleDetail?.vehicleModel) }}</div>
          <div class="c-stock-vehicle-detail__performance" v-if="vehicleModel">
            <JssRichText class="c-stock-vehicle-detail__performance-title" :field="fields.performanceTitle" />
            <div class="c-stock-vehicle-detail__performance-items">
              <div class="c-stock-vehicle-detail__performance-item" v-for="item in vehicleModel.performances" :key="item.id">
                <div class="c-stock-vehicle-detail__performance-item-num" v-html="item.number" />
                <div class="c-stock-vehicle-detail__performance-item-des" v-html="item.description" />
              </div>
            </div>
          </div>
          <div class="c-stock-vehicle-detail__collapse">
            <stock-collapse :dataSource="configuration" :close-btn="fields?.closeModalCta" v-if="configuration?.length" />
          </div>
          <jss-rich-text class="c-stock-vehicle-detail__description" :field="fields.body" />
        </div>
      </div>
      <div class="c-stock-vehicle-detail__nav">
        <stock-vehicle-price-nav
          type="detail"
          v-if="priceNavParams"
          :params="priceNavParams"
          :priceOptions="priceOptions"
          :vehicleDetail="vehicleDetail"
          :car-series="carSeries"
          :down-payment="downPayment"
          :page="page"
          :fields="fields"
          :model-title="fields?.title?.value"
          :model-color="configuration.length > 0 && configuration[0] && configuration[0].value?.length > 0 ? configuration[0]?.value[0].label : null"
          :model-pay="fields?.pay?.value"
          :model-return-deposit="fields?.returnDeposit?.value"
          :model-agreement="fields?.agreement?.value"
          :model-vat="fields?.vat?.value"
        />
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { getCurrentInstance, inject, nextTick, onBeforeMount, onBeforeUnmount, onMounted, provide, reactive, toRefs } from 'vue';
import Swiper from 'swiper';
import api from '@/api';
import useDevice from '@/hooks/useDevice';
import { updateDictionaryValue } from '@/services/dictService';
import { getDownPayment } from '@/services/siteService';
import { getPageAlpha2Code } from '@/utils/site-utils';
import { gtmPush } from '@/utils/gtm-utils';
import services from '@/services';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'StockVehicleDetail',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const { deviceComputes } = useDevice();
    const loading = inject('loading');
    let swiper = null,
      alpha2Code;
    const { vin } = route.query;
    const country = getPageAlpha2Code(props.page);
    const state = reactive({
      swiperEl: null,
      paginationEl: null,
      vehicleDetail: null,
      carSeries: null,
      vehicleModel: null,
      configuration: [],
      priceOptions: [],
      totalPrice: null,
      fullScreen: false,
      priceNavParams: null,
      navLeftEl: null,
      navRightEl: null,
      orderRules: [],
      downPayment: null,
      orderRule: null
    });
    const stockMethods = {
      pushView(detail, event = 'view_car') {
        let region = 'GB',
          language = 'en';
        if (props.page.itemLanguage !== 'en') {
          [language, region] = props.page.itemLanguage.split('-');
        }
        gtmPush({
          event,
          cars: [
            {
              model_name: detail.vehicleModel,
              version_name: detail.series,
              filter_id: detail.filterId,
              transfer_code: detail.transferCode,
              vehicle_type: 'stock',
              price: detail.priceStartFrom
            }
          ],
          currency: detail.currency,
          region,
          language
        });
      }
    };
    const _methods = {
      async getOrderRules(detail) {
        state.downPayment = await getDownPayment(props.page, detail.seriesCode);
        const [resRule] = await api.order.rule.query(null, {
          items: [
            {
              orderType: 200,
              carSeriesCode: state.vehicleDetail?.seriesCode,
              carModelCode: state.vehicleDetail?.modelCode ?? '',
              country: alpha2Code
            }
          ]
        });
        state.orderRules = resRule;
        if (resRule?.length) {
          state.orderRule = resRule[0];
          if (state.orderRule?.orderAmountNumber >= 0) {
            state.downPayment = state.orderRule?.orderAmountNumber;
          }
        }
      }
    };
    const methods = {
      /**
       * 获取车辆详情
       */
      async getVehicleDetail() {
        const [res, ex] = await api.order.stockDetailByVin(null, {
          country: country,
          vin: vin
        });
        if (ex) {
          return false;
        }
        state.vehicleDetail = res;
        await _methods.getOrderRules(res);
        stockMethods.pushView(res);
        state.carSeries = await services.site.getCarSeriesByCode(res.seriesCode);
        state.vehicleModel = await services.site.getCarModelByCode(res.seriesCode, res.modelCode);
        const configuration = JSON.parse(res?.configurationDetails);
        configuration.forEach((x, i) => (x.open = i === 0));
        state.configuration = configuration;
        const priceInfo = JSON.parse(res?.detailPrice).filter((x) => !['PFU', 'IPT'].includes(x.elementName));
        state.priceOptions = await updateDictionaryValue(priceInfo, 'elementName', 'label', (x) => x.visibility);
        state.totalPrice = state.priceOptions.find((x) => x.elementName === 'Total Price');
        state.priceNavParams = {
          country: country,
          transfer_code: res?.transferCode,
          filter_id: res?.filterId,
          vin: res?.vin,
          vid: res?.vid
        };
      },
      /**
       * 返回上一页
       */
      handleBack() {
        if (!isNullOrEmpty(route.redirectedFrom?.fullPath)) {
          router.back();
        } else {
          router.replace({ path: `/${props.page.itemLanguage}` });
        }
      },
      /**
       * 创建swiper
       */
      buildSwiper() {
        if (swiper) {
          swiper.destroy(true, true);
        }
        swiper = new Swiper(state.swiperEl, {
          observer: true,
          observeParents: true,
          pagination: {
            el: state.paginationEl,
            bulletElement: 'div',
            clickable: true
          },
          navigation: {
            prevEl: state.navLeftEl?.rootEl,
            nextEl: state.navRightEl?.rootEl
          }
        });
      }
    };
    provide('stockMethods', stockMethods);
    onBeforeMount(() => {
      loading.show();
    });
    onMounted(async () => {
      alpha2Code = getPageAlpha2Code(props.page);
      await methods.getVehicleDetail();
      await nextTick();
      methods.buildSwiper();
      loading.hide();
    });
    onBeforeUnmount(() => {
      if (swiper?.destroy) {
        swiper.destroy(true, true);
      }
    });

    return {
      ...toRefs(state),
      deviceComputes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-stock-vehicle-detail {
  $c: &;
  position: relative;
  user-select: none;
  #{$c}__header {
    position: absolute;
    width: 100%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__back {
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;

      &__icon {
        cursor: pointer;
        margin-right: $space-12;
        position: relative;
        top: -1px;
        svg,
        path {
          height: 16px;
        }
      }
    }

    &__year {
      font-size: 14px;
      font-weight: 700;
    }
  }
  &__content {
    display: block;
    height: auto;
  }
  &__detail {
    width: 100%;
    display: block;
    padding: $space-50 $space-24 $space-80;
    overflow: hidden;
    &.lessen {
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
    }

    #{$c}__model {
      font-size: 56px;
      line-height: 1;
      letter-spacing: 3px;
      margin-top: $space-48;
    }

    #{$c}__collapse {
      margin-top: $space-48;
    }
  }
  &__description {
    font-size: 12px;
    color: $grey-next;
    margin-top: 56px;
    margin-bottom: 88px;
  }

  &__performance {
    margin-top: grid-width(1);
    &-items {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__swiper {
    width: 100%;
    height: 100vw;
    overflow: hidden;
    position: relative;
    transition: all 0.3s;

    .swiper-slide {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    #{$c}__pagination {
      position: absolute;
      left: 50%;
      bottom: $space-32;
      transform: translate(-50%, 0);
      display: flex;
      align-items: center;
      z-index: 1;

      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background: $white;
        margin-left: 16px;
        border-radius: 100%;
        cursor: pointer;

        &:first-child {
          margin: 0;
        }
      }

      .swiper-pagination-bullet-active {
        background: $yellow;
      }
    }

    #{$c}__screen {
      position: absolute;
      left: $space-32;
      bottom: $space-32;
      z-index: 1;
      cursor: pointer;

      svg,
      path {
        width: 40px;
      }
    }

    #{$c}__close {
      position: absolute;
      top: $space-32;
      right: $space-32;
      z-index: 1;
      cursor: pointer;

      svg,
      path {
        width: 40px;
      }
    }

    &.grow {
      width: 100vw;
      @include height-except-header;
    }
  }

  &__nav {
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &__nav-left,
  &__nav-right {
    position: absolute;
    top: calc(50% - 25px);
    z-index: 1;
    padding: 10px;
    border: 1px solid $yellow;
    color: $yellow;
    display: none;
    cursor: pointer;
    > svg {
      width: 28px !important;
      height: 28px !important;
    }
    &.swiper-button-disabled {
      opacity: 0;
      z-index: -1;
      cursor: default;
    }
    &.nav-ani- {
      &enter-active,
      &leave-active {
        transition: 600ms ease;
      }
      &enter-to,
      &leave-from {
        transform: scale(1);
      }

      &enter-from,
      &leave-to {
        transform: scale(0.5);
      }
    }
  }
  &__nav-left {
    left: 24px;
  }
  &__nav-right {
    right: 24px;
  }
  @include tablet-landscape {
    &__content {
      display: flex;
      flex-direction: row-reverse;
      height: calc(100vh - 100px - #{tablet-header-height()});
    }

    &__detail {
      width: 50%;
      padding: $space-50 grid-width(1.8);
      transition: all 0.3s;
      overflow: auto;
    }

    &__swiper {
      position: sticky;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
    }

    &__nav-left {
      left: 100px;
    }
    &__nav-right {
      right: 100px;
    }
  }
}
</style>
