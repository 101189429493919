<template>
  <div>
    <modal ref="modalEl" animation="right-skew-in" class="c-region-modal" :theme="$settingValue(fields.theme, 'white')" closable>
      <jss-text class="c-region-modal__overline" :field="fields.overline" tag="div" />
      <jss-text class="c-region-modal__title" :field="fields.title" tag="div" />
      <jss-rich-text class="c-region-modal__body" :field="fields.body" tag="div" />
      <div class="c-region-modal__area" v-for="area in areas" :key="area.id">
        <div class="c-region-modal__area-header" @click="toggleExpand(area)">
          <div class="c-region-modal__area-name">{{ area.fields.name.value }}</div>
          <icon name="down" />
        </div>
        <transition class="c-region-modal__regions" :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave" tag="div">
          <div v-if="expandAreas.includes(area.id)">
            <template v-for="region in area.fields.regions" :key="region.id">
              <div class="c-region-modal__region" :data-total="area.fields.regions.length" v-if="region.fields.noSite?.value !== true">
                <div class="c-region-modal__region-name">{{ region.displayName }}</div>
                <div class="c-region-modal__languages">
                  <template v-for="lang in region.fields.languages" :key="lang.id">
                    <site-link class="c-region-modal__language" :link="lang.fields.link" v-if="lang.fields.link.value?.href">
                      <span>{{ lang.fields.text.value }}</span>
                      <icon name="arrow-right" />
                    </site-link>
                    <div class="c-region-modal__language" @click="changeLanguage(lang)" v-else>
                      <span>{{ lang.fields.text.value }}</span>
                      <icon name="arrow-right" />
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </transition>
      </div>
    </modal>
  </div>
</template>

<script>
/**
 * @typedef RegionModalFields
 * @property {SimpleField} overline
 * @property {SimpleField} title
 * @property {SimpleField} body
 */
import { inject, nextTick, reactive, toRefs } from 'vue';
import BUS_EVENTS from '@/utils/bus-events';
import gsap from 'gsap';
import { gtmPush } from '@/utils/gtm-utils';
import { getGlobalConfigs } from '@/utils/site-utils';
import { loadChineseFont } from '@/utils/dom-utils';

export default {
  name: 'RegionModal',
  props: {
    fields: {
      type: Object
    },
    /**@type SitePage*/
    page: {
      type: Object
    }
  },
  setup(props) {
    const $bus = inject('$bus');
    /**@type Array<SiteArea>*/
    let [areas] = getGlobalConfigs(props.page, 'areas');
    const state = reactive({
      /**@type ModalRef*/
      modalEl: null,
      areas,
      expandAreas: []
    });
    const methods = {
      toggleExpand(area) {
        const index = state.expandAreas.findIndex((x) => x === area.id);
        if (index >= 0) {
          state.expandAreas.splice(index, 1);
        } else {
          state.expandAreas.push(area.id);
        }
      },
      changeLanguage(lang) {
        const { code } = lang.fields;
        if (!code.value) return;
        const { origin, pathname, search, hash } = window.location;
        let newPath;
        if (/^\/(en|([a-z]{2}-[a-z]{2}))\/?$/gi.test(pathname)) {
          newPath = pathname.replace(/^\/(en|([a-z]{2}-[a-z]{2}))\/?$/gi, `/${code.value}`);
        } else if (/^\/(en|([a-z]{2}-[a-z]{2}))\//gi.test(pathname)) {
          newPath = pathname.replace(/^\/(en|([a-z]{2}-[a-z]{2}))\//gi, `/${code.value}/`);
        } else {
          newPath = `/${code.value}${pathname}`;
        }
        gtmPush({
          event: 'market_selector',
          event_label: code.value
        });
        setTimeout(() => {
          window.location.href = `${origin}${newPath}${search}${hash}`;
        }, 100);
      },
      onBeforeEnter(el) {
        el.style.overflow = 'hidden';
        el.style.marginBottom = 0;
        el.style.height = '0px';
      },
      onEnter(el, done) {
        const height = el.scrollHeight;
        gsap
          .to(el, {
            height,
            marginBottom: 24,
            ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)'
          })
          .then(() => {
            done();
          });
      },
      onLeave(el, done) {
        gsap
          .to(el, {
            height: 0,
            marginBottom: 0,
            ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)'
          })
          .then(() => {
            done();
          });
      }
    };
    $bus.on(BUS_EVENTS.OPEN_REGION_MODAL, () => {
      nextTick(() => {
        loadChineseFont();
        state.modalEl?.open({ duration: 0.6 });
      });
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-region-modal {
  $c: &;
  $m: '.e-modal';
  &.e-modal {
    z-index: 13;
    transform: translateZ(1px);
  }
  #{$m}__content {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 60px grid-width-m(1) 60px grid-width-m(1);
    background: $white;
    color: $black;
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    font-family: sans-serif;
    font-weight: lighter;
    cursor: pointer;
  }
  &__overline {
    @include h7;
    margin-bottom: $space-20;
  }
  &__title {
    @include h3;
    margin-bottom: $space-40;
  }
  &__body {
    margin-bottom: $space-40;
  }
  &__area-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    background: $grey-light;
    padding: $space-8 $space-16;
    cursor: pointer;
    margin-bottom: $space-24;
  }
  &__regions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: $space-24;
    margin-bottom: $space-24;
    padding-left: $space-16;
    padding-right: $space-16;
  }
  &__region {
    margin-bottom: $space-24;
  }
  &__region-name {
    @include h9;
  }
  &__languages {
    margin-top: $space-8;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: $space-16;
    grid-row-gap: $space-16;
  }
  &__language {
    @include h10;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    color: $grey-neutral;
    .e-icon {
      transform: translateX(0);
      opacity: 0;
      transition: all 600ms cubic-bezier(0.38, 0.015, 0, 0.995);
      > svg {
        width: 12px;
        height: auto;
      }
    }
    &:hover {
      .e-icon {
        transform: translateX(10px);
        opacity: 1;
      }
    }
  }
  &__region-link {
    display: flex;
    > span {
      margin-right: $space-24;
    }
  }
  @include tablet-landscape {
    #{$m}__content {
      padding: 60px grid-width(1) 60px grid-width(1);
    }
  }
  @include desktop {
    &__languages {
      grid-template-columns: repeat(2, 1fr);
    }
    &__language {
      &:hover {
        > span {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
