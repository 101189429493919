<script setup>
import { computed, inject, nextTick, onMounted, ref } from 'vue';
import { canUseDOM, getCurrentPosition } from '@/utils/dom-utils';
import { ERROR_CODES, sitecoreComponentProps } from '@/utils/constants';
import { getBetterUrl, getQueryDataList } from '@/utils/uri-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import api from '../api';
import { toThousands } from '@/utils/math-utils';
import querystring from 'querystring';
import UsedCarImageModal from '../sections/used-car-image-modal';
import { onWidthChanged } from '@/hooks/onWidthChanged';
import { debounce } from 'lodash';
import useAppStore from '../store/appStore';
import Swiper from 'swiper';
import { formatDate } from '@/utils/date-utils';
import { equalString, formatString, ifEmpty } from '@/utils/string-utils';
import { getGlobalConfigs } from '@/utils/site-utils';
import utils from '@/utils';
const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
let swiper;
/**@type AppStore*/
const appStore = useAppStore();
const loading = inject('loading');
const toast = inject('toast');
const { deviceComputes } = inject('device-common');
const swiperEl = ref();
const scrollbarEl = ref();
const mainRef = ref();
const usedCarImageModalRef = ref();
const authModalRef = ref();
const unPublishedModalRef = ref();
const detail = ref(null);
const vehicleInfo = ref([]);
const store = ref(null);
const contactBtn = ref(props.fields?.contactButton);
const storeLink = computed(() => {
  const { storeLink } = props.fields || {};
  let href = storeLink?.value?.href;
  const { storeName, storeCode, longitude, latitude, detailAddress } = store.value || {};
  if (isNullOrEmpty(href) || isNullOrEmpty(storeName) || isNullOrEmpty(longitude)) {
    return storeName;
  }
  href = formatString(href, {
    storeName: encodeURIComponent(storeName),
    storeCode,
    address: encodeURIComponent(ifEmpty(detailAddress, storeName)),
    longitude: longitude,
    latitude: latitude
  });
  href = getBetterUrl(href);
  return `<a href="${href}" target="_blank">${storeName}</a>`;
});
const storeTitle = computed(() => storeLink.value.replace(/<\/.*?>/gi, ''));
const infoLabels = computed(() => {
  const { infoLabels } = props.fields;
  if (isNullOrEmpty(infoLabels.value)) return [];
  const temp = querystring.parse(infoLabels.value);
  const keys = Object.keys(temp);
  return keys.map((x) => ({
    code: x,
    text: temp[x]
  }));
});
const buildContactButton = () => {
  const { contactButton } = props.fields;
  if (!contactButton?.fields) return null;
  const { link, ...rest } = contactButton.fields;
  let disabled = false;
  let { href, ...linkRest } = link?.value || {};
  const { saleStore, saleStoreName } = detail.value?.basicResp ?? {};
  if (isNullOrEmpty(link?.value?.href)) disabled = true;
  else if (!isNullOrEmpty(saleStore)) {
    const storeName = encodeURIComponent(saleStoreName);
    href = formatString(href, {
      storeName,
      storeCode: saleStore
    });
  }
  console.log('detail.value', detail.value?.basicResp);
  href = utils.uri.appendQuery(href, {
    used_car: utils.site.encodeData({
      id: detail.value?.basicResp?.id
    })
  });
  contactBtn.value = {
    disabled,
    fields: {
      ...rest,
      link: {
        value: {
          ...linkRest,
          href
        }
      }
    }
  };
};
const authorizeLocation = async () => {
  const [res, ex] = await getCurrentPosition();
  if (!res?.coords?.longitude) {
    if (ex?.code === 1) {
      toast.showEx(ERROR_CODES.GeoRefused);
    } else if (ex) {
      toast.showError(ex.message);
    } else {
      toast.showEx();
    }
    appStore.setGeoLocation(null);
    return;
  }
  appStore.setGeoLocation({
    longitude: res.coords.longitude,
    latitude: res.coords.latitude,
    authorized: true
  });
};
const openGallery = () => {
  usedCarImageModalRef.value?.open();
};
const buildSwiper = () => {
  swiper?.destroy();
  console.log('scrollbarEl.value', scrollbarEl.value);
  swiper = new Swiper(swiperEl.value, {
    pagination: {
      el: scrollbarEl.value,
      type: 'progressbar'
    },
    on: {
      click(_swiper) {
        usedCarImageModalRef.value?.open(_swiper.activeIndex);
      }
    }
  });
};
const formatItemField = (key, val) => {
  if (equalString(key, 'productionTime')) return formatDate(val, 'yyyy');
  return val;
};
const gotoDetail = () => {
  mainRef.value.scrollIntoView(true);
};
if (canUseDOM()) {
  loading.show();
}
onWidthChanged({
  callback: debounce(() => {
    if (deviceComputes.isMobileOrTablet.value) {
      buildSwiper();
    }
  }, 100)
});
onMounted(async () => {
  const [d] = getQueryDataList('d');
  if (isNullOrEmpty(d?.id)) return;
  if (!appStore.hasGeoLocation) {
    await authorizeLocation();
  }
  const [res, ex] = await api.usedCar.detail(null, {
    id: d.id,
    longitude: appStore.geoLocation?.longitude ?? null,
    latitude: appStore.geoLocation?.latitude ?? null
  });
  if (!res) {
    await toast.showEx(ex);
    return;
  }
  const { saleStore, publishStatus } = res?.basicResp ?? {};
  if (equalString(publishStatus, 'Unpublished')) {
    const [usedCarListLink] = getGlobalConfigs(props.page, 'usedCarListLink');
    const usedCarListLinkHref = usedCarListLink?.value?.href;
    if (isNullOrEmpty(usedCarListLinkHref)) {
      await toast.showError('The car is not available now');
      return;
    }
    await unPublishedModalRef.value?.open();
    window.location = getBetterUrl(usedCarListLinkHref, null, true);
  }
  detail.value = res;
  if (!isNullOrEmpty(res.basicResp?.vehicleJson)) {
    vehicleInfo.value = JSON.parse(res.basicResp.vehicleJson);
    console.log('vehicleInfo', vehicleInfo.value);
  }
  const [resStore] = await api.store.detail({
    storeCode: saleStore
  });
  console.log('resStore', store);
  store.value = resStore;
  buildContactButton();
  await nextTick();
  if (deviceComputes.isMobileOrTablet.value) {
    buildSwiper();
  }
  loading.hide();
});
</script>

<template>
  <overall-settings v-bind="props">
    <div class="c-used-car-detail" v-if="detail">
      <div class="c-used-car-detail__banner">
        <div class="c-used-car-detail__side">
          <div class="c-used-car-detail__images" v-if="$deviceComputes.largeThanTablet.value">
            <template v-for="(pic, index) in detail.picList" :key="index">
              <div class="c-used-car-detail__image" v-aspect-ratio="'3_2'" v-if="index < 6">
                <img :src="pic.url" alt="" />
                <div class="c-used-car-detail__image-toggle" v-if="index === 0" @click="openGallery">
                  <icon name="arrow-right" />
                  <icon name="arrow-right" />
                </div>
                <div
                  class="c-used-car-detail__image-cover"
                  v-if="$deviceComputes.largeThanTablet.value && (index === 5 || (detail.picList?.length > 2 && index === detail.picList?.length - 1))"
                  @click="openGallery"
                >
                  <template-string :field="fields.imageText" :data="{ total: detail.picList?.length }" />
                  <icon name="arrow-right" />
                </div>
              </div>
            </template>
          </div>
          <div v-else>
            <div class="swiper" ref="swiperEl">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(pic, index) in detail.picList" :key="index">
                  <div class="c-used-car-detail__image">
                    <img :src="pic.url" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="c-used-car-detail__swiper-bar" ref="scrollbarEl" />
          </div>
        </div>
        <div class="c-used-car-detail__product">
          <div class="c-used-car-detail__info">
            <div class="flex ac g4 mg-b-_16" v-if="detail.basicResp?.lotusApproval === 1">
              <icon :field="fields.approvedIcon" />
              <jss-text class="fs-10 fw-600 __lh-2 ls-1 ttu" :field="fields.approvedText" />
            </div>
            <div class="__ft-h3" v-html="detail.basicResp.model" v-if="!$isNullOrEmpty(detail.basicResp)" />
            <div class="c-used-car-detail__price" v-html="$formatMoney(detail.priceResp.allInPrice, detail.priceResp.currency)" v-if="!$isNullOrEmpty(detail.priceResp?.allInPrice)" />
            <div class="c-used-car-detail__pairs" v-if="!$isNullOrEmpty(detail.basicResp)">
              <template v-for="infoLabel in infoLabels" :key="infoLabel.code">
                <div class="c-used-car-detail__pair" v-if="!$isNullOrEmpty(detail.basicResp[infoLabel.code])">
                  <span> {{ infoLabel.text }}:</span>
                  <span>{{ formatItemField(infoLabel.code, detail.basicResp[infoLabel.code]) }}</span>
                </div>
              </template>
            </div>
            <div class="c-used-car-detail__store" v-if="!$isNullOrEmpty(storeLink) || detail.distance > 0">
              <icon name="location" size="tiny" />
              <template-string class="c-used-car-detail__store-text" :field="fields.storeText" :data="{ storeName: storeLink }" tag="span" v-if="!$isNullOrEmpty(storeLink)" />
              <template v-if="detail.distance > 0">
                <template-string :field="fields.distanceText" :data="{ distance: $formatDistance(detail.distance, 'km', 0) }" tag="span" />
              </template>
            </div>
            <site-button class="mg-b-56" v-bind="contactBtn" />
            <button class="c-used-car-detail__view-detail-btn button-pure small hand" @click="gotoDetail" v-if="!$isNullOrEmpty(fields.viewDetailText.value)">
              <span>{{ fields.viewDetailText.value }}</span>
              <icon name="down" />
            </button>
          </div>
        </div>
      </div>
      <div class="c-used-car-detail__main" ref="mainRef">
        <div class="c-used-car-detail__description" v-html="detail.basicResp?.description" v-if="!$isNullOrEmpty(detail.basicResp?.description)" />
        <div class="c-used-car-detail__config">
          <template v-for="(item, i) in vehicleInfo" :key="i">
            <site-accordion :title="item.type" v-model:active="item.opened">
              <label-value :label="info.value" size="small" v-for="(info, j) in item.infos" :key="j">
                {{ info.label }}
              </label-value>
            </site-accordion>
          </template>
        </div>
      </div>
      <store-info class="mg-b-40" v-bind="fields.storeInfo" :store="store" />
      <store-map-view :store="store" :icon-field="fields.mapIcon" />
      <div class="c-used-car-detail__body" v-if="detail.basicResp?.lotusApproval === 1">
        <div class="flex fdc ac">
          <icon :field="fields.approvedIcon" class="i-32 mg-b-16" />
          <jss-text :field="fields.approvedText" class="mg-b-8" />
          <div class="c-used-car-detail__body-line" />
        </div>
        <jss-rich-text class="c-used-car-detail__body-text" :field="fields.body" tag="div" />
      </div>
      <div class="c-used-car-detail__toolbar">
        <div class="flex fdc jcc">
          <div class="ttu fs-14 pc-fs-18">{{ detail.basicResp.model }}</div>
          <div class="__c-grey-taubmans">{{ $formatMoney(detail.priceResp.allInPrice, detail.priceResp.currency) }}</div>
        </div>
        <div class="flex ac g32 lt-both">
          <div class="flex ac g4" v-if="$deviceComputes.largeThanTablet.value">
            <icon name="location" size="tiny" />
            <span class="tdl tec __lh-1_5" v-html="storeLink" />
          </div>
          <site-button v-bind="contactBtn" />
        </div>
      </div>
    </div>
    <notice-modal v-bind="fields.authModal" ref="authModalRef" />
    <notice-modal v-bind="fields.unpublishedModal" ref="unPublishedModalRef" />
    <used-car-image-modal :detail="detail" ref="usedCarImageModalRef" />
  </overall-settings>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-used-car-detail {
  $btn: '.e-site-button';
  .e-site-accordion {
    &__icon {
      > svg {
        height: 16px;
      }
    }
    &__content {
      .e-label-value {
        padding-left: 0;
        &__right {
          color: $grey-next;
        }
      }
    }
  }
  &__swiper {
    &-bar {
      height: 1px;
      background: $grey-89;
      margin-top: 16px;
      .swiper-pagination-progressbar-fill {
        display: block;
        height: 100%;
        background: $black;
        transform-origin: left center;
      }
    }
  }
  &__image {
    aspect-ratio: 3/2;
    position: relative;
    overflow: hidden;
    > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &-toggle {
      position: absolute;
      right: 16px;
      bottom: 16px;
      color: $yellow;
      border: 1px solid $yellow;
      width: 40px;
      height: 40px;
      cursor: pointer;
      transition: transform 600ms ease;
      .e-icon {
        position: absolute;
        left: calc(50% - 5px);
        top: calc(50% - 5px);
        transition: transform 600ms ease;
        svg {
          width: 10px;
          height: 10px;
        }
        &:first-child {
          transform: rotate(-135deg) translateX(8px);
        }
        &:last-child {
          transform: rotate(45deg) translateX(8px);
        }
      }
      &:hover {
        background-color: $yellow;
        color: $black;
        .e-icon {
          &:first-child {
            transform: rotate(-135deg) translateX(15px);
          }
          &:last-child {
            transform: rotate(45deg) translateX(15px);
          }
        }
      }
    }
  }
  &__info {
    position: sticky;
    top: 0;
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__pairs {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 24px;
  }
  &__pair {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    > span {
      + span {
        color: $grey-next;
      }
    }
  }
  &__config {
    margin-top: 48px;
  }
  &__store {
    color: $grey-next;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    &-text {
      display: flex;
      > a {
        color: $black;
        text-decoration: underline;
      }
      + span {
        display: flex;
        align-items: center;
        gap: 4px;
        &:before {
          content: '';
          height: 10px;
          border-left: 1px solid $grey-next;
          vertical-align: middle;
        }
      }
    }
  }
  &__view-detail-btn {
    align-self: center;
    .e-icon {
      position: relative;
      top: -1px;
    }
  }
  &__main {
    padding: 56px 32px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 72px 32px;
    background-color: $black;
    color: $white;
    &-line {
      align-self: stretch;
      height: 1px;
      background-image: linear-gradient(to right, rgba(255, 242, 0, 0) 0%, #fff200 47.4%, #fff983 50%, #fff200 52.6%, rgba(255, 242, 0, 0) 100%);
    }
  }
  &__toolbar {
    position: sticky;
    bottom: 0;
    padding: 8px grid-width(2);
    background: radial-gradient(60.41% 90% at 49.82% 140%, rgba(46, 46, 46, 0.64) 0%, rgba(0, 0, 0, 0.8) 100%);
    backdrop-filter: blur(4px);
    color: $white;
    display: flex;
    justify-content: space-between;
    .e-site-button {
      font-size: 12px;
    }
  }
  @include tablet-landscape {
    &__banner {
      @include grid-container;
    }
    &__side {
      @include grid-block(1, 12);
    }
    &__product {
      @include grid-block(15, 8);
    }
    &__images {
      display: grid;
      gap: 16px;
    }
    &__image {
      &-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        color: $white;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        .e-icon {
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
      &:nth-child(1),
      &:nth-child(2) {
        @include grid-block(1, 2);
      }
    }
    &__info {
      padding: 100px 0 80px 0;
    }
    &__main {
      padding: 56px 0;
      @include grid-container;
    }
    &__description {
      @include grid-block(3, 10);
    }
    &__config {
      @include grid-block(15, 8);
      margin-top: 0;
    }
    &__body {
      padding: 72px grid-width(8);
    }
    &__toolbar {
      .e-site-button {
        font-size: 16px;
      }
    }
  }
}
html.rtl {
  .c-used-car-detail {
    &__swiper {
      &-bar {
        .swiper-pagination-progressbar-fill {
          transform-origin: right center;
        }
      }
    }
  }
}
</style>
