<template>
  <overall-settings v-bind="$props" style="z-index: 10;">
    <div class="c-reservation-bar">
      <section class="c-reservation-bar__content">
        <section class="c-reservation-bar__detail" :class="[`m-align-${$settingValue(fields.mobileAlign, 'left')}`]">
          <JssText class="c-reservation-bar__detail__title" :field="fields.title" tag="div" />
          <JssText class="c-reservation-bar__detail__num" :field="fields.num" tag="div" />
        </section>

        <section class="c-reservation-bar__button-wrapper">
          <site-button v-bind="fields.button" />
        </section>
      </section>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef ReservationBarFields
 * @property {GlobalSettingEntry} theme
 * */
export default {
  name: 'ReservationBar',
  props: {
    /**@type ReservationBarFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {}
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-reservation-bar {
  $c: &;
  z-index: 10;
  color: #000;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  overflow: hidden;
  border-top: 1px solid rgba(153, 153, 153, 0.5);
  &__content {
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 20px;
    background: rgb(255, 242, 0);
  }
  &__title {
    flex-basis: 100%;
    &__logo {
      width: 40px;
      height: 40px;
    }
    &__word {
      display: none;
    }
  }
  &__detail {
    flex-basis: 100%;
    color: #000;
    padding-right: 16px;
    &.m-align {
      &-left {
        text-align: start;
      }
      &-center {
        padding-left: 40px;
      }
      &-right {
        text-align: end;
      }
    }
    &__title {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      letter-spacing: -1px;
    }
    &__num {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -1px;
      padding-top: 7px;
    }
  }
  &__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100%;
    flex-shrink: 0;
    background: $black;
    .e-site-button {
      background: transparent !important;
      color: $white !important;
      border: none !important;
      height: 100%;
    }
  }
  @include tablet-landscape {
    .c-reservation-bar__content {
      padding-left: 40px;
    }

    .c-reservation-bar__title {
      .c-reservation-bar__title__logo {
        display: none;
      }
      .c-reservation-bar__title__word {
        display: block;
        width: 110px;
        height: auto;
      }
    }
    &__detail {
      &.m-align {
        &-left {
          text-align: start;
        }

        &-center {
          padding-left: 0;
        }

        &-right {
          text-align: start;
        }
      }
    }
    &__button-wrapper {
      width: 20%;
    }
  }
}
</style>
