<template>
  <overall-settings v-bind="$props">
    <div class="c-usp" :class="rootClasses" ref="rootEl">
      <div class="c-usp__tab-images">
        <adaptive-image
          class="c-usp__tab-image"
          :class="[{ active: index === activeIndex }]"
          :field="item.fields.image"
          v-for="(item, index) in fields.items"
          :key="item.id"
          :ref="(ref) => (tabImageRef[index] = ref)"
        >
          <heading class="c-usp__title" :field="fields.title" :type="fields.type" rich />
        </adaptive-image>
      </div>
      <div class="c-usp__tabs">
        <div class="c-usp__tab-header">
          <div class="c-usp__tab-bar" ref="tabBarRef">
            <jss-rich-text
              class="c-usp__tab"
              :class="[{ active: index === activeIndex }]"
              :field="item.fields.title"
              v-for="(item, index) in fields.items"
              :key="item.id"
              :ref="(ref) => (tabRef[index] = ref)"
              tag="div"
              @click="onTabClick($event, item, index)"
            />
          </div>
          <div class="c-usp__line" ref="lineRef" />
        </div>
        <div class="c-usp__tab-bodies" ref="tabBodiesRef">
          <jss-rich-text
            class="c-usp__tab-body"
            :class="[{ active: activeIndex === index }]"
            v-for="(item, index) in fields.items"
            :key="item.id"
            :field="$adaptiveTextField(item.fields.body, item.fields.bodyMobile)"
            tag="div"
            :ref="(ref) => (tabBodyRef[index] = ref)"
          />
        </div>
      </div>
    </div>
  </overall-settings>
</template>
<script>
import { reactive, toRefs, onMounted, onBeforeUnmount, inject, computed } from 'vue';
import { outOfElement } from '@/utils/dom-utils';
import { debounce } from 'lodash';
import gsap from 'gsap';
import { settingValue } from '@/utils/site-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
export default {
  name: 'USP',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    let animating = false;
    const { deviceComputes } = inject('device-common');
    const _methods = {
      checkTab() {
        if (!state.rootEl) return;
        const $tabs = state.rootEl.querySelector('.c-usp__tabs');
        const $tabHeader = state.rootEl.querySelector('.c-usp__tab-header');
        const activeTab = state.tabRef[state.activeIndex].$el;
        if (deviceComputes.isMobileOrTablet.value) {
          if (state.activeIndex === props.fields.items.length - 1) {
            state.direction = 'left';
          } else if (state.activeIndex === 0) {
            state.direction = 'right';
          }
          if (state.direction === 'left') {
            $tabHeader.style.textAlign = 'right';
            state.tabBarRef.style.transform = `translateX(-${activeTab.offsetLeft + activeTab.clientWidth - $tabs.clientWidth}px)`;
            state.lineRef.style.left = `${$tabs.clientWidth - activeTab.clientWidth}px`;
          } else {
            $tabHeader.style.textAlign = 'left';
            state.tabBarRef.style.transform = `translateX(-${activeTab.offsetLeft}px)`;
            state.lineRef.style.left = '0';
          }
        } else {
          $tabHeader.style.textAlign = 'left';
          state.tabBarRef.style.transform = `translateX(0)`;
          state.lineRef.style.left = activeTab.offsetLeft + 'px';
        }
        state.lineRef.style.width = activeTab.clientWidth + 'px';
      },
      updateBodyHeight() {
        if (!state.tabBodiesRef) return;
        state.tabBodiesRef.style.height = state.tabBodyRef[state.activeIndex].$el.scrollHeight + 96 + 'px';
      },
      onResize: debounce(() => {
        _methods.checkTab();
        _methods.updateBodyHeight();
      }, 100),
      onMouseDown(e) {
        if (e.target === state.rootEl || state.rootEl.contains(e.target)) {
          state.swipable = true;
          state.swipePosition = { x: e.clientX, y: e.clientY };
        }
      },
      onMouseUp(e) {
        state.swipable = false;
      },
      onMouseMove(e) {
        _methods.onMove(e.clientX, e.clientY);
      },
      onTouchStart(e) {
        if (!e.touches?.length) return;
        const { clientX, clientY } = e.touches[0];
        state.swipable = true;
        state.swipePosition = { x: clientX, y: clientY };
      },
      onTouchMove(e) {
        if (!e.touches?.length) return;
        const { clientX, clientY } = e.touches[0];
        _methods.onMove(clientX, clientY);
      },
      onTouchEnd() {
        state.swipable = false;
      },
      onMove(x, y) {
        if (outOfElement(state.rootEl, x, y)) {
          state.swipable = false;
        }
        if (state.animating) return;
        if (state.swipable) {
          const distance = x - state.swipePosition.x;
          const activeTab = state.tabRef[state.activeIndex].$el;
          const $activeImage = state.tabImageRef[state.activeIndex].rootEl.$el;
          const $activeBody = state.tabBodyRef[state.activeIndex].$el;
          let nextIndex = distance > 0 ? state.activeIndex - 1 : state.activeIndex + 1;
          if (nextIndex < 0) {
            nextIndex = props.fields.items.length - 1;
          }
          if (nextIndex > props.fields.items.length - 1) {
            nextIndex = 0;
          }
          state.activeIndex = nextIndex;
          _methods.checkTab();
          const $nextImage = state.tabImageRef[nextIndex].rootEl.$el;
          const $nextBody = state.tabBodyRef[nextIndex].$el;
          state.animating = true;
          const tl = gsap.timeline({ paused: true });
          $nextImage.style.zIndex = 2;
          $nextImage.style.opacity = 0;
          $nextImage.style.display = 'block';
          $nextBody.style.opacity = 0;
          $nextBody.style.display = 'block';
          gsap.killTweensOf([$activeImage, $activeBody, $nextImage, $nextBody]);
          tl.to(
            $activeImage,
            {
              opacity: 0,
              zIndex: 0
            },
            0
          );
          tl.to(
            $activeBody,
            {
              opacity: 0
            },
            0
          );
          tl.to(
            $nextImage,
            {
              opacity: 1,
              duration: 0.6,
              zIndex: 1
            },
            0
          );
          tl.to(
            $nextBody,
            {
              opacity: 1,
              duration: 0.2,
              onComplete() {
                setTimeout(() => {
                  state.animating = false;
                }, 150);
                if (deviceComputes.isMobileOrTablet.value) {
                  _methods.updateBodyHeight();
                }
              }
            },
            '-=0.2'
          );
          tl.play();
        }
      }
    };
    const methods = {
      onTabClick: debounce((e, item, index) => {
        if (index !== state.activeIndex) {
          if (state.animating) return;
          state.animating = true;
          const tl = gsap.timeline({ paused: true });
          const $container = state.rootEl.querySelector('.c-usp__tab-images');
          const $prevImage = state.tabImageRef[state.activeIndex].rootEl.$el;
          const $prevBody = state.tabBodyRef[state.activeIndex].$el;
          const $nextImage = state.tabImageRef[index].rootEl.$el;
          const $nextBody = state.tabBodyRef[index].$el;
          state.activeIndex = index;
          _methods.checkTab();
          gsap.killTweensOf([$prevImage, $prevBody, $nextImage, $nextBody]);
          $nextImage.style.zIndex = 2;
          $nextImage.style.opacity = 0;
          $nextImage.style.display = 'block';
          $nextBody.style.opacity = 0;
          $nextBody.style.display = 'block';
          tl.to(
            $prevImage,
            {
              opacity: 0,
              zIndex: 0
            },
            0
          );
          tl.to(
            $prevBody,
            {
              opacity: 0
            },
            0
          );
          tl.to(
            $nextImage,
            {
              opacity: 1,
              duration: 0.6,
              zIndex: 1
            },
            0
          );
          tl.to(
            $nextBody,
            {
              opacity: 1,
              duration: 0.2,
              onComplete() {
                setTimeout(() => {
                  state.animating = false;
                }, 150);
                if (deviceComputes.isMobileOrTablet.value) {
                  _methods.updateBodyHeight();
                }
              }
            },
            '-=0.2'
          );
          tl.play();
        }
      }, 100)
    };
    const state = reactive({
      /**@type HTMLDivElement|null*/
      rootEl: null,
      tabRef: [],
      /**@type HTMLDivElement|null*/
      tabBarRef: null,
      tabImageRef: [],
      tabBodyRef: [],
      /**@type HTMLDivElement|null*/
      tabBodiesRef: null,
      /**@type HTMLDivElement|null*/
      lineRef: null,
      direction: 'right',
      activeIndex: 0,
      animating: false,
      swipable: false,
      swipePosition: { x: 0, y: 0 }
    });
    const computes = {
      rootClasses: computed(() => {
        const theme = settingValue(props.fields.theme);
        return [
          {
            animating: state.animating,
            [`theme-${theme}`]: !isNullOrEmpty(theme)
          }
        ];
      })
    };
    onMounted(() => {
      if (!state.rootEl) return;
      _methods.checkTab();
      _methods.updateBodyHeight();
      window.addEventListener('resize', _methods.onResize, { passive: true });
      window.addEventListener('mousedown', _methods.onMouseDown, { passive: true });
      window.addEventListener('mousemove', _methods.onMouseMove, { passive: true });
      window.addEventListener('mouseup', _methods.onMouseUp, { passive: true });
      window.addEventListener('touchstart', _methods.onTouchStart, { passive: true });
      window.addEventListener('touchmove', _methods.onTouchMove, { passive: true });
      window.addEventListener('touchend', _methods.onTouchEnd, { passive: true });
    });
    onBeforeUnmount(() => {
      if (!state.rootEl) return;
      window.removeEventListener('resize', _methods.onResize);
      window.removeEventListener('mousedown', _methods.onMouseDown);
      window.removeEventListener('mousemove', _methods.onMouseMove);
      window.removeEventListener('mouseup', _methods.onMouseUp);
      window.removeEventListener('touchstart', _methods.onTouchStart);
      window.removeEventListener('touchmove', _methods.onTouchMove);
      window.removeEventListener('touchend', _methods.onTouchEnd);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-usp {
  $c: &;
  -webkit-user-drag: none;
  user-select: none;
  &__tabs {
    position: relative;
    width: calc(100vw - 48px);
    margin: 0 auto;
    padding-top: 32px;
    overflow: hidden;
  }
  &__tab-header {
    position: relative;
    border-bottom: 1px solid rgba($grey, 0.6);
  }
  &__tab-bar {
    display: flex;
    padding-bottom: 16px;
    font-size: 16px;
    transition: all 0.4s cubic-bezier(0.1, 0.1, 0.1, 1);
  }
  &__tab {
    cursor: pointer;
    flex-shrink: 0;
    opacity: 0.4;
    transition: all 0.4s cubic-bezier(0.1, 0.1, 0.1, 1);
    &.active {
      opacity: 1;
    }
    & + & {
      margin-left: 100px;
    }
  }
  &__line {
    position: absolute;
    bottom: -1px;
    height: 2px;
    background-color: $black;
    transition: all 0.4s cubic-bezier(0.1, 0.1, 0.1, 1);
  }
  &__title {
    position: absolute;
    z-index: 3;
    left: 16px;
    bottom: 36px;
  }
  &__tab-images {
    background-color: $black;
    height: #{math.div(100, 3) * 4}vw;
    position: relative;
    color: $white;
  }
  &__tab-image {
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    &.active {
      opacity: 1;
    }
    .e-background-image__img {
      background-color: transparent;
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, rgba(#000, 0.6), transparent);
      }
    }
  }
  &__tab-bodies {
    position: relative;
  }
  &__tab-body {
    position: absolute;
    font-size: 16px;
    top: 32px;
    left: 0;
    color: #4f4f4f;
    display: none;
    &.active {
      display: block;
    }
  }
  @include mobile-tablet {
    &.theme {
      &-black,
      &-night-blue {
        #{$c}__line {
          background-color: $white;
        }
      }
      &-neutral-grey {
        #{$c}__line {
          background-color: $white;
        }
        #{$c}__tab-body {
          color: $white;
        }
      }
      &-yellow,
      &-light-grey,
      &-yellow-white,
      &-white-yellow {
        #{$c}__line {
          background-color: $black;
        }
        #{$c}__tab-body {
          color: $black;
        }
      }
    }
  }
  @include tablet-landscape {
    position: relative;
    color: $white;
    &__tab-images {
      height: 100vh;
    }
    &__tabs {
      position: absolute;
      z-index: 3;
      top: 0;
      left: grid-width(2);
      padding: 100px 0 0 0;
      width: grid-width(20);
    }
    &__line {
      background: $white;
    }
    &__tab-bar {
      justify-content: space-between;
    }
    &__tab {
      white-space: nowrap;
      flex-shrink: 1;
      & + & {
        margin-left: 48px;
      }
    }
    &__tab-bodies {
      padding: 32px 0 300px 0;
      transition: all 0.2s cubic-bezier(0.1, 0.1, 0.1, 1);
    }
    &__tab-body {
      width: grid-width(8);
      color: $white;
    }
    &__tab-image {
      .e-background-image__img {
        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(to bottom, rgba(#000, 0.6), transparent);
        }
      }
    }
    &__title {
      left: 0;
      bottom: 100px;
      padding: 0 grid-width(2);
    }
  }
}
</style>
