<template>
  <overall-settings v-bind="$props">
    <div class="c-stage-reserve">
      <background-image class="c-stage-reserve__image" :image="$adaptiveImageField(fields.image?.fields?.desktopImage, fields.image?.fields?.mobileImage, fields.image?.fields?.tabletImage)">
        <div class="c-stage-reserve__main">
          <div class="c-stage-reserve__content">
            <div :class="{ 'c-stage-reserve--align-right': $settingValue(fields.buttonPosition) === 'right' }">
              <a class="c-stage-reserve__return" :href="fields.button.fields?.link.value?.href" v-if="fields.button?.fields">
                <icon class="c-stage-reserve__return-icon" :svg="fields.button.fields?.icon?.field?.value?.svgCode" name="back" />
                <span class="c-stage-reserve__return-text">{{ $ifEmpty(fields.button.fields?.text.value, fields.button.fields?.link.value?.text) }}</span>
              </a>
              <!--          <site-button v-bind="fields.button" />-->
            </div>

            <jss-rich-text class="c-stage-reserve__title" :field="fields.title" tag="div" :class="{ 'c-stage-reserve--align-right': $settingValue(fields.titlePostion) === 'right' }" />
            <jss-rich-text
              class="c-stage-reserve__description"
              :field="fields.description"
              tag="div"
              :class="{ 'c-stage-reserve--align-right': $settingValue(fields.descriptionPosition) === 'right' }"
            />
          </div>
        </div>
      </background-image>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef StageReserveFields
 * @property {GlobalSettingEntry} theme
 * */

export default {
  name: 'StageReserve',
  props: {
    /**@type StageReserveFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {}
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-stage-reserve {
  position: relative;
  width: 100%;
  overflow: hidden;
  &__main {
    @include grid-container;
  }
  &__content {
    position: relative;
    z-index: 2;
    @include grid-block(2, 10);
    padding: 40px 0 50px 0;
    overflow: hidden;
  }
  &__return {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $white;
    .e-icon {
      width: 0;
      overflow: hidden;
      transition: width 0.5s;
      > svg {
        width: 20px;
        height: 20px;
      }
    }
    &:hover,
    &:visited {
      color: $white;
      .e-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  &__title {
    clear: both;
    width: 78%;
    font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
    color: #fff200;
    font-size: 54px;
    line-height: 1.2;
    letter-spacing: -8px;
    float: left;
    padding-top: 80px;
    margin-left: -10px;
  }
  &__description {
    clear: both;
    width: 78%;
    font-size: 16px;
    color: #fff200;
    line-height: 1.5;
    padding: 24px 0 0 0;
    float: left;
  }

  &--align-right {
    float: right !important;
    text-align: end !important;
  }
  @include tablet-landscape {
    &__content {
      @include grid-block(3, 20);
      padding: 60px 0 120px 0;
    }
    &__title {
      font-size: 78px;
      width: 75%;
    }
  }
}
html.rtl {
  .c-stage-reserve {
    &__return {
      float: right;
    }
    &__title {
      float: right;
    }
    &__description {
      float: right;
    }
  }
}
html[lang='ja-JP'] {
  .c-stage-reserve {
    &__title {
      font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
    }
  }
}
html[lang='ko-KR'] {
  .c-stage-reserve {
    &__title {
      font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
    }
  }
}
</style>
