<script setup>
/**
 * @typedef {DashboardLayoutFields} VehicleConfigListFields
 * @property {SimpleField} configureTitle
 * @property {DropLinkField<PopMenu>} popMenu
 * */
import { reactive, onMounted, inject, ref } from 'vue';
import { sitecoreComponentProps } from '@/utils/constants';
import { appendQuery, getQueryStrings } from '@/utils/uri-utils';
import { isArray, isNullOrEmpty } from '@/utils/obj-utils';
import api from '@/api';
import utils from '@/utils';
import services from '@/services';
import { equalString } from '@/utils/string-utils';
import { canUseDOM } from '@/utils/dom-utils';
import useAppStore from '@/store/appStore';
import { webStorage } from '@/utils/web-storage';
import { S_CAR_MODELS } from '@/utils/web-storage-keys';

const props = defineProps({
  /**@type VehicleConfigListFields*/
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
/**@type AppStore*/
const appStore = useAppStore();
const toast = inject('toast');
const loading = inject('loading');
/**@type Ref<SaveVehicleConfigModalRef>*/
const saveModalRef = ref();
const state = reactive({
  filterId: null,
  transferCode: null,
  alpha2Code: null,
  configuration: null,
  configurationCode: null,
  collapsed: false,
  list: []
});
const getList = async () => {
  state.list = await services.site.getVehicleConfigList();
};
const onMenuCommand = (command, item) => {
  switch (command) {
    case 'deleted':
      {
        const index = state.list.findIndex((x) => equalString(x.id, item.id));
        state.list.splice(index, 1);
        services.site.setVehicleConfigList(state.list);
      }
      break;
    case 'rename':
      {
        saveModalRef.value.openEdit(item);
      }
      break;
    default:
      break;
  }
};
const saveConfiguration = async () => {
  const [res, ex] = await api.configuration.get(null, {
    filterId: state.filterId,
    transferCode: state.transferCode,
    engineeringCode: 1,
    showPrice: 1
  });
  if (!res) {
    await toast.showEx(ex);
    return null;
  }
  state.configuration = res;
  state.features = utils.mhp.analyzeConfiguration(res);
  const carModel = await services.site.getCarModelByCode(res.seriesCode, res.modelCode);
  const [priceIds, accPriceIds] = utils.mhp.getPriceIds(state.features);
  const buttonFeatures = utils.mhp.getButtonFeatures(state.features);
  const [formulaNo] = await services.site.getFormulaNos(props.page, res.series);
  const priceInfo = await services.price.getCarPrices(state.filterId, state.transferCode, formulaNo, state.alpha2Code, state.configuration?.modelCode, priceIds, [], accPriceIds);
  const { price: totalPrice, currency } = priceInfo.find((x) => equalString(x.elementName, 'Total Price')) || {};
  const images = await services.mhp.getImages(props.page, res.series, carModel, state.filterId, buttonFeatures);
  const body = {
    filterId: state.filterId,
    transferCode: state.transferCode,
    configurationCode: state.configurationCode,
    configurationName: res.model,
    vehicleModelName: res.model,
    currency: currency,
    // infoJson: JSON.stringify(state.features),
    // orgConfigInfo: JSON.stringify(res),
    images
  };
  if (!isNaN(totalPrice)) {
    body.totalPrice = Number(totalPrice);
  }
  const [saveRes, saveEx] = await api.vehicleConfig.save(null, body);
  if (saveEx) {
    await toast.showEx(saveEx);
  }
  body.id = saveRes;
  return body;
};
const onItemSaved = async () => {
  window.location = appendQuery(window.location.href, { __t: new Date().getMilliseconds() }, ['filter_id', 'transfer_code', 'configuration_code']);
};
if (canUseDOM()) {
  loading.show();
}
onMounted(async () => {
  loading.show();
  if (!appStore.hasLoggedIn) return;
  state.alpha2Code = utils.site.getPageAlpha2Code(props.page);
  [state.filterId, state.transferCode, state.configurationCode] = getQueryStrings('filter_id', 'transfer_code', 'configuration_code');
  await getList();
  if (!isNullOrEmpty(state.filterId) && !isNullOrEmpty(state.transferCode) && !isNullOrEmpty(state.configurationCode)) {
    const existItem = state.list.find((x) => equalString(x.configurationCode, state.configurationCode));
    if (!existItem) {
      const newItem = await saveConfiguration();
      if (!isNullOrEmpty(newItem?.id)) {
        saveModalRef.value.open(newItem);
      }
    } else {
      const repeatedErrMsg = props.fields?.repeatedErrMsg?.value;
      if (!isNullOrEmpty(repeatedErrMsg)) {
        toast.showError(repeatedErrMsg);
      }
    }
  }
  loading.hide();
});
</script>
<template>
  <DashboardLayout :fields="fields?.dashboardHeader?.fields" active-menu="configuration-list" :show-order-on-mobile="false" :show-account-on-mobile="false">
    <OverallSettings v-bind="$props">
      <div class="c-vehicle-config-list">
        <div class="c-vehicle-config-list__header">
          <JssRichText class="c-vehicle-config-list__title" :field="fields.title" />
        </div>
        <div class="c-vehicle-config-list__toolbar">
          <template-string :field="fields.summaryText" :data="{ total: state.list.length }" />
        </div>
        <div class="c-vehicle-config-list__main">
          <template v-for="(item, index) in state.list" :key="index">
            <div class="c-vehicle-config-list__line" />
            <vehicle-config-item :page="page" :fields="fields" :item="item" @menu-command="onMenuCommand" />
          </template>
        </div>
        <adaptive-media class="c-vehicle-config-list__create" :field="fields.configureMedia" :isBackground="false">
          <div class="c-vehicle-config-list__create-content">
            <JssRichText class="c-vehicle-config-list__create-content-text" :field="fields.configureTitle" />
            <SiteButton class="c-vehicle-config-list__create-content-btn" v-bind="fields.configureButton" />
          </div>
        </adaptive-media>
      </div>
    </OverallSettings>
  </DashboardLayout>
  <save-vehicle-config-modal v-bind="fields.saveConfigModal" @saved="onItemSaved" ref="saveModalRef" />
</template>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-vehicle-config-list {
  margin-top: 44px;
  &__header {
    padding: 0 grid-width-m(1);
  }
  &__toolbar {
    padding: 32px grid-width-m(1) 0 grid-width-m(1);
  }
  &__main {
    padding: 40px 0;
  }
  &__line {
    margin: 24px grid-width-m(1);
    border-top: 1px solid $grey-light;
  }
  &__create {
    color: $white;
    img {
      display: block;
    }
    &-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      padding: 24px grid-width-m(1);
      > .e-site-button {
        width: 100% !important;
      }
    }
  }
  @include tablet-landscape {
    padding: 0;
    margin-top: 100px;
    &__header {
      padding: 0 grid-width(2);
    }
    &__toolbar {
      padding: 32px grid-width(2) 0 grid-width(2);
    }
    &-content {
      padding: 24px grid-width(2);
    }
    &__line {
      margin: 40px grid-width(2);
    }
    &__create {
      &-content {
        height: 100%;
        justify-content: flex-end;
        padding: 0 grid-width(2) grid-width(2);
        > .e-site-button {
          width: 250px !important;
        }
      }
    }
  }
}
</style>
