<template>
  <overall-settings v-bind="$props" class="form-overall-settings">
    <form-layout v-bind="$props" ref="layoutRef">
      <template v-slot:image-content>
        <div class="c-reservation__info" v-if="carPrice">
          <div class="c-reservation__model-line" />
          <div class="c-reservation__info-main">
            <animated-content type="skew-in" class="c-reservation__model-heading">
              <heading :field="fields.reservationName" rich />
            </animated-content>
            <animated-content type="bottom-fade-in" :delay="200" offset="98%" class="c-reservation__price">
              <div v-html="$formatMoney(carPrice.price, carPrice.currency)" />
            </animated-content>
          </div>
        </div>
      </template>
      <div class="c-reservation" :class="[{ app: $isOnApp($route) }]" v-if="loaded" ref="rootEl">
        <div class="c-reservation__failed" v-if="paymentWasFailed">
          <jss-rich-text class="c-reservation__failed-overline" :field="fields.failedOverline" />
          <jss-rich-text class="c-reservation__failed-title" :field="fields.failedTitle" />
          <jss-rich-text class="c-reservation__failed-message" :field="fields.failedErrorMessage" />
          <jss-rich-text class="c-reservation__failed-subtitle" :field="fields.failedSubtitle" />
          <jss-rich-text class="c-reservation__failed-body" :field="fields.failedBody" />
          <div class="c-reservation__failed-widget" ref="failedWidgetRef" />
        </div>
        <div class="c-reservation__forms" v-else>
          <div class="c-reservation__steps" v-html="$formatString(fields.steps.value, { step: step, total: totalSteps })" />
          <heading class="c-reservation__title" :field="fields.title" :type="fields.titleType" rich />
          <!-- <animated-content type="open-curtain"> -->
          <adaptive-image class="c-reservation__image-mobile" :field="fields.image" v-if="fields.image" />
          <!-- </animated-content> -->
          <div class="c-reservation__info-mobile">
            <div class="c-reservation__model-line" />
            <div class="c-reservation__info-mobile-main" v-if="carPrice">
              <heading class="c-reservation__model-heading" :field="fields.reservationNameMobile" rich />
              <div class="c-reservation__price" v-html="$formatMoney(carPrice.price, carPrice.currency)" />
            </div>
          </div>
          <div v-if="fields.models?.length > 0" v-show="step === 1">
            <reservation-model-select :models="fields.models" v-model:selected-model="selectedModel" />
          </div>
          <div v-show="fields.modelForm && step === 1">
            <dynamic-form class="c-reservation__model-form" :form="fields.modelForm" @init-form="onModelFormInit" @change="onModelFormChange" ref="modelFormRef" v-if="fields.modelForm" />
            <div class="c-reservation__sold-out-models">
              <div class="c-reservation__sold-out-model" v-for="soldoutModel in fields.soldOutModels" :key="soldoutModel.id">
                <div class="c-reservation__sold-out-model-main">
                  <jss-text class="c-reservation__sold-out-model-version" :field="soldoutModel.fields.engineVersion" tag="div" />
                  <div class="c-reservation__sold-out-model-part">
                    <jss-text class="c-reservation__sold-out-model-type" :field="soldoutModel.fields.engineType" tag="div" />
                    <jss-text class="c-reservation__sold-out-model-transmission" :field="soldoutModel.fields.transmission" tag="div" />
                  </div>
                  <div class="c-reservation__sold-out-model-part">
                    <jss-text class="c-reservation__sold-out-model-edition" :field="soldoutModel.fields.edition" tag="div" />
                    <icon class="c-reservation__sold-out-model-brand" :field="soldoutModel.fields.brand" />
                  </div>
                </div>
                <jss-rich-text class="c-reservation__sold-out-model-desc" :field="fields.soldOutTitle" tag="div" />
              </div>
            </div>
          </div>
          <div v-show="(hasModelSelection && step === 2) || (!hasModelSelection && step === 1)">
            <heading class="c-reservation__secondary-title" :field="fields.contactTitle" rich />
            <dynamic-form
              :form="fields.mobileForm"
              v-model:data="mobileFormConfig"
              ref="mobileFormRef"
              :disabled="addressHasValidated"
              @change="onMobileFormChange"
              @btn-click="onMobileFormBtnClick"
            />
            <gee-captcha captcha-name="web-common" ref="geeCaptchaRef" />
          </div>
          <div v-show="(hasModelSelection && step === 3) || (!hasModelSelection && step === 2)">
            <heading class="c-reservation__secondary-title" :field="fields.addressTypeTitle" rich />
            <dynamic-form class="c-reservation__address-type-form" :form="fields.addressTypeForm" ref="addressTypeFormRef" @change="onAddressTypeFormChange" />
            <heading class="c-reservation__secondary-title" :field="fields.shippingTitle" rich />
            <div class="c-reservation__address-forms">
              <dynamic-form :form="fields.shippingForm" v-model:data="shippingFormCfg" :carModel="carPrice" ref="shippingFormRef" :disabled="addressHasValidated" />
              <checkbox
                class="c-reservation__check"
                :value="isDifferentAddress"
                :mandatoryText="fields.diffAddress?.fields.mandatoryText.value"
                v-if="fields.diffAddress"
                :disabled="addressHasValidated"
                @change="onDiffAddressChange"
              />
              <transition
                @before-enter="transitions.accordion.beforeEnter"
                @enter="transitions.accordion.enter"
                @leave="transitions.accordion.leave"
                @after-enter="onBillingAniAfterEnter"
                @after-leave="onBillingAniAfterLeave"
              >
                <div v-if="isDifferentAddress">
                  <div class="c-reservation__secondary-title">
                    <heading :field="fields.billingTitle" rich />
                  </div>
                  <dynamic-form :form="fields.billingForm" ref="billingFormRef" :disabled="addressHasValidated" />
                </div>
              </transition>
              <div v-if="fields.storeForm && storeFormCfg.store.controlProps.options.length > 1">
                <heading class="c-reservation__secondary-title" :field="fields.storeTitle" rich />
                <jss-rich-text class="c-reservation__secondary-subtitle" :field="fields.storeSubtitle" tag="div" />
                <dynamic-form :form="fields.storeForm" ref="storeFormRef" :data="storeFormCfg" />
              </div>
            </div>
            <dynamic-form :form="fields.consentForm" ref="consentFormRef" />
          </div>
          <div v-show="(hasModelSelection && step === 4) || (!hasModelSelection && step === 3)">
            <heading class="c-reservation__secondary-title" :field="fields.payTitle" rich />
            <div class="c-reservation__pay-widget" ref="payWidgetRef" />
            <dynamic-form :form="fields.payForm" ref="payFormRef" />
          </div>
        </div>
        <div class="c-reservation__failed-bar" v-if="paymentWasFailed">
          <site-button :fields="fields.contactButton?.fields" />
          <site-button :fields="fields.retryButton?.fields" @click="rePay" />
        </div>
        <div class="c-reservation__bar" v-else>
          <div class="c-reservation__pickup-time" v-html="carModel?.expectedDeliveryDate" v-if="step === 1 && fields.modelForm" />
          <site-button class="c-reservation__next" v-if="step < totalSteps" :fields="fields.nextButton?.fields" @click="goNext" :disabled="nextDisabled" />
          <site-button class="c-reservation__pay" v-if="step === totalSteps" :fields="fields.payButton?.fields" @click="onPay" />
          <site-button class="c-reservation__back" v-if="step > 1 && step < totalSteps" :fields="fields.backButton?.fields" @click="goBack" />
        </div>
      </div>
    </form-layout>
    <notice-modal ref="accountModalRef" v-bind="fields.accountModal" />
  </overall-settings>
</template>

<script>
/**
 * @typedef ReservationFields
 * @property {SimpleField} reservationName
 * @property {SimpleField} reservationNameMobile
 * @property {SimpleField} title
 * @property {GlobalSettingEntry} titleType
 * @property {SimpleField} contactTitle
 * @property {SimpleField} steps
 * @property {SimpleField} modelTitle
 * @property {SimpleField} soldOutTitle
 * @property {CarPrice} carPrice
 * @property {SimpleField} carPriceRequiredMessage
 * @property {Array<ReservationModel>} models
 * @property {Form} modelForm
 * @property {Form} mobileForm
 * @property {SimpleField} estimatedPickupTime
 * @property {SimpleField} addressTypeTitle
 * @property {Form} addressTypeForm
 * @property {SimpleField} shippingTitle
 * @property {Form} shippingForm
 * @property {CheckField} diffAddress
 * @property {SimpleField} billingTitle
 * @property {Form} billingForm
 * @property {SimpleField} storeTitle
 * @property {SimpleField} storeSubtitle
 * @property {Form} storeForm
 * @property {Form} consentForm
 * @property {SimpleField} payTitle
 * @property {SimpleField} paymentFailedTitle
 * @property {Form} payForm
 * @property {ButtonField} payButton
 * @property {ButtonField} backButton
 * @property {ButtonField} nextButton
 * */
/**
 * @typedef ReservationModel
 * @property {{
 *   data: SimpleField,
 *   name: SimpleField,
 *   body: SimpleField,
 *   sections: Array<ReservationModelSection>
 *  }} fields
 * */
/**
 * @typedef ReservationModelSection
 * @property {{
 *   name: SimpleField,
 *   contents: Array<ReservationModelSectionContent>
 * }} fields
 * */
/**
 * @typedef ReservationModelSectionContent
 * @property {{
 *  items: Array<ReservationModelSectionListContent|ReservationModelSectionKvContent>
 * }} fields
 * */
/**
 * @typedef ReservationModelSectionListContent
 * @property {{
 *   items: Array<ReservationModelSectionListItem>,
 *   columnCount: GlobalSettingEntry
 * }} fields
 * */
/**
 * @typedef ReservationModelSectionListItem
 * @property {{
 *   title: SimpleField
 * }} fields
 * */
/**
 * @typedef ReservationModelSectionKvContent
 * @property {{
 *   items: Array<ReservationModelSectionKvItem>
 * }} fields
 * */
/**
 * @typedef ReservationModelSectionKvItem
 * @property {{
 *   label: SimpleField,
 *   content: SimpleField
 * }} fields
 * */
import { computed, getCurrentInstance, inject, nextTick, onMounted, reactive, toRefs, watch } from 'vue';
import { canUseDOM, refreshPage, toErrorElement } from '@/utils/dom-utils';
import api from '@/api';
import { CAPTCHA_SCENE, CAPTCHA_TYPE, PAY_CHANNEL, TRADE_PLATFORM } from '@/utils/constants';
import services from '@/services';
import { debounce, difference, merge } from 'lodash';
import useAppStore from '@/store/appStore';
import { loadStripe } from '@/utils/stripe-utils';
import { buildAddress, getGlobalConfigs, getPageAlpha2Code, isAppBrowser, isOnApp, scrollToTop, settingValue } from '@/utils/site-utils';
import { webStorage } from '@/utils/web-storage';
import { S_ORDER_INFO, S_RESERVATION_DETAIL } from '@/utils/web-storage-keys';
import { countryNeedDoubleOptin, getCarModel, getCarSeries } from '@/services/siteService';
import { useI18n } from 'vue-i18n';
import { formatString, equalString } from '@/utils/string-utils';
import { appendQuery, getBetterUrl, getQueryStrings } from '@/utils/uri-utils';
import { formatDate } from '@/utils/date-utils';
import { gtmFormView, gtmFormStart, gtmFormStep, gtmFormSubmit, gtmPush } from '@/utils/gtm-utils';
import { transitions } from '@/utils/transitions';
import { GET_CAPTCHA_SCENE, getCaptchaExecutor } from '@/utils/captcha-utils';
import { useRoute } from 'vue-router';
import { ifNullOrEmpty, isNullOrEmpty } from '@/utils/obj-utils';
import onScrollToView from '@/hooks/onScrollToView';

export default {
  name: 'Reservation',
  props: {
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const { $nativeCommon } = proxy;
    /**@type AppStore*/
    const appStore = useAppStore();
    const toast = inject('toast');
    const loading = inject('loading');
    const appMethods = inject('appMethods');
    const { t } = useI18n();
    const route = useRoute();
    const { formType } = props.page.fields;
    let alpha2Code;
    let getCaptcha, checkCaptcha;
    let confirmPayment = null;
    let codeTimeout = null;
    let orderInfo = null;
    let clientSecret = null;
    let redirectStatus = null;
    let stripeHelper = null;
    let stripe = null;
    let detailHref = null;
    const state = reactive({
      /**@type FormLayout*/
      layoutRef: null,
      rootEl: null,
      /**@type HTMLElement*/
      payWidgetRef: null,
      /**@type DynamicForm*/
      modelFormRef: null,
      /**@type DynamicForm*/
      mobileFormRef: null,
      /**@type DynamicForm*/
      shippingFormRef: null,
      accountModalRef: null,
      shippingFormCfg: {
        companyName: {
          visible: false
        },
        email: {
          trigger: 'blur'
        }
      },
      /**@type DynamicForm*/
      billingFormRef: null,
      /**@type DynamicForm*/
      consentFormRef: null,
      /**@type DynamicForm*/
      payFormRef: null,
      /**@type CarPriceModel*/
      modelInfo: null,
      loaded: false,
      step: 1,
      carSeries: null,
      carModel: null,
      selectedModel: props.fields.models?.length > 0 ? props.fields.models[0] : null,
      isDifferentAddress: false,
      mobileHasValidated: false,
      addressHasValidated: false,
      nextDisabled: !(props.fields.modelForm || props.fields.models?.length > 0),
      /**@type DynamicForm*/
      addressTypeFormRef: null,
      addressType: 'private',
      modelFormConfig: {
        engine: {
          controlProps: {
            selectedOption: null
          }
        }
      },
      mobileFormConfig: {
        mobile: {
          controlProps: {
            disabled: false,
            value: null
          },
          buttons: {
            btnSendSms: {
              visible: true
            },
            btnChange: {
              visible: false
            },
            btnResend: {
              visible: false,
              disabled: false
            }
          }
        },
        code: {
          visible: false,
          buttons: {
            btnSubmitMobile: {
              visible: false
            }
          }
        }
      },
      /**@type DynamicForm*/
      storeFormRef: null,
      storeFormCfg: {
        store: {
          controlProps: {
            options: [],
            selectedOption: null
          }
        }
      },
      payModalRef: null,
      hasPayed: false,
      payHasError: false,
      paymentWasFailed: false,
      failedWidgetRef: null,
      /**@type GeeCaptchaRef*/
      geeCaptchaRef: null,
      orderRules: [],
      orderRule: null
    });
    const computes = {
      carPrice: computed(() => {
        if (!props.fields.carPrice?.fields) return null;
        const { id } = props.fields.carPrice;
        const { code, prePrice, carModel, carModelPic, transferCode, userOptionId, market, currency, currencySymbol } = props.fields.carPrice?.fields;
        return {
          itemId: id,
          ruleId: state.orderRule?.id,
          code: code.value,
          carModel: carModel.value,
          market: market.value,
          price: state.orderRule?.orderAmountNumber ?? prePrice.value,
          currency: state.orderRule?.currency ?? currency.value,
          currencySymbol: currencySymbol.value,
          carModelPic: carModelPic.value,
          transferCode: transferCode.value,
          userOptionId: userOptionId.value
        };
      }),
      totalSteps: computed(() => (computes.hasModelSelection.value ? 4 : 3)),
      hasModelSelection: computed(() => props.fields.modelForm || props.fields.models?.length > 0)
    };
    const _methods = {
      async getOrderRules() {
        const alpha2Code = getPageAlpha2Code(props.page);
        const [rules] = await api.order.rule.query(null, {
          items: [
            {
              orderType: 100,
              carSeriesCode: state.carSeries?.code,
              country: alpha2Code
            }
          ]
        });
        const rule = rules?.length > 0 ? rules[0] : null;
        state.orderRules = rules;
        state.orderRule = rule;
        return rules;
      }
    };
    const methods = {
      async showAccountNotice() {
        const button = await state.accountModalRef?.open();
        if (button?.buttonCode === 'sign-out') {
          await appMethods.justLogout();
          refreshPage();
        }
      },
      async onModelFormInit(data) {
        state.carModel = await getCarModel(state.carSeries?.series, data.engine?.controlProps?.selectedOption?.code);
      },
      async onModelFormChange(dataName, value) {
        if (dataName === 'engine') {
          state.carModel = await getCarModel(state.carSeries?.series, value?.code);
        }
      },
      onAddressTypeFormInit(data) {
        state.shippingFormCfg.companyName.visible = data.addressType?.controlProps?.selectedOption?.code === 'business';
      },
      onAddressTypeFormChange(dataName, value) {
        if (dataName !== 'addressType') return;
        state.addressType = value.code;
        state.shippingFormCfg.companyName.visible = value.code === 'business';
      },
      checkoutTracking(data) {
        const { productName, productVariant } = props.page?.fields || {};
        const pushData = merge(
          {
            product_name: productName?.value,
            product_variant: productVariant?.value
          },
          data
        );
        gtmPush(pushData);
      },
      onMobileFormBtnClick: debounce(async (e, item, btnName) => {
        const { mobile, code } = state.mobileFormConfig;
        const { btnSendSms, btnResend, btnChange } = mobile.buttons;
        const { btnSubmitMobile } = code.buttons;
        switch (btnName) {
          case 'btnSendSms':
          case 'btnResend':
            {
              const [valid, mobileData] = state.mobileFormRef.validateItem(item, true);
              if (valid) {
                loading.show();
                let geeResult = await state.geeCaptchaRef.validate();
                const captchaBody = merge(
                  {
                    captchaType: CAPTCHA_TYPE.phone,
                    captchaScene: CAPTCHA_SCENE.registerLogin,
                    phone: mobileData.number,
                    mobileAreaCode: mobileData.area.code
                  },
                  geeResult
                );
                const [res, ex] = await getCaptcha(null, captchaBody);
                state.mobileFormConfig.mobile.value = mobileData;
                loading.hide();
                if (ex) {
                  const msg = await services.site.getSiteError(ex);
                  await toast.showError(msg);
                  break;
                }
                methods.startCodeCountdown(60);
                methods.checkoutTracking({
                  event: 'checkout_verify_phone',
                  checkout_step: 1
                });
                btnSendSms.visible = false;
                btnResend.visible = true;
                code.visible = true;
                btnSubmitMobile.visible = true;
                state.mobileFormRef.clearItemSuccess('mobile');
              }
            }
            break;
          case 'btnSubmitMobile':
            {
              const [valid, mobileData] = await state.mobileFormRef.validate();
              if (valid) {
                loading.show();
                const [res, ex] = await checkCaptcha(null, {
                  mobileAreaCode: mobileData.mobile.area.code,
                  phone: mobileData.mobile.number,
                  captcha: mobileData.code
                });
                loading.hide();
                if (ex) {
                  await toast.showEx(ex);
                  break;
                }
                methods.checkoutTracking({
                  event: 'checkout_verify_code',
                  checkout_step: 1
                });
                if (codeTimeout) {
                  clearTimeout(codeTimeout);
                  btnResend.disabled = false;
                  state.mobileFormRef.setItemInfoMsg('mobile', null);
                }
                state.nextDisabled = false;
                mobile.controlProps.disabled = true;
                btnChange.visible = true;
                btnResend.visible = false;
                state.mobileHasValidated = true;
                state.mobileFormRef.setItemSuccess('mobile');
                code.visible = false;
                btnSubmitMobile.visible = false;
              }
            }
            break;
          case 'btnChange':
            {
              mobile.controlProps.disabled = false;
              btnSendSms.visible = true;
              btnChange.visible = false;
              code.visible = false;
              btnSubmitMobile.visible = false;
              state.mobileFormRef.clearItemSuccess('mobile');
              code.controlProps.value = '';
              state.nextDisabled = true;
            }
            break;
          default:
            break;
        }
      }, 100),
      onMobileFormChange(dataName, value) {
        const { mobile, code } = state.mobileFormConfig;
        const { btnSendSms, btnResend, btnChange } = mobile.buttons;
        if (dataName === 'mobile') {
          if (codeTimeout) {
            clearTimeout(codeTimeout);
            btnResend.visible = false;
            state.mobileFormRef.setItemInfoMsg('mobile', null);
          }
          btnSendSms.visible = true;
          btnChange.visible = false;
        }
      },
      onDiffAddressChange(e, checked) {
        state.isDifferentAddress = checked;
      },
      async submitOrder() {
        let valid = true,
          modelInfo = null,
          isBillingValid,
          billingFormData,
          storeValid = true,
          storeFormData = null;
        const consentNos = [];
        const [, mobileFormData] = await state.mobileFormRef.validate();
        if (state.modelFormRef) {
          [, modelInfo] = await state.modelFormRef.validate();
        }
        const [isShippingValid, shippingFormData] = await state.shippingFormRef.validate();
        valid = valid && isShippingValid;
        if (state.isDifferentAddress) {
          [isBillingValid, billingFormData] = await state.billingFormRef.validate();
          consentNos.push(state.billingFormRef.getVerifiedConsents());
          valid &= isBillingValid;
        } else {
          billingFormData = shippingFormData;
        }
        const [isConsentValid, consentFormData] = await state.consentFormRef.validate();
        if (props.fields.storeForm && state.storeFormRef) {
          [storeValid, storeFormData] = await state.storeFormRef.validate();
        }
        valid &= storeValid;
        valid &= isConsentValid;
        if (!valid) {
          await toErrorElement(state.rootEl);
          return null;
        }

        loading.show();
        if (!isAppBrowser()) {
          const [loginRes, loginEx] = await api.cidp.phoneRegisterLogin(null, {
            email: shippingFormData.email,
            firstName: shippingFormData.firstName,
            middleName: '',
            lastName: shippingFormData.lastName,
            mobileAreaCode: mobileFormData.mobile.area.code,
            phone: mobileFormData.mobile.number,
            terms: true,
            zipcode: shippingFormData.zipcode,
            source: '202', //1,
            password: '',
            title: '',
            address: shippingFormData.address,
            address2: shippingFormData.address2,
            city: shippingFormData.city,
            country: shippingFormData.country.code,
            contactsAddress: buildAddress(shippingFormData.address, shippingFormData.address2, shippingFormData.zipcode, shippingFormData.city, shippingFormData.country.text)
          });
          if (loginEx) {
            await toast.showEx(loginEx);
            loading.hide();
            return;
          }
          appStore.updateLoginInfo(loginRes);
        }
        const buyCarType = state.addressType === 'private' ? 100 : 200;
        const [orderId, ex] = await services.order.createReservationOld(
          mobileFormData,
          computes.carPrice.value,
          modelInfo ?? state.selectedModel,
          shippingFormData,
          billingFormData,
          storeFormData,
          buyCarType
        );
        if (ex) {
          await toast.showEx(ex);
          loading.hide();
          return null;
        }
        const formDetails = {
          selected_model: computes.carPrice?.value?.code ?? null,
          selected_location_name: null,
          selected_location_type: null,
          selected_location_date: null,
          selected_location_time: null,
          customer_type: null,
          dealer_name: null
        };
        const leadsDetails = {};
        const allConsents = Array.from(new Set([...consentNos, ...state.shippingFormRef.getVerifiedConsents(), ...state.consentFormRef.getVerifiedConsents()]));
        const newsLetterConsents = state.consentFormRef.getItemConsents('termsCondition');
        const consentsToBind = difference(allConsents, newsLetterConsents);
        const leadTypes = state.consentFormRef.getLeadTypes();
        try {
          const [bindRes, bindEx] = await services.site.bindConsents(consentsToBind, {
            countryRegion: mobileFormData.mobile.area.data.alpha2Code,
            phone: mobileFormData.mobile.number,
            firstName: shippingFormData.firstName,
            lastName: shippingFormData.lastName,
            email: shippingFormData.email
          });
          if (leadTypes?.length) {
            leadTypes.forEach((l) => {
              leadsDetails[l.leadType] = bindRes ?? null;
            });
          }
        } catch (ex) {
          console.log('ex', ex);
        }
        if (consentFormData.CPTC) {
          const cptcBody = merge(
            {
              firstName: shippingFormData.firstName,
              middleName: shippingFormData.middleName ?? '',
              lastName: shippingFormData.lastName,
              email: shippingFormData.email,
              countryRegion: alpha2Code,
              channel: 'Official Website'
            },
            mobileFormData?.externalData,
            shippingFormData?.externalData,
            billingFormData?.externalData,
            consentFormData?.externalData
          );
          if (!isNullOrEmpty(consentFormData.isAboveTwenty)) {
            merge(cptcBody, { isAboveTwenty: consentFormData.isAboveTwenty });
          }
          const [newsletterRes] = await api.lcms.crm.cptc.create(null, cptcBody);
          // const needDoubleOptin = await countryNeedDoubleOptin(alpha2Code);
          // const [newsletterRes, newsletterEx] = await api.crm.newsletter.subscribe(
          //   null,
          //   merge(
          //     {
          //       vehicleModelOfInterest: computes.carPrice.value.code,
          //       countryRegion: shippingFormData.country.data.alpha2Code,
          //       language: props.page.itemLanguage,
          //       needDoubleOptin,
          //       termsAndConditions: newsLetterConsents.map((revisionNumber) => ({
          //         revisionNumber: revisionNumber,
          //         title: window.document.title,
          //         formUrl: window.location.href,
          //         effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
          //       })),
          //       phone: mobileFormData.mobile.number,
          //       firstName: shippingFormData.firstName,
          //       middleName: '',
          //       lastName: shippingFormData.lastName,
          //       email: shippingFormData?.email,
          //       channel: isOnApp(route) ? 'APP' : 'Official Website',
          //       company: shippingFormData?.companyName ?? '',
          //       title: shippingFormData?.title ?? ''
          //     },
          //     mobileFormData?.externalData,
          //     shippingFormData?.externalData,
          //     billingFormData?.externalData,
          //     consentFormData?.externalData
          //   )
          // );
          if (leadTypes?.length) {
            leadTypes.forEach((l) => {
              if (equalString(l.consentType, 'CPTC')) {
                leadsDetails[l.leadType] = newsletterRes?.leadId ?? null;
              }
            });
          }
        }
        gtmFormSubmit(
          formType?.value,
          props.fields.shippingForm?.id,
          {
            email: shippingFormData?.email,
            mobileAreaCode: mobileFormData.mobile.area.code,
            phone: mobileFormData.mobile.number
          },
          formDetails,
          leadsDetails
        );
        state.addressHasValidated = true;
        if (isAppBrowser()) {
          $nativeCommon.goPage({ type: 1, params: { orderId } });
          loading.hide();
          return null;
        } else {
          const [resPay, exPay] = await api.reservation.prePay(null, {
            id: orderId,
            tradePlatform: TRADE_PLATFORM.website,
            payChannel: PAY_CHANNEL.other,
            storeId: storeFormData?.store?.code ?? ''
          });
          if (!resPay) {
            await toast.showEx(exPay);
            loading.hide();
            return false;
          }
          const res = { ...resPay, orderId };
          webStorage.set(S_ORDER_INFO, res);
          loading.hide();
          return res;
        }
      },
      onPay: debounce(async () => {
        loading.show();
        if (state.payFormRef) {
          const [payFormValid] = await state.payFormRef.validate();
          if (!payFormValid) {
            loading.hide();
            return;
          }
        }
        if (!clientSecret) {
          const consentNos = state.payFormRef?.getVerifiedConsents() ?? [];
          const [, mobileFormData] = await state.mobileFormRef.validate();
          const [, shippingFormData] = await state.shippingFormRef.validate();
          services.site
            .bindConsents(consentNos, {
              countryRegion: mobileFormData.mobile.area.data.alpha2Code,
              phone: mobileFormData.mobile.number,
              firstName: shippingFormData.firstName,
              lastName: shippingFormData.lastName,
              email: shippingFormData.email
            })
            .catch();
        }
        try {
          const redirectUrl = appendQuery(window.location.href, {}, ['payment_intent', 'payment_intent_client_secret', 'redirect_status']);
          const error = await confirmPayment(redirectUrl);
          if (error) {
            loading.hide();
          }
        } catch {
          loading.hide();
        }
      }, 1000),
      rePay: debounce(async () => {
        loading.show();
        const redirectUrl = appendQuery(window.location.href, {}, ['payment_intent', 'payment_intent_client_secret', 'redirect_status']);
        const error = await confirmPayment(redirectUrl);
        if (error) {
          loading.hide();
        }
      }, 1000),
      goBack: debounce(async () => {
        state.step -= 1;
        if (state.step === 1 && computes.hasModelSelection.value) {
          state.nextDisabled = false;
        }
        methods.checkoutTracking({
          event: 'checkout',
          checkout_step: state.step
        });
        await nextTick();
        scrollToTop();
      }, 50),
      goNext: debounce(async () => {
        let res = null;
        const nextFunc = async () => {
          state.step++;
          methods.checkoutTracking({
            event: 'checkout',
            checkout_step: state.step
          });
          await nextTick();
          scrollToTop();
        };
        const saveOrder = async () => {
          res = await methods.submitOrder();
          if (!res) return;
          await nextFunc();
          await nextTick();
          const stripeHelper = await loadStripe(props.page, toast);
          if (!stripeHelper) return;
          const stripe = stripeHelper.initSdk(res);
          confirmPayment = stripeHelper.initWidget(stripe, state.payWidgetRef, res);
          loading.hide();
        };
        const stepFunc = {
          1: async () => {
            if (isAppBrowser()) {
              state.step += 2;
            } else {
              if (computes.totalSteps.value === 4) {
                if (state.modelFormRef) {
                  const [modelValid] = await state.modelFormRef.validate();
                  if (!modelValid) return;
                }
                state.nextDisabled = !state.mobileHasValidated;
                await nextFunc();
              } else {
                await nextFunc();
              }
            }
          },
          2: async () => {
            if (computes.totalSteps.value === 4) {
              await nextFunc();
            } else {
              await saveOrder();
            }
          },
          3: async () => {
            if (computes.totalSteps.value === 4) {
              await saveOrder();
            } else {
              await nextFunc();
            }
          }
        };
        await stepFunc[state.step]();
        return res;
      }, 50),
      startCodeCountdown(seconds) {
        const { mobile } = state.mobileFormConfig;
        const { btnResend } = mobile.buttons;
        const text = t('Please wait ##seconds## seconds before requesting a new code');
        btnResend.disabled = true;
        const fn = (seconds) => {
          state.mobileFormRef.setItemInfoMsg('mobile', formatString(text, { seconds }));
          if (seconds > 0) {
            codeTimeout = setTimeout(() => {
              fn(--seconds);
            }, 1000);
          } else {
            state.mobileFormRef.setItemInfoMsg('mobile', null);
            btnResend.disabled = false;
          }
        };
        fn(seconds);
      },
      onStepClick(step) {
        if (step === 1 && state.step === 2) {
          methods.goBack();
        }
      },
      onBillingAniAfterEnter() {
        // methods.updateLayoutSMagic();
      },
      onBillingAniAfterLeave() {
        // methods.updateLayoutSMagic();
      },
      async checkPayment() {
        loading.show();
        orderInfo = webStorage.get(S_ORDER_INFO);
        const { productName, productVariant } = props.page?.fields || {};

        gtmPush({
          event: 'generate_lead',
          lead_id: clientSecret?.replace(/_secret/gi, ''),
          user_id: appStore.loginInfo?.lotusId,
          product_name: productName?.value,
          product_variant: productVariant?.value
        });
        if (redirectStatus === 'succeeded') {
          stripeHelper = await loadStripe(props.page, toast);
          stripe = stripeHelper.initSdk(orderInfo);
          await methods.watching();
        } else {
          state.loaded = true;
          state.paymentWasFailed = true;
          await loading.hide();
          await nextTick();
          stripeHelper = await loadStripe(props.page, toast);
          stripe = stripeHelper.initSdk(orderInfo);
          setTimeout(() => {
            confirmPayment = stripeHelper.initWidget(stripe, state.failedWidgetRef, orderInfo);
          }, 500);
        }
      },
      async watching() {
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        switch (paymentIntent.status) {
          case 'succeeded':
            if (!detailHref || !orderInfo?.orderId) return;
            setTimeout(() => {
              window.location = appendQuery(detailHref, {
                orderId: orderInfo.orderId
              });
            }, 1000);
            break;
          case 'processing':
            console.log('Your payment is processing.');
            setTimeout(() => {
              methods.watching();
            }, 2000);
            break;
          case 'requires_payment_method':
          default:
            state.loaded = true;
            state.paymentWasFailed = true;
            loading.hide();
            await nextTick();
            confirmPayment = stripeHelper.initWidget(stripe, state.failedWidgetRef, orderInfo);
            break;
        }
      } //,
      // updateLayoutSMagic() {
      //   if (updateLayoutSMagicTimeout) {
      //     clearTimeout(updateLayoutSMagicTimeout);
      //   }
      //   updateLayoutSMagicTimeout = setTimeout(() => {
      //     state.layoutRef.updateScrollMagic();
      //   }, 20);
      // },
      // onModelCollapseChange() {
      //   methods.updateLayoutSMagic();
      // }
    };
    watch(
      () => state.step,
      (v) => {
        let stepName = '';
        switch (v) {
          case 1:
            {
              stepName = 'model_selecte';
            }
            break;
          case 2:
            {
              stepName = 'mobile_verify';
            }
            break;
          case 3:
            {
              stepName = 'personal_details';
            }
            break;
          case 4:
            {
              stepName = 'pay';
            }
            break;
          default:
            break;
        }
        gtmFormStep(formType?.value, props.fields.shippingForm?.id, appStore?.loginInfo, v.value, stepName, null);
      },
      { immediate: true }
    );
    if (canUseDOM()) {
      loading.show();
    }
    onScrollToView({
      getContainer() {
        return state.layoutRef.rootEl;
      },
      callback() {
        gtmFormView(formType?.value, props.fields.shippingForm?.id);
      }
    });
    onMounted(async () => {
      let [token] = getQueryStrings('access_token');
      if (isAppBrowser()) {
        const tkObj = await $nativeCommon.getTokenAsync();
        token = ifNullOrEmpty(tkObj.token, token);
        if (isNullOrEmpty(token)) {
          $nativeCommon.login();
          return;
        }
      }
      if (!isNullOrEmpty(token)) {
        const [userInfo] = await api.cidp.user.info(null, null, {
          headers: {
            authorization: token
          }
        });
        if (userInfo) {
          userInfo.token = token;
          await appStore.setLoginInfo(userInfo);
        }
      }
      // if (appStore?.hasLoggedIn) {
      //   await methods.showAccountNotice();
      // }
      alpha2Code = getPageAlpha2Code(props.page);
      const { carModel: carPriceModel } = props.fields.carPrice?.fields;
      state.carSeries = await getCarSeries(carPriceModel?.value ?? 'Eletre');
      if (!computes.carPrice.value) {
        await toast.show({
          message: props.fields.carPriceRequiredMessage.value,
          closable: true
        });
        return;
      }
      const { detailLink } = props.fields;
      detailHref = detailLink.value?.href;
      if (!detailHref) {
        await toast.showEx(null);
        return;
      }
      detailHref = getBetterUrl(detailHref, props.page.itemLanguage, true);
      [clientSecret, redirectStatus] = getQueryStrings('payment_intent_client_secret', 'redirect_status');
      if (clientSecret) {
        await methods.checkPayment();
      } else {
        await _methods.getOrderRules();
        state.storeFormCfg.store.controlProps.options = await services.agent.getSalesAgentOptions(props.page);
        methods.checkoutTracking({
          event: 'checkout',
          checkout_step: state.step
        });
        loading.hide();
        state.loaded = true;
        await nextTick();
        [getCaptcha, checkCaptcha] = getCaptchaExecutor(GET_CAPTCHA_SCENE.RESERVATION);
        state.geeCaptchaRef.init();
        gtmFormStart(formType?.value, props.fields.shippingForm?.id);
      }
    });
    return {
      transitions,
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-reservation {
  $c: &;
  padding-top: $space-24;
  padding-bottom: $space-40;
  &__model-heading {
    @include h6;
    font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
    margin-bottom: $space-12;
    text-transform: uppercase;
  }
  &__model-price {
    @include h7;
  }
  &__model-line {
    width: grid-width(1);
    border-bottom: 5px solid $grey;
    margin-right: $space-24;
    position: relative;
    bottom: 8px;
  }
  &__info {
    position: fixed;
    left: grid-width(1);
    bottom: grid-width(1);
    display: flex;
    align-items: stretch;
    #{$c}__price {
      color: $yellow;
    }
    #{$c}__model-line {
      border-color: $yellow;
    }
  }
  &__info-mobile {
    display: flex;
    align-items: stretch;
    margin-bottom: 40px;
  }
  &__image-mobile {
    display: block;
    height: grid-width(12);
    margin-bottom: 40px;
  }
  &__steps {
    display: flex;
    align-items: center;
    margin-bottom: $space-24;
  }
  &__title {
    @include h5;
    margin-bottom: 60px;
  }
  &__check {
    margin-bottom: 40px;
  }
  &__secondary-title {
    @include h7;
    font-weight: bold;
    display: flex;
    margin-bottom: 16px;
    + #{$c}__secondary-subtitle {
      margin-top: -8px;
    }
  }
  &__secondary-subtitle {
    @include h10;
    margin-bottom: 16px;
  }
  &__model-form {
    .e-radio-list {
      &__item {
        align-items: center;
        .e-radio-list__item-label {
          width: 100%;
          color: $grey;
          @include h11;
          > div {
            height: 64px;
          }
          svg {
            fill: currentColor;
          }
        }
        &.checked {
          .e-radio-list__item-label {
            color: $black;
          }
        }
      }
    }
    + .c-reservation__secondary-title {
      margin-bottom: $space-24;
    }
  }
  &__sold-out-models {
    padding-left: 40px;
    margin-bottom: $space-16;
  }
  &__sold-out-model {
    width: 100%;
    padding: $space-8 $space-16;
    border: 1px solid $grey;
    color: $grey !important;
    min-height: 64px;
    @include h10;
    &-main {
      display: flex;
      align-items: center;
    }
    &-desc {
      margin-top: $space-4;
    }
    &-version {
      @include h8;
      font-weight: bold;
      padding-right: $space-8;
    }
    &-part {
      & + & {
        padding-left: $space-24;
        margin-left: $space-24;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 1px;
          height: 36px;
          background-color: $grey;
        }
      }
    }
    &-transmission {
      font-size: 10px;
    }
    &-edition {
    }
    &-brand {
      position: relative;
      top: -5px;
      svg {
        width: 85px;
        height: auto;
      }
    }
    &-tag {
      font-size: 10px;
      margin-top: -8px;
    }
  }
  &__address-forms {
    margin-bottom: $space-24;
    border-bottom: 1px solid $grey-light;
  }
  &__newsletter-form {
    margin-top: -32px;
  }
  &__pay-widget {
    margin-bottom: 40px;
  }
  &__bar {
    padding: $space-20 0;
    border-top: 1px solid $grey-light;
    z-index: 0;
    .e-site-button {
      width: 80%;
      margin-left: 10%;
    }
  }
  &__back,
  &__next {
    &.disabled {
      &,
      &:hover {
        background: transparent !important;
        border: 1px solid $black !important;
        color: $black !important;
      }
    }
  }
  &__back {
    margin-top: $space-16;
  }
  &__pickup-time {
    text-align: center;
    margin-bottom: $space-24;
  }
  &__failed {
    &-overline {
      margin-bottom: $space-16;
    }
    &-title {
      margin-bottom: $space-16;
    }
    &-message {
      margin-bottom: $space-16;
    }
    &-subtitle {
      margin-bottom: $space-16;
    }
    &-body {
      margin-bottom: $space-24;
    }
    &-bar {
      margin-top: $space-16;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .e-site-button {
        width: 190px;
      }
    }
  }
  &.app {
    #{$c}__steps {
      display: none;
    }
  }
  @include tablet-landscape {
    &__info-mobile {
      display: none;
    }
    &__image-mobile {
      display: none !important;
    }
    &__bar {
      display: grid;
      position: relative;
      grid-template-columns: 120px 1fr 120px;
      .e-site-button {
        width: auto;
        margin-left: 0;
      }
    }
    &__pickup-time {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &__back {
      @include grid-block(1, 1, 1);
      margin-top: 0;
    }
    &__next,
    &__pay {
      @include grid-block(3, 1, 1);
    }
  }
  @include desktop {
    &__bar {
      grid-template-columns: 190px 1fr 190px;
    }
  }
}
</style>
