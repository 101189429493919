<script setup>
import { computed, inject, onMounted, reactive, ref } from 'vue';
import { ERROR_CODES, sitecoreComponentProps } from '@/utils/constants';
import { transitions } from '@/utils/transitions';
import { isArray, isNullOrEmpty } from '@/utils/obj-utils';
import api from '@/api';
import { getGlobalConfig, getGlobalConfigs, getPageAlpha2Code } from '@/utils/site-utils';
import { canUseDOM, getCurrentPosition } from '@/utils/dom-utils';
import useAppStore from '@/store/appStore';
import { merge } from 'lodash';
const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
/**@type AppStore*/
const appStore = useAppStore();
const toast = inject('toast');
const loading = inject('loading');
/**@type Ref<UsedCarFilterMenuRef>*/
const filterMenuRef = ref();
/**@type Ref<NoticeModalRef>*/
const authModalRef = ref();
const dealerSelectModalRef = ref();
const bodyVisible = ref(false);
const sortOption = ref(null);
const locationDenied = ref(false);
const fetching = ref(false);
const list = ref([]);
const filter = reactive({
  currency: 'EUR',
  series: [],
  yearStart: null,
  yearEnd: null,
  mileageStart: null,
  mileageEnd: null,
  priceStart: null,
  priceEnd: null,
  colors: [],
  transmission: [],
  approved: null
});
const pagination = reactive({
  page: 1,
  pageSize: props.fields?.pageSize.value ?? 5,
  total: null
});
const [distanceCfg] = getGlobalConfigs(props.page, 'distanceUnit');
const distanceUnit = reactive({
  phrase: distanceCfg?.fields.phrase.value ?? 'km',
  text: distanceCfg?.fields.text.value ?? 'Km'
});
const count = computed(() => list.value.filter((x) => x.type === 'used-car-card').length);
const toggleBody = () => {
  bodyVisible.value = !bodyVisible.value;
};
const openFilter = () => {
  filterMenuRef.value.open(filter);
};
const setFilterInfo = (filterInfo) => {
  filter.currency = filterInfo?.currency ?? 'EUR';
  filter.series = filterInfo?.series ?? [];
  filter.yearStart = filterInfo?.yearStart ?? null;
  filter.yearEnd = filterInfo?.yearEnd ?? null;
  filter.mileageStart = filterInfo?.mileageStart ?? null;
  filter.mileageEnd = filterInfo?.mileageEnd ?? null;
  filter.priceStart = filterInfo?.priceStart ?? null;
  filter.priceEnd = filterInfo?.priceEnd ?? null;
  filter.colors = filterInfo?.colors ?? [];
  filter.transmission = filterInfo?.transmission ?? [];
  filter.approved = filterInfo?.approved ?? null;
};
const init = async () => {
  loading.show();
  const filterInfo = await filterMenuRef.value?.init();
  setFilterInfo(filterInfo);
  pagination.page = 1;
  await fetchData(true);
};
const onSortChange = (e, selectedOption) => {
  sortOption.value = selectedOption;
  pagination.page = 1;
  loading.show();
  fetchData(true);
};
const authorizeLocation = async () => {
  loading.show();
  const [res, ex] = await getCurrentPosition();
  if (ex?.code === 1) {
    locationDenied.value = true;
    toast.showEx(ERROR_CODES.GeoRefused);
  } else if (!isNullOrEmpty(ex?.message)) {
    toast.showError(ex.message);
  }
  if (res?.coords) {
    appStore.setGeoLocation({
      longitude: res.coords.longitude,
      latitude: res.coords.latitude,
      authorized: true
    });
  }
  await init();
};
const onFilterUpdate = async (filterInfo) => {
  setFilterInfo(filterInfo);
  pagination.page = 1;
  loading.show();
  await fetchData(true);
  loading.hide();
};
const onChangeLocation = async () => {
  dealerSelectModalRef.value?.open();
};
const onEnterLocation = async () => {
  await authorizeLocation();
};
const onSearchGeo = async (geoInfo) => {
  dealerSelectModalRef.value?.close();
  appStore.setGeoLocation({
    latitude: geoInfo.lat,
    longitude: geoInfo.lng,
    authorized: false
  });
  pagination.page = 1;
  loading.show();
  await fetchData(true);
};
const fetchData = async (refresh = false) => {
  fetching.value = true;
  const alpha2Code = getPageAlpha2Code(props.page);
  let distanceRange = props.fields.distanceRange.value;
  const body = {
    pageNum: pagination.page,
    pageSize: pagination.pageSize,
    currency: filter.currency,
    seriesList: filter.series,
    mileageMin: filter.mileageStart,
    mileageMax: filter.mileageEnd,
    priceMin: filter.priceStart,
    priceMax: filter.priceEnd,
    sort: sortOption.value?.code ?? null,
    countryCode: alpha2Code
  };
  if (!isNullOrEmpty(distanceRange)) {
    if (distanceUnit.phrase === 'miles') {
      distanceRange = Math.floor(distanceRange / 0.6214);
    }
    merge(body, {
      distanceRange: distanceRange * 1000
    });
  }
  if (!isNullOrEmpty(filter.yearStart)) {
    merge(body, {
      productionTimeStart: new Date(filter.yearStart, 0, 1, 0, 0, 0, 0).getTime()
    });
  }
  if (!isNullOrEmpty(filter.yearEnd)) {
    merge(body, {
      productionTimeEnd: new Date(filter.yearEnd, 11, 31, 23, 59, 59, 999).getTime()
    });
  }
  if (filter.transmission?.length > 0) {
    merge(body, {
      transmission: filter.transmission
    });
  }
  if (filter.colors?.length > 0) {
    merge(body, {
      colorIdList: filter.colors
    });
  }
  if (!isNullOrEmpty(filter.approved)) {
    merge(body, {
      lotusApproved: filter.approved === 'yes' ? 1 : filter.approved === 'no' ? 0 : null
    });
  }
  if (appStore.hasGeoLocation) {
    let { longitude, latitude } = appStore.geoLocation;
    merge(body, {
      longitude: longitude,
      latitude: latitude
    });
  }
  const [res, ex] = await api.usedCar.list(null, body).finally(() => {
    fetching.value = false;
    loading.hide();
  });
  if (!isArray(res?.records)) {
    toast.showEx(ex);
    return;
  }
  const result = res.records.map((x) => ({
    type: 'used-car-card',
    data: {
      fields: props.fields,
      page: props.page,
      item: x
    }
  }));
  const start = list.value.length,
    end = result.length + list.value.length;
  const { linkedOutCards } = props.fields;
  for (let i = linkedOutCards.length - 1; i >= 0; i--) {
    const linkedOutCard = linkedOutCards[i];
    const { position } = linkedOutCard.fields;
    const cardData = {
      type: 'used-car-linked-out-card',
      data: {
        fields: linkedOutCard.fields,
        page: props.page
      }
    };
    if (position.value > start && position.value <= end) {
      result.splice(position.value - 1, 0, cardData);
    }
    if (position.value === end + 1) {
      result.push(cardData);
    }
  }
  if (refresh) {
    list.value = result;
  } else {
    list.value.push(...result);
  }
  pagination.total = Number(res?.total) ?? null;
};
const loadMore = async () => {
  pagination.page += 1;
  await fetchData();
};
if (canUseDOM()) {
  loading.show();
}
onMounted(async () => {
  if (!appStore.hasGeoLocation) {
    await authorizeLocation();
  } else {
    await init();
  }
});
</script>

<template>
  <overall-settings v-bind="props">
    <div class="c-used-car-list">
      <div class="c-used-car-list__header">
        <jss-rich-text class="mg-b-24" :field="fields.title" tag="h2" />
        <div class="mg-b-24">
          <jss-rich-text class="inline tl-b" :field="fields.subtitle" />
          <jss-rich-text class="inline tl-b tdl hand" :field="bodyVisible ? fields.collapseText : fields.readMoreText" @click="toggleBody" />
        </div>
        <div class="mg-b-24">
          <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
            <div v-show="bodyVisible">
              <jss-rich-text class="mg-b-24" :field="fields.body" tag="div" />
            </div>
          </transition>
        </div>
        <div class="c-used-car-list__top">
          <div class="c-used-car-list__top-main">
            <button class="c-used-car-list__filter-btn" @click="openFilter">
              <icon :field="fields.filterIcon" />
              <span>
                <jss-text :field="fields.filterText" tag="span" v-if="!$deviceComputes.isMobileOrTablet.value" />
                <span v-if="pagination.total !== null">&nbsp;({{ pagination.total }})</span>
              </span>
            </button>
            <dropdown class="c-used-car-list__sort-ddl" theme="search-grey" v-bind="$formDdl(fields.sortDorpdown)" :selected-option="sortOption" @change="onSortChange" />
          </div>
          <jss-rich-text class="c-used-car-list__help-m" :field="fields.mobileContactText" v-if="$deviceComputes.isMobileOrTablet.value" />
        </div>
        <div class="c-used-car-list__summary">
          <div>
            <template-string
              :field="!$isNullOrEmpty(appStore.hasGeoLocation) && !$isNullOrEmpty(fields.distanceRange?.value) ? fields.locationText : locationUnauthrizedText"
              :data="{ total: pagination.total, distance: $formatDistance(fields.distanceRange?.value, 'km', 0) }"
              tag="span"
              v-if="pagination.total !== null"
            />
            <jss-text class="c-used-car-list__location-btn" :field="fields.changeLocationText" @click="onChangeLocation" v-if="appStore?.hasGeoLocation" />
            <jss-text class="c-used-car-list__location-btn" :field="fields.enterLocationText" @click="onEnterLocation" v-else />
          </div>
          <jss-rich-text class="c-used-car-list__help-p" :field="fields.contactText" v-if="!$deviceComputes.isMobileOrTablet.value" />
        </div>
        <div class="c-used-car-list__content" v-if="list.length > 0">
          <template v-for="(item, index) in list" :key="index">
            <component :is="item.type" v-bind="item.data" />
          </template>
        </div>
        <div class="c-used-car-list__pagination">
          <template-string :field="fields.totalText" :data="{ count, total: pagination.total }" tag="div" />
          <site-button v-bind="fields.loadMoreButton" :loading="fetching" @click="loadMore" v-if="list.length < pagination.total" />
        </div>
      </div>
    </div>
    <used-car-filter-menu v-bind="fields.filterMenu" @update-filter="onFilterUpdate" ref="filterMenuRef" />
    <notice-modal v-bind="fields.authModal" ref="authModalRef" />
    <dealer-select-modal v-bind="fields.dealerSelectModal" purpose="get-geo" @get-geo="onSearchGeo" ref="dealerSelectModalRef" />
  </overall-settings>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-used-car-list {
  $c: &;
  $ddl: '.e-dropdown';
  $btn: '.e-site-button';
  padding: 80px 32px 48px 32px;
  .e-dropdown {
    &__selection {
      font-size: 14px;
      border: 2px solid #000;
      font-weight: 700;
    }
    &__placeholder {
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding-bottom: 32px;
    border-bottom: 1px solid $grey-89;
    &-main {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  &__filter-btn {
    background-color: transparent;
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-family: lotusFontFamily('Overpass Bold'), sans-serif !important;
    border: 2px solid #000;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.355, 0.005, 0.26, 1);
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
  &__sort-ddl {
    width: 140px;
  }
  &__help-m {
    justify-self: flex-end;
  }
  &__summary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 48px 0;
  }
  &__location-btn {
    cursor: pointer;
    text-decoration: underline;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__pagination {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 72px;
  }
  @include tablet {
    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @include tablet-landscape {
    padding: 120px grid-width(2) 48px grid-width(2);
    &__top {
      display: block;
      &-main {
        justify-content: space-between;
      }
    }
    &__filter-btn {
      padding: 0 32px;
    }
    &__sort-ddl {
      width: 240px;
    }
    &__content {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
