<template>
  <div class="c-sticky" v-if="$appVisible($route, fields)">
    <AnimatedContent :type="showDiv ? 'bottom-fade-in' : 'bottom-fade-out'" :turnOff="false" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)" :hide-content="false">
      <div v-if="isMobile()" class="c-sticky__wrapper">
        <div class="c-sticky__left" @click="goCallOrLink(fields.isPhone?.value)">
          <Icon v-if="!(fields?.isPhone?.value && displayPhone)" class="c-sticky__icon" :svg="fields.icon?.value?.svgCode" />
          <Icon v-if="fields?.isPhone?.value && displayPhone" class="c-sticky__icon" :svg="fields.businesshoursicon?.value?.svgCode" />
          <jss-rich-text class="c-sticky__support" :field="fields.leftname" :has-link-animation="false" />
        </div>
        <div class="c-sticky__right" @click="linkTo">
          <jss-rich-text class="c-sticky__name" :field="fields.rightname" :has-link-animation="false" />
        </div>
      </div>
      <div v-if="!isMobile() && fields?.lefthovername?.value != ''" class="c-sticky__content">
        <div class="c-sticky__leftpc" @mouseleave="leave" @mouseenter="over" @click="goCallOrLink(fields.isPhone?.value)">
          <Icon v-if="!(fields.isPhone?.value && displayPhone && isHover)" class="c-sticky__icon" :svg="fields.icon?.value?.svgCode" />
          <Icon v-if="fields.isPhone?.value && displayPhone && isHover" class="c-sticky__icon" :svg="fields.businesshoursicon?.value?.svgCode" />
          <div ref="leftEl">
            <AnimatedContent type="fade-in" :duration="800" :offset="'99%'" :turnOff="false" v-if="isHover">
              <jss-rich-text
                class="c-sticky__support"
                :field="fields.isPhone?.value ? (displayPhone ? { value: phoneNumber } : fields.lefthovername) : fields.lefthovername"
                :has-link-animation="false"
              />
            </AnimatedContent>
            <AnimatedContent type="fade-in" :duration="800" :offset="'99%'" :turnOff="false" v-else>
              <jss-rich-text class="c-sticky__support" :field="fields.leftname" :has-link-animation="false" />
            </AnimatedContent>
          </div>
        </div>
        <div class="c-sticky__rightpc" @click="linkTo">
          <jss-rich-text class="c-sticky__name" :field="fields.rightname" :has-link-animation="false" />
        </div>
      </div>
      <div v-if="!isMobile() && fields?.lefthovername?.value == ''" class="c-sticky__rightpc1" @click="linkTo">
        <jss-rich-text class="c-sticky__name1" :field="fields.rightname" :has-link-animation="false" />
      </div>
    </AnimatedContent>
  </div>
</template>

<script>
import { onMounted, reactive, watch, toRefs, onBeforeUnmount, nextTick, computed } from 'vue';
import { isMobile, getScrollTop, canUseDOM } from '@/utils/dom-utils';
import { appVisible, settingValue } from '@/utils/site-utils';
import { useRoute, useRouter } from 'vue-router';
import { debounce } from 'lodash';
/**
 * @typedef StickyFields
 * @property {GlobalSettingEntry} theme
 * */

export default {
  name: 'Sticky',
  props: {
    /**@type StickyFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    if (!appVisible(route, props.fields)) return;
    const state = reactive({
      leftEl: null,
      showDiv: false,
      isHover: false,
      displayPhone: false,
      currentTime: new Date(),
      workday: []
    });
    watch(
      () => state.currentTime,
      (val) => {
        if (!props.fields?.hasbusinesshours?.value) return;
        state.workday.forEach((item) => {
          if (item == val.getDay()) {
            const h = val.getHours();
            const m = val.getMinutes();
            const startH = props.fields?.starthour?.value?.slice(0, 1) == 0 ? props.fields?.starthour?.value?.slice(1, 2) : props.fields?.starthour?.value;
            const startM = props.fields?.startminute?.value?.slice(0, 1) == 0 ? props.fields?.startminute?.value?.slice(1, 2) : props.fields?.startminute?.value;
            const endH = props.fields?.endhour?.value?.slice(0, 1) == 0 ? props.fields?.endhour?.value?.slice(1, 2) : props.fields?.endhour?.value;
            const endM = props.fields?.endminute?.value?.slice(0, 1) == 0 ? props.fields?.endminute?.value?.slice(1, 2) : props.fields?.endminute?.value;
            if (startH * 3600 + startM * 60 < endH * 3600 + endM * 60) {
              if (startH * 3600 + startM * 60 <= h * 3600 + m * 60 && endH * 3600 + endM * 60 >= h * 3600 + m * 60) {
                state.displayPhone = true;
              } else {
                state.displayPhone = false;
              }
            } else {
              if (startH * 3600 + startM * 60 <= h * 3600 + m * 60 || endH * 3600 + endM * 60 >= h * 3600 + m * 60) {
                state.displayPhone = true;
              } else {
                state.displayPhone = false;
              }
            }
          }
        });
      }
    );
    watch(
      () => state.isHover,
      () => {
        nextTick(() => {
          if (canUseDOM()) {
            let space = 60;
            if (computes.hasLeftIcon.value) {
              space = 96;
            }
            document.querySelector('.c-sticky__leftpc').style.width = `${state.leftEl.scrollWidth + space + 20}px`;
          }
        });
      },
      {
        immediate: true
      }
    );
    onMounted(() => {
      if (props.fields?.hasbusinesshours?.value) {
        setInterval(() => {
          state.currentTime = new Date();
        }, 1000);
        methods.getWorkdays();
      }
      window.addEventListener('scroll', methods.onScroll);
    });
    onBeforeUnmount(() => {
      if (props.fields?.hasbusinesshours?.value) {
        clearInterval();
      }
      window.removeEventListener('scroll', methods.onScroll);
    });
    const computes = {
      bgColor: `background-color: ${settingValue(props.fields.bgcolor)};`,
      phoneNumber: computed(() => {
        return props.fields.phonenumber?.value?.replace(/(^[^+0-9])|([^0-9]$)/gi, '');
      }),
      hasLeftIcon: computed(() => (props.fields.icon?.value?.svgCode && props.fields.businesshoursicon?.value?.svgCode ? true : false))
    };
    const methods = {
      isMobile: isMobile,
      over: () => {
        state.isHover = true;
      },
      leave: () => {
        state.isHover = false;
      },
      async goCallOrLink(goCall) {
        if (goCall) {
          if (state.displayPhone) {
            window.location.href = 'tel:' + computes.phoneNumber.value;
          } else {
            if (props.fields?.leftlink?.value?.href?.indexOf('http') != -1) {
              window.location.href = props.fields?.leftlink?.value?.href;
            } else {
              router.push({
                path: props.fields?.leftlink?.value?.href
              });
            }
          }
        } else {
          if (props.fields?.leftlink?.value?.href?.indexOf('http') != -1) {
            window.location.href = props.fields?.leftlink?.value?.href;
          } else {
            router.push({
              path: props.fields?.leftlink?.value?.href
            });
          }
        }
      },
      linkTo() {
        if (props.fields?.rightlink?.value?.href?.indexOf('http') != -1) {
          window.location.href = props.fields?.rightlink?.value?.href;
        } else {
          router.push({
            path: props.fields?.rightlink?.value?.href
          });
        }
      },
      onScroll: debounce(() => {
        if (canUseDOM) {
          const scrollTop = getScrollTop();
          const footerEl = document.querySelector('.c-footer');
          const footerHeight = footerEl?.offsetHeight;
          const statementEl = document.querySelector('.c-site-statement');
          const statementHeight = statementEl?.offsetHeight ?? 0;
          const windowHeight = document.documentElement.clientHeight;
          const scrollHeight = document.documentElement.scrollHeight;
          if (scrollTop <= props.fields.position?.value) {
            state.showDiv = false;
          } else {
            if (props.fields?.footerdistance?.value == 0 && scrollTop + windowHeight >= scrollHeight - footerHeight - statementHeight - 120) {
              state.showDiv = false;
            } else if (props.fields?.footerdistance?.value > 0 && scrollTop + windowHeight + 20 >= scrollHeight - props.fields?.footerdistance?.value) {
              state.showDiv = false;
            } else {
              state.showDiv = true;
            }
          }
        }
      }, 20),
      getWorkdays() {
        if (props.fields?.workday) {
          props.fields?.workday.forEach((val) => {
            if (val.fields?.value) {
              state.workday.push(val.fields?.value?.value);
            }
          });
        }
      }
    };

    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-sticky {
  position: fixed;
  width: grid-width-m(12);
  height: 60px;
  z-index: 11;
  bottom: 0;
  &__wrapper {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
  }
  &__left {
    flex: 1;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  &__right {
    flex: 1;
    background-color: #fdef00;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &__support {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #ffffff;
    position: relative;
  }
  &__name {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  &__icon {
    width: 24px;
    height: 24px;
    color: #ffff20;
  }
}
@include tablet {
  .c-sticky {
    width: fit-content;
    right: 60px;
    animation: smooth-appear 1s ease forwards;
    @keyframes smooth-appear {
      to {
        bottom: 60px;
        opacity: 1;
      }
    }
    &__leftpc {
      background-color: #000000;
      display: flex;
      flex-direction: row;
      gap: 12px;
      animation: fadeIn linear 0.6s;
      transition: all 0.6s cubic-bezier(0.355, 0.005, 0.26, 1);
      padding: 18px 30px;
      overflow: hidden;
      cursor: pointer;
      white-space: nowrap;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    }
    &__rightpc {
      width: fit-content;
      background-color: #fdef00;
      animation: fadeIn linear 1s;
      padding: 18px 30px;
      cursor: pointer;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    }
    &__support {
      font-weight: 700;
      font-size: 16px;
      animation: fadeIn linear 1s;
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
    &__name {
      font-weight: 700;
      font-size: 16px;
      animation: fadeIn linear 1s;
    }
    &__content {
      width: 600px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      animation: fadeIn linear 1s;
    }
    &__rightpc1 {
      background-color: #fdef00;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
      animation: fadeIn linear 1s;
      cursor: pointer;
    }
    &__name1 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
      flex: none;
      order: 0;
      flex-grow: 0;
      animation: fadeIn linear 1s;
      white-space: nowrap;
    }
    &__icon {
      width: 21px;
      height: 21px;
      color: #ffff20;
    }
  }
}
html.rtl {
  .c-sticky__content {
    flex-direction: row-reverse;
  }
}
</style>
