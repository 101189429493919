<template>
  <OverallSettings v-bind="$props">
    <div class="c-store-locator-country-list" v-if="fields">
      <Heading class="c-store-locator-country-list__title" :field="fields.title" :type="fields.titleType" rich />
      <div class="c-store-locator-country-list__area" v-for="a in areaList" :key="a.id">
        <JssRichText class="c-store-locator-country-list__subtitle" :field="{ value: $formatString(fields.areaName.value, { area: a.fields.name.value }) }" />
        <div class="c-store-locator-country-list__countries">
          <JssRichText
            class="c-store-locator-country-list__country"
            v-for="c in a.countries"
            :key="c.id"
            :field="{ value: $formatString(fields.countryName.value, { country: c.localName }) }"
            @click="jump(c)"
          />
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { reactive, toRefs, onMounted, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getStoreTypes, getCountries } from '@/services/siteService';
import { redirectTo, appendQuery } from '@/utils/uri-utils';
import { getGlobalConfigs } from '@/utils/site-utils';
import { getWebLinkStores } from '@/services/agentService';
import { equalString } from '@/utils/string-utils';
import api from '@/api/index';
export default {
  name: 'StoreLocatorCountryList',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const loading = inject('loading');
    const route = useRoute();
    const router = useRouter();
    const toast = inject('toast');
    const [areas, storeLocatorOverviewLink] = getGlobalConfigs(props.page, 'areas', 'storeLocatorOverviewLink');
    const state = reactive({
      storeTypes: null,
      areaList: [],
      countryList: [],
      storeDetails: null,
      detailFields: null,
      webLinkStores: []
    });
    const methods = {
      initPage: async () => {
        const [res, ex] = await api.store.countryList();
        if (ex) {
          await toast.showEx(ex);
          setTimeout(() => {
            router.back();
          }, 2500);
        }
        areas.forEach((a) => {
          const countries = a.fields.regions.filter((i) => res.some((j) => equalString(j.country2Code, i.fields.alpha2Code.value)));
          countries.forEach((c) => {
            const alpha2Code = c.fields.alpha2Code.value;
            state.webLinkStores.forEach((s) => {
              if (s.children?.length) {
                const details = JSON.parse(s.children[0].details);
                if (equalString(alpha2Code, details.country2Code)) {
                  c.storeList = s.children;
                }
              }
            });
            c.localName = state.countryList.find((i) => equalString(alpha2Code, i.alpha2Code))?.countryName;
          });
          if (countries?.length) {
            a.countries = countries;
            state.areaList.push(a);
          }
        });
      },
      jump: async (c) => {
        const reg = /[`~!@#$^&*()=|{}':;',\\\\[\]\\.<>\\/?~@#……&*——|{}'""'\s]/g;
        if (c.fields.noSite.value) {
          return;
        }
        loading.show();
        if (c.fields?.storesLink?.value?.href) {
          window.location = c.fields?.storesLink?.value?.href;
          return;
        } else {
          if (c.storeList?.length == 1) {
            const storeDetails = JSON.parse(c.storeList[0].details);
            if (c.storeList[0].detailsLink?.url) {
              window.open(c.storeList[0].detailsLink.url);
            } else if (!c.storeList[0].disabled) {
              const detailsLink = appendQuery(`${storeLocatorOverviewLink?.value.href}/${storeDetails.countryName.replace(reg, '')}/${storeDetails.storeName.replace(reg, '')}`);
              redirectTo({ url: detailsLink, router });
            }
          } else {
            redirectTo({ url: appendQuery(`${storeLocatorOverviewLink?.value.href}/${c.name.replace(reg, '')}`), router });
          }
        }
      }
    };
    onMounted(async () => {
      loading.show();
      state.countryList = await getCountries();
      state.storeTypes = await getStoreTypes();
      state.webLinkStores = await getWebLinkStores();
      await methods.initPage();
      loading.hide();
    });
    return {
      ...toRefs(state),
      ...methods,
      ...route
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-store-locator-country-list {
  padding: 56px grid-width-m(1) 120px;
  &__title {
    padding-bottom: $space-40;
    font-size: 32px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  &__area {
    padding: $space-40 0;
    border-top: 1px solid $grey-light;
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
  }
  &__subtitle {
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: $space-24;
  }
  &__countries {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__country {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
  }
  @include tablet-landscape {
    padding: 80px grid-width(3) 180px;
    &__area {
      display: flex;
      flex-direction: row;
      gap: grid-width(1);
    }
    &__subtitle {
      width: grid-width(3);
    }
    &__countries {
      flex: 1;
      flex-direction: row;
      gap: 4px 0;
      flex-wrap: wrap;
    }
    &__country {
      width: 33%;
    }
  }
}
</style>
