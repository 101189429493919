<template>
  <OverallSettings v-bind="$props">
    <div class="c-test-drive-v3">
      <div class="c-test-drive-v3__greeting" v-if="step === 'greeting'">
        <div class="c-test-drive-v3__greeting-img">
          <AdaptiveImage :field="fields.greetingImage">
            <div class="c-test-drive-v3__greeting-img-text">
              <!-- <AnimatedContent type="fade-in" :duration="1200" :delay="400" :turn-off="!largeThanTablet() || fields.turnOffAnimation?.value"> -->
              <AnimatedContent type="fade-in" :duration="1200" :delay="400">
                <JssRichText :field="fields.greetingImageTitle" class="c-test-drive-v3__greeting-img-text-title" />
              </AnimatedContent>
              <!-- <AnimatedContent type="fade-in" :duration="1200" :delay="600" :turn-off="!largeThanTablet() || fields.turnOffAnimation?.value"> -->
              <AnimatedContent type="fade-in" :duration="1200" :delay="600">
                <JssRichText :field="fields.greetingImageBody" class="c-test-drive-v3__greeting-img-text-body" />
              </AnimatedContent>
            </div>
          </AdaptiveImage>
        </div>
        <div class="c-test-drive-v3__greeting-main">
          <!-- <AnimatedContent type="fade-in" :duration="800" :delay="1800" :turn-off="!largeThanTablet() || fields.turnOffAnimation?.value"> -->
          <AnimatedContent type="fade-in" :duration="800" :delay="1800">
            <SiteLink :link="{ value: { href: nav.path, linktype: 'internal', isExternal: false } }" class="c-test-drive-v3__back">
              <Icon name="back" />
              <JssText :field="fields.greetingBackText" tag="span" />
              <span>&nbsp;{{ nav.name }}</span>
            </SiteLink>
            <JssRichText :field="fields.greetingTitle" class="c-test-drive-v3__greeting-main-title" />
            <JssRichText :field="fields.greetingBody" class="c-test-drive-v3__greeting-main-body" />
            <div class="c-test-drive-v3__greeting-main-list">
              <div class="c-test-drive-v3__greeting-main-list-item" v-for="item in fields.greetingList" :key="item.id">
                <Icon name="skew-arrow" class="c-test-drive-v3__greeting-main-list-item-icon" />
                <JssRichText :field="item.fields.text" class="c-test-drive-v3__greeting-main-list-item-text" />
              </div>
            </div>
            <SiteButton class="c-test-drive-v3__greeting-main-btn" v-bind="fields.startButton" @click="getStart" />
            <div class="c-test-drive-v3__help">
              <JssRichText :field="fields.helpTitle" class="c-test-drive-v3__help-title" />
              <JssRichText :field="fields.helpBody" class="c-test-drive-v3__help-body" />
              <div class="c-test-drive-v3__help-btns">
                <SiteButton class="c-test-drive-v3__help-btn" v-for="btn in fields.helpButtons" :key="btn.id" v-bind="btn" />
              </div>
            </div>
          </AnimatedContent>
        </div>
      </div>
      <div class="c-test-drive-v3__model" v-if="step === 'model'">
        <div class="c-test-drive-v3__model-img">
          <AdaptiveImage :field="fields.modelImage">
            <div class="c-test-drive-v3__model-img-text">
              <JssRichText :field="fields.modelImageTitle" class="c-test-drive-v3__model-img-text-title" />
              <JssRichText :field="fields.modelImageBody" class="c-test-drive-v3__model-img-text-body" />
            </div>
          </AdaptiveImage>
        </div>
        <div class="c-test-drive-v3__model-main">
          <JssRichText :field="fields.modelTitle" class="c-test-drive-v3__model-main-title" />
          <div class="c-test-drive-v3__model-main-cars">
            <div class="c-test-drive-v3__model-main-cars-item" v-for="item in fields.modelItems" :key="item.id" @click="selectCar(item)">
              <AdaptiveImage class="c-test-drive-v3__model-main-cars-item-image" :field="item.fields.modelItemImage" :isBackground="false" />
              <SiteButton class="c-test-drive-v3__model-main-cars-item-btn" v-bind="item.fields.modelItemButton" />
            </div>
          </div>
        </div>
      </div>
      <div class="c-test-drive-v3__location" v-if="step === 'location'">
        <div class="c-test-drive-v3__location-header">
          <div class="c-test-drive-v3__back" @click="goBack('model')"><Icon name="back" /> <JssText :field="fields.locationBackText" tag="span" /></div>
          <JssRichText :field="fields.locationStepText" />
        </div>
        <div class="c-test-drive-v3__location-title" v-html="$formatString(fields.locationTitle.value, { carCode: currentCar.code.toUpperCase() })"></div>
        <div class="c-test-drive-v3__location-main">
          <JssRichText :field="fields.locationBody" class="c-test-drive-v3__location-main-body" />
          <div class="c-test-drive-v3__location-main-list">
            <div class="c-test-drive-v3__location-main-list-item" v-for="item in currentModelItem.fields.locationItems" :key="item.id" @click="selectLocation(item.fields)">
              <AdaptiveImage :field="item.fields.locationItemImage" class="c-test-drive-v3__location-main-list-item-img" :isBackground="false" />
              <JssRichText :field="item.fields.locationItemDescription" class="c-test-drive-v3__location-main-list-item-des" />
              <SiteButton class="c-test-drive-v3__location-main-list-item-btn" v-bind="item.fields.locationItemButton" />
            </div>
          </div>
        </div>
      </div>
      <div class="c-test-drive-v3__agent" v-if="step === 'agent'">
        <AdaptiveImage :field="fields.agentsImage" class="c-test-drive-v3__agent-img" />
        <div class="c-test-drive-v3__agent-main">
          <div class="c-test-drive-v3__agent-main-header">
            <div class="c-test-drive-v3__back" @click="goBack('location')"><Icon name="back" /> <JssText :field="fields.agentsBackText" tag="span" /></div>
            <JssRichText :field="fields.agentsStepText" />
          </div>
          <JssRichText class="c-test-drive-v3__agent-main-title" :field="fields.agentsTitle" />
          <DynamicForm class="c-test-drive-v3__agent-main-form" :form="fields.agentsForm" :data="agentFormData" :carModel="currentCar" ref="agentsFormRef" @change="onAgentsFormChange" />
          <div class="c-test-drive-v3__agent-main-list">
            <div class="c-test-drive-v3__agent-main-list-item" v-for="item in storeList" :key="item.id" @click="selectAgent(item)">
              <div class="c-test-drive-v3__agent-main-list-item-left">
                <div class="c-test-drive-v3__agent-main-list-item-name">{{ item.data.storeName }}</div>
                <div class="c-test-drive-v3__agent-main-list-item-des">{{ item.data.addressDetail }}</div>
              </div>
              <div class="c-test-drive-v3__agent-main-list-item-right">
                <Icon name="back" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-test-drive-v3__form" v-if="step === 'form'">
        <AdaptiveImage :field="currentModelItem.fields.formImage" class="c-test-drive-v3__form-img" />
        <div class="c-test-drive-v3__form-main">
          <div class="c-test-drive-v3__form-main-header">
            <div class="c-test-drive-v3__back" @click="goBack('agent')"><Icon name="back" /> <JssText :field="fields.formBackText" tag="span" /></div>
            <JssRichText :field="fields.formStepText" />
          </div>
          <JssRichText class="c-test-drive-v3__form-main-title" :field="formTitle" />
          <JssRichText class="c-test-drive-v3__form-main-body" :field="formBody" />
          <DynamicForm class="c-test-drive-v3__form-main-form" :form="fields.form" :data="mainFormData" ref="mainFormRef" @change="onMainFormChange" />
          <SiteButton class="c-test-drive-v3__form-main-btn" v-bind="fields.formButton" @click="submit" />
          <JssRichText class="c-test-drive-v3__form-main-policy" :field="fields.formPolicy" />
          <div class="c-test-drive-v3__help">
            <JssRichText :field="fields.helpTitle" class="c-test-drive-v3__help-title" />
            <JssRichText :field="fields.helpBody" class="c-test-drive-v3__help-body" />
            <div class="c-test-drive-v3__help-btns">
              <SiteButton class="c-test-drive-v3__help-btn" v-for="btn in fields.helpButtons" :key="btn.id" v-bind="btn" />
            </div>
          </div>
        </div>
      </div>
      <div class="c-test-drive-v3__summary" v-if="step === 'summary'">
        <AdaptiveImage class="c-test-drive-v3__summary-img" :field="currentModelItem.fields.summaryImage">
          <div class="c-test-drive-v3__summary-img-title">
            <JssRichText :field="fields.summaryTitle" />
          </div>
          <div class="c-test-drive-v3__summary-img-text">
            <JssRichText :field="fields.summaryBody" class="c-test-drive-v3__summary-img-text-body" />
            <div class="c-test-drive-v3__summary-img-text-list">
              <div class="c-test-drive-v3__summary-img-text-list-item" v-for="item in fields.summaryList" :key="item.id">
                <Icon name="skew-arrow" class="c-test-drive-v3__summary-img-text-list-item-icon" />
                <JssRichText :field="item.fields.text" class="c-test-drive-v3__summary-img-text-list-item-text" />
              </div>
            </div>
          </div>
        </AdaptiveImage>
        <div class="c-test-drive-v3__summary-main">
          <AdaptiveImage class="c-test-drive-v3__summary-main-img" :field="currentModelItem.fields.summaryPoster" />
          <div class="c-test-drive-v3__summary-main-detail">
            <AdaptiveImage class="c-test-drive-v3__summary-main-detail-img" :field="currentModelItem.fields.carImage" :isBackground="false" />
            <JssRichText class="c-test-drive-v3__summary-main-detail-title" :field="currentModelItem.fields.summaryDetailsTitle" />
            <div class="c-test-drive-v3__summary-main-detail-text">
              <JssRichText class="c-test-drive-v3__summary-main-detail-text-subtitle" :field="fields.summaryDetailsSubtitle1" />
              <div class="c-test-drive-v3__summary-main-detail-text-location">
                <div class="c-test-drive-v3__summary-main-detail-text-location-row">{{ `${selectedStore.data.storeName}, ${selectedStore.data.cityName} ${selectedStore.data.districtName}` }}</div>
                <div class="c-test-drive-v3__summary-main-detail-text-location-row">
                  <div class="c-test-drive-v3__summary-main-detail-text-location-row-item">
                    <Icon name="location" class="c-test-drive-v3__summary-main-detail-text-location-row-item-icon" />
                    <div class="c-test-drive-v3__summary-main-detail-text-location-row-item-text">{{ `${selectedStore.data.cityName} (${selectedStore.data.districtName})` }}</div>
                  </div>
                  <div class="c-test-drive-v3__summary-main-detail-text-location-row-item">
                    <Icon name="region" class="c-test-drive-v3__summary-main-detail-text-location-row-item-icon" />
                    <div class="c-test-drive-v3__summary-main-detail-text-location-row-item-text">{{ `${selectedStore.data.storeName}` }}</div>
                  </div>
                </div>
              </div>
              <JssRichText class="c-test-drive-v3__summary-main-detail-text-subtitle" :field="fields.summaryDetailsSubtitle2" />
              <div class="c-test-drive-v3__summary-main-detail-text-info">
                <div>{{ userInfo.firstName }} {{ userInfo.surname }}</div>
                <div>{{ userInfo.email }}</div>
                <div>{{ userInfo.phone }}</div>
                <div>{{ userInfo.birthday }}</div>
                <div v-if="userInfo.type === 'business'">{{ userInfo.companyName }}</div>
              </div>
            </div>
            <SiteButton class="c-test-drive-v3__summary-main-btn" v-bind="fields.completeButton" />
          </div>
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { reactive, toRefs, inject, onMounted, defineComponent, computed, watch } from 'vue';
import { getShippingCountryOptions } from '@/services/siteService';
import { getCurrentAlpha2Code } from '@/utils/site-utils';
import { largeThanTablet } from '@/utils/dom-utils';
import { formatDate } from '@/utils/date-utils';
import { equalString, formatString } from '@/utils/string-utils';
import { deepCopy, isNullOrEmpty } from '@/utils/obj-utils';
import { appendQuery, getQueryStrings } from '@/utils/uri-utils';
import { debounce, merge } from 'lodash';
import api from '@/api';
import services from '@/services';
import utils from '@/utils';
export default defineComponent({
  name: 'TestDriveV3',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const loading = inject('loading');
    const toast = inject('toast');
    const state = reactive({
      step: 'greeting',
      nav: {
        name: 'Home',
        path: '/'
      },
      currentCar: {
        code: null
      },
      locationCode: null,
      currentCountry: null,
      agentsFormRef: null,
      agentFormData: {
        countryRegion: {
          controlProps: {
            options: [],
            selectedOption: null
          }
        }
      },
      storeList: null,
      selectedStore: null,
      selectedStoreCode: null,
      mainFormRef: null,
      mainFormData: {
        company: {
          visible: false,
          controlProps: {
            value: ''
          }
        }
      },
      userInfo: null
    });

    const computes = {
      currentModelItem: computed(() => props.fields.modelItems.find((l) => l.fields.code.value === state.currentCar.code)),
      formTitle: computed(() => {
        let tmp = deepCopy(props.fields.formTitle);
        tmp.value = formatString(tmp.value, { storeName: state.selectedStore?.data?.storeName });
        return tmp;
      }),
      formBody: computed(() => {
        let tmp = deepCopy(props.fields.formBody);
        tmp.value = formatString(tmp.value, { storeName: state.selectedStore?.data?.storeName });
        return tmp;
      })
    };

    watch(
      () => state.step,
      (v) => {
        if (equalString(v, 'agent')) {
          loading.show();
          setTimeout(async () => {
            const shippingCountries = await getShippingCountryOptions(props.carModel?.code);
            let options = deepCopy(shippingCountries);
            if (state.currentCountry) {
              options.forEach((o, i) => {
                if (o.code === state.currentCountry.code) {
                  options.splice(i, 1);
                }
              });
              options.unshift(state.currentCountry);
            }
            state.agentsFormRef.updateItem('countryRegion', {
              controlProps: {
                options: options,
                selectedOption: options[0]
              }
            });
            state.storeList = await services.agent.getSalesAgentOptions(props.page, options[0].code);
            loading.hide();
          }, 20);
        } else if (equalString(v, 'form') && state.selectedStoreCode && !state.selectedStore) {
          loading.show();
          setTimeout(async () => {
            const [res, ex] = await api.store.query({ pageNum: '1', pageSize: '999999' });
            if (ex) {
              await toast.showEx(ex);
            } else {
              const selectedStore = res.find((i) => equalString(i.storeCode, state.selectedStoreCode));
              if (selectedStore) {
                state.selectedStore = {
                  data: selectedStore
                };
                loading.hide();
              } else {
                window.location = appendQuery(
                  window.location.href,
                  {
                    model: state.currentCar.code,
                    location: state.locationCode
                  },
                  ['store']
                );
              }
            }
          }, 20);
        }
      },
      {
        immediate: true
      }
    );

    const methods = {
      largeThanTablet,
      goBack: (code) => {
        loading.show();
        setTimeout(() => {
          loading.hide();
          state.step = code;
        }, 1000);
      },
      getStart: () => {
        loading.show();
        setTimeout(() => {
          state.step = 'model';
          loading.hide();
        }, 1000);
      },
      selectCar: (i) => {
        state.currentCar.code = i.fields.code.value;
        window.location = appendQuery(window.location.href, {
          model: i.fields.code.value
        });
      },
      selectLocation: (i) => {
        if (i.code.value == 'agent') {
          state.locationCode = i.code.value;
          window.location = appendQuery(window.location.href, {
            model: state.currentCar.code,
            location: i.code.value
          });
        } else {
          window.location = appendQuery(window.location.href, {
            model: state.currentCar.code,
            location: i.code.value
          });
          const { eventHref } = computes.currentModelItem.value.fields;
          window.location.href = eventHref.value;
        }
      },
      selectAgent: (i) => {
        loading.show();
        state.selectedStore = i.data;
        state.selectedStoreCode = i.data.storeCode;
        window.location = appendQuery(window.location.href, {
          model: state.currentCar.code,
          location: state.locationCode,
          store: state.selectedStoreCode
        });
      },
      onAgentsFormChange: async (key, value) => {
        if (value?.code) {
          state.storeList = await services.agent.getSalesAgentOptions(props.page, value.code);
        } else {
          state.storeList = [];
        }
      },
      onMainFormChange: (key, value) => {
        if (key === 'type') {
          if (value.code === 'business') {
            state.mainFormData.company.visible = true;
          } else {
            state.mainFormData.company.visible = false;
            state.mainFormData.company.controlProps.value = '';
          }
        }
      },
      submit: debounce(async () => {
        const alpha2Code = getCurrentAlpha2Code();
        loading.show();
        const [valid, formData] = await state.mainFormRef.validate();
        const { isAboveTwenty } = formData ?? {};
        const consents = state.mainFormRef.getVerifiedConsents();
        if (valid) {
          const body = merge(
            {
              termsAndConditions: consents.map((x) => ({
                revisionNumber: x,
                title: window.document.title,
                formUrl: window.location.href,
                effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
              })),
              vehicleModelOfInterest: state.currentCar.code,
              storeCode: state.selectedStore.data.storeCode,
              firstName: formData.firstName,
              lastName: formData.surname,
              birthday: formatDate(formData.brithday),
              email: formData.email,
              company: formData.company,
              phone: `+${formData.phone.area.code} ${formData.phone.number}`,
              countryRegion: alpha2Code,
              language: props.page.itemLanguage,
              testDriveRequest: true,
              formScId: formData.formScId,
              gRecaptchaToken: formData.gRecaptchaToken ?? null
            },
            formData?.externalData
          );
          if (!isNullOrEmpty(isAboveTwenty)) {
            merge(body, { isAboveTwenty });
          }
          const [res, ex] = await api.testDrive.reservation(null, body);

          if (ex) {
            loading.hide();
            await toast.showEx(ex);
            return;
          }
          state.userInfo = {
            type: formData.type.code,
            firstName: formData.firstName,
            surname: formData.surname,
            email: formData.email,
            phone: `+${formData.phone.area.code} ${formData.phone.number}`,
            birthday: formatDate(formData.brithday),
            companyName: formData.company
          };
          await utils.dom.sleep(1000);
          loading.hide();
          state.step = 'summary';
        } else {
          loading.hide();
        }
      }, 20)
    };
    onMounted(async () => {
      loading.show();
      const alpha2Code = getCurrentAlpha2Code();
      const shippingCountries = await getShippingCountryOptions(props.carModel?.code);
      state.currentCountry = shippingCountries.find((x) => equalString(x.code, alpha2Code));
      const [model, location, store] = getQueryStrings('model', 'location', 'store');
      state.currentCar.code = model;
      state.locationCode = location;
      state.selectedStoreCode = store;
      const hasCar = props.fields.modelItems.some((m) => m.fields.code.value === model);
      if (state.currentCar.code && hasCar) {
        if (state.locationCode) {
          if (equalString(state.locationCode, 'agent')) {
            if (state.selectedStoreCode) {
              setTimeout(() => {
                loading.hide();
                state.step = 'form';
              }, 1000);
            } else {
              setTimeout(() => {
                loading.hide();
                state.step = 'agent';
              }, 1000);
            }
          } else if (equalString(state.locationCode, 'event')) {
            const { eventHref } = computes.currentModelItem.value.fields;
            window.location.href = eventHref.value;
          } else {
            window.location = appendQuery(window.location.href, { model: state.currentCar.code }, ['location']);
          }
        } else {
          setTimeout(() => {
            loading.hide();
            state.step = 'location';
          }, 1000);
        }
      } else {
        const [res] = await api.web.breadcrumb({
          id: props.page.itemId
        });
        state.nav = res[res.length - 2];
        loading.hide();
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
});
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-test-drive-v3 {
  $c: &;
  &__back {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  &__greeting {
    &-img {
      height: grid-width-m(math.div(12 * 9, 16));
      .e-background-image {
        height: 100%;
      }
      &-text {
        position: absolute;
        bottom: 50px;
        left: 24px;
        &-body {
          margin-top: 20px;
        }
      }
    }
    &-main {
      padding: 0 24px 56px;
      #{$c}__back {
        margin-top: 40px;
        .e-icon {
          margin-right: 10px;
          > svg {
            height: 20px;
            width: 20px;
          }
        }
      }
      &-title {
        margin-top: 40px;
      }
      &-body {
        margin-top: 15px;
      }
      &-list {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        &-item {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 15px;
          &-icon {
            transform: rotate(-90deg);
            > svg {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
      &-btn {
        margin-top: 40px;
      }
    }
  }
  &__model {
    &-img {
      height: grid-width-m(math.div(12 * 9, 16));
      .e-background-image {
        height: 100%;
      }
      &-text {
        margin-left: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &-body {
          margin-top: 20px;
        }
      }
    }
    &-main {
      padding: 0 24px 40px;
      margin-top: 48px;
      &-cars {
        margin-top: 32px;
        padding-top: 32px;
        border-top: 1px solid $grey-taubmans;
        display: flex;
        flex-direction: column;
        gap: 48px;
        &-item {
          img {
            width: 100%;
            height: auto;
          }
          &-btn {
            height: auto;
            margin-top: 24px;
          }
        }
      }
    }
  }
  &__location {
    padding: 24px 24px 56px;
    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &-title {
      @include h6;
      margin-top: 32px;
    }
    &-main {
      margin-top: 48px;
      padding-top: 48px;
      border-top: 1px solid $grey-taubmans;
      overflow: auto;
      &-list {
        margin-top: 48px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        &-item {
          border: 1px solid #e3e3e3;
          img {
            width: 100%;
            height: auto;
          }
          &-des {
            padding: 24px 24px 0;
          }
          &-btn {
            &.e-site-button {
              height: auto;
              margin: 24px;
            }
          }
        }
      }
    }
  }
  &__agent {
    &-img {
      height: grid-width-m(math.div(12 * 9, 16));
    }
    &-main {
      padding: 40px 24px 56px;
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &-title {
        margin-top: 40px;
      }
      &-form {
        margin-top: 24px;
      }
      &-list {
        display: flex;
        flex-direction: column;
        &-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 30px 0;
          border-bottom: 1px solid $grey-taubmans;
          &-left {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }
          &-name {
            font-size: 18px;
            line-height: 32px;
            color: $black;
          }
          &-des {
            font-size: 16px;
            line-height: 24px;
            color: $grey-neutral;
            opacity: 0.8;
          }
          &-right {
            .e-icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  &__form {
    &-img {
      height: grid-width-m(math.div(12 * 9, 16));
    }
    &-main {
      padding: 40px 24px;
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &-title {
        margin-top: 28px;
      }
      &-body {
        margin-top: 14px;
      }
      &-form {
        margin-top: 24px;
      }
      &-policy {
        margin-top: 20px;
      }
    }
  }
  &__summary {
    &-img {
      height: 620px;
      .e-background-image {
        height: 100%;
        &__content {
          padding: 50px 24px 0;
        }
      }
      &-text {
        margin-top: 50px;
        &-list {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          &-item {
            display: flex;
            flex-direction: row;
            gap: 20px;
            &-icon {
              color: $white;
              transform: rotateY(180deg);
            }
          }
        }
      }
    }
    &-main {
      display: flex;
      flex-direction: column;
      margin-top: -120px;
      padding: 0 24px 60px;
      &-img {
        width: 100%;
        height: 284px;
      }
      &-detail {
        margin-top: 30px;
        &-img {
          height: 136px;
          > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        &-title {
          margin-top: 50px;
        }
        &-text {
          margin-top: 50px;
          padding-top: 10px;
          border-top: 1px solid $grey-taubmans;
          &-subtitle {
            margin-top: 40px;
          }
          &-location {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 16px;
            &-row {
              display: inline-flex;
              flex-direction: row;
              gap: 20px;
              &-item {
                display: inline-flex;
                flex-direction: row;
                gap: 8px;
                &-icon {
                  color: $grey-taubmans;
                  > svg {
                    width: 16px;
                    height: 16px;
                  }
                }
                &-text {
                  font-size: 12px;
                  line-height: 20px;
                  color: #262626;
                }
              }
            }
          }
          &-info {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            > div {
              font-size: 16px;
              line-height: 24px;
              color: $grey-neutral;
            }
          }
        }
      }
      &-btn {
        margin-top: 24px;
      }
    }
  }
  &__help {
    margin-top: 50px;
    border-top: 1px solid $grey-taubmans;
    &-title {
      margin-top: 48px;
    }
    &-body {
      margin-top: 16px;
    }
    &-btns {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      > .e-site-button {
        height: auto;
      }
    }
  }
  @include tablet-landscape {
    &__greeting {
      display: flex;
      flex-direction: row-reverse;
      &-img {
        position: relative;
        height: 100vh;
        animation: draw-in 3s forwards;
        z-index: 2;
        &-text {
          left: 80px;
          bottom: 116px;
        }
      }
      &-main {
        height: 100vh;
        overflow: auto;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        padding: 0 92px 68px;
        &-list {
          margin-top: 60px;
        }
        &-btns {
          margin-top: 60px;
        }
      }
    }
    &__model {
      &-img {
        height: 276px;
        &-text {
          margin-left: 108px;
        }
      }
      &-main {
        padding: 0 108px 40px;
        margin-top: 80px;
        &-cars {
          padding-top: 40px;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 24px;
          &-item {
            width: calc(50% - 12px);
            cursor: pointer;
            &-btn {
              margin-top: 32px;
            }
          }
        }
      }
    }
    &__location {
      padding: 50px 108px 56px;
      &-title {
        margin-top: 36px;
      }
      &-main {
        margin-top: 40px;
        padding-top: 40px;
        &-list {
          margin-top: 64px;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 24px;
          &-item {
            width: calc(50% - 12px);
            cursor: pointer;
            &-des {
              padding: 32px 32px 0;
            }
            &-btn {
              &.e-site-button {
                margin: 32px;
              }
            }
          }
        }
      }
    }
    &__agent {
      display: flex;
      flex-direction: row-reverse;
      &-img {
        width: 50%;
        height: 100vh;
      }
      &-main {
        height: 100vh;
        overflow: auto;
        flex: 1;
        padding: 50px 108px 56px;
        &-title {
          margin-top: 48px;
        }
        &-form {
          margin-top: 34px;
        }
        &-list {
          &-item {
            cursor: pointer;
          }
        }
      }
    }
    &__form {
      display: flex;
      flex-direction: row-reverse;
      &-img {
        width: 50%;
        height: 100vh;
      }
      &-main {
        height: 100vh;
        overflow: auto;
        flex: 1;
        padding: 50px 108px 56px;
        &-title {
          margin-top: 48px;
        }
        &-body {
          margin-top: 15px;
        }
        &-form {
          margin-top: 24px;
        }
      }
    }
    &__summary {
      &-img {
        height: grid-width(math.div(24 * 9, 16));
        .e-background-image {
          &__content {
            padding: 180px 108px 0;
            display: flex;
            flex-direction: row;
            > div {
              flex: 1;
            }
          }
        }
        &-text {
          margin: 0;
        }
      }
      &-main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 90px;
        margin: 0 108px;
        padding-bottom: 120px;
        &-img {
          width: grid-width(10);
          height: grid-width(12);
          margin-top: -200px;
        }
        &-detail {
          width: grid-width(9);
          margin-top: 10px;
          &-img {
            height: 180px;
          }
        }
      }
    }
  }
}
@keyframes draw-in {
  0% {
    width: 100vw;
  }
  40% {
    width: 100vw;
  }
  100% {
    width: 50%;
  }
}
</style>
