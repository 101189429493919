<template>
  <overall-settings v-bind="$props" ref="overallRef">
    <form-layout :fields="layoutFields" :class="['c-test-drive__layout', { submitted }]" ref="layoutRef">
      <template v-slot:image-content>
        <icon class="c-test-drive__logo" :field="fields.logo" />
        <div class="c-test-drive__image-content" v-if="!submitted">
          <heading class="c-test-drive__image-title" :field="fields.imageTitle" rich />
          <jss-rich-text class="c-test-drive__image-body" :field="fields.imageBody" />
        </div>
      </template>
      <div v-if="!submitted">
        <div class="c-test-drive__header">
          <a class="c-test-drive__return" :href="`/${page.itemLanguage}`">{{ $i18n.t('Return to home') }}</a>
          <div class="c-test-drive__steps">{{ $formatString(fields.stepText.value, { step, total: 2 }) }}</div>
        </div>
        <heading class="c-test-drive__title" :field="fields.title" :font-family="null" rich />
        <div class="c-test-drive__step1" v-show="step === 1">
          <jss-rich-text class="c-test-drive__model-title" :font-family="null" :field="fields.modelTitle" tag="div" />
          <jss-rich-text class="c-test-drive__model-description" :field="fields.modelDescription" tag="div" />
          <div class="c-test-drive__models">
            <div class="c-test-drive__model" :class="[{ selected: selectedModels.includes(model.fields?.code.value) }]" v-for="model in fields.carModels" :key="model.id">
              <checkbox class="c-test-drive__model-check" :value="selectedModels.includes(model.fields?.code.value)" @change="onModelSelectChange(model)" />
              <div class="c-test-drive__model-image-wrapper" :class="[{ 'no-image': $isNullOrEmpty(model.fields?.image.value?.src) }]" @click="onModelSelectChange(model)">
                <jss-rich-text class="c-test-drive__model-name" :field="model.fields.name" tag="div" />
                <img class="c-test-drive__model-image" v-bind="model.fields?.image.value" />
              </div>
            </div>
          </div>
        </div>
        <div class="c-test-drive__step2" v-show="step === 2">
          <heading class="c-test-drive__title" :field="fields.formTitle" :font-family="null" rich />
          <jss-rich-text class="c-test-drive__body" :field="fields.formBody" />
          <dynamic-form :form="fields.form" :data="formConfig" @change="onFormChange" ref="formRef" />
          <jss-rich-text class="c-test-drive__bottom-text" :field="fields.bottomText" tag="div" />
        </div>
        <div class="c-test-drive__toolbar">
          <site-button class="c-test-drive__back-btn" v-bind="fields.backButton" v-if="step === 2" @click="addStep(-1)" />
          <site-button class="c-test-drive__next-btn" v-bind="fields.nextButton" :disabled="selectedModels.length === 0" v-if="step === 1" @click="addStep(1)" />
          <site-button class="c-test-drive__submit-btn" :disabled="someFieldIsEmpty" v-bind="fields.submitButton" v-if="step === 2" @click="onSubmit" />
        </div>
      </div>
      <div class="c-test-drive__result" v-else>
        <heading class="c-test-drive__result-title" :field="fields.successLayout?.fields.title" rich />
        <jss-rich-text class="c-test-drive__result-body" v-if="needDoubleOptin && result?.isFirstSubmit" :field="fields.successNotice" />
        <div v-html="$formatString(fields.successNotice2.value, { name: `${formConfig.firstName.controlProps.value} ${formConfig.lastName.controlProps.value}` })" />
        <site-button class="c-test-drive__result-btn" v-bind="fields.successButton" />
      </div>
    </form-layout>
  </overall-settings>
</template>

<script>
/**
 * @typedef NewsletterFields
 * @property {SimpleField} stepText
 * @property {DropLinkField<FormLayoutFields>} layout
 * @property {SimpleField} imageTitle
 * @property {SimpleField} imageBody
 * @property {SimpleField} modelDescription
 * @property {Array<TestDriveModel>} carModels
 * @property {SimpleField} formTitle
 * @property {SimpleField} formBody
 * @property {Form} form
 * @property {ButtonField} backButton
 * @property {ButtonField} nextButton
 * @property {ButtonField} submitButton
 * @property {DropLinkField<FormLayoutFields>} successLayout
 * @property {SimpleField} successNotice
 * @property {SimpleField} successNotice2
 * @property {ButtonField} successButton
 * */
/**
 * @typedef NewsletterResponse
 * @property {Boolean} isFirstSubmit
 * @property {String} leadId
 * */
/**
 * @typedef TestDriveModel
 * @property {{
 *   code: SimpleField,
 *   image: ImageField
 * }} fields
 * */
import { computed, inject, nextTick, onMounted, reactive, toRefs } from 'vue';
import { merge, debounce } from 'lodash';
import api from '@/api';
import { formatDate } from '@/utils/date-utils';
import { countryNeedDoubleOptin } from '@/services/siteService';
import { getPageAlpha2Code, scrollElToTop, settingValue } from '@/utils/site-utils';
import { gtmFormView, gtmFormStart, gtmFormStep, gtmFormSubmit } from '@/utils/gtm-utils';
import { isNullOrEmpty, isNullOrWhitespace } from '@/utils/obj-utils';
import onScrollToView from '@/hooks/onScrollToView';
import useAppStore from '@/store/appStore';

export default {
  name: 'Newsletter',
  props: {
    /**@type NewsletterFields*/
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    const toast = inject('toast');
    const loading = inject('loading');
    const { formType } = props.page.fields;
    const appStore = useAppStore();
    const state = reactive({
      overallRef: null,
      /**@type FormLayout*/
      layoutRef: null,
      /**@type DynamicForm*/
      formRef: null,
      step: 1,
      selectedModels: [],
      submitted: false,
      /**@type NewsletterResponse*/
      result: null,
      formConfig: {
        firstName: {
          controlProps: {
            value: ''
          }
        },
        lastName: {
          controlProps: {
            value: ''
          }
        },
        email: {
          trigger: 'blur',
          controlProps: {
            value: ''
          }
        },
        countryRegion: {
          controlProps: {
            selectedOption: null
          }
        },
        consent: {
          controlProps: {
            value: false
          }
        }
      },
      needDoubleOptin: false,
      formChanged: false
    });
    const computes = {
      layoutFields: computed(() => (state.submitted ? props.fields.successLayout?.fields : props.fields.layout?.fields) ?? props.fields.layout?.fields),
      someFieldIsEmpty: computed(() => {
        const { firstName, lastName, email, countryRegion, consent } = state.formConfig;
        return (
          isNullOrWhitespace(firstName.controlProps.value) ||
          isNullOrWhitespace(lastName.controlProps.value) ||
          isNullOrWhitespace(email.controlProps.value) ||
          isNullOrWhitespace(countryRegion) ||
          !consent.controlProps.value
        );
      })
    };
    const methods = {
      onModelSelectChange(model) {
        if (!state.formChanged) {
          state.formChanged = true;
          gtmFormStart(formType?.value, props.fields.form.id);
        }
        const { code } = model.fields;
        const index = state.selectedModels.findIndex((x) => x === code.value);
        if (index < 0) {
          state.selectedModels.push(code.value);
        } else {
          state.selectedModels.splice(index, 1);
        }
      },
      addStep(increment) {
        state.step += increment;
        if (increment > 0) {
          switch (state.step) {
            case 2:
              {
                gtmFormStep(formType?.value, props.fields.form.id, appStore?.loginInfo, 2, 'personal_details', null);
              }
              break;
            default:
              break;
          }
        }
        window.scroll(0, 0);
      },
      onSubmit: debounce(async () => {
        loading.show();
        const { channel } = props.fields;
        const alpha2Code = getPageAlpha2Code(props.page);
        const [valid, formData] = await state.formRef.validate();
        const leadTypes = state.formRef.getLeadTypes();
        if (valid) {
          const { firstName, lastName, mobile, email, countryRegion, getBehindTheWheel, isAboveTwenty, externalData } = formData;
          const consents = state.formRef.getVerifiedConsents();
          state.needDoubleOptin = await countryNeedDoubleOptin(alpha2Code);
          const body = merge(
            {
              vehicleModelOfInterest: state.selectedModels.join(','),
              countryRegion: countryRegion.code,
              language: props.page.itemLanguage,
              needDoubleOptin: state.needDoubleOptin,
              termsAndConditions: consents.map((x) => ({
                revisionNumber: x,
                title: window.document.title,
                formUrl: window.location.href,
                effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
              })),
              firstName: firstName,
              middleName: '',
              lastName: lastName,
              email: email,
              phone: mobile?.area ? `${mobile?.area?.code} ${mobile?.number}` : '',
              channel: settingValue(channel, 'Official Website'),
              company: '',
              title: '',
              testDriveInterest: getBehindTheWheel ?? false,
              leadFormName: 'Test Drive KMI',
              leadProvider: 'Global Website',
              formScId: formData.formScId,
              gRecaptchaToken: formData.gRecaptchaToken ?? null
            },
            externalData
          );
          const cptcBody = {
            firstName: formData.firstName,
            middleName: formData.middleName ?? '',
            lastName: formData.lastName,
            email: formData.email,
            countryRegion: alpha2Code,
            channel: externalData?.channel ?? 'Official Website'
          };
          if (!isNullOrEmpty(isAboveTwenty)) {
            merge(body, { isAboveTwenty });
            merge(cptcBody, { isAboveTwenty });
          }
          const [res, ex] = await api.lcms.crm.newsletter.subscribe(null, body);
          if (formData?.CPTC) {
            await api.lcms.crm.cptc.create(null, cptcBody);
          }
          if (ex) {
            await toast.showEx(ex);
            loading.hide();
            return;
          }
          const formDetails = {
            selected_model: state.selectedModels?.join(','),
            selected_location_name: null,
            selected_location_type: null,
            selected_location_date: null,
            selected_location_time: null,
            customer_type: formData?.typeOfCustomer?.code ?? null,
            dealer_name: null
          };
          const leadsDetails = {};
          if (leadTypes?.length) {
            leadTypes.forEach((l) => {
              leadsDetails[l.leadType] = res ?? null;
            });
          }
          gtmFormSubmit(
            formType?.value,
            props.fields.form.id,
            {
              email: email,
              mobileAreaCode: mobile?.area?.code,
              phone: mobile?.number
            },
            formDetails,
            leadsDetails
          );
          state.result = res;
          state.submitted = true;
          await nextTick();
          scrollElToTop(state.overallRef?.rootEl);
          // await nextTick();
          // state.layoutRef.updateScrollMagic();
        }
        loading.hide();
      }, 100)
    };
    onScrollToView({
      getContainer() {
        return state.layoutRef.rootEl;
      },
      callback() {
        gtmFormView(formType?.value, props.fields.form.id);
      }
    });
    onMounted(() => {
      gtmFormStep(formType?.value, props.fields.form.id, appStore?.loginInfo, 1, 'model_select', null);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-test-drive {
  $c: &;
  $l: s-form-layout;
  padding-bottom: $space-40;
  &__layout {
    .#{$l}__side-image {
      display: block;
      color: $white;
      width: 100vw;
      height: 100vw;
      @include grid-block(1, 12, 1);
    }
    .#{$l}__image-wrapper {
      height: 100vw;
    }
    .#{$l}__image {
      height: 100vw;
    }
    .#{$l}__main {
      padding-top: $space-40;
      padding-bottom: $space-40;
      @include grid-block(2, 10, 2);
    }
    .#{$l}__image-content {
      height: 100vw;
      position: relative;
    }
  }
  &__logo {
    margin-left: grid-width-m(1);
    margin-top: $space-24;
  }
  &__image-content {
    position: absolute;
    bottom: grid-width-m(1);
    left: grid-width-m(1);
  }
  &__image-body {
    margin-top: $space-40;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $space-40;
  }
  &__return {
    text-transform: uppercase;
    font-weight: bold;
  }
  &__model-title {
    @include h5;
    margin-bottom: $space-40;
  }
  &__models {
    margin-top: $space-40;
  }
  &__model {
    display: flex;
    gap: 16px;
    + #{$c}__model {
      margin-top: $space-24;
    }
    &.selected {
      #{$c}__model-image-wrapper {
        &:before {
          width: 60px;
          background: $yellow;
        }
        &.no-image {
          &:before {
            display: none;
          }
        }
      }
    }
  }
  &__model-image {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    &-wrapper {
      position: relative;
      padding-bottom: $space-16;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 20px;
        height: 100%;
        background: $grey-light;
        transition: all 400ms cubic-bezier(0.355, 0.005, 0.26, 1);
      }
      &.no-image {
        &:before {
          display: none;
        }
      }
    }
  }
  &__model-name {
    position: absolute;
  }
  &__toolbar {
    height: 60px;
    margin-top: $space-40;
    position: relative;
  }
  &__back-btn {
    position: absolute !important;
    top: 0;
    left: 0;
  }
  &__next-btn {
    position: absolute !important;
    top: 0;
    right: 0;
  }
  &__submit-btn {
    position: absolute !important;
    top: 0;
    right: 0;
    &.disabled {
      background-color: rgba($yellow, 0.3) !important;
      border-color: rgba($yellow, 0.3) !important;
    }
  }
  &__result {
    &-title {
      @include h5;
      margin-bottom: $space-40;
    }
    &-btn {
      margin-top: 40px;
    }
  }
  @include tablet-landscape {
    &__layout {
      .#{$l}__side-image {
        display: block;
        color: $white;
        width: auto;
        height: 100vh;
        @include grid-block(1, 12, 1);
      }
      .#{$l}__image-wrapper {
        height: 100vh;
      }
      .#{$l}__image {
        height: 100%;
        max-height: 100vh;
      }
      .#{$l}__main {
        padding-top: $space-40;
        padding-bottom: $space-40;
        @include grid-block(15, 8, 1);
      }
      .#{$l}__image-content {
        height: 100vh;
      }
      &.submitted.s-form-layout {
        position: static;
        .#{$l}__side-image {
          position: static;
          height: auto;
        }
      }
    }
    &__image-content {
      position: absolute;
      bottom: grid-width(2);
      left: grid-width(2);
    }
    &__logo {
      margin-left: grid-width(2);
      margin-top: grid-width(1);
      svg {
        color: $white;
      }
    }

    &__model {
      &.selected {
        #{$c}__model-image-wrapper {
          &:before {
            width: 120px;
          }
          &.no-image {
            &:before {
              display: none;
            }
          }
        }
      }
    }
    &__model-image {
      &-wrapper {
        &:before {
          width: 40px;
        }
      }
    }
  }
}
html.rtl {
  .c-test-drive {
    &__logo {
      margin-right: grid-width-m(1);
    }
    &__image-content {
      right: grid-width-m(1);
    }
    @include tablet-landscape {
      &__logo {
        margin-right: grid-width(2);
      }
      &__image-content {
        right: grid-width(2);
      }
    }
  }
}
</style>
