import { createApp as createVueApp, createSSRApp, getCurrentInstance, h } from 'vue';
import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue';
import { createMetaManager as createVueMetaManager, deepestResolver, defaultConfig } from 'vue-meta';
import AppRoot from './AppRoot';
import { createRouter } from './router';
import SitecoreJssStorePlugin from './lib/SitecoreJssStorePlugin';
import componentFactory from './temp/componentFactory';
import directives from '@/directives';
import elements from './elements';
import sections from './sections';
import EventBus from '@/utils/bus';
import { createPinia } from 'pinia';
import { canUseDOM } from '@/utils/dom-utils';
import { formatDate, formatOrderDate, getLastDayOfCurrentMonth } from '@/utils/date-utils';
import { isNullOrEmpty, padding } from '@/utils/obj-utils';
import {
  appVisible,
  buildAddress,
  checkLangOfUrl,
  formatDistance,
  formatModel,
  formDdl,
  getAdaptiveField,
  getAdaptiveImageField,
  getAdaptiveTextField,
  getAdaptiveValueField,
  getPage,
  getPageAlpha2Code,
  gridLayoutClass,
  handleComClass,
  isEmptyFields,
  isMarket,
  isOnApp,
  settingVal,
  settingValue,
  tryField,
  tryValueField
} from '@/utils/site-utils';
import { concatString, equalString, formatString, ifEmpty, toCapitalize } from '@/utils/string-utils';
import spinners from '@/spinners';
import { formatMoney, formatShopMoney, sum, toThousands } from '@/utils/math-utils';
import Swiper, { Autoplay, Navigation, Pagination, Thumbs } from 'swiper';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { merge } from 'lodash';
import { getBetterUrl, goBack, redirectTo } from '@/utils/uri-utils';
import useDevice from '@/hooks/useDevice';
import JsBridge from '@/js-bridge';

const createMetaManager = (isSSR = false) => {
  const options = merge(defaultConfig, {
    meta: {
      nameless: true
    }
  });
  return createVueMetaManager(isSSR, options, deepestResolver);
};
// createApp is invoked by both the main and SSR entry points, so the two entry points can use the same app creation process.
export function createApp(initialState, i18n, isSSR) {
  const router = createRouter(isSSR);
  const metaManager = createMetaManager(isSSR);
  const { deviceState, deviceComputes } = useDevice();
  // const graphQLProvider = createGraphQLProvider(initialState);

  const vueOptions = {
    router,
    render: () => h(AppRoot),
    i18n
  };
  const app = isSSR ? createSSRApp(vueOptions) : createVueApp(vueOptions);
  const $bus = new EventBus();
  // app.provide(DefaultApolloClient, graphQLProvider);

  app.use(router);
  app.use(SitecoreJssStorePlugin);
  app.use(SitecoreJssPlaceholderPlugin, { componentFactory });
  app.use(i18n);
  app.use(metaManager);
  app.use(directives);
  app.use(elements);
  app.use(sections);
  app.use(spinners);
  app.use(JsBridge);
  app.provide('$bus', $bus);
  app.provide('device-common', { deviceState, deviceComputes });
  app.config.globalProperties.$bus = $bus;
  app.config.globalProperties.$deviceState = deviceState;
  app.config.globalProperties.$deviceComputes = deviceComputes;
  app.config.globalProperties.$isNullOrEmpty = isNullOrEmpty;
  app.config.globalProperties.$formatDate = formatDate;
  app.config.globalProperties.$settingValue = settingValue;
  app.config.globalProperties.$settingVal = settingVal;
  app.config.globalProperties.$formatString = formatString;
  app.config.globalProperties.$handleComClass = handleComClass;
  app.config.globalProperties.$padding = padding;
  app.config.globalProperties.$concatString = concatString;
  app.config.globalProperties.$buildAddress = buildAddress;
  app.config.globalProperties.$buildHref = getBetterUrl;
  app.config.globalProperties.$formatMoney = formatMoney;
  app.config.globalProperties.$formatShopMoney = formatShopMoney;
  app.config.globalProperties.$equalString = equalString;
  app.config.globalProperties.$ifEmpty = ifEmpty;
  app.config.globalProperties.$tryField = tryField;
  app.config.globalProperties.$tryValueField = tryValueField;
  app.config.globalProperties.$toCapitalize = toCapitalize;
  app.config.globalProperties.$sum = sum;
  app.config.globalProperties.$redirectTo = (url, target) => {
    redirectTo({ url, target, router });
  };
  app.config.globalProperties.$goBack = () => {
    goBack(router);
  };
  app.config.globalProperties.$formatOrderDate = formatOrderDate;
  app.config.globalProperties.$adaptiveField = (desktopField, mobileField, tabletField = null, desktopLargeField = null) => {
    return getAdaptiveField(deviceState, desktopField, mobileField, tabletField, desktopLargeField);
  };
  app.config.globalProperties.$adaptiveValueField = (desktopField, mobileField, tabletField, desktopLargeField, valueFn) => {
    return getAdaptiveValueField(deviceState, desktopField, mobileField, tabletField, desktopLargeField, valueFn);
  };
  app.config.globalProperties.$adaptiveTextField = (desktopField, mobileField, tabletField = null, desktopLargeField = null) => {
    return getAdaptiveTextField(deviceState, desktopField, mobileField, tabletField, desktopLargeField);
  };
  app.config.globalProperties.$adaptiveImageField = (desktopField, mobileField, tabletField = null, desktopLargeField = null) => {
    return getAdaptiveImageField(deviceState, desktopField, mobileField, tabletField, desktopLargeField);
  };
  app.config.globalProperties.$adpText = (desktopVal, mobileVal, tabletVal, desktopLargeVal) => {
    return ifEmpty(getAdaptiveValueField(deviceState, desktopVal, mobileVal, tabletVal, desktopLargeVal), desktopVal, mobileVal, tabletVal, desktopLargeVal);
  };
  app.config.globalProperties.$isEmptyFields = isEmptyFields;
  app.config.globalProperties.$gridLayoutClass = gridLayoutClass;
  app.config.globalProperties.$isOnApp = isOnApp;
  app.config.globalProperties.$appVisible = appVisible;
  app.config.globalProperties.$equalAlpha2Code = ($route, alpha2Code) => {
    return equalString(getPageAlpha2Code($route), alpha2Code);
  };
  app.config.globalProperties.$formatModel = formatModel;
  app.config.globalProperties.$getLastDayOfCurrentMonth = getLastDayOfCurrentMonth;
  app.config.globalProperties.$formDdl = formDdl;
  app.config.globalProperties.$toThousands = toThousands;
  app.config.globalProperties.$formatDistance = (distance, unit, fractionalNum) => {
    const page = getPage();
    return formatDistance(page, distance, unit, fractionalNum);
  };
  app.config.globalProperties.$isMarket = (phrase) => {
    const page = getPage();
    return isMarket(page, phrase);
  };
  app.config.globalProperties.$tu = (phrase) => {
    const { proxy } = getCurrentInstance();
    const { $t } = proxy;
    return $t(phrase).toUpperCase();
  };
  app.config.globalProperties.$tl = (phrase) => {
    const { proxy } = getCurrentInstance();
    const { $t } = proxy;
    return $t(phrase).toLowerCase();
  };
  app.config.globalProperties.$mJudge = (judge) => {
    return deviceComputes.largeThanTablet.value || judge;
  };
  app.config.globalProperties.$dJudge = (judge) => {
    return deviceComputes.isMobileOrTablet.value || judge;
  };
  if (canUseDOM()) {
    checkLangOfUrl();
    const pinia = createPinia();
    app.use(pinia);

    Swiper.use([Autoplay, Pagination, Navigation, Thumbs]);
    gsap.registerPlugin(ScrollTrigger);
  }
  // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
  if (initialState) {
    app.config.globalProperties.$jss.store.setSitecoreData(initialState);
  }

  return { app, router /*, graphQLProvider*/ };
}

export function createGraphQLProvider(initialState) {
  return null;
  // return initialState && initialState.APOLLO_STATE ? GraphQLClientFactory(config.graphQLEndpoint, false, initialState.APOLLO_STATE) : GraphQLClientFactory(config.graphQLEndpoint, true);
}
