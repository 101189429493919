<template>
  <OverallSettings v-bind="$props">
    <div class="c-text-content" v-if="fields">
      <div class="c-text-content__content" :class="`align-${align}`">
        <JssRichText class="c-text-content__title" :field="fields.title" tag="div" />
        <JssRichText class="c-text-content__list" :field="fields.list" tag="div" />
        <div class="c-text-content__tag-group">
          <JssRichText class="c-text-content__tag-dec" :field="fields.tagDec" tag="div" />
          <div class="c-text-content__tag-items">
            <JssRichText class="c-text-content__tag-item" v-for="item in fields.tagItems" :key="item.id" :field="item.fields.body" tag="div" />
          </div>
        </div>
        <JssRichText class="c-text-content__section" :field="fields.section" />
        <SiteButton class="c-text-content__btn" v-for="btn in fields.buttons" :key="btn.id" v-bind="btn" />
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { computed } from 'vue';
export default {
  name: 'TextContent',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      align: computed(() => (props.fields.align?.fields.phrase?.value ? props.fields.align?.fields.phrase.value : 'left'))
    };
    return {
      ...computes
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-text-content {
  @include grid-container;
  &__content {
    @include grid-block(2, 10);
    &.align- {
      &left {
        justify-content: flex-start;
        text-align: start;
      }
      &center {
        justify-content: center;
        text-align: center;
      }
      &right {
        justify-content: flex-end;
        text-align: end;
      }
    }
  }
  &__title {
    @include h3;
    margin-bottom: $space-40;
  }
  &__list {
    @include h9;
    list-style: unset;
    margin-bottom: $space-40;
  }
  &__tag {
    &-group {
      @include h9;
      margin-bottom: $space-40;
    }
    &-dec {
      margin-bottom: $space-40;
    }
    &-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      align-content: center;
    }
    &-item {
      @include h5;
    }
  }
  &__section {
    @include h9;
    margin-bottom: $space-40;
  }
  @include tablet-landscape {
    &__content {
      @include grid-block(3, 20);
    }
  }
}
</style>
