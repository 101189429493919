<template>
  <overall-settings v-bind="$props">
    <div class="c-title-block">
      <div class="c-title-block__content" :class="rootClasses">
        <div class="c-title-block_content_titles">
          <!-- <animated-content type="skew-in" :hide-content="false" :turn-off="fields.turnOffTextAnimation?.value"> -->
          <animated-content type="skew-in" :hide-content="false">
            <jss-rich-text class="c-title-block_content_subtitle" :class="{ 'c-title-block_content_subtitle--noneDistance': fields.outlineNoneDistance?.value }" :field="fields.outline" tag="div" />
          </animated-content>
          <!-- <animated-content type="skew-in" :delay="100" :hide-content="false" :turn-off="fields.turnOffTextAnimation?.value"> -->
          <animated-content type="skew-in" :delay="100" :hide-content="false">
            <heading class="c-title-block_content_title" :field="$adaptiveTextField(fields.title, fields.titleMobile)" :type="fields.titleType" :font-family="null" rich />
          </animated-content>
        </div>
        <!-- <animated-content type="fade-in" :delay="600" :hide-content="false" :turn-off="fields.turnOffTextAnimation?.value"> -->
        <animated-content type="fade-in" :delay="600" :hide-content="false">
          <jss-rich-text class="c-title-block_content_word" :field="fields.description" tag="div" />
        </animated-content>
      </div>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef TitleBlockFields
 * @property {GlobalSettingEntry} theme
 * @property {SimpleField} titleMobile
 * */
import { computed } from 'vue';
import { settingValue } from '@/utils/site-utils';

export default {
  name: 'TitleBlock',
  props: {
    /**@type TitleBlockFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const computes = {
      layout: computed(() => settingValue(props.fields.layout)),
      layout2: computed(() => settingValue(props.fields.layout2)),
      rootClasses: computed(() => [
        computes.layout.value && `c-title-block__content-${computes.layout.value}`,
        computes.layout2.value && `c-title-block__content-${computes.layout2.value}`,
        props.fields.nowrap?.name === 'Yes' && `c-title-block__content-line`
      ])
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-title-block {
  width: 100%;
  @include grid-container;

  .c-title-block__content {
    @include grid-block(2, 10);
    display: flex;
    flex-direction: column;

    .c-title-block_content_titles {
      clear: both;
      width: 100%;
    }
    .c-title-block_content_subtitle {
      width: 100%;
      font-size: 20px;
      line-height: 1.3;
      padding-bottom: 20px;

      &.c-title-block_content_subtitle--noneDistance {
        padding-bottom: 0 !important;
      }
    }
    .c-title-block_content_title {
      clear: both;
      width: 100%;
      line-height: 1;
      font-weight: normal;
      word-break: break-all;
      font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
      font-size: 46px;
      letter-spacing: -10px;
      .__ff-overpass-light {
        letter-spacing: -5px;
      }
    }
    .c-title-block_content_word {
      clear: both;
      line-height: 18px;
      width: 100%;
      font-size: 16px;
      padding-top: 10px;
    }

    &.c-title-block__content-top {
      align-items: flex-start;
    }

    &.c-title-block__content-center {
      align-items: center;
    }

    &.c-title-block__content-bottom {
      align-items: flex-end;
    }

    &.c-title-block__content-left {
      .c-title-block_content_title {
        text-align: start;
      }
      .c-title-block_content_subtitle {
        text-align: start;
      }
    }
    &.c-title-block_right {
      align-items: flex-start;

      .c-title-block_content_title {
        text-align: end;
      }
      .c-title-block_content_subtitle {
        text-align: end;
      }
    }
  }
}

@include tablet-landscape {
  .c-title-block {
    .c-title-block__content {
      @include grid-block(3, 20);
      align-items: flex-end;

      .c-title-block_content_title {
        font-size: 80px;
        letter-spacing: -16px;
        .__ff-overpass-light {
          letter-spacing: -8px;
        }
      }
      .c-title-block_content_subtitle {
        padding-bottom: 30px;
      }

      .c-title-block_content_word {
        width: grid-width(6);
        font-size: 18px;
        padding-top: 0;
      }

      &.c-title-block__content-left {
        .c-title-block_content_title {
          text-align: start;
        }
        .c-title-block_content_subtitle {
          text-align: start;
        }

        .c-title-block_content_word {
          text-align: start;
        }
      }
      &.c-title-block__content-right {
        .c-title-block_content_title {
          text-align: end;
        }
        .c-title-block_content_subtitle {
          text-align: end;
        }
        .c-title-block_content_word {
          text-align: start;
        }
      }

      &.c-title-block__content-line {
        flex-direction: row;

        &.c-title-block__content-right {
          flex-direction: row-reverse;
        }
      }
    }
  }
}

@include desktop-large {
  .c-title-block {
    .c-title-block__content {
      .c-title-block_content_title {
        font-size: 100px;
      }
    }
  }
}
html[lang='ja-JP'] {
  .c-title-block {
    .c-title-block__content {
      .c-title-block_content_title {
        letter-spacing: 3px;
        font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
        * {
          letter-spacing: 3px;
        }
      }
    }
    @include tablet-landscape {
      .c-title-block__content {
        .c-title-block_content_title {
          letter-spacing: 3px;
          * {
            letter-spacing: 3px;
          }
        }
      }
    }
    @include desktop-large {
      .c-title-block__content {
        .c-title-block_content_title {
          letter-spacing: 3px;
          * {
            letter-spacing: 3px;
          }
        }
      }
    }
  }
}
html[lang='ko-KR'] {
  .c-title-block {
    .c-title-block__content {
      .c-title-block_content_title {
        font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
      }
    }
  }
}
</style>
