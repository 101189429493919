<template>
  <overall-settings v-bind="$props">
    <section class="c-text-block-list" :class="rootClasses" ref="rootEl">
      <div class="c-text-block-list__content">
        <div
          class="c-text-block-list__item"
          v-for="(item, index) in fields.list"
          :key="index"
          :class="{ 'fade-in': addStatus ? addStatus.indexOf(index) !== -1 : false, 'c-text-block-list__item--heightAuto': fields.list.length < 4 }"
        >
          <section class="c-text-block-list__item__icon" v-if="fields.iconShow && fields.iconShow.name === 'Yes'">
            <Icon :svg="item.fields.icon.value.svgCode" :style="fields.iconColor ? 'color:' + fields.iconColor.value : ''" />
          </section>

          <section class="c-text-block-list__item__sortNo" :class="{ selected: item.fields.active.value }" v-if="fields.sortNo && fields.sortNo.name === 'Yes'">
            {{ index > 9 ? index : '0' + (index + 1) }}
          </section>

          <jss-rich-text class="c-text-block-list__item__title" :field="item.fields.title" tag="div" />

          <jss-rich-text class="c-text-block-list__item__description" :field="item.fields.description" tag="div" />
        </div>
      </div>
    </section>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed } from 'vue';
/**
 * @typedef TextBlockListFields
 * @property {GlobalSettingEntry} theme
 * */
export default {
  name: 'TextBlockList',
  props: {
    /**@type TextBlockListFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      thisCount: 0,
      addStatus: 'asList:',
      rootEl: null
    });
    const computes = {
      rootClasses: computed(() => ({
        'has-animation': !props.fields?.turnOffAnimation?.value
      }))
    };
    const methods = {
      onScroll() {
        if (props.fields?.turnOffAnimation?.value) return;
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let thisOfTop = state.rootEl?.getBoundingClientRect().top,
          windowHeightDistance = windowHeight / 1.5;

        let listLength = props?.fields?.list?.length,
          indexItemLs = 0;

        if (state.thisCount === 0 && thisOfTop <= windowHeightDistance) {
          state.thisCount = 1;

          var st_fade = setInterval(() => {
            if (indexItemLs < listLength && state.addStatus.indexOf(indexItemLs) === -1) {
              state.addStatus += ',' + indexItemLs;
              indexItemLs += 1;
            } else {
              clearInterval(st_fade);
            }
          }, 300);
        }
      }
    };
    onMounted(() => {
      methods.onScroll();
      window.addEventListener('scroll', methods.onScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', methods.onScroll);
    });

    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-text-block-list {
  $c: &;
  width: 100%;
  color: #000;
  @include grid-container;
  padding: 50px 0;
  &.has-animation {
    #{$c}__item {
      transform: scale(0);
      transform-origin: 0 0;
      transition: transform 0.6s ease 0s;
      #{$c}__item__description {
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 2;
          background: #fff;
          overflow: hidden;
          right: 0;
          bottom: 0;
          opacity: 1;
          transition: opacity 0.6s ease 0.3s;
        }
      }
      &.fade-in {
        transform: scale(1);
        #{$c}__item__description {
          &::after {
            opacity: 0;
          }
        }
      }
    }
  }
}
.c-text-block-list__content {
  @include grid-block(2, 10);
  display: block;
}
.c-text-block-list__item {
  position: relative;
  width: 100%;
  height: auto;
  padding: 30px 0 30px 0;
}
.c-text-block-list__item__icon {
  position: relative;
  width: 20px;
  height: 20px;
  margin-bottom: 16px;
  left: -20px;

  .icon-line1 {
    position: absolute;
    width: 20px;
    height: 4px;
    overflow: hidden;
    background: #000;
    top: 0;
    left: 0;
    transform: rotate(45deg);
    transform-origin: 0 5px;
  }
  .icon-line2 {
    position: absolute;
    width: 16px;
    height: 4px;
    overflow: hidden;
    background: #000;
    right: 0;
    bottom: 0;
  }
  .icon-line3 {
    position: absolute;
    width: 4px;
    height: 16px;
    overflow: hidden;
    background: #000;
    right: 0;
    bottom: 0;
  }
  .e-icon > svg {
    width: 100%;
    height: auto;
  }
}
.c-text-block-list__item__sortNo {
  width: 100%;
  font-size: 32px;
  padding-bottom: 6px;
  opacity: 0.5;

  &.selected {
    opacity: 1;
  }
}
.c-text-block-list__item__title {
  width: 100%;
  font-size: 24px;
  overflow: hidden;
  margin-bottom: 20px;
}
.c-text-block-list__item__description {
  position: relative;
  width: 100%;
  line-height: 20px;
  font-size: 16px;
}

@include tablet-landscape {
  .c-text-block-list__content {
    @include grid-block(3, 20);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
  .c-text-block-list__item {
    padding: 0;
    &.c-text-block-list__item--heightAuto {
      height: auto;
    }
  }
}
html.rtl {
  .c-text-block-list {
    @include tablet-landscape {
      &__item {
        float: right;
      }
    }
  }
}
</style>
