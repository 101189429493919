<template>
  <OverallSettings v-bind="$props">
    <div class="c-store-locator-details" v-if="fields && storeDetails">
      <JssRichText class="c-store-locator-details__eyebrow" :field="{ value: $formatString(fields.eyebrow?.value, { eyebrow }) }" />
      <Heading class="c-store-locator-details__title" :field="{ value: title }" :type="fields.titleType" rich />
      <div class="c-store-locator-details__banner" ref="bannerEl" v-if="fields.showBanner?.value">
        <div class="c-store-locator-details__swiper" @mouseleave="onSwiperMouseleave">
          <div class="swiper" ref="swiperEl">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="slide in storeDetails?.storeImgDtoList" :key="slide.id">
                <BackgroundImage :image="{ value: { src: slide.externalUrl } }" />
              </div>
            </div>
          </div>
          <Icon class="c-store-locator-details__nav-left" name="left" />
          <Icon class="c-store-locator-details__nav-right" name="right" />
        </div>
        <div class="c-store-locator-details__pagination" v-if="storeDetails?.storeImgDtoList?.length > 1">
          <div
            class="c-store-locator-details__bullet"
            :class="[{ active: activeIndex === index }]"
            v-for="(slide, index) in storeDetails?.storeImgDtoList"
            :key="slide.sn"
            @click="(e) => changeSlide(e, index)"
          >
            {{ $padding(index + 1) }}
          </div>
        </div>
      </div>
      <div class="c-store-locator-details__main">
        <div class="c-store-locator-details__main-left">
          <div class="c-store-locator-details__address">
            <!-- <JssRichText class="c-store-locator-details__address-label" :field="fields.addressLabel" /> -->
            <div class="c-store-locator-details__address-label">{{ $tu('Address') }}</div>
            <div class="c-store-locator-details__address-text">{{ storeDetails.addressDetail }}</div>
            <div class="c-store-locator-details__address-text">{{ `${storeDetails.extra.storeZipCode ?? ''} ${storeDetails.cityName ?? ''}, ${storeDetails.countryName ?? ''}` }}</div>
            <div class="c-store-locator-details__address-directions">
              <Icon class="c-store-locator-details__address-directions-icon" :svg="fields.directionsIcon?.value.svgCode" />
              <div class="c-store-locator-details__address-directions-text" @click="goMap()">{{ $t('Get directions') }}</div>
            </div>
          </div>
          <div class="c-store-locator-details__contact">
            <div class="c-store-locator-details__contact-phone">
              <div
                class="c-store-locator-details__contact-phone-content"
                v-if="serviceConfig && storeDetails.serviceCall && showroomConfig && storeDetails.extra?.salesLine && $equalString(storeDetails.serviceCall, storeDetails.extra?.salesLine)"
              >
                <div class="c-store-locator-details__contact-phone-label">{{ $t('Call us') }}</div>
                <a :href="`tel:${storeDetails.serviceCall}`" class="c-store-locator-details__contact-phone-num" @click="clickCall">
                  <Icon class="c-store-locator-details__contact-phone-num-icon" :svg="fields.contactPhoneIcon?.value.svgCode" />
                  <div class="c-store-locator-details__contact-phone-num-text">{{ storeDetails.serviceCall }}</div>
                </a>
              </div>
              <template v-else>
                <div class="c-store-locator-details__contact-phone-content" v-if="showroomConfig && storeDetails.extra?.salesLine">
                  <!-- <JssRichText class="c-store-locator-details__contact-phone-label" :field="showroomConfig.fields.phoneLabel" /> -->
                  <div class="c-store-locator-details__contact-phone-label">{{ $t('Call showroom team') }}</div>
                  <a class="c-store-locator-details__contact-phone-num" :href="`tel:${storeDetails.extra.salesLine}`">
                    <Icon class="c-store-locator-details__contact-phone-num-icon" :svg="fields.contactPhoneIcon?.value.svgCode" />
                    <div class="c-store-locator-details__contact-phone-num-text">{{ storeDetails.extra.salesLine }}</div>
                  </a>
                </div>
                <div class="c-store-locator-details__contact-phone-content" v-if="serviceConfig && storeDetails.serviceCall">
                  <!-- <JssRichText class="c-store-locator-details__contact-phone-label" :field="serviceConfig.fields.phoneLabel" /> -->
                  <div class="c-store-locator-details__contact-phone-label">{{ $t('Call service team') }}</div>
                  <a :href="`tel:${storeDetails.serviceCall}`" class="c-store-locator-details__contact-phone-num" @click="clickCall">
                    <Icon class="c-store-locator-details__contact-phone-num-icon" :svg="fields.contactPhoneIcon?.value.svgCode" />
                    <div class="c-store-locator-details__contact-phone-num-text">{{ storeDetails.serviceCall }}</div>
                  </a>
                </div>
                <div class="c-store-locator-details__contact-phone-content" v-if="experienceConfig && storeDetails.serviceCall">
                  <!-- <JssRichText class="c-store-locator-details__contact-phone-label" :field="experienceConfig.fields.phoneLabel" /> -->
                  <div class="c-store-locator-details__contact-phone-label">{{ $t('Call service team') }}</div>
                  <a :href="`tel:${storeDetails.serviceCall}`" class="c-store-locator-details__contact-phone-num" @click="clickCall">
                    <Icon class="c-store-locator-details__contact-phone-num-icon" :svg="fields.contactPhoneIcon?.value.svgCode" />
                    <div class="c-store-locator-details__contact-phone-num-text">{{ storeDetails.serviceCall }}</div>
                  </a>
                </div>
              </template>
            </div>
            <div class="c-store-locator-details__contact-web" v-if="fields.contactWebIcon && fields.contactWebText && storeDetails.extra?.officialWebsiteUrl">
              <Icon class="c-store-locator-details__contact-web-icon" :svg="fields.contactWebIcon?.value.svgCode" />
              <div class="c-store-locator-details__contact-web-text" @click="goWeb()">{{ $t('View website') }}</div>
            </div>
          </div>
        </div>
        <div class="c-store-locator-details__main-right">
          <div
            class="c-store-locator-details__timesheet"
            v-if="(salesTime?.length > 0 && !!showroomConfig) || (serviceTime?.length > 0 && !!serviceConfig) || (serviceTime?.length > 0 && !!experienceConfig)"
          >
            <div class="c-store-locator-details__timesheet-item" v-if="salesTime?.length > 0 && showroomConfig">
              <!-- <JssRichText class="c-store-locator-details__timesheet-title" :field="showroomConfig.fields.timeLabel" /> -->
              <div class="c-store-locator-details__timesheet-title">{{ $tu('Showroom hours') }}</div>
              <ul>
                <li v-for="item in salesTime" :key="item.dayTime">
                  <div class="c-store-locator-details__timesheet-day">{{ item.dayTime }}</div>
                  <div class="c-store-locator-details__timesheet-time">{{ item.time }}</div>
                </li>
              </ul>
              <div class="c-store-locator-details__timesheet-des">{{ storeDetails?.salesComment }}</div>
            </div>
            <div class="c-store-locator-details__timesheet-item" v-if="serviceTime?.length > 0 && serviceConfig">
              <!-- <JssRichText class="c-store-locator-details__timesheet-title" :field="serviceConfig.fields.timeLabel" /> -->
              <div class="c-store-locator-details__timesheet-title">{{ $tu('Service centre hours') }}</div>
              <ul>
                <li v-for="item in serviceTime" :key="item.dayTime">
                  <div class="c-store-locator-details__timesheet-day">{{ item.dayTime }}</div>
                  <div class="c-store-locator-details__timesheet-time">{{ item.time }}</div>
                </li>
              </ul>
              <div class="c-store-locator-details__timesheet-des">{{ storeDetails?.serviceComment }}</div>
            </div>
            <div class="c-store-locator-details__timesheet-item" v-if="serviceTime?.length > 0 && experienceConfig">
              <!-- <JssRichText class="c-store-locator-details__timesheet-title" :field="experienceConfig.fields.timeLabel" /> -->
              <div class="c-store-locator-details__timesheet-title">{{ $tu('Service centre hours') }}</div>
              <ul>
                <li v-for="item in serviceTime" :key="item.dayTime">
                  <div class="c-store-locator-details__timesheet-day">{{ item.dayTime }}</div>
                  <div class="c-store-locator-details__timesheet-time">{{ item.time }}</div>
                </li>
              </ul>
              <div class="c-store-locator-details__timesheet-des">{{ storeDetails?.serviceComment }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-store-locator-details__map" ref="mapNode"></div>
      <SimpleHero class="c-store-locator-details__simple-hero" :fields="simpleHeroFields" v-if="simpleHeroFields" />
      <ImageDrawer class="c-store-locator-details__image-drawer" v-bind="fields.imageDrawer" v-if="fields.imageDrawer" />
      <ListContent class="c-store-locator-details__list-content" :fields="listContentFields" v-if="listContentFields" />
      <HomeRange class="c-store-locator-details__home-range" :fields="homeRangeFields" v-if="homeRangeFields" />
      <Faq class="c-store-locator-details__faq" v-bind="fields.faq" :rendering="rendering" v-if="fields.faq" />
      <SimpleHero class="c-store-locator-details__simple-hero-bottom" :fields="fields.simpleHeroBottom?.fields" v-if="fields.simpleHeroBottom?.fields" />
    </div>
  </OverallSettings>
</template>
<script>
import { computed, onMounted, reactive, toRefs, nextTick, inject, onBeforeUnmount } from 'vue';
import { loadGMap, getGlobalConfigs } from '@/utils/site-utils';
import { formatString, equalString } from '@/utils/string-utils';
import { isNullOrEmpty, deepCopy } from '@/utils/obj-utils';
import { getStoreTypes, getCarSeriesList } from '@/services/siteService';
import { getCursorPosition, largeThanTabletLandscape } from '@/utils/dom-utils';
import { gridWidth, gridWidthMobile } from '@/utils/site-utils';
import { isSubset, hasIntersection } from '@/utils/array-utils';
import { setMetaData } from '@/utils/dom-utils';
import { gtmPush } from '@/utils/gtm-utils';
import SimpleHero from '@/components/SimpleHero';
import ImageDrawer from '@/components/ImageDrawer';
import ListContent from '@/components/ListContent';
import HomeRange from '@/components/HomeRange';
import Faq from '@/components/Faq';
import { useI18n } from 'vue-i18n';
import { debounce } from 'lodash';
import Swiper from 'swiper';
export default {
  name: 'StoreLocatorDetails',
  components: { SimpleHero, ImageDrawer, ListContent, HomeRange, Faq },
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const toast = inject('toast');
    const loading = inject('loading');
    const { t: i18n } = useI18n();
    const [gmapStyleId] = getGlobalConfigs(props.page, 'gmapStyleId');
    let map = null;
    let maps = null;
    let swiper = null;
    const state = reactive({
      eyebrow: '',
      showroomConfig: null,
      serviceConfig: null,
      experienceConfig: null,
      salesTime: [],
      serviceTime: [],
      mapNode: null,
      bannerEl: null,
      swiperEl: null,
      leftIconEl: null,
      rightIconEl: null,
      activeIndex: 0,
      clientX: 0,
      clientY: 0,
      carSeriesItems: null
    });
    const computes = {
      storeDetails: computed(() => JSON.parse(props.fields.storeDetail?.fields.details?.value)),
      title: computed(() => formatString(props.fields.title?.value, { storeName: computes.storeDetails.value?.storeName.toUpperCase() })),
      simpleHeroFields: computed(() => {
        let fields = deepCopy(props.fields.simpleHero?.fields);
        fields.overline.value = formatString(fields.overline?.value, { storeName: computes.storeDetails.value?.storeName.toUpperCase() });
        fields.title.value = formatString(fields.title.value, { storeName: computes.storeDetails.value?.storeName.toUpperCase() });
        fields.body.value = formatString(fields.body.value, { storeName: computes.storeDetails.value?.storeName.toUpperCase() });
        return fields;
      }),
      listContentFields: computed(() => {
        let fields = deepCopy(props.fields.listContent?.fields);
        let showTestDrive = false;
        state.carSeriesItems?.forEach((s) => {
          if (s.agentCode && computes.storeDetails.value.extra[s.agentCode]) {
            showTestDrive = true;
          }
        });
        if (!showTestDrive) {
          fields.itemList = fields.itemList.filter((i) => !equalString(i.fields?.code?.value, 'test drive'));
        } else {
          let testDriveItem = fields.itemList.find((i) => equalString(i.fields?.code?.value, 'test drive'));
          if (!isNullOrEmpty(testDriveItem?.fields?.buttons[0]?.fields?.link?.value?.href)) {
            testDriveItem.fields.buttons[0].fields.link.value.href += `?store=${computes.storeDetails.value?.storeCode}`;
          }
        }
        if (fields.itemList.length > 1 && fields.itemList.length < 5) {
          fields.countPerView.fields.phrase.value = fields.itemList.length;
        }
        return fields;
      }),
      homeRangeFields: computed(() => {
        let fields = deepCopy(props.fields.homeRange?.fields);
        fields.description.value = formatString(fields.description?.value, { storeName: computes.storeDetails.value?.storeName.toUpperCase() });
        return fields;
      })
    };

    const methods = {
      initPage: async () => {
        gtmPush({
          event: 'dealer_view',
          dealer_name: computes.storeDetails.value.storeName
        });
        let temp = {
          '@context': 'https://schema.org',
          '@type': 'AutomotiveBusiness',
          name: computes.storeDetails.value.storeName,
          image: computes.storeDetails.value.storeImgDtoList[0]?.externalUrl,
          '@id': window.location.href,
          url: computes.storeDetails.value.extra?.officialWebsiteUrl,
          telephone: computes.storeDetails.value.serviceCall,
          address: {
            '@type': 'PostalAddress',
            streetAddress: computes.storeDetails.value.addressDetail,
            addressLocality: computes.storeDetails.value.countryName,
            postalCode: computes.storeDetails.value.extra?.storeZipCode,
            addressCountry: computes.storeDetails.value.country2Code
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: computes.storeDetails.value.latitude,
            longitude: computes.storeDetails.value.longitude
          },
          openingHoursSpecification: [
            {
              '@type': 'OpeningHoursSpecification',
              dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
              opens: computes.storeDetails.value.workStartTime,
              closes: computes.storeDetails.value.workEndTime
            }
          ]
        };
        const script = document.createElement('script');
        script.setAttribute('type', 'application/ld+json');
        script.textContent = JSON.stringify(temp);
        document.head.appendChild(script);

        state.showroomConfig =
          computes.storeDetails.value.storeType.indexOf('1') >= 0 || computes.storeDetails.value.storeType.indexOf('4') >= 0
            ? props.fields.storeTypes.find((s) => s.fields.type?.fields?.code?.value.indexOf('1') >= 0 || s.fields.type?.fields?.code?.value.indexOf('4') >= 0)
            : null;
        state.serviceConfig = computes.storeDetails.value.storeType.indexOf('2') >= 0 ? props.fields.storeTypes.find((s) => s.fields.type?.fields?.code?.value.indexOf('2') >= 0) : null;
        // state.experienceConfig = props.storeDetails.storeType.indexOf('4') >= 0 ? props.fields.storeTypes.find((s) => equalString(s.fields.type?.fields?.code?.value, '4')) : null;
        if (computes.storeDetails.value.businessHoursSalesObj?.length) {
          computes.storeDetails.value.businessHoursSalesObj.forEach((b) => {
            let salesWorkTime = '';
            if (!b.type) {
              salesWorkTime = b.workStartTime.slice(0, 5) + ' - ' + b.workEndTime.slice(0, 5);
            } else {
              salesWorkTime = i18n('Closed');
            }
            let dayTime = i18n('Monday');
            if (b.week == 2) {
              dayTime = i18n('Tuesday');
            } else if (b.week == 3) {
              dayTime = i18n('Wednesday');
            } else if (b.week == 4) {
              dayTime = i18n('Thursday');
            } else if (b.week == 5) {
              dayTime = i18n('Friday');
            } else if (b.week == 6) {
              dayTime = i18n('Saturday');
            } else if (b.week == 7) {
              dayTime = i18n('Sunday');
            }
            state.salesTime.push({
              dayTime: dayTime,
              time: salesWorkTime
            });
          });
        }
        if (computes.storeDetails.value.businessHoursServiceObj?.length) {
          computes.storeDetails.value.businessHoursServiceObj.forEach((b) => {
            let serviceWorkTime = '';
            if (!b.type) {
              serviceWorkTime = b.workStartTime.slice(0, 5) + ' - ' + b.workEndTime.slice(0, 5);
            } else {
              serviceWorkTime = i18n('Closed');
            }
            let dayTime = i18n('Monday');
            if (b.week == 2) {
              dayTime = i18n('Tuesday');
            } else if (b.week == 3) {
              dayTime = i18n('Wednesday');
            } else if (b.week == 4) {
              dayTime = i18n('Thursday');
            } else if (b.week == 5) {
              dayTime = i18n('Friday');
            } else if (b.week == 6) {
              dayTime = i18n('Saturday');
            } else if (b.week == 7) {
              dayTime = i18n('Sunday');
            }
            state.serviceTime.push({
              dayTime: dayTime,
              time: serviceWorkTime
            });
          });
        }
        const storeTypeConfigs = await getStoreTypes();
        let storeTypeNames = [];
        let metaTitle = '';
        let metaDes = '';
        const storeTypes = computes.storeDetails.value.storeType.split(',');
        storeTypeConfigs.forEach((s) => {
          const types = s.code.split(',');
          if (hasIntersection(types, storeTypes)) {
            storeTypeNames.push(s.name);
            metaTitle = formatString(s.metaTitle, { districtName: computes.storeDetails.value.districtName, countryName: computes.storeDetails.value.countryName });
            metaDes = formatString(s.metaDescription, { districtName: computes.storeDetails.value.districtName, countryName: computes.storeDetails.value.countryName });
          }
        });
        if (isSubset(['1', '2'], storeTypes) || isSubset(['2', '4'], storeTypes)) {
          const config = storeTypeConfigs.find((t) => t.code.indexOf('1') >= 0);
          metaTitle = formatString(config.metaTitle, { districtName: computes.storeDetails.value.districtName, countryName: computes.storeDetails.value.countryName });
          metaDes = formatString(config.metaDescription, { districtName: computes.storeDetails.value.districtName, countryName: computes.storeDetails.value.countryName });
        }
        state.eyebrow = storeTypeNames.join(' & ').toUpperCase();
        setMetaData('title', metaTitle);
        setMetaData('description', metaDes);
      },
      initComponent: (_maps) => {
        if (state.mapNode) {
          maps = _maps;
          methods.initMap();
        }
      },
      initMap: () => {
        let mapOptions = {
          mapId: gmapStyleId?.value,
          mapTypeId: maps.MapTypeId.ROADMAP,
          mapTypeControl: false,
          fullscreenControl: false,
          zoom: 16,
          center: { lat: Number(computes.storeDetails.value.latitude), lng: Number(computes.storeDetails.value.longitude) }
        };
        map = new maps.Map(state.mapNode, mapOptions);
        let icon = `data:image/svg+xml;base64,${window.btoa(`${props.fields.mapIcon.value.svgCode}`)}`;
        const marker = new maps.Marker({
          position: new maps.LatLng(Number(computes.storeDetails.value.latitude), Number(computes.storeDetails.value.longitude)),
          icon,
          map,
          code: computes.storeDetails.value.storeCode,
          type: computes.storeDetails.value.storeType,
          id: computes.storeDetails.value.id
        });
        new maps.Marker({ position: marker.position, icon, map });
      },
      goWeb: () => {
        gtmPush({
          event: 'dealer_click_outbound',
          dealer_name: computes.storeDetails.value.storeName,
          link_url: computes.storeDetails.value?.extra?.officialWebsiteUrl
        });
        window.open(computes.storeDetails.value?.extra?.officialWebsiteUrl);
      },
      goMap: () => {
        gtmPush({
          event: 'dealer_click_directions',
          dealer_name: computes.storeDetails.value.storeName
        });
        let url = `https://www.google.com/maps/place/${computes.storeDetails.value?.addressDetail}/@${computes.storeDetails.value?.latitude},${computes.storeDetails.value?.longitude}`;
        window.open(url);
      },
      changeSlide(e, index) {
        if (swiper) {
          swiper.slideToLoop(index, 1000, false);
        }
      },
      hideArrows() {
        const leftIconEl = state.bannerEl.querySelector('.c-store-locator-details__nav-left');
        const rightIconEl = state.bannerEl.querySelector('.c-store-locator-details__nav-right');
        leftIconEl.style.display = 'none';
        rightIconEl.style.display = 'none';
      },
      onMousemove(e) {
        if (largeThanTabletLandscape()) {
          [state.clientX, state.clientY] = getCursorPosition(e);
          methods.checkIconVisible();
        }
      },
      checkIconVisible() {
        if (!state.bannerEl) return;
        const containerEl = state.bannerEl.querySelector('.c-store-locator-details__swiper .swiper');
        const leftIconEl = state.bannerEl.querySelector('.c-store-locator-details__nav-left');
        const rightIconEl = state.bannerEl.querySelector('.c-store-locator-details__nav-right');
        const rect = containerEl.getBoundingClientRect();
        const { clientX, clientY } = state;
        const leftGap = clientX - rect.left,
          rightGap = rect.right - clientX;
        if (clientY < rect.top + 36 || clientY > rect.bottom - 36) {
          leftIconEl.style.display = 'none';
          rightIconEl.style.display = 'none';
          return;
        }
        if (leftGap > 36 && leftGap < rect.width / 2 && clientY + 36 < rect.bottom) {
          leftIconEl.style.display = 'block';
          const leftIconRect = leftIconEl.getBoundingClientRect();
          leftIconEl.style.left = clientX - leftIconRect.width / 2 + 'px';
          leftIconEl.style.top = clientY - leftIconRect.height / 2 + 'px';
        } else {
          leftIconEl.style.display = 'none';
        }
        if (rightGap > 36 && rightGap < rect.width / 2 && clientY + 36 < rect.bottom) {
          rightIconEl.style.display = 'block';
          const rightIconRect = rightIconEl.getBoundingClientRect();
          rightIconEl.style.left = clientX - rightIconRect.width / 2 + 'px';
          rightIconEl.style.top = clientY - rightIconRect.height / 2 + 'px';
        } else {
          rightIconEl.style.display = 'none';
        }
      },
      onSwiperMouseleave: debounce((e) => {
        methods.hideArrows();
      }, 100),
      onScroll: debounce(() => {
        methods.checkIconVisible();
      }, 100),
      buildSwiper: async () => {
        if (state.bannerEl) {
          await nextTick();
          swiper = new Swiper(state.swiperEl, {
            observer: true,
            speed: 1000,
            spaceBetween: gridWidthMobile(1),
            on: {
              slideChange() {
                state.activeIndex = swiper?.realIndex ?? 0;
              }
            },
            navigation: {
              prevEl: state.bannerEl.querySelector('.c-store-locator-details__nav-left'),
              nextEl: state.bannerEl.querySelector('.c-store-locator-details__nav-right')
            },
            breakpoints: {
              768: {
                spaceBetween: gridWidth(1)
              }
            }
          });
        }
      },
      clickCall: () => {
        gtmPush({
          event: 'dealer_click_to_call',
          dealer_name: computes.storeDetails.value.storeName
        });
      }
    };

    onMounted(async () => {
      loading.show();
      if (isNullOrEmpty(props.fields.storeDetail) || props.fields.storeDetail.fields.disabled.value) {
        loading.hide();
        await toast.showEx(null);
        setTimeout(() => {
          window.history.back();
        }, 3000);
      }
      state.carSeriesItems = await getCarSeriesList();
      await methods.initPage();
      await methods.buildSwiper();

      await nextTick(() => {
        loadGMap(props.page).then((maps) => {
          maps && methods.initComponent(maps);
        });
      });
      window.addEventListener('mousemove', methods.onMousemove);
      window.addEventListener('scroll', methods.onScroll);
      loading.hide();
    });
    onBeforeUnmount(() => {
      window.removeEventListener('mousemove', methods.onMousemove);
      window.removeEventListener('scroll', methods.onScroll);
      if (swiper) {
        swiper.destroy(true, true);
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-store-locator-details {
  padding-top: 80px;
  &__eyebrow {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $grey-next;
    padding: 0 grid-width-m(1);
  }
  &__title {
    margin-top: 16px;
    padding: 0 grid-width-m(1);
  }
  &__banner {
    width: 100vw;
    padding: 40px grid-width-m(1) 0;
    .swiper {
      width: grid-width-m(10);
    }
    .swiper-slide {
      width: grid-width-m(10);
    }
    .e-background-image__img.background-image {
      position: unset;
    }
  }
  &__pagination {
    display: flex;
    justify-content: flex-end;
    padding: $space-24 0 $space-24 $space-24;
    margin-left: grid-width-m(1);
    margin-right: grid-width-m(1);
    gap: $space-20;
  }
  &__bullet {
    font-size: 16px;
    color: $grey-neutral;
    cursor: pointer;
    user-select: none;
    &.active {
      color: $black;
    }
  }
  &__nav-left,
  &__nav-right {
    &.e-icon {
      display: none;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    gap: 68px;
    padding: 0 grid-width-m(1);
  }
  &__address {
    margin-top: 40px;
    &-label {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      text-transform: uppercase;
      margin-bottom: 8px;
    }
    &-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $grey-next;
    }
    &-directions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      margin-top: 4px;
      &-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-decoration-line: underline;
        cursor: pointer;
      }
      &-icon {
        > svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  &__contact {
    &-phone {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 64px;
      &-label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $grey-next;
      }
      &-num {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        margin-top: 4px;
        &-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px; /* 150% */
          text-decoration-line: underline;
        }
        &-icon {
          > svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    &-web {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      margin-top: 64px;
      &-icon {
        color: transparent;
        > svg {
          width: 16px;
          height: 16px;
        }
      }
      &-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; /* 150% */
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
  &__timesheet {
    display: flex;
    flex-direction: column;
    gap: 68px;
    &-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      text-transform: uppercase;
      margin-bottom: 8px;
    }
    li {
      padding: $space-4 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $grey-next;
      > div {
        flex: 1;
      }
    }
    &-des {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: $grey-next;
    }
  }
  &__map {
    width: 100vw;
    height: 100vw;
    margin-top: 80px;
  }
  @include tablet-landscape {
    padding-top: grid-width(2);
    &__eyebrow {
      padding: 0 grid-width(2);
    }
    &__title {
      margin-top: 24px;
      padding: 0 grid-width(2);
    }
    &__banner {
      padding: 70px grid-width(2);
      .swiper {
        width: grid-width(20);
      }
    }
    &__pagination {
      margin-left: grid-width(1);
      margin-right: grid-width(1);
    }
    &__nav-left,
    &__nav-right {
      position: fixed;
      z-index: 1;
      padding: 10px;
      border: 1px solid $yellow;
      color: $white;
      display: none;
      cursor: pointer;
      > svg {
        width: 28px !important;
        height: 28px !important;
      }
      &.swiper-button-disabled {
        opacity: 0;
        z-index: -1;
        cursor: default;
      }
      &.nav-ani- {
        &enter-active,
        &leave-active {
          transition: 600ms ease;
        }
        &enter-to,
        &leave-from {
          transform: scale(1);
        }

        &enter-from,
        &leave-to {
          transform: scale(0.5);
        }
      }
    }
    &__main {
      flex-direction: row;
      gap: grid-width(1);
      padding: 0 grid-width(2);
      &-left {
        width: grid-width(11);
      }
      &-right {
        width: grid-width(8);
      }
    }
    &__timesheet {
      margin-top: 40px;
    }
    &__map {
      height: grid-width(10);
    }
  }
}
</style>
