<template>
  <overall-settings v-bind="$props" v-if="!$isOnApp($route)">
    <div class="c-site-statement">
      <jss-rich-text class="c-site-statement__overline" :field="fields.overline" tag="div" />
      <template-string class="c-site-statement__paragraph1" :field="fields.paragraph1" :data="{ lastDayOfMonth: $getLastDayOfCurrentMonth() }" />
      <template-string class="c-site-statement__paragraph2" :field="fields.paragraph2" :data="{ lastDayOfMonth: $getLastDayOfCurrentMonth() }" />
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef SiteStatementFields
 * @property {SimpleField} overline
 * @property {SimpleField} paragraph1
 * @property {SimpleField} paragraph2
 * */

export default {
  name: 'SiteStatement',
  props: {
    /**@type SiteStatementFields*/
    fields: {
      type: Object
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-site-statement {
  $c: &;
  font-size: 14px;
  color: $grey-medium;
  padding: 0 grid-width-m(1);
  > div {
    + div {
      margin-top: grid-width-m(1);
    }
  }
  &__overline {
    text-align: justify;
  }
  &__paragraph1 {
    text-align: justify;
  }
  &__paragraph2 {
    text-align: justify;
  }
  @include tablet-landscape {
    @include grid-container;
    grid-row-gap: grid-width(1);
    padding: 0;
    > div {
      + div {
        margin-top: 0;
      }
    }
    &__overline {
      @include grid-block(3, 20);
    }
    &__paragraph1 {
      @include grid-block(3, 9);
    }
    &__paragraph2 {
      @include grid-block(14, 9);
    }
  }
}
</style>
