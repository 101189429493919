<template>
  <overall-settings v-bind="$props">
    <div class="c-roadside" v-if="fields">
      <div class="c-roadside__content">
        <div class="c-roadside__image">
          <section class="c-roadside__image__slide" v-for="(item, index) in fields.tagList" :key="'tagList' + index" :class="{ selected: index == selectedIndex }">
            <kv-slide-bg :image="item.fields.image" animate="none" />
          </section>
        </div>

        <div class="c-roadside__main">
          <ul class="c-roadside__main__tag">
            <li v-for="(item, index) in fields.tagList" :key="'tagList2' + index" :class="{ selected: index == selectedIndex }" @click="changeTag(index)">
              <jss-rich-text :field="item.fields.title" tag="div" />
            </li>
          </ul>

          <section class="c-roadside__main__slide" :class="{ selected: selectList.thisIndex == selectedIndex }">
            <div class="c-roadside__main__item" v-for="(item, index) in selectList" :key="'selectList' + index">
              <div class="c-roadside__main__item__word">
                <jss-rich-text :field="item.fields.title" tag="em" />
                <jss-rich-text :field="item.fields.remarks" tag="p" />
              </div>

              <SiteButton v-bind="item.fields.button" />
            </div>
          </section>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
/**
 * @typedef RoadsideTabListFields
 * @property {GlobalSettingEntry} theme
 * */

export default {
  name: 'RoadsideTabList',
  props: {
    /**@type RoadsideTabListFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      seletMain: {},
      selectList: [],
      selectedIndex: 0
    });

    const methods = {
      changeTag(index) {
        state.selectedIndex = index;
        state.selectList = props.fields.tagList[state.selectedIndex].fields.list || [];
        state.selectList.thisIndex = state.selectedIndex;
      }
    };

    onMounted(() => {
      if (props.fields && props.fields.tagList && props.fields.tagList.length > 0) {
        methods.changeTag(0);
      }
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/color.scss';
@import '../styles/rte/fontFamily.scss';

.c-roadside {
  position: relative;
  width: 100%;
  @include grid-container;

  .c-roadside__content {
    @include grid-block(2, 10);
  }
  .c-roadside__image {
    position: relative;
    width: 100%;
    height: grid-width-m(math.div(10 * 3.1, 5.5));
    overflow: hidden;
  }
  .c-roadside__image__slide {
    width: 100%;
    height: 100%;
    opacity: 0;

    &.selected {
      transition: opacity 0.3s;
      opacity: 1;
    }
  }

  .c-roadside__main {
    width: 100%;
  }
  .c-roadside__main__tag {
    width: 100%;
    display: flex;
    padding-top: 15px;

    li {
      display: inline-block;
      padding: 0 0 10px 0;
      margin: 0 30px 0 0;
      color: #888;
      border-bottom: 2px solid #fff;
      cursor: pointer;
      font-size: 14px;

      &.selected {
        color: #000;
        border-bottom: 2px solid #000;
      }
    }
  }

  .c-roadside__main__slide {
    width: 100%;
    opacity: 0;

    &.selected {
      opacity: 1;
      transition: opacity 0.3s;
    }

    .c-roadside__main__item {
      display: flex;
      width: 100%;
      padding: 18px 0;
      border-bottom: 1px solid #ccc;
      align-items: center;

      .c-roadside__main__item__word {
        flex-basis: 100%;
        padding-right: 30px;

        em {
          display: block;
          width: 100%;
          font-style: normal;
          font-size: 16px;
          color: #000;
        }
        p {
          width: 100%;
          font-size: 12px;
          color: #888;
          padding: 5px 0 0 0;
          line-height: 1.5;
        }
      }

      .e-site-button {
        flex-shrink: 0;

        .e-icon {
          svg {
            width: 18px;
            height: 18px;
            cursor: pointer;
            fill: #333;
          }
        }
      }
    }
  }
}

@include tablet-landscape {
  .c-roadside {
    .c-roadside__content {
      display: flex;
      width: 100%;
      @include grid-block(3, 20);
    }

    .c-roadside__image {
      position: relative;
      width: 60%;
      height: grid-width(math.div(20 * 3.1 * 0.6, 5.5));
      flex-shrink: 0;
      z-index: 0;
    }
    .c-roadside__main {
      position: relative;
      flex-basis: 100%;
      padding-left: 126px;
      z-index: 1;
    }

    .c-roadside__main__tag {
      padding-top: 0;
    }
  }
}
</style>
