<template>
  <div class="c-selective-assembly" v-if="fields">
    <div class="swiper-container" ref="swiperEl">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in fields.multipleSelections" :key="item.id">
          <BackgroundImage class="c-selective-assembly__image" :image="item.fields.image" />
          <div class="c-selective-assembly__title-group">
            <div :class="titleClasses(title)" v-for="title in item.fields.titleList" :key="title.id">
              <JssRichText :field="title.fields.title" tag="div" />
            </div>
          </div>
          <div class="c-selective-assembly__dec">
            <JssRichText :field="item.fields.dec" tag="div" />
          </div>
        </div>
      </div>
    </div>
    <div class="c-selective-assembly__bottom-group">
      <JssRichText :field="price" tag="div" />
      <div class="c-selective-assembly__paginations" v-if="fields.multipleSelections.length > 1">
        <Icon :svg="fields.prevIcon?.value.svgCode" class="c-selective-assembly__paginations-left" size="large" ref="prevEl" v-show="showSelections > 0" />
        <Icon :svg="fields.nextIcon?.value.svgCode" class="c-selective-assembly__paginations-right" size="large" ref="nextEl" v-show="showSelections < fields.multipleSelections.length - 1" />
      </div>
    </div>
  </div>
</template>
<script>
import { toRefs, onMounted, onUnmounted, nextTick, reactive, computed } from 'vue';
import Swiper from 'swiper';
export default {
  name: 'SelectiveAssembly',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let swiper = null;
    const state = reactive({
      swiperEl: null,
      prevEl: null,
      nextEl: null,
      showSelections: 0
    });
    const computes = {
      price: computed(() => props.fields.multipleSelections[state.showSelections].fields.price)
    };
    const methods = {
      titleClasses: (i) => ['c-selective-assembly__title', `__c-${i.fields.color?.fields.phrase.value ?? 'black'}`]
    };
    const buildSwiper = () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
      swiper = new Swiper(state.swiperEl, {
        slidesPerView: 'auto',
        navigation: {
          prevEl: state.prevEl,
          nextEl: state.nextEl
        },
        on: {
          slideChangeTransitionStart: function() {
            state.showSelections = this.activeIndex;
          }
        }
      });
    };
    onMounted(() => {
      window.addEventListener('resize', buildSwiper);
      nextTick(() => {
        buildSwiper();
      });
    });
    onUnmounted(() => {
      swiper.destroy(true, true);
      window.removeEventListener('resize', buildSwiper);
    });
    return {
      ...toRefs(state),
      ...methods,
      ...computes
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/color.scss';
.c-selective-assembly {
  height: 100vh;
  position: relative;
  .swiper-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    .swiper-wrapper {
      .swiper-slide {
        opacity: 0.9;
        &-active {
          opacity: 1;
        }
      }
    }
  }
  &__image {
    height: 100%;
  }
  &__title-group {
    @include h1;
    position: absolute;
    top: 100px;
    left: grid-width(2);
  }
  &__dec {
    @include h6;
    position: absolute;
    top: 100px;
    right: grid-width(2);
  }
  &__bottom-group {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: $space-30 grid-width(2);
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba($color: #f2f1f0, $alpha: 0.5);
  }
}
</style>
