<template>
  <overall-settings v-bind="$props">
    <div class="c-reservation-detail__outSide">
      <kv-slide-bg :image="fields.image" animate="basic" class="reservation-detail__kvImage" />

      <section class="c-reservation-detail__mainWord">
        <div class="c-reservation-detail__mainWord__content">
          <JssRichText class="c-reservation-detail__mainWord__title" :field="fields.title" />
          <JssRichText class="c-reservation-detail__mainWord__subTitle" :field="fields.subtitle" />
        </div>
      </section>

      <form-layout :fields="fields" class="c-reservation-detail__layout">
        <div class="c-reservation-detail__informationTitle">
          <JssRichText :field="fields.informationTitle" />
        </div>

        <div class="c-reservation-detail" v-if="detail">
          <!--<Heading class="c-reservation-detail__title" :field="fields.title" rich />
          <Heading class="c-reservation-detail__subtitle" :field="fields.subtitle" rich />-->
          <div class="c-reservation-detail__items">
            <div class="c-reservation-detail__section">{{ $t('Order details') }}</div>
            <div class="c-reservation-detail__item">
              <label>{{ $t('Order number') }}</label>
              <span>{{ detail.orderNo }}</span>
            </div>
            <div class="c-reservation-detail__item">
              <label>{{ $t('Order date') }}</label>
              <span>{{ $formatDate(detail.createTime, 'yyyy-MM-dd') }}</span>
            </div>
            <div class="c-reservation-detail__item">
              <label>{{ $t('Preferred model') }}</label>
              <span>{{ detail.carSeries }} {{ detail.carModel }}</span>
            </div>
            <div class="c-reservation-detail__item" v-if="fields.showAgentInfo?.value && !$isNullOrEmpty(store?.storeName)">
              <label>{{ $t('Preferred agent') }}</label>
              <span>{{ store?.storeName }}</span>
            </div>
            <div class="c-reservation-detail__section">{{ $t('Customer details') }}</div>
            <div class="c-reservation-detail__item">
              <label>{{ $t('Name') }}</label>
              <span>{{ detail.buyerName }}</span>
            </div>
            <div class="c-reservation-detail__item">
              <label>{{ $t('Phone number') }}</label>
              <span>+{{ detail.areaCode }}{{ detail.buyerPhone }}</span>
            </div>
            <div class="c-reservation-detail__item">
              <label>{{ $t('Email address') }}</label>
              <span>{{ detail.email }}</span>
            </div>
            <div class="c-reservation-detail__item">
              <label>{{ $t('Lotus ID') }}</label>
              <span>{{ appStore?.loginInfo?.lotusId }}</span>
            </div>
            <div class="c-reservation-detail__item" v-if="[100, 200].includes(detail.buyCarType)">
              <label>{{ $t('Order type') }}</label>
              <span>{{ detail.buyCarType === 100 ? 'Private' : 'Business' }}</span>
            </div>
            <div class="c-reservation-detail__section">{{ $t('Address details') }}</div>
            <div class="c-reservation-detail__item">
              <label>{{ $t('Invoice address') }}</label>
              <span>{{ $buildAddress(detail.billingAddress.name, detail.billingAddress.street, detail.billingAddress.zipCode, detail.billingAddress.city, billingCountry?.countryName) }} </span>
            </div>
            <div class="c-reservation-detail__item">
              <label>{{ $t('Delivery address') }}</label>
              <span>{{ $buildAddress(detail.detailAddress.name, detail.detailAddress.street, detail.detailAddress.zipCode, detail.detailAddress.city, country?.countryName) }} </span>
            </div>
            <div class="c-reservation-detail__section" v-if="detail.buyCarType === 200">{{ $t('Company details') }}</div>
            <div class="c-reservation-detail__item" v-if="detail.buyCarType === 200">
              <label>{{ $t('Company name') }}</label>
              <span>{{ detail.companyName }}</span>
            </div>
            <!--            <div class="c-reservation-detail__item">-->
            <!--              <label>{{ $i18n.t('Country') }}</label>-->
            <!--              <span>{{ country?.countryName ?? '' }}</span>-->
            <!--            </div>-->
            <!--            <div class="c-reservation-detail__item">-->
            <!--              <label>{{ $i18n.t('Model') }}</label>-->
            <!--              <span>{{ detail.carModel }}</span>-->
            <!--            </div>-->
            <!--            <div class="c-reservation-detail__item">-->
            <!--              <label>{{ $i18n.t('Pay method') }}</label>-->
            <!--              <span>{{ detail.tradeAccount }}</span>-->
            <!--            </div>-->
          </div>
          <site-button class="c-reservation-detail__next-btn" v-bind="fields.nextButton" />
        </div>
      </form-layout>
    </div>

    <section class="c-reservation-detail__nextContent">
      <JssRichText class="c-reservation-detail__nextContent__title" :field="fields.nextContentTitle" />
      <JssRichText class="c-reservation-detail__nextContent__remarks" :field="fields.nextContentRemarks1" />
      <div class="c-reservation-detail__nextContent__buttonLine">
        <SiteButton v-bind="fields.nextContentButton1" />
      </div>

      <JssRichText class="c-reservation-detail__nextContent__subtitle" :field="fields.nextContentSubTitle" />
      <JssRichText class="c-reservation-detail__nextContent__remarks" :field="fields.nextContentRemarks2" />
      <div class="c-reservation-detail__nextContent__buttonLine">
        <SiteButton v-bind="fields.nextContentButton2" />
      </div>
    </section>
  </overall-settings>
</template>

<script>
/**
 * @typedef ReservationDetailFields
 * @property {SimpleField} nextButton
 * @property {CheckField} showAgentInfo
 * @property {SimpleField} nextContentTitle
 * @property {SimpleField} nextContentRemarks1
 * @property {ButtonField} nextContentButton1
 * @property {SimpleField} nextContentSubTitle
 * @property {SimpleField} nextContentRemarks2
 * @property {ButtonField} nextContentButton2
 * */
/**
 * @typedef OrderDetail
 * @property {String} orderNo
 * @property {String} tradeAccount
 * */
import { inject, onMounted, reactive, toRefs } from 'vue';
import { getQueryStrings } from '@/utils/uri-utils';
import services from '@/services';
import api from '@/api';
import { getSiteError } from '@/services/siteService';
import useAppStore from '@/store/appStore';

import { isDesktop, isTabletLandscape, isDesktopLarge } from '@/utils/dom-utils';
import useDevice from '@/hooks/useDevice';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'ReservationDetail',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    const loading = inject('loading');
    const toast = inject('toast');
    /**@type AppStore*/
    const appStore = useAppStore();
    const { deviceState } = inject('device-common');
    const state = reactive({
      detail: null,
      store: null,
      /**@type Country*/
      country: null,
      /**@type Country*/
      billingCountry: null
    });
    const methods = {
      isDesktop: isDesktop,
      isDesktopLarge: isDesktopLarge,
      isTabletLandscape: isTabletLandscape,
      async getDetail() {
        await loading.show();
        const [id] = getQueryStrings('orderId');
        const [res, ex] = await api.order.detail(null, {
          id
        });
        if (ex) {
          await toast.showEx(ex);
          return;
        }
        await loading.hide();
        state.detail = res;
        state.country = await services.site.getCountry((x) => x.alpha2Code === res.detailAddress?.country);
        state.billingCountry = await services.site.getCountry((x) => x.alpha2Code === res.billingAddress?.country);
        if (!isNullOrEmpty(res.storeId)) {
          [state.store] = await api.store.detail({
            storeCode: res.storeId
          });
        }
      }
    };
    onMounted(async () => {
      await methods.getDetail();
    });
    return {
      ...toRefs(state),
      ...methods,
      deviceState,
      appStore
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.reservation-detail__kvImage {
  height: 46%;
}
.c-reservation-detail__outSide {
  position: relative;
  width: 100%;

  .c-reservation-detail__mainWord {
    position: relative;
    z-index: 0;
    width: 100%;
    height: auto;
    @include grid-container;
    padding: 40px 0;
  }
  .c-reservation-detail__mainWord__content {
    @include grid-block(2, 10);
  }
  .c-reservation-detail__mainWord__title {
    width: 100%;
    color: yellow;
    @include h5;
    font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
    line-height: 1.2;
  }
  .c-reservation-detail__mainWord__subTitle {
    width: 100%;
    font-size: 14px;
    color: #fff;
    line-height: 1.5;
    padding: 20px 0 0 0;
  }

  .s-form-layout.full-width-image {
    .s-form-layout__side-image {
      display: none;
    }
  }

  .c-reservation-detail__informationTitle {
    width: 100%;
    padding: 25px 0;
    border-bottom: 1px solid #dcdcdc;
    font-size: 20px;
    margin-bottom: 24px;
    text-align: center;
  }
}

.c-reservation-detail {
  $c: &;
  $l: '.s-form-layout';
  margin-bottom: 40px;
  background: $white;
  &__layout {
    &.s-form-layout {
      display: block;
    }
    width: 100%;
    margin-top: 220px;
    #{$l}__main {
      padding: 0;
    }
    #{$l}__content {
      padding: 0 16px 24px 16px !important;
      .s-content-loading {
        height: 100%;
      }
    }
  }
  &__title {
    @include h5;
    padding-bottom: 40px;
    border-bottom: 1px solid $grey-light;
  }
  &__subtitle {
    @include h6;
    margin: 20px 0 40px 0;
  }
  &__items {
    margin-bottom: 40px;
  }
  &__section {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    font-family: lotusFontFamily('Overpass'), sans-serif;
    > label {
      text-transform: uppercase;
    }
    > span {
      text-align: end;
    }
    + #{$c}__item {
      margin-top: 16px;
    }
    + #{$c}__section {
      margin-top: 24px;
    }
  }
  &__next-btn {
    margin-top: $space-60;
  }
}
.c-reservation-detail__nextContent {
  clear: both;
  display: block;
  position: relative;
  width: grid-width-m(10);
  margin: 0 auto;
  text-align: center;
  padding-bottom: 70px;

  .c-reservation-detail__nextContent__title {
    width: 100%;
    padding: 90px 0 50px 0;
    @include h5;
  }
  .c-reservation-detail__nextContent__subtitle {
    width: 100%;
    padding: 90px 0 50px 0;
    @include h7;
  }
  .c-reservation-detail__nextContent__remarks {
    width: 100%;
    line-height: 1.4;
  }
  .c-reservation-detail__nextContent__buttonLine {
    width: 100%;
    padding-top: 10px;
  }
}

@include tablet-landscape {
  .reservation-detail__kvImage {
    height: 100%;
  }
  .c-reservation-detail__outSide {
    .c-reservation-detail__mainWord {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      padding: grid-width(1) 0 0 0;
      left: 0;
      top: 0;
    }
    .c-reservation-detail__mainWord__content {
      @include grid-block(3, 21);
    }
    .c-reservation-detail__mainWord__title {
      width: 50%;
    }
    .c-reservation-detail__mainWord__subTitle {
      width: 30%;
      padding: 20px 0 0 0;
    }
  }
  .c-reservation-detail__layout {
    margin-top: 0;
    &.s-form-layout {
      display: grid;
    }
  }

  .c-reservation-detail__nextContent {
    width: grid-width(8);
  }
}
</style>
