<template>
  <OverallSettings v-bind="$props">
    <BackgroundImage :image="fields.image" class="c-slogan-banner">
      <heading class="c-slogan-banner__title" :field="fields.title" :type="fields.titleType" rich />
      <jss-rich-text class="c-slogan-banner__body" :field="fields.body" tag="div" />
    </BackgroundImage>
  </OverallSettings>
</template>

<script>
export default {
  name: 'SloganBanner',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-slogan-banner {
  height: 56vw;
  position: relative;
  .e-background-image__content {
    position: absolute;
    left: grid-width(2);
    right: grid-width(2);
    bottom: grid-width(1);
  }
  &__title {
    @include h2;
    margin-bottom: grid-width(0.5);
  }
  @include tablet-landscape {
    height: 21vw;
  }
}
</style>
