<template>
  <div class="c-space" :class="[spaceClass]" />
</template>

<script>
import { computed } from 'vue';
import { overallSettingClasses, settingValue, themeClass } from '@/utils/site-utils';

export default {
  name: 'Space',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      spaceClass: computed(() => {
        const gap = settingValue(props.fields.gap);
        return [themeClass(props), { [`gap-${gap}`]: gap }];
      })
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-space {
  @include component-themes;
  @for $i from 1 through length($gaps) {
    $gap-item: nth($gaps, $i);
    $key: map-get($gap-item, key);
    $spaces: map-get($gap-item, gap);
    &.gap-#{$key} {
      padding-top: nth($spaces, 1);
      @include tablet {
        padding-top: nth($spaces, 2);
      }
      @include tablet-landscape {
        padding-top: nth($spaces, 3);
      }
      @include desktop {
        padding-top: nth($spaces, 4);
      }
      @include desktop-large {
        padding-top: nth($spaces, 5);
      }
    }
  }
}
</style>
