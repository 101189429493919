<script setup>
import { computed, inject, onMounted, reactive, ref } from 'vue';
import { sitecoreComponentProps } from '@/utils/constants';
import { isArray, isNullOrEmpty } from '@/utils/obj-utils';
import api from '@/api';
import { formatModel, getAdaptiveTextField, getGlobalConfigs, getPageAlpha2Code } from '@/utils/site-utils';
import { canUseDOM, tryParseJson } from '@/utils/dom-utils';
import useAppStore from '@/store/appStore';
import { debounce, merge } from 'lodash';
import { getQueryStrings } from '@/utils/uri-utils';
import services from '@/services';
import { onWidthChanged } from '@/hooks/onWidthChanged';
import { equalString, formatString } from '@/utils/string-utils';
import { analyzeVehicleFeatures } from '@/utils/mhp-utils';
const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
/**@type AppStore*/
const appStore = useAppStore();
const { deviceState } = inject('device-common');
const toast = inject('toast');
const loading = inject('loading');
const descRef = ref();
const descRef1 = ref();
/**@type Ref<UsedCarFilterMenuRef>*/
const filterMenuRef = ref();
const dealerSelectModalRef = ref();
const bodyVisible = ref(false);
const showReadMore = ref(false);
const sortOption = ref(null);
const country = ref(null);
const series = ref(null);
const model = ref(null);
const locationDenied = ref(false);
const fetching = ref(false);
const list = ref([]);
const filter = ref(null);
const configureHref = ref('');
const pagination = reactive({
  page: 1,
  pageSize: props.fields?.pageSize.value ?? 5,
  count: null,
  total: null
});
const [distanceCfg] = getGlobalConfigs(props.page, 'distanceUnit');
const distanceUnit = reactive({
  phrase: distanceCfg?.fields.phrase.value ?? 'km',
  text: distanceCfg?.fields.text.value ?? 'Km'
});
const count = computed(() => list.value.filter((x) => x.type === 'used-car-card').length);
const checkDescHeight = () => {
  if (bodyVisible.value) return;
  showReadMore.value = descRef1.value.scrollHeight - descRef.value.offsetHeight > 5;
};
const toggleBody = () => {
  bodyVisible.value = !bodyVisible.value;
};
const openFilter = () => {
  filterMenuRef.value.open(filter);
};
const init = async () => {
  loading.show();
  let [seriesName, modelName] = getQueryStrings('series', 'model');
  modelName = formatModel(modelName);
  country.value = getPageAlpha2Code(props.page);
  series.value = await services.site.getCarSeries(seriesName);
  model.value = await filterMenuRef.value?.init(props.page.itemLanguage, series.value, modelName);
  pagination.page = 1;
  await fetchData(true);
};
const onSortChange = (e, selectedOption) => {
  sortOption.value = selectedOption;
  pagination.page = 1;
  loading.show();
  fetchData(true);
};
// const authorizeLocation = async () => {
//   loading.show();
//   const [res, ex] = await getCurrentPosition();
//   if (ex?.code === 1) {
//     locationDenied.value = true;
//     toast.showEx(ERROR_CODES.GeoRefused);
//   } else if (!isNullOrEmpty(ex?.message)) {
//     toast.showError(ex.message);
//   }
//   if (res?.coords) {
//     appStore.setGeoLocation({
//       longitude: res.coords.longitude,
//       latitude: res.coords.latitude,
//       authorized: true
//     });
//   }
//   await init();
// };
const onFilterUpdate = async (filterInfo) => {
  loading.show();
  const { location, ...restFilter } = filterInfo ?? {};
  if (!isNullOrEmpty(location)) {
    appStore.setGeoLocation(location);
  } else {
    appStore.setGeoLocation(null);
  }
  filter.value = restFilter;
  pagination.page = 1;
  await fetchData(true);
  loading.hide();
};
const onChangeLocation = async () => {
  filterMenuRef.value?.open();
};
const onEnterLocation = async () => {
  filterMenuRef.value?.open();
};
const onSearchGeo = async (geoInfo) => {
  dealerSelectModalRef.value?.close();
  appStore.setGeoLocation({
    latitude: geoInfo.lat,
    longitude: geoInfo.lng,
    authorized: false
  });
  pagination.page = 1;
  loading.show();
  await fetchData(true);
};
const getVehicleFeatures = (configItem, isAccessories) => {
  if (configItem.leaf) {
    if (isAccessories) {
      return configItem.configurationFeature.featureName;
    } else {
      return { label: configItem.parent.parent.levelName, value: configItem.configurationFeature.featureName };
    }
  } else {
    const result = [];
    for (const l of configItem.nextLevels) {
      l.parent = configItem;
      const temp = getVehicleFeatures(l, isAccessories);
      if (isArray(temp)) {
        result.push(...temp);
      } else {
        result.push(temp);
      }
    }
    return result;
  }
};
const fetchData = async (refresh = false) => {
  fetching.value = true;
  let { distance: distanceRange, ...rest } = filter.value || {};
  const body = {
    country: country.value,
    model: model.value?.code,
    series: series.value?.series,
    pageNum: pagination.page,
    pageSize: pagination.pageSize,
    webPublishStatus: 1,
    ...rest
  };
  if (!isNullOrEmpty(distanceRange) && distanceRange !== 'all') {
    // if (distanceUnit.phrase === 'miles') {
    //   distanceRange = Math.floor(distanceRange / 0.6214);
    // }
    merge(body, {
      distance: distanceRange + ''
    });
  }
  if (appStore.hasGeoLocation) {
    let { longitude, latitude } = appStore.geoLocation;
    merge(body, {
      longitude: longitude + '',
      latitude: latitude + ''
    });
  }
  let sortBy = 'Newest',
    sortModel = 1;
  if (!isNullOrEmpty(sortOption.value)) {
    let sortDirection;
    [sortBy, sortDirection] = sortOption.value?.code.split(' ');
    sortModel = equalString(sortDirection, 'desc') ? 1 : 2;
  }
  merge(body, {
    sortBy,
    sortModel
  });
  const [res, ex] = await api.order.stockVehiclePages(null, body).finally(() => {
    fetching.value = false;
    loading.hide();
  });
  if (!isArray(res?.records)) {
    await toast.showEx(ex);
    return;
  }
  const result = [];
  for (const r of res.records) {
    const carModel = await services.site.getCarModel(r.series, r.model);
    if (isNullOrEmpty(r.configuration)) continue;
    const configuration = { nextLevels: tryParseJson(r.configuration, []) };
    const [features, accessories] = analyzeVehicleFeatures(configuration);
    const item = {
      type: 'stock-vehicle-card-v2',
      data: {
        fields: props.fields,
        page: props.page,
        item: {
          ...r,
          configuration,
          features,
          accessories,
          carModel
        }
      }
    };
    result.push(item);
  }
  buildList(result, pagination.pageSize * (pagination.page - 1) + 1);
  if (refresh) {
    list.value = result;
  } else {
    list.value.push(...result);
  }
  pagination.count = list.value.filter((x) => x.type === 'stock-vehicle-card-v2').length;
  pagination.total = Number(res?.total) ?? null;
};
const buildList = (_list, start) => {
  const end = start + _list.length;
  const { linkedOutCards } = props.fields;
  for (let i = linkedOutCards.length - 1; i >= 0; i--) {
    const linkedOutCard = linkedOutCards[i];
    const { mobilePosition, tabletPosition, desktopPosition } = linkedOutCard.fields;
    const position = getAdaptiveTextField(deviceState, desktopPosition, mobilePosition, tabletPosition);
    const cardData = {
      type: 'linked-out-card',
      data: {
        fields: linkedOutCard.fields
      }
    };
    console.log('start', start, position.value);
    if (position.value > start && position.value <= end) {
      _list.splice(position.value - 1, 0, cardData);
    }
    if (position.value === end + 1) {
      _list.push(cardData);
    }
  }
  return _list;
};
const loadMore = async () => {
  pagination.page += 1;
  await fetchData();
};
if (canUseDOM()) {
  loading.show();
}
onWidthChanged({
  callback: debounce(() => {
    const _list = list.value.filter((x) => x.type === 'stock-vehicle-card-v2');
    list.value = buildList(_list, 0);
    checkDescHeight();
  }, 400)
});
onMounted(async () => {
  checkDescHeight();
  const [mhpLink] = getGlobalConfigs(props.page, 'mhpLink');
  configureHref.value = formatString(mhpLink.value, {
    lang: props.page.itemLanguage,
    series: 'eletre',
    model: 'eletre'
  });
  await init();
});
</script>

<template>
  <overall-settings v-bind="props">
    <div class="c-stock-vehicle-list-v2">
      <div class="c-stock-vehicle-list-v2__header">
        <jss-rich-text class="mg-b-24" :field="fields.title" tag="h2" />
        <div class="mg-b-60">
          <div class="c-stock-vehicle-list-v2__desc" :class="[{ visible: !bodyVisible }]" ref="descRef" v-html="fields.body.value" />
          <div class="c-stock-vehicle-list-v2__desc-all" :class="[{ visible: bodyVisible }]" ref="descRef1" v-html="fields.body.value" />
          <jss-rich-text class="inline tl-b tdl hand" :field="bodyVisible ? fields.collapseText : fields.readMoreText" @click="toggleBody" v-if="showReadMore" />
        </div>
        <div class="c-stock-vehicle-list-v2__top">
          <div class="c-stock-vehicle-list-v2__top-main">
            <button class="c-stock-vehicle-list-v2__filter-btn" @click="openFilter">
              <icon :field="fields.filterIcon" />
              <span>
                <jss-text :field="fields.filterText" tag="span" v-if="!$deviceComputes.isMobileOrTablet.value" />
                <span v-if="pagination.total !== null">&nbsp;({{ pagination.total }})</span>
              </span>
            </button>
            <dropdown class="c-stock-vehicle-list-v2__sort-ddl" theme="search-grey" v-bind="$formDdl(fields.sortDorpdown)" :selected-option="sortOption" @change="onSortChange" />
          </div>
          <jss-rich-text class="c-stock-vehicle-list-v2__help-m" :field="fields.mobileHelpText" v-if="$deviceComputes.isMobileOrTablet.value" />
        </div>
      </div>
      <div class="c-stock-vehicle-list-v2__summary">
        <div>
          <template-string
            :field="appStore.hasGeoLocation ? fields.summaryTextWithLocation : fields.summaryText"
            :data="{ total: pagination.total, detailAddress: appStore.geoLocation?.detailAddress ?? 'you' }"
            tag="span"
            class="text-desc"
            v-if="pagination.total !== null"
          />
          &nbsp;
          <jss-text class="c-stock-vehicle-list-v2__location-btn" :field="fields.changeLocationText" @click="onChangeLocation" v-if="appStore?.hasGeoLocation" />
          <jss-text class="c-stock-vehicle-list-v2__location-btn" :field="fields.enterLocationText" @click="onEnterLocation" v-else />
        </div>
        <jss-rich-text class="c-stock-vehicle-list-v2__help-p" :field="fields.desktopHelpText" v-if="!$deviceComputes.isMobileOrTablet.value" />
      </div>
      <template v-if="pagination.total > 0">
        <div class="c-stock-vehicle-list-v2__content">
          <template v-for="(item, index) in list" :key="index">
            <component :is="item.type" v-bind="item.data" />
          </template>
        </div>
        <div class="c-stock-vehicle-list-v2__pagination">
          <template-string :field="fields.totalText" :data="pagination" tag="div" />
          <site-button v-bind="fields.loadMoreButton" :loading="fetching" @click="loadMore" v-if="list.length < pagination.total" />
        </div>
      </template>
      <div class="c-stock-vehicle-list-v2__empty" v-if="pagination.total === 0">
        <jss-rich-text :field="fields.emptyTitle" />
        <template-string :field="fields.emptyBodyWithLocation" v-if="appStore.hasGeoLocation" :data="{ location: appStore.geoLocation.detailAddress }" />
        <jss-rich-text :field="fields.emptyBody" v-else />
        <div class="c-stock-vehicle-list-v2__empty-buttons">
          <div class="c-stock-vehicle-list-v2__empty-btn" @click="onChangeLocation">
            <icon name="location" />
            <jss-text :field="fields.emptyChangeLocText" />
          </div>
          <a class="c-stock-vehicle-list-v2__empty-btn" :href="configureHref">
            <icon name="config" />
            <jss-text :field="fields.emptyConfigureText" />
          </a>
        </div>
      </div>
    </div>
    <stock-vehicle-filter-menu-v2 v-bind="fields.filterMenu" @update-filter="onFilterUpdate" ref="filterMenuRef" />
  </overall-settings>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-stock-vehicle-list-v2 {
  $c: &;
  $ddl: '.e-dropdown';
  $btn: '.e-site-button';
  padding: 80px 32px 48px 32px;
  .e-dropdown {
    &__selection {
      font-size: 14px;
      border: 2px solid #000;
      font-weight: 700;
    }
    &__placeholder {
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding-bottom: 32px;
    border-bottom: 1px solid $grey-89;
    &-main {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  &__desc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: $grey-next;
    height: 0;
    &.visible {
      height: auto;
    }
    &-all {
      height: 0;
      overflow: hidden;
      color: $grey-next;
      &.visible {
        height: auto;
        overflow: visible;
      }
    }
  }
  &__filter-btn {
    background-color: transparent;
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-family: lotusFontFamily('Overpass Bold'), sans-serif !important;
    border: 2px solid #000;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.355, 0.005, 0.26, 1);
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
  &__sort-ddl {
    width: 140px;
  }
  &__help-m {
    justify-self: flex-end;
  }
  &__summary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 48px 0;
  }
  &__location-btn {
    cursor: pointer;
    text-decoration: underline;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__pagination {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 72px;
  }
  &__empty {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &-buttons {
      display: flex;
      flex-direction: column;
    }
    &-btn {
      cursor: pointer;
      height: 40px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  @include tablet {
    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @include tablet-landscape {
    padding: 120px grid-width(2) 48px grid-width(2);
    &__top {
      display: block;
      &-main {
        justify-content: space-between;
      }
    }
    &__filter-btn {
      padding: 0 32px;
    }
    &__sort-ddl {
      width: 240px;
    }
    &__content {
      grid-template-columns: repeat(2, 1fr);
    }
    .e-linked-out-card {
      @include grid-block(1, 2);
    }
    &__empty {
      padding: 0 grid-width(6);
      align-items: center;
      text-align: center;
      &-buttons {
        align-items: center;
      }
    }
  }
  @include desktop {
    &__content {
      grid-template-columns: repeat(3, 1fr);
    }
    .e-linked-out-card {
      @include grid-block(1, 3);
    }
  }
}
</style>
