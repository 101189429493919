<template>
  <overall-settings v-bind="$props">
    <div class="c-stock-choose-model" v-if="fields">
      <div class="c-stock-choose-model__poster">
        <img :src="adaptiveImage" class="c-stock-choose-model__poster__img" alt="" />
        <div class="c-stock-choose-model__poster__txt">
          <JssRichText :field="fields?.posterTitle" tag="div" class="c-stock-choose-model__poster__title" />
          <JssRichText :field="fields?.posterDesc" tag="div" class="c-stock-choose-model__poster__desc" />
        </div>
      </div>
      <div class="c-stock-choose-model__header">
        <jss-text :field="fields?.title" class="c-stock-choose-model__title" tag="header" />
      </div>
      <div class="c-stock-choose-model__body" ref="bodyRef">
        <div class="c-stock-choose-model__item" v-for="(item, index) in fields?.items" :key="index" @click="handleRouter(item)">
          <img :src="item?.fields?.image?.value?.src" class="c-stock-choose-model__item__img" alt="" />
          <div class="c-stock-choose-model__item__handle">
            {{ item?.fields?.series?.value }}
            <Icon name="forward"></Icon>
          </div>
        </div>
      </div>
      <stock-vehicle-splash-screen v-bind="fields.slashScreen" @hide="onSplashHide" />
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, computed } from 'vue';
import { openWindow } from '@/utils/dom-utils';
import { appendQuery, getBetterUrl } from '@/utils/uri-utils';
import { useRouter } from 'vue-router';
import useDevice from '@/hooks/useDevice';
import gsap from 'gsap';

export default {
  name: 'StockChooseModel',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const router = useRouter();
    const { deviceState } = useDevice();
    const methods = {
      /**
       * 跳转至车辆库存列表页
       * @param data
       */
      handleRouter(data) {
        const { lang = 'en' } = router.currentRoute.value?.params ?? {};
        const path = getBetterUrl(data?.fields?.path?.value?.href, lang) ?? '';
        // const allSeries = (props?.fields?.items || []).map((item) => {
        //   return item?.fields?.series?.value;
        // });
        const link = appendQuery(path, { series: data?.fields?.series?.value });
        openWindow(link, '_self');
      },
      onSplashHide() {
        gsap.fromTo(
          state.bodyRef,
          {
            transform: 'translateY(100%)',
            opacity: 0
          },
          {
            transform: 'translateY(0)',
            delay: 2,
            opacity: 1,
            duration: 1.2
          },
          0
        );
      }
    };
    const state = reactive({
      bodyRef: null
    });
    const computes = {
      adaptiveImage: computed(() => {
        if (['mobile', 'tablet'].includes(deviceState.deviceType)) {
          return props?.fields?.posterImage?.fields?.mobileImage?.value?.src;
        } else {
          return props?.fields?.posterImage?.fields?.desktopImage?.value?.src;
        }
      })
    };
    return {
      ...toRefs(state),
      ...methods,
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-stock-choose-model {
  $this: &;
  user-select: none;
  &__poster {
    color: $white;
    position: relative;

    &__img {
      width: 100%;
      display: block;
    }

    &__txt {
      position: absolute;
      left: grid-width(1.8);
      top: calc(50% - 24px);
      transform: translate(0, -50%);
    }

    &__title {
      font-size: 56px;
      letter-spacing: 3px;
      line-height: 1;
    }

    &__desc {
      font-size: 18px;
      line-height: 32px;
      margin-top: 12px;
    }
  }

  &__header {
    margin: $space-80 grid-width(1.8) 0;
    padding-bottom: $space-32;
    border-bottom: 1px solid rgba($grey-neutral, 0.4);
  }

  &__title {
    font-size: 32px;
    line-height: 1;
  }

  &__body {
    margin: $space-48 grid-width(1.8) 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: $space-24;
    grid-column-gap: $space-24;
    transform: translateY(100%);
    opacity: 0;
  }

  &__item {
    cursor: pointer;
    &__img {
      width: 100%;
      display: block;
    }

    &__handle {
      font-size: 14px;
      margin-top: $space-32;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg,
      path {
        height: 12px;
      }
    }
    &:active {
      background: transparent;
    }
  }
}

@include mobile {
  .c-stock-choose-model {
    $this: &;
    display: block;

    &__poster {
      &__txt {
        left: $space-24;
      }

      &__title {
        font-size: 32px;
        line-height: 32px;
      }

      &__desc {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__header {
      border-bottom: 1px solid rgba($grey-neutral, 0.4);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: $space-48 $space-24 0;
    }

    &__title {
      font-size: 18px;
      line-height: 24px;
    }

    &__body {
      margin: 0 $space-24;
      display: block;
    }

    &__item {
      flex: none;
      margin-top: $space-48;

      &:first-child {
        margin-top: $space-32;
      }

      &:nth-child(2n) {
        margin-left: 0;
      }

      &__img {
        width: 100%;
      }

      &__handle {
        font-size: 12px;
        margin-top: $space-24;
      }
    }
  }
}
</style>
