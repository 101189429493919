<template>
  <overall-settings v-bind="$props">
    <div class="c-reservation-processing">
      <div class="c-reservation-processing__pay" v-if="payHasError">
        <jss-rich-text class="c-reservation-processing__pay-error" :field="fields.payError" tag="div" />
        <div class="c-reservation-processing__widget" ref="widgetRef" />
        <div class="c-reservation-processing__buttons">
          <site-button v-bind="fields.payButton" @click="onPay" />
        </div>
      </div>
      <jss-rich-text v-else-if="serverHasError" class="c-reservation-processing__server-error" :field="fields.serverError" tag="div" />
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef ReservationProcessingFields
 * @property {SimpleField} payError
 * @property {SimpleField} serverError
 * @property {ButtonField} payButton
 * */
import { debounce } from 'lodash';
import { inject, onMounted, reactive, toRefs } from 'vue';
import { appendQuery, getQueryStrings } from '@/utils/uri-utils';
import { loadStripe } from '@/utils/stripe-utils';
import { getGlobalConfigs } from '@/utils/site-utils';
import { webStorage } from '@/utils/web-storage';
import { S_DETAIL_LINK, S_ORDER_INFO } from '@/utils/web-storage-keys';
import { gtmPush } from '@/utils/gtm-utils';
import useAppStore from '@/store/appStore';

export default {
  name: 'ReservationProcessing',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    const appStore = useAppStore();
    const loading = inject('loading');
    const toast = inject('toast');
    let orderInfo,
      reservationProcessingLink,
      reservationDetailLink,
      detailLink,
      clientSecret,
      stripeHelper,
      stripe,
      confirmPayment = null;
    const state = reactive({
      hasError: false,
      widgetRef: null,
      loading: true,
      payHasError: false,
      serverHasError: false
    });
    const methods = {
      async getDetail() {
        orderInfo = webStorage.get(S_ORDER_INFO);
        [reservationProcessingLink, reservationDetailLink] = getGlobalConfigs(props.page, 'reservationProcessingLink', 'reservationDetailLink');
        const { productName, productVariant } = props.page?.fields || {};
        detailLink = webStorage.get(S_DETAIL_LINK);
        [clientSecret] = getQueryStrings('payment_intent_client_secret');
        gtmPush({
          event: 'generate_lead',
          lead_id: clientSecret,
          user_id: appStore.loginInfo?.lotusId,
          product_name: productName?.value,
          product_variant: productVariant?.value
        });
        stripeHelper = await loadStripe(props.page, toast);
        stripe = stripeHelper.initSdk();
        await methods.watching();
      },
      async watching() {
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        console.log('paymentIntent', paymentIntent);
        console.log('orderInfo', orderInfo);
        switch (paymentIntent.status) {
          case 'succeeded':
            if (!detailLink || !orderInfo?.orderId) return;
            setTimeout(() => {
              state.loading = false;
              console.log('Payment succeeded!');
              window.location = appendQuery(detailLink, {
                orderId: orderInfo.orderId
              });
            }, 5000);
            break;
          case 'processing':
            console.log('Your payment is processing.');
            setTimeout(() => {
              methods.watching();
            }, 2000);
            break;
          case 'requires_payment_method':
            state.loading = false;
            state.payHasError = true;
            confirmPayment = stripeHelper.initWidget(stripe, state.widgetRef, orderInfo);
            break;
          default:
            state.loading = false;
            state.serverHasError = true;
            break;
        }
      },
      onPay: debounce(async () => {
        if (!confirmPayment || !reservationProcessingLink?.value.href) return;
        confirmPayment(reservationProcessingLink.value.href);
      }, 100)
    };
    onMounted(async () => {
      loading.show();
      await methods.getDetail();
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-reservation-processing {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__buttons {
    margin-top: $space-24;
  }
}
</style>
