<template>
  <overall-settings v-bind="$props">
    <div :class="rootClasses" :style="rootVars" v-if="fields" ref="rootEl">
      <svg style="display: none">
        <defs>
          <linearGradient :id="svgId" class="c-quote-content__svg-ani">
            <stop offset="0" />
            <stop offset="0" ref="svgAni1El" />
            <stop offset="0" ref="svgAni2El" />
            <stop offset="100%" />
          </linearGradient>
        </defs>
      </svg>
      <div class="c-quote-content__wrapper" ref="wrapperEl">
        <div :class="mainClasses" ref="mainEl">
          <Icon :svg="fields.icon.value?.svgCode" size="large" :id="iconId" class="c-quote-content__icon" />
          <heading class="c-quote-content__title" :field="fields.title" :type="fields.titleType" :id="titleId" rich />
          <p :class="lineClasses" :id="lineId" v-if="fields?.lineColor" />
          <jss-rich-text :field="fields.author" class="c-quote-content__author" tag="div" />
          <animated-content type="bottom-fade-in">
            <jss-rich-text :field="fields.body" class="c-quote-content__body" ref="bodyEl" :id="bodyId" tag="div" />
          </animated-content>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from 'vue';
import { loadScrollMagic, themeClass } from '@/utils/site-utils';
import gsap from 'gsap';
export default {
  name: 'QuoteContent',
  props: {
    fields: {
      type: Object
    },
    rendering: {
      type: Object
    }
  },
  setup(props) {
    let ScrollMagic, controller, scene, tl;
    const state = reactive({
      rootEl: null,
      wrapperEl: null,
      mainEl: null,
      svgAni1El: null,
      svgAni2El: null,
      bodyEl: null,
      iconId: `${props.rendering.uid}-icon`,
      lineId: `${props.rendering.uid}-line`,
      svgId: `${props.rendering.uid}-svg`,
      titleId: `${props.rendering.uid}-title`,
      bodyId: `${props.rendering.uid}-body`,
      rootVars: {
        '--svg-id': `url(#${props.rendering.uid}-svg)`
      }
    });
    const computes = {
      rootClasses: computed(() => ['c-quote-content', themeClass(props)]),
      mainClasses: computed(() => ['c-quote-content__main', `align-${props.fields?.align.fields?.phrase.value ?? 'center'}`]),
      lineClasses: computed(() => ['c-quote-content__line', props.fields?.lineColor.fields?.phrase.value ?? 'yellow'])
    };
    onMounted(() => {
      /**勿删*/
      // tl = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: state.rootEl,
      //     start: 'top 50%',
      //     end: 'bottom bottom+=50%',
      //     scrub: 5
      //   }
      // });
      // tl.to(
      //   state.svgAni1El,
      //   {
      //     attr: {
      //       offset: '100%'
      //     }
      //   },
      //   '1'
      // );
      // tl.to(
      //   state.svgAni2El,
      //   {
      //     attr: {
      //       offset: '100%'
      //     }
      //   },
      //   '1'
      // );
      // tl.to(
      //   document.getElementById(state.titleId),
      //   {
      //     '--offset': '100%',
      //     position: '+=1'
      //   },
      //   '+=1'
      // );
      // tl.to(
      //   document.getElementById(state.lineId),
      //   {
      //     '--offset': '100%'
      //   },
      //   '+=1'
      // );
      // tl.to(
      //   document.getElementById(state.bodyId),
      //   {
      //     translateY: 0,
      //     opacity: 1
      //   },
      //   '+=1'
      // );
      const titleEl = document.getElementById(state.titleId);
      const lineEl = document.getElementById(state.lineId);
      // const bodyEl = document.getElementById(state.bodyId);
      ScrollMagic = loadScrollMagic();
      controller = new ScrollMagic.Controller();
      tl = gsap.timeline();
      //eslint-disable prettier/prettier
      tl.to(
        state.svgAni1El,
        {
          attr: {
            offset: '150%'
          }
        },
        '1'
      );
      tl.to(
        state.svgAni2El,
        {
          attr: {
            offset: '100%'
          }
        },
        '1'
      );
      if (titleEl) {
        tl.to(
          titleEl,
          {
            '--offset': '100%'
          },
          '+=1'
        );
      }
      if (lineEl) {
        tl.to(
          lineEl,
          {
            '--offset': '100%'
          },
          '+=1'
        );
      }
      // if (bodyEl) {
      //   tl.to(
      //     bodyEl,
      //     {
      //       translateY: 0,
      //       opacity: 1
      //     },
      //     '+=1'
      //   );
      // }
      scene = new ScrollMagic.Scene({
        triggerElement: state.rootEl,
        triggerHook: 'onEnter',
        offset: '100%',
        duration: state.rootEl.offsetHeight
      })
        .setTween(tl)
        .addTo(controller);
    });
    return {
      ...toRefs(state),
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
.c-quote-content {
  $c: &;
  overflow: hidden;
  --t-color: #{$yellow};
  --f-color: #{$grey-light};
  &__wrapper {
    @include grid-container;
  }
  &__main {
    @include grid-block(2, 10);
    display: flex;
    flex-direction: column;
    align-items: center;
    &.align- {
      &left {
        align-items: flex-start;
      }
      &center {
        align-items: center;
      }
      &right {
        align-items: flex-end;
      }
    }
  }
  &__svg-ani {
    stop {
      &:nth-child(1) {
        stop-color: $yellow;
      }
      &:nth-child(2) {
        stop-color: $yellow;
      }
      &:nth-child(3) {
        stop-color: $grey-neutral;
      }
      &:nth-child(4) {
        stop-color: $grey-neutral;
      }
    }
  }
  &__icon {
    > svg {
      fill: var(--svg-id) !important;
    }
  }
  #{$c}__svg-ani {
    stop {
      &:nth-child(1) {
        stop-color: var(--t-color);
      }
      &:nth-child(2) {
        stop-color: var(--t-color);
      }
      &:nth-child(3) {
        stop-color: var(--f-color);
      }
      &:nth-child(4) {
        stop-color: var(--f-color);
      }
    }
  }
  &__title {
    --offset: 0%;
    margin-top: $space-40;
    @include h2;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(to right, var(--t-color) 0%, var(--t-color) var(--offset), var(--f-color) var(--offset), var(--f-color) 100%);
  }
  &__line {
    --line-color: #{$yellow};
    --offset: 0%;
    width: 45px;
    height: 6px;
    margin-top: $space-40;
    background-image: linear-gradient(to right, var(--line-color) 0%, var(--line-color) var(--offset), var(--f-color) var(--offset), var(--f-color) 100%);
    &.yellow {
      --line-color: #{$yellow};
    }
  }
  &__author {
    margin-top: $space-24;
    @include h7;
    &:empty {
      display: none;
    }
  }
  &__body {
    margin-top: $space-60;
    text-align: center;
    //opacity: 0;
    //transform: translateY(50%);
    @include h6;
  }
  &.theme- {
    &white {
      color: $black;
      --bg-color: #{$yellow};
    }
    &yellow-white,
    &white-yellow,
    &yellow {
      --t-color: #{$black};
      --f-color: #{$blue-sky};
      #{$c}__line {
        --line-color: #{$grey-neutral};
        &.yellow {
          --line-color: #{$grey-neutral};
        }
      }
    }
  }
  @include tablet-landscape {
    &__main {
      @include grid-block(3, 20);
    }
    &__line {
      width: 180px;
      height: 8px;
    }
  }
}
</style>
