<template>
  <overall-settings v-bind="$props" v-if="columns?.length > 0">
    <div class="c-table-list" ref="rootEl">
      <div class="c-table-list__column" v-for="(column, index) in columns" :key="index">
        <div class="c-table-list__block" v-for="block in column" :key="block.id">
          <jss-rich-text class="c-table-list__block-title" :field="block.fields.title" tag="div" />
          <div class="c-table-list__table">
            <div class="c-table-list__table-row" v-for="item in block.fields.items" :key="item.id">
              <jss-rich-text class="c-table-list__table-head" :class="[{ 'just-head': $isNullOrEmpty(item.fields.content.value) }]" :field="item.fields.title" tag="div" />
              <jss-rich-text class="c-table-list__table-cell" :field="item.fields.content" tag="div" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef TableListFields
 * @property {Array<TableListBlock>} leftBlocks
 * @property {Array<TableListBlock>} rightBlocks
 * */
/**
 * @typedef TableListBlock
 * @property {{
 *   title: SimpleField,
 *   items: Array<TableListBlockItem>
 * }} fields
 * */
/**
 * @typedef TableListBlockItem
 * @property {{
 *  title: SimpleField,
 *  content: SimpleField
 * }} fields
 * */
import { computed } from 'vue';

export default {
  name: 'TableList',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const computes = {
      columns: computed(() => [props.fields.leftBlocks, props.fields.rightBlocks])
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-table-list {
  $c: &;
  grid-template-columns: repeat(var(--mobile-columns), 1fr);
  padding: $space-16 grid-width-m(1);
  &__column {
    + #{$c}__column {
      margin-top: $space-40;
    }
  }
  &__block {
    &-title {
      @include h6;
      margin-bottom: $space-12;
    }
    + #{$c}__block {
      margin-top: $space-40;
    }
  }
  &__table {
  }
  &__table-row {
    display: flex;
    padding: $space-8 $space-12;
    &:nth-child(2n + 1) {
      background: white;
    }
    &:nth-child(2n + 2) {
      background: transparent;
    }
  }
  &__table-head {
    width: grid-width-m(2);
    flex-shrink: 0;
    &.just-head {
      width: 100%;
    }
  }
  &__table-cell {
    flex-grow: 1;
    margin-left: $space-16;
  }
  &.theme- {
    &neutral-grey {
      #{$c}__table-row {
        &:nth-child(2n + 2) {
          color: $white;
        }
      }
    }

    &night-blue {
      #{$c}__table-row {
        &:nth-child(2n + 2) {
          color: $yellow;
        }
      }
    }
    &black {
      #{$c}__table-row {
        &:nth-child(2n + 2) {
          background: $black;
          color: $white;
        }
      }
    }
  }
  @include tablet-landscape {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: grid-width(1);

    &__column {
      + #{$c}__column {
        margin-top: 0;
      }
    }
    &__table-head {
      width: grid-width(3);
      &.just-head {
        width: 100%;
      }
    }
  }
}
</style>
