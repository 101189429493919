<template>
  <OverallSettings v-bind="$props">
    <div class="c-simple-hero" v-if="fields">
      <div class="c-simple-hero__content" :class="rootClasses">
        <JssRichText class="c-simple-hero__overline" :field="fields.overline" />
        <JssRichText class="c-simple-hero__title" :field="fields.title" />
        <JssRichText class="c-simple-hero__body" :field="fields.body" />
        <div class="c-simple-hero__btns">
          <SiteButton v-for="btn in fields.buttons" :key="btn.id" v-bind="btn" />
        </div>
      </div>
    </div>
  </OverallSettings>
</template>

<script>
import { computed, inject } from 'vue';
import { settingValue } from '@/utils/site-utils';
export default {
  name: 'SimpleHero',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props, context) {
    const { deviceComputes } = inject('device-common');
    const computes = {
      rootClasses: computed(() => [
        `grid-layout-${deviceComputes.isMobileOrTablet.value ? '2-20' : settingValue(props.fields.gridLayout, '2-20')}`,
        `align-${settingValue(props.fields.align, 'left')}`
      ])
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-simple-hero {
  @include grid-container;
  &__content {
    @include grid-layout;
    &.align {
      &-left {
        text-align: start;
      }
      &-center {
        text-align: center;
      }
      &-right {
        text-align: end;
      }
    }
  }
  &__overline {
    margin-bottom: $space-60;
  }
  &__title {
    margin-bottom: $space-20;
  }
  &__body {
    margin-bottom: $space-20;
    font-size: 16px;
  }
  &__btns {
    .e-site-button + .e-site-button {
      margin-left: $space-20;
    }
  }

  @include tablet-landscape {
    &__overline {
      margin-bottom: $space-30;
    }
    &__title,
    &__body {
      margin-bottom: $space-40;
    }
  }
}
</style>
